import { useState } from "react";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import EditOrgForm from "./EditOrgForm";
import Confirm from "../Common/Confirm";

import Api from "../../../services/Api";
import { fetchOrganizations } from "../../../redux/Organizations/actions";

export default function EditOrgNameModal({
  currentOrg,
  onClose,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const formID = "edit-organization-name-form";

  const dispatch = useDispatch();

  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveChangesError, setSaveChangesError] = useState(null);
  const [firstTextConfirmation, setFirstTextConfirmation] = useState("");

  const defaultValues = {
    orgName: saveData?.name || currentOrg?.label,
  };

  const editOrgNameOnSubmit = async (dataMutation) => {
    await Api.updateOrganization(dataMutation);

    setFirstTextConfirmation("Your organization was updated successfully.");
    dispatch(fetchOrganizations());
    setConfirm(true);
    setConfirmModal(true);
  };

  const requestCloseHandle = (data) => {
    setSaveChangesError(null);
    setSaveData(data);
    setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (!saveData.name || isEqual(defaultValues.orgName, saveData.name))
      setSaveChangesError("orgName");
    else await editOrgNameOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <EditOrgForm
          id={formID}
          defaultValues={defaultValues}
          onSubmit={editOrgNameOnSubmit}
          requestedClose={requestedClose}
          organizationId={currentOrg?.id}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          buttonText="Go Back"
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

EditOrgNameModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  setOnRequestClose: PropTypes.func.isRequired,
  currentOrg: PropTypes.shape().isRequired,
};
