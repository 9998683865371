import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Confirm from "./Common/Confirm";

import { LoadingButton } from "../../core/buttons";
import "../../core/styles/markdown.css";

import Api from "../../services/Api";
import clearTextIcon from "../assets/clearTextIcon.svg";
import { interactionEventDataLayer } from "../../utils/DataLayers";

const MAX_MESSAGE_LENGTH = 250;

function MessageStudentModal({
  filters,
  onClose,
  students,
  studentNote,
  organizationId,
  requestedClose,
  setConfirmModal,
  messageStudents,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [student, setStudent] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const [contentLength, setContentLength] = useState(0);
  const [invalidLength, setInvalidLength] = useState(false);

  useEffect(() => {
    const markdownString = studentNote;
    const rawData = markdownToDraft(markdownString);
    const contentState = convertFromRaw(rawData);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setConfirmModal(true);

    if (messageStudents === 1) setStudent(students[0]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestedClose) setSave(true);
  }, [requestedClose]);

  const onEditorStateChange = (editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length;

    setEditorState(editorState);
    setContentLength(inputLength);
    setInvalidLength(inputLength > MAX_MESSAGE_LENGTH);

    if (inputLength > 0) setConfirmModal(false);
    else setConfirmModal(true);
  };

  const onSubmit = async () => {
    if (invalidLength || contentLength === 0) return;

    interactionEventDataLayer({ eventName: "send_notification_send_button" });

    const content = editorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject);

    setIsLoading(true);
    await Api.notifyStudents({
      organizationId,
      message: markdownString,
      students: {
        filter: students?.length > 0 ? null : filters,
        studentIds:
          students?.length > 0
            ? students.map((student) => student.studentId)
            : null,
      },
    });
    setIsLoading(false);
    setConfirm(true);
    setConfirmModal(true);
  };

  const saveChanges = async () => {
    setOnRequestClose(false);
    await onSubmit();
    onClose();
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const firstText = student
    ? `Sent! You've sent a message to ${student.firstName} ${student.lastName}`
    : `Sent! You've sent a message to ${messageStudents} students`;

  return (
    <>
      {!confirm && !save && (
        <>
          <div className="App px-1 h-full overflow-y-hidden">
            <div className="pt-5 pb-10 h-full">
              <p
                className={`${
                  invalidLength && "font-semibold text-primary-red"
                }`}
              >
                {contentLength}/{MAX_MESSAGE_LENGTH}
              </p>
              <div className="h-full border markdown-body border-black">
                <Editor
                  editorState={editorState}
                  id="notification-markdown-editor"
                  wrapperStyle={{ height: "100%", overflowY: "hidden" }}
                  editorStyle={{
                    height: "90%",
                    paddingLeft: "1rem",
                  }}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "link",
                      "remove",
                      "history",
                    ],
                    remove: {
                      icon: clearTextIcon,
                    },
                    blockType: {
                      inDropdown: false,
                      options: ["Normal", "Blockquote"],
                    },
                    inline: {
                      options: ["bold", "italic"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      options: ["link", "unlink"],
                      popupClassName: "popup",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row bottom-0">
            <LoadingButton
              isLoading={isLoading}
              disabled={invalidLength || contentLength === 0}
              className="
                w-1/2
                ml-auto
                text-white
                bg-primary-blue
                border-primary-blue
                hover:bg-white
                active:bg-white
                hover:text-primary-blue
                active:text-primary-blue
                mobile:w-full
                mobile:text-sm
                mobile-sm:w-full
                mobile-sm:text-sm
                tablet-sm:text-sm
              "
              onClick={onSubmit}
              id="notification-send-button"
            >
              send
            </LoadingButton>
          </div>
        </>
      )}
      {confirm && !save && (
        <Confirm buttonText="Back" onClick={onClose} firstText={firstText} />
      )}
      {save && (
        <Confirm
          secondButton={true}
          onClick={saveChanges}
          isLoading={isLoading}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </>
  );
}

MessageStudentModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  studentNote: PropTypes.string,
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  setOnRequestClose: PropTypes.func,
  messageStudents: PropTypes.number,
  students: PropTypes.arrayOf(PropTypes.shape()),
};

export default MessageStudentModal;
