import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import AddFinancialAidForm from "./AddFinancialAidForm";

import FinancialAidList from "./FinancialAidList";

function AddAdditionalFinancialAidModal({
  onClose,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const formID = "add-additional-financial-aid-form";

  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [defaultValues, setDefaultValues] = useState(null);
  const [saveChangesError, setSaveChangesError] = useState(null);

  const addFinancialAidOnSubmit = async (form) => {
    setConfirmData(form);
    setConfirm(true);
  };

  const saveChanges = async () => {
    const errors = [];
    setIsLoading(true);
    if (saveData.financialAids.length === 0) {
      if (saveData.name === "") errors.push("name");
      if (saveData.type === null) errors.push("type");
      if (saveData.amount === 0) errors.push("amount");
    }

    if (errors.length > 0) setSaveChangesError(errors);
    else {
      if (
        saveData.name &&
        saveData.amount &&
        saveData.type &&
        saveData.name !== "" &&
        saveData.amount !== 0 &&
        saveData.type !== null
      ) {
        await addFinancialAidOnSubmit(
          saveData.financialAids.concat([
            {
              added: true,
              type: saveData.type,
              name: saveData.name,
              amount: saveData.amount,
              id: `${saveData.type.id}-${saveData.name}-${saveData.amount}`.toLowerCase(),
            },
          ])
        );
      } else await addFinancialAidOnSubmit(saveData.financialAids);
      setSaveData({});
    }

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const requestCloseHandle = (form) => {
    setSaveChangesError(null);
    setSaveData(form);
    setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const handlerOnClose = () => onClose(confirmData);

  const handlerOnAddOther = () => {
    setSaveData({ financialAids: confirmData });
    setConfirm(false);
  };

  const handlerOnRemoveConfirmData = (removeFinancialAid) =>
    setConfirmData((confirmData) => {
      const computedData = confirmData.filter(
        (data) => data.id !== removeFinancialAid.id
      );

      if (computedData.length === 0) setConfirm(false);

      return computedData;
    });

  const setConfirmSaveData = useCallback(() => {
    if (confirm) requestCloseHandle({ financialAids: confirmData });
  }, [confirm, confirmData]);

  useEffect(() => {
    if (requestedClose) setConfirmSaveData();
  }, [setConfirmSaveData, requestedClose]);

  useEffect(() => {
    setDefaultValues({
      name: saveData?.name || "",
      type: saveData?.type || null,
      amount: saveData?.amount || "",
      financialAids: saveData?.financialAids || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  return (
    <div className="flex pb-6 h-full flex-col overflow-hidden">
      {!confirm && !save && defaultValues && (
        <AddFinancialAidForm
          id={formID}
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          setConfirmModal={setConfirmModal}
          onSubmit={addFinancialAidOnSubmit}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}

      {confirm && !save && (
        <Confirm
          buttonText="Continue"
          secondButton={true}
          onClick={handlerOnClose}
          secondOnClick={handlerOnAddOther}
          secondButtonText="Add Another Award?"
          firstText={
            <FinancialAidList
              confirm={true}
              financialAids={confirmData}
              onRemoveFinancialAid={handlerOnRemoveConfirmData}
            />
          }
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

AddAdditionalFinancialAidModal.propTypes = {
  requestedClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  setOnRequestClose: PropTypes.func.isRequired,
};

export default AddAdditionalFinancialAidModal;
