import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import AdvisorProfile from "./AdvisorProfile";
import NavigationMenu from "./NavigationMenu";

import { Notifications } from "../../core/compose";
import { Banner, SideMenu, Dropdown, LinkCustom } from "../../core";
import {
  NotificationStatus,
  getNotificationList
} from "../../core/compose/Notifications/utils";
import { Logo, Profile, Notification, HamburgerMenu } from "../../core/icons";

import { Colors, decidedRoute, decidedRouteSupport } from "../../core/utils";
import { waitForTimeout, useIsDesktopMQL } from "../../core/hooks";

import { useAdvisor } from "../../redux/Advisor/hooks";
import { saveState, Items } from "../../redux/storePersist";
import { usePermissions } from "../../redux/Permissions/hooks";
import { useOrganizations } from "../../redux/Organizations/hooks";
import {
  setLists,
  fetchOrganizations,
  setSelectedOrganizationId
} from "../../redux/Organizations/actions";
import {
  fetchNotifications,
  markNotificationAsRead,
  resetNotificationsState
} from "../../redux/Notifications/actions";
import {
  useNotifications,
  useRefetchNotifications
} from "../../redux/Notifications/hooks";

import Api from "../../services/Api";
import { fetchAdvisor } from "../../redux/Advisor/actions";
import { interactionEventDataLayer } from "../../utils/DataLayers";

function SimpleHeader({ logoWhite = true }) {
  return (
    <div
      className={`
        px-8
        py-4
        z-20
        flex
        top-0
        flex-1
        sticky
        flex-row
        items-center
        justify-between
        bg-primary-blue
      `}
    >
      <div className="flex flex-col items-center justify-center">
        {logoWhite ? <Logo size={40} color="fill-white" /> : <Logo size={40} />}
      </div>
    </div>
  );
}

SimpleHeader.propTypes = {
  logoWhite: PropTypes.bool
};

function Header({ isSimple }) {
  const navigate = useNavigate();
  const isDesktop = useIsDesktopMQL();
  const advisorState = useAdvisor();

  const { permissions } = usePermissions();

  const [data, setData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [doFetch, setDoFetch] = useState(false);
  const [currentOrg, setCurrentOrg] = useState({});
  const [resetData, setResetData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);
  const [openUserNotifications, setOpenUserNotifications] = useState(false);
  const [newNotificationsCounter, setNewNotificationsCounter] = useState(0);

  useRefetchNotifications({});
  const dispatch = useDispatch();
  const notificationsState = useNotifications();
  const { selectedOrganizationId, pages } = useOrganizations();

  const toggleAdvisorProfile = useCallback(() => {
    setOpenUserProfile(!openUserProfile);
  }, [openUserProfile]);

  const toggleNotificationsDrawer = useCallback(() => {
    setOpenUserNotifications(!openUserNotifications);
  }, [openUserNotifications]);

  const handleNavigationMenuModalClick = useCallback(() => {
    setOpenNavigationMenu(!openNavigationMenu);
  }, [openNavigationMenu]);

  const closeNavigationMenuModal = useCallback(() => {
    setOpenNavigationMenu(false);
  }, []);

  const handleUserNotificationsMarkAll = async () => {
    setMarkAllAsRead(true);

    dispatch(resetNotificationsState());

    await Api.readNotifications({ notificationIds: null });

    setData(() => {
      setDoFetch(() => {
        setCurrentPage(1);

        return true;
      });

      return [];
    });
  };

  const handleUserNotificationMark = async (notificationId, status, close) => {
    if (status === NotificationStatus.NEW) {
      setMarkAsRead(true);
      await Api.readNotifications({ notificationIds: [notificationId] });
      dispatch(markNotificationAsRead({ notificationId }));
      setData((localData) =>
        localData.map((notification) => {
          if (notification.id === notificationId)
            return {
              ...notification,
              status: NotificationStatus.READ
            };

          return notification;
        })
      );
    }

    if (close) setOpenUserNotifications(false);
  };

  const callbackLetterReviewed = (content) => {
    handleOnSelectOrg({ id: content.organizationId });
    navigate(
      `/dashboard/students/${content.organizationStudentId}/${content.schoolId}/upload-award/view-award-letter`
    );
    setOpenUserNotifications(false);
  };

  const handlePagination = (isIntersecting) => {
    const getNotifications = () => {
      setIsLoadingPage(true);
      const newData = getNotificationList(
        notificationsState.pages[currentPage - 1],
        {
          letterReviewed: callbackLetterReviewed,
          markAsRead: handleUserNotificationMark
        }
      );
      setData((data) => data.concat(newData));

      setCurrentPage(currentPage + 1);
      setIsLoadingPage(false);
      if (currentPage + 1 <= notificationsState.pageCount) setDoFetch(true);
    };

    if (
      openUserNotifications &&
      isIntersecting &&
      !resetData &&
      !notificationsState.isLoading &&
      Object.is(notificationsState.error, null)
    ) {
      if (currentPage <= notificationsState.pageCount)
        if (!isLoadingPage) getNotifications();
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    const getNotifications = () => {
      dispatch(
        fetchNotifications({ page: { pageNumber: currentPage, pageSize: 15 } })
      );

      if (isSubscribed) setDoFetch(false);
    };

    if (doFetch) getNotifications();
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doFetch]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed)
      setNewNotificationsCounter(notificationsState.newItemsCount);

    return () => (isSubscribed = false);
  }, [notificationsState.newItemsCount]);

  useEffect(() => {
    let isSubscribed = true;
    const resetDataCallback = async () => {
      if (isSubscribed) {
        setResetData(true);
        setCurrentPage(1);
      }

      await waitForTimeout(100);

      if (isSubscribed) {
        setData([]);
        setResetData(false);
      }
    };

    if (!markAsRead && !markAllAsRead) resetDataCallback();
    else if (markAsRead && isSubscribed) setMarkAsRead(false);
    else if (markAllAsRead && isSubscribed) setMarkAllAsRead(false);

    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotificationsCounter]);

  useEffect(() => {
    let isSubscribed = true;
    if (selectedOrganizationId && pages.length > 0) {
      const list = pages[0].map((org) => {
        return {
          enabled: true,
          role: org.role,
          id: org.organization.id,
          label: org.organization.name,
          code: org.organization.code
        };
      });
      const current = list.find((org) => org.id === selectedOrganizationId);
      if (isSubscribed) {
        setCurrentOrg(current);
        setOrgList(list);
      }
    } else {
      if (isSubscribed) {
        setCurrentOrg({});
        setOrgList([]);
      }
    }

    return () => (isSubscribed = false);
  }, [selectedOrganizationId, pages]);

  const handleOnSelectOrg = (data) => {
    navigate("/dashboard");
    setCurrentOrg(data);
    dispatch(setLists({ studentLists: [], advisorLists: [] }));
    dispatch(setSelectedOrganizationId(data.id));
    dispatch(fetchAdvisor({ organizationId: data.id }));
    dispatch(fetchOrganizations());
    saveState({ internalState: {} }, Items.APP_STATE);
    interactionEventDataLayer({
      eventName: "switch_your_org",
      params: {
        organization_id: data.id
      }
    });
  };

  const handleOnSelectOption = (data) => data.handlerOnClick();

  const getInviteActions = useCallback(() => {
    const dropdownOptions = [
      {
        id: "students",
        enabled: permissions?.students.canAccess,
        handlerOnClick: () => navigate("/dashboard/invites/students"),
        label: <div className="text-center text-primary-blue">Students</div>
      },
      {
        id: "advisors",
        enabled: permissions?.advisors.canAccess,
        handlerOnClick: () => navigate("/dashboard/invites/advisors"),
        label: <div className="text-center text-primary-blue">Advisors</div>
      }
    ];

    return dropdownOptions.filter(
      (action) => permissions && permissions[action.id].canAccess
    );
  }, [navigate, permissions]);

  return (
    <>
      <header
        className="
          py-1
          px-4  
          z-30
          flex
          top-0
          flex-1
          sticky
          flex-row
          shadow-md
          bg-white
          items-center
          justify-center
          mobile:py-6
          mobile-sm:py-6
          tablet-sm:py-6
        "
      >
        <div
          className="
            flex
            flex-1
            flex-row
            items-center
            justify-center
            tablet:hidden
            laptop:hidden
            desktop:hidden
          "
        >
          <HamburgerMenu
            size={30}
            className="cursor-pointer"
            onClick={handleNavigationMenuModalClick}
          />
          <h1
            className="
              mx-4
              flex
              flex-1
              text-2xl
              font-black
              items-center
              justify-start
            "
          >
            DecidED.
          </h1>
        </div>

        <div
          className="
            flex
            flex-1
            flex-row
            mobile:hidden
            mobile-sm:hidden
            tablet-sm:hidden
          "
        >
          <LinkCustom
            target="_blank"
            href={decidedRoute("/")}
            id="header-button-decided"
            className="py-2 px-5 font-extrabold"
          >
            <div className="flex flex-col items-center justify-center">
              <Logo size={40} />
              <span className="font-black">DecidED.</span>
            </div>
          </LinkCustom>
          <div className="flex flex-1 flex-row justify-end items-center">
            <LinkCustom
              target="_blank"
              id="header-button-guides"
              href={decidedRoute("/guides")}
              className="
                py-2
                px-5
                font-extrabold
                hover:text-primary-blue
              "
            >
              Guides
            </LinkCustom>
            <LinkCustom
              target="_blank"
              id="header-button-help"
              href={decidedRouteSupport()}
              className="
                py-2
                px-5
                font-extrabold
                hover:text-primary-blue
              "
            >
              Help
            </LinkCustom>
            {!isSimple && (
              <div className="-my-2 ml-2 mr-4 font-bold">
                <Dropdown
                  color={Colors.BLUE}
                  id="invites-dropdown"
                  defaultLabel="Invite +"
                  options={getInviteActions()}
                  handleOnSelect={handleOnSelectOption}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-end items-center">
          {!isSimple && (
            <Notification
              size={isDesktop ? 38 : 33}
              className="mx-2 cursor-pointer"
              id="button-header-notifications"
              onClick={toggleNotificationsDrawer}
              notifications={newNotificationsCounter}
            />
          )}
          <Profile
            size={isDesktop ? 42 : 37}
            id="button-header-profile"
            boxColor="fill-primary-blue"
            onClick={toggleAdvisorProfile}
            className="mx-2 cursor-pointer"
          />
        </div>
      </header>

      <SideMenu open={openNavigationMenu} direction="ltr">
        <NavigationMenu
          open={openNavigationMenu}
          onClose={closeNavigationMenuModal}
        />
      </SideMenu>

      <SideMenu open={openUserProfile} direction="rtl">
        <AdvisorProfile
          orgList={orgList}
          currentOrg={currentOrg}
          isOpen={openUserProfile}
          advisorState={advisorState}
          onClose={toggleAdvisorProfile}
          handleOnSelectOrg={handleOnSelectOrg}
          organizationId={selectedOrganizationId}
        />
      </SideMenu>

      <SideMenu direction="rtl" open={openUserNotifications}>
        <Notifications
          color={Colors.BLUE}
          notifications={data}
          open={openUserNotifications}
          onClose={toggleNotificationsDrawer}
          handlePagination={handlePagination}
          newNotifications={newNotificationsCounter}
          onMarkAll={handleUserNotificationsMarkAll}
          totalNotifications={notificationsState.itemsCount}
          isLoading={
            (markAllAsRead || notificationsState.isLoading) &&
            openUserNotifications
          }
        />
      </SideMenu>
      <Banner color={Colors.BLUE} content={advisorState.profile?.banner} />
    </>
  );
}

export { SimpleHeader };
export default Header;
