import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import { getChartColors } from "./utils";

function GaugeChart({ percent, affordability }) {
  const divSvgRef = useRef();
  const constainerRef = useRef();

  const [radius, setRadius] = useState(0);

  useEffect(() => {
    const radiansPercent = Math.min(Math.max(0, percent || 0), 100) / 100;

    const strokeWidth = 1.5;
    const svgSize = radius * 2;
    const outerRadiusFactor = 0.9;
    const offsetHeightSize = 0.46;
    const innerRadiusFactor = 0.45;

    const outerRadius = radius * outerRadiusFactor;
    const innerRadius = radius * innerRadiusFactor;

    const needleHeight = innerRadius * 1.2;
    const needleRadius = innerRadius * 0.4;

    const xGauge = radius;
    const yGauge = radius * 0.915;

    const divSvg = d3.select(divSvgRef.current);
    if (svgSize > 0) {
      const gaugeColor = getChartColors(affordability);

      const innerCircle = d3
        .arc()
        .startAngle(0)
        .innerRadius(0)
        .endAngle(Math.PI * 2)
        .outerRadius((outerRadius - innerRadius) / 2 - strokeWidth);

      const gaugeContainer = d3
        .arc()
        .startAngle(0)
        .endAngle(Math.PI)
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

      const gaugeContent = d3
        .arc()
        .startAngle(0)
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .endAngle(Math.PI * radiansPercent);

      const svg = divSvg
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${svgSize} ${svgSize * offsetHeightSize}`);

      const group = svg.append("g");

      const circleX =
        xGauge -
        (Math.cos(Math.PI * radiansPercent) * (outerRadius + innerRadius)) / 2;
      const circleY =
        yGauge -
        (Math.sin(Math.PI * radiansPercent) * (outerRadius + innerRadius)) / 2;

      group
        .append("path")
        .attr("d", gaugeContainer)
        .attr("id", "container-affordability")
        .attr("transform", `translate(${xGauge}, ${yGauge}) rotate(-90)`)
        .attr("class", `fill-current stroke-current ${gaugeColor.secondary}`)
        .style("stroke-width", `${strokeWidth}px`);

      group
        .append("path")
        .attr("d", innerCircle)
        .attr("transform", `translate(${circleX}, ${circleY})`)
        .attr("class", `fill-current stroke-current ${gaugeColor.primary}`)
        .style("stroke-width", `${strokeWidth}px`);

      group
        .append("path")
        .attr("d", gaugeContent)
        .attr("transform", `translate(${xGauge}, ${yGauge}) rotate(-90)`)
        .attr("class", `fill-current stroke-current ${gaugeColor.primary}`)
        .style("stroke-width", `${strokeWidth}px`);

      group
        .append("path")
        .attr("d", gaugeContainer)
        .attr("transform", `translate(${xGauge}, ${yGauge}) rotate(-90)`)
        .style("stroke-width", `${strokeWidth}px`)
        .style("fill", "transparent")
        .style("stroke", "#000000");

      const needleBase = d3
        .arc()
        .startAngle(0)
        .innerRadius(0)
        .endAngle(Math.PI * 2)
        .outerRadius(needleRadius);

      const needleAngle = -90 * (1 - radiansPercent) + 90 * radiansPercent;
      const needleInnerAngle =
        Math.PI / 2 - Math.asin(needleRadius / needleHeight);
      const needleYOffset = Math.cos(needleInnerAngle) * needleRadius;
      const needleHalfBase = Math.sin(needleInnerAngle) * needleRadius;

      const yGout = yGauge - needleRadius;
      group
        .append("path")
        .attr("d", needleBase)
        .attr("transform", `translate(${xGauge}, ${yGout})`)
        .style("stroke-width", `${strokeWidth * 2}px`)
        .style("fill", "transparent")
        .style("stroke", "#000000");

      group
        .append("path")
        .attr(
          "transform",
          `translate(${xGauge}, ${yGout}) rotate(${needleAngle})`
        )
        .attr(
          "d",
          `M ${-needleHalfBase} ${-needleYOffset}` +
            `L 0 ${-needleHeight} L ${needleHalfBase} ${-needleYOffset} Z`
        )
        .attr("class", `fill-current ${gaugeColor.alternative}`)
        .style("stroke-width", `${strokeWidth}px`)
        .style("stroke", "#000000");

      group
        .append("path")
        .attr("d", needleBase)
        .attr("transform", `translate(${xGauge}, ${yGout})`)
        .attr("class", `fill-current ${gaugeColor.alternative}`);
    }

    return () => divSvg.selectAll("*").remove();
  }, [percent, affordability, radius]);

  useEffect(() => {
    getRadius();

    window.addEventListener("resize", getRadius);
    return () => window.removeEventListener("resize", getRadius);
  }, []);

  const getRadius = () => {
    if (constainerRef.current) {
      const node = constainerRef.current;
      setRadius(node.getBoundingClientRect().width / 2);
    }
  };

  return (
    <div ref={constainerRef}>
      <div ref={divSvgRef} />
    </div>
  );
}

GaugeChart.propTypes = {
  percent: PropTypes.number,
  affordability: PropTypes.string,
};

export default GaugeChart;
