import PropTypes from "prop-types";

function Category({ name, border }) {
  return (
    <div
      className={`
        p-2
        w-full
        text-white
        bg-primary-blue
        ${border && "border-b border-black"}
      `}
    >
      <p className="font-semibold">{name}</p>
    </div>
  );
}

Category.propTypes = {
  border: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default Category;
