import PropTypes from "prop-types";

function Copy({ size = 25, color = "fill-black" }) {
  return (
    <svg
      width={size}
      height={size}
      className={color}
      viewBox="0 0 579.8 579.8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="96.6,128.8 96.6,96.6 0,96.6 0,579.8 483.2,579.8 483.2,483.2 450.9,483.2 450.9,547.6 32.2,547.6 32.2,128.8 " />
      <path
        d="M579.8,477.8V5.3c0-3-3-5.3-6.6-5.3L103.3,0c-3.7,0-6.7,2.4-6.7,5.3v472.5c0,3,3,5.3,6.6,5.3l469.9,0
        C576.8,483.2,579.8,480.8,579.8,477.8z M547.6,450.9l-418.7,0l0-418.7h418.7V450.9z"
      />
    </svg>
  );
}

Copy.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Copy;
