import PropTypes from "prop-types";

import { Earnings } from "../";
import { Panel } from "../../compose";
import Description from "../Earnings/Description";
import { getTheme } from "../../utils";

function EarningsCard({
  id,
  open,
  chosenSchool,
  externalToggle,
  callbackToggle,
}) {
  const { award, school } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });

  return (
    <Panel
      id={id}
      title="earnings"
      initiallyOpened={open}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      className="p-4 mb-2 bg-white shadow-lg"
      titleClassName="capitalize font-semibold"
      expandableContent={
        <>
          <Earnings earnings={school.earnings} />
          <Description color={theme.color} />
        </>
      }
    />
  );
}

EarningsCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default EarningsCard;
