import { useMemo } from "react";
import PropTypes from "prop-types";

function NoResults({ trigger, tableSelector }) {
  const top = useMemo(() => {
    function getCoords(elem) {
      if (!elem) return null;
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    }

    const element = document.getElementById(tableSelector);
    const coordinates = getCoords(element);

    if (coordinates) return coordinates.top;
    else return window.innerHeight * 0.275;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, tableSelector]);

  return (
    <div
      style={{ top: `${top}px` }}
      className="
        flex
        absolute
        flex-col
        inset-x-0
        font-bold
        items-center
      "
    >
      <div className="text-3xl">No results found</div>
      <div className="text-sm">Try refining your search to get results</div>
    </div>
  );
}

NoResults.propTypes = {
  tableSelector: PropTypes.string,
};

export default NoResults;
