export const SortingType = {
  NAME: "name",
  ROLE: "role",
  ITEMS_COUNT: "itemsCount",
  LAST_ACTIVITY: "lastLogin",
};

export const AdvisorRoles = {
  ADMIN: "ADMIN",
  ADVISOR: "ADVISOR",
};

export const ModalTypes = {
  EDIT_LIST: "EDIT_ADVISOR_LIST",
  INVITE_ADVISOR: "INVITE_ADVISOR",
  DELETE_ADVISOR: "DELETE_ADVISOR",
  CREATE_LIST: "CREATE_ADVISOR_LIST",
  ADD_TO_LIST: "ADD_ADVISOR_TO_LIST",
  DELETE_LIST: "DELETE_ADVISOR_LIST",
  ARCHIVE_LIST: "ARCHIVE_ADVISOR_LIST",
  DELETE_INVITE: "DELETE_ADVISOR_INVITE",
  UNARCHIVE_LIST: "UNARCHIVE_ADVISOR_LIST",
  RE_SEND_INVITE: "RE_SEND_ADVISOR_INVITE",
  EDIT_ACCESS_ADVISOR: "EDIT_ACCESS_ADVISOR",
  REMOVE_ADVISOR_FROM_LIST: "REMOVE_ADVISOR_FROM_LIST",
};

export const GetRole = (role) => {
  switch (role) {
    case AdvisorRoles.ADMIN:
      return "Admin";
    case AdvisorRoles.ADVISOR:
      return "Advisor";
    default:
      return "No role";
  }
};

export const GetAccess = () => {
  return [
    {
      id: 1,
      name: "Admin (they will be able to see, create and share any student or advisor lists. They can edit access of other advisors and fellow admins.) ",
      role: AdvisorRoles.ADMIN,
    },
    {
      id: 2,
      name: "Advisor (they will be able to create and see any student lists that fellow student admins share with them, however they will not see a list of fellow advisors.) ",
      role: AdvisorRoles.ADVISOR,
    },
  ];
};
