export const Order = {
  asc: "ASC",
  desc: "DESC",
};

export function toggleOrder(order) {
  switch (order) {
    case Order.asc:
      return Order.desc;
    case Order.desc:
    default:
      return Order.asc;
  }
}
