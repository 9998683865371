import { useEffect, useState } from "react";

export function useIsDesktopMQL() {
  const mql = window.matchMedia("(min-width: 768px)");

  const [isDesktop, setIsDesktop] = useState(mql.matches);

  function listener(mql) {
    setIsDesktop(mql.matches);
  }

  useEffect(() => {
    mql.addListener(listener);

    return function clean() {
      mql.removeListener(listener);
    };
  });

  return isDesktop;
}
