import { useParams, useNavigate, Route } from "react-router-dom";

import CustomSwitch from "../../../Common/CustomSwitch";
import PrivateRoute from "../../../Common/PrivateRoute";

import Hmm from "./Hmm";
import Success from "./Success";
import UploadImages from "./UploadImages";
import DontHaveMyAward from "./DontHaveAward";
import HowDoesThisLook from "./HowDoesThisLook";
import ReportErrorSuccess from "./ReportAnError/ReportErrorSuccess";
import ReportAnError from "./ReportAnError";
import ViewAwardLetterScreen from "./ViewAwardLetter";

function UploadLetterRoutes() {
  const { studentId, collegeId } = useParams();
  const navigate = useNavigate();

  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <UploadImages />
          </PrivateRoute>
        }
      />
      <Route
        path="/dont-have-award"
        element={
          <PrivateRoute>
            <DontHaveMyAward 
              onBack={() => navigate(-1)} 
              onBackToHome={() => navigate(`/dashboard/students/${studentId}`)} 
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/success"
        element={
          <PrivateRoute>
            <Success 
            timeout={5000} 
            onTimeout={() => navigate(`/dashboard/students/${studentId}/${collegeId}/upload-award/how-does-this-look`)} 
          />
          </PrivateRoute>
        }
      />
      <Route
        path="/hmm"
        element={
          <PrivateRoute>
            <Hmm 
              timeout={10000} 
              onTimeout={() => navigate(`/dashboard/students/${studentId}`)} 
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/view-award-letter"
        element={
          <PrivateRoute>
            <ViewAwardLetterScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/report-error"
        element={
          <PrivateRoute>
            <ReportAnError 
              onBack={() => navigate(-1)} 
              onNext={() => navigate(`/dashboard/students/${studentId}/${collegeId}/upload-award`)} 
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/report-error/success"
        element={
          <PrivateRoute>
            <ReportErrorSuccess 
              onBack={() => navigate(`/dashboard/students/${studentId}`)} 
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/how-does-this-look"
        element={
          <PrivateRoute>
            <HowDoesThisLook 
              onYes={() => navigate(`/dashboard/students/${studentId}`)} 
              onNo={() => navigate(`/dashboard/students/${studentId}/${collegeId}/upload-award/report-error`)} 
              onView={() => navigate(`/dashboard/students/${studentId}/${collegeId}/upload-award/view-award-letter`)} 
            />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

export default UploadLetterRoutes;
