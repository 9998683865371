import PropTypes from "prop-types";
import { useRef, useState } from "react";

import { FloatBox } from "../../../../../core";
import { Filter } from "../../../../../core/icons";

import AdvisorFilter from "../../../Common/AdvisorFilter";

import { useAdvisorInvites } from "../../../../../redux/AdvisorInvites/hooks";

function SenderHeader({ advisors, onFilter }) {
  const invitesState = useAdvisorInvites();

  const containerRef = useRef(null);
  const [show, setShow] = useState(false);

  const outSideClick = (state) => setShow(state);

  return (
    <div
      role="button"
      ref={containerRef}
      onClick={() => setShow(!show)}
      id="students-affordability-options-filter-button"
      className={`
        flex
        flex-row
        items-center
        justify-center
        ${
          invitesState.isLoading ? "pointer-events-none" : "pointer-events-auto"
        }
      `}
    >
      <div className="relative place-self-center">
        &nbsp;
        <FloatBox
          open={show}
          width={250}
          containerRef={containerRef}
          handleOnClickOutside={outSideClick}
          className="
            p-4
            mt-2
            -ml-32
            bg-white
            border-black
          "
        >
          <AdvisorFilter
            advisors={advisors}
            onSelect={onFilter}
            id="students-advisors-filter-item-button"
          />
        </FloatBox>
      </div>
      <div className="mr-2 place-self-center">
        <Filter size={15} />
      </div>
      <span className="text-sm">Sender</span>
    </div>
  );
}

SenderHeader.protoTypes = {
  onFilter: PropTypes.func,
  advisors: PropTypes.arrayOf(PropTypes.shape()),
};

SenderHeader.defaultProp = {
  onFilter: () => {},
};

export default SenderHeader;
