import PropTypes from "prop-types";

import Table from "../../../Common/Table";

function AdvisorInvitesTableBody({
  data,
  columns,
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  handlerOnSelectInvitations,
}) {
  return (
    <>
      <Table
        data={data}
        columns={columns}
        pageSize={pageSize}
        itemsCount={itemsCount}
        currentPage={currentPage}
        addSelectionColumn={true}
        onPageChange={onPageChange}
        tableSelector="viewport-advisor-invites-table"
        handlerOnSelectRows={handlerOnSelectInvitations}
        propsBody={{ className: "min-h-15rem h-screen-footer-xl" }}
      />
    </>
  );
}

AdvisorInvitesTableBody.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handlerOnSelectInvitations: PropTypes.func.isRequired,
};

export default AdvisorInvitesTableBody;
