import React from "react";
import PropTypes from "prop-types";

function RocksPattern({
  className = "",
  width = 250,
  height = 200,
  color = "fill-primary-green"
}) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 450 311"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M224.558 125.957L223.391 124.318L214.788 112.226L214.416 111.681C209.003 103.539 200.449 98.7693 189.667 97.8757L159.153 95.3296L170.673 111.298L199.673 113.71C209.046 114.49 216.743 118.359 221.926 124.909L222.044 125.071L222.249 125.346C222.603 125.81 222.932 126.287 223.255 126.764L223.36 126.918L223.447 127.046C223.522 127.16 223.59 127.281 223.671 127.395C223.686 127.429 223.704 127.46 223.727 127.489L223.925 127.798L224.391 128.591C224.602 128.954 224.8 129.323 225.011 129.699L225.092 129.861C225.278 130.223 225.458 130.593 225.632 130.969C225.653 131.023 225.678 131.074 225.707 131.124C225.924 131.607 226.129 132.098 226.327 132.595C226.785 133.738 227.181 134.909 227.513 136.102L227.606 136.411C227.705 136.767 227.792 137.123 227.879 137.479C227.916 137.64 227.96 137.808 227.997 137.976C228.078 138.312 228.146 138.648 228.214 139.004C228.258 139.192 228.289 139.387 228.32 139.582C228.382 139.918 228.444 140.253 228.5 140.589C228.537 140.798 228.562 141.013 228.593 141.228C228.674 141.778 228.742 142.329 228.798 142.894C228.835 143.189 228.866 143.485 228.891 143.787C228.916 144.089 228.928 144.338 228.947 144.62C228.965 144.902 228.99 145.231 229.003 145.554C229.015 145.876 229.003 146.098 229.003 146.374C229.003 146.649 229.003 147.045 229.003 147.361C229.003 147.677 229.003 147.892 229.003 148.167C229.003 148.443 229.003 148.893 229.003 149.269C229.003 149.504 229.003 149.746 229.003 149.995C229.003 150.505 228.959 151.029 228.928 151.547C228.928 151.641 228.928 151.728 228.928 151.822C228.152 162.819 224.502 171.573 218.376 177.142C212.249 182.712 203.857 185.11 193.832 184.277L164.696 181.859L164.739 181.187L169.618 112.42L158.122 96.4851L153.014 168.563L163.318 183.061L163.547 183.343L193.721 185.849C215.049 187.623 228.754 174.939 230.387 151.929C231.088 142.047 229.226 133.374 224.993 126.622C224.949 126.555 224.558 125.957 224.558 125.957Z"
      />
      <path
        className={color}
        d="M135.099 197.015L115.596 196.438L115.379 196.686C115.317 196.767 92.952 222.833 90.6367 225.527L111.654 225.937L135.565 198.722L170.133 207.751L180.189 244.66L155.682 272.54L121.114 263.511L111.294 227.468L90.8105 227.072L100.804 264.317H100.885L136.806 273.561L156.135 274.233L181.771 245.063L171.219 206.447L135.099 197.015Z"
      />
      <path
        className={color}
        d="M318.995 31.5817L311.23 12.2L310.932 12.0925L276.32 0L284.861 20.7857L317.76 32.7507L324.799 70.4727L298.139 95.9342L264.433 83.6737L257.401 45.9786L283.434 21.1217L275.122 0.880067L248.127 26.4088L248.158 26.4894L255.607 65.6155L263.204 84.8695L298.461 97.6944L326.357 71.0572L318.995 31.5817Z"
      />
      <path
        className={color}
        d="M328.489 292.143L342.952 306.305H343.269L379.395 301.817L363.684 286.688L329.159 290.517L308.328 259.325L322.872 224.21L358.253 220.286L379.084 251.472L364.882 285.734L380.189 300.44L395 265.103L394.944 265.049L373.219 232.762L358.942 218.654L321.941 222.765L306.727 259.5L328.489 292.143Z"
      />
      <path
        className={color}
        d="M61.0792 236.517L61.0854 236.53V236.524L55.0768 217.955L12.4269 214.636L19.7825 236.262L60.0612 239.151L68.2113 308.723L8.46046 281.575L18.6651 237.122L11.2661 215.355L0 263.779L5.84724 281.931V281.938H5.85338L69.8065 311L61.0792 236.517Z"
      />
      <path
        className={color}
        d="M311.304 142.974V142.981L309.038 123.451L267.99 110.499L270.976 133.32L309.796 145.312L304.234 215.187L251.1 175.04L269.722 133.905L266.717 110.936L246.283 155.712L248.474 174.791V174.798H248.481L305.351 217.773L311.304 142.974Z"
      />
      <path
        className={color}
        d="M213.734 207.145L213.256 207.374H213.212L199.662 217.525L217.992 262.314L218.11 262.597L232.734 251.404L214.833 208.046C222.862 204.37 231.707 203.306 240.286 204.984C248.866 206.663 256.807 211.011 263.139 217.497C269.471 223.983 273.919 232.326 275.94 241.505C277.96 250.684 277.465 260.301 274.515 269.18L233.641 252.331L219.122 263.443L261.381 280.91L261.679 281.031L275.155 270.954H275.192L275.403 270.329C278.581 261.145 279.186 251.151 277.144 241.597C275.101 232.043 270.501 223.353 263.919 216.613C257.337 209.874 249.065 205.384 240.137 203.706C231.21 202.028 222.024 203.236 213.728 207.179L213.734 207.145Z"
      />
    </svg>
  );
}

RocksPattern.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
};

export default RocksPattern;
