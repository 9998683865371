import PropTypes from "prop-types";

function GaugeChart({
  width = 50,
  height = 25,
  strokeColor = "stroke-black",
  fillColor1 = "fill-primary-blue",
  fillColor2 = "fill-secondary-blue",
  fillColor3 = "fill-alternative-blue"
}) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 36 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1"
        strokeMiterlimit="10"
        className={`${fillColor2} ${strokeColor}`}
        d="M10.217 15.1896C10.3566 13.503 11.2373 11.9244 12.6819 10.7718C14.1265 9.61922 16.0275 8.97837 18.0019 8.97837C19.9763 8.97837 21.8773 9.61922 23.3219 10.7718C24.7665 11.9244 25.6474 13.503 25.7869 15.1896H35C34.8837 11.3946 33.0416 7.78852 29.8661 5.13959C26.6907 2.49067 22.4329 1.0083 18 1.0083C13.567 1.0083 9.30924 2.49067 6.1338 5.13959C2.95837 7.78852 1.1163 11.3946 1 15.1896H10.217Z"
      />
      <path
        className={fillColor1}
        d="M21.4164 1.29129C21.9958 1.42266 22.5389 1.6516 23.0139 1.96476C23.4888 2.27792 23.8862 2.669 24.1826 3.11516C24.4791 3.56131 24.6687 4.05358 24.7405 4.56322C24.8123 5.07286 24.7647 5.58964 24.6006 6.08336C24.451 6.58496 24.1871 7.0563 23.8242 7.47032C23.4613 7.88434 23.0064 8.23287 22.4857 8.49588C21.965 8.7589 21.3887 8.93122 20.79 9.00294C20.1913 9.07466 19.582 9.04437 18.997 8.91382C18.6674 8.87522 18.3341 8.86403 18.0019 8.8804C16.0167 8.90402 14.1143 9.56583 12.6723 10.7345C11.2303 11.9032 10.3539 13.4934 10.217 15.1896H1C1.30047 7.36993 8.80441 1.00056 18.0019 1.00056C19.149 0.991642 20.2938 1.08912 21.4164 1.29129V1.29129Z"
      />
      <path
        strokeWidth="1"
        strokeMiterlimit="10"
        className={strokeColor}
        d="M10.217 15.1896C10.3566 13.503 11.2373 11.9244 12.6819 10.7718C14.1265 9.61922 16.0275 8.97837 18.0019 8.97837C19.9763 8.97837 21.8773 9.61922 23.3219 10.7718C24.7665 11.9244 25.6474 13.503 25.7869 15.1896H35C34.8837 11.3946 33.0416 7.78852 29.8661 5.13959C26.6907 2.49067 22.4329 1.0083 18 1.0083C13.567 1.0083 9.30924 2.49067 6.1338 5.13959C2.95837 7.78852 1.1163 11.3946 1 15.1896H10.217Z"
      />
      <path
        strokeWidth="1"
        strokeMiterlimit="10"
        className={`${fillColor3} ${strokeColor}`}
        d="M17.5451 18.0001C17.5451 18.0001 13.9863 17.7628 13.9863 15.0393C13.9863 13.2014 15.2975 11.4837 18.9305 4.96729C18.9305 4.96729 21.385 13.3517 21.385 15.0995C21.385 16.1655 20.624 18.0001 17.5451 18.0001Z"
      />
    </svg>
  );
}

GaugeChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fillColor1: PropTypes.string,
  fillColor2: PropTypes.string,
  fillColor3: PropTypes.string,
  strokeColor: PropTypes.string
};

export default GaugeChart;
