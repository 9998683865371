import Types from "./types";

import Api from "../../services/Api";

function setReset() {
  return {
    type: Types.RESET,
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setPage({ page, items, pageCount, itemsCount }) {
  return {
    type: Types.SET_PAGE,
    page,
    items,
    pageCount,
    itemsCount,
  };
}

export function resetAdvisorInvitesState() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchAdvisorInvites({ page, filter, orderBy, organizationId }) {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setIsLoading(true));

      const invitations = await Api.fetchAdvisorInvites({
        page,
        filter,
        orderBy,
        organizationId,
      });
      dispatch(setPage({ page, ...invitations }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
