import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Tip, { TipDirection } from "./Tip";

import { useIsDesktopMQL } from "../../../core/hooks";

function StudentsTips({ role, onSkip, onFinish }) {
  const isDesktop = useIsDesktopMQL();

  const [tip, setTip] = useState({
    content: null,
    position: null,
    direction: null,
    handlerOnClick: () => {},
  });
  const [tipsState, setTipsState] = useState({
    table: true,
    detail: false,
    filters: false,
    advancedMenu: false,
  });

  const getLocation = useCallback(() => {
    if (tipsState.table) {
      const refPosition = document
        .getElementById("students-sort-by-name-button")
        .getBoundingClientRect();

      setTip({
        direction: TipDirection.BOTTOM,
        position: { top: refPosition.y, left: refPosition.x },
        content: (
          <p>
            This is an example of a student list. Once you add students, you can
            sort or filter as needed.
          </p>
        ),
        handlerOnClick: () =>
          setTipsState({
            table: false,
            detail: false,
            filters: true,
            advancedMenu: false,
          }),
      });
    } else if (tipsState.detail) {
      const refPosition = document
        .getElementById("table-item-1")
        .getBoundingClientRect();

      setTip({
        direction: TipDirection.BOTTOM,
        position: { top: refPosition.y, left: refPosition.x },
        content: (
          <p>
            Click on a student name to see more details of their college choices
            and financial aid status.
          </p>
        ),
        handlerOnClick: () => {
          if (isDesktop) {
            const menu = document.getElementById("student-item-menu-button-2");
            menu.dispatchEvent(
              new MouseEvent("click", {
                buttons: 1,
                view: window,
                bubbles: true,
                cancelable: true,
              })
            );
          }

          setTipsState({
            table: false,
            detail: false,
            filters: false,
            advancedMenu: true,
          });
        },
      });
    } else if (tipsState.filters) {
      const refPosition = document
        .getElementById("students-affordability-options-filter-button")
        .getBoundingClientRect();

      setTip({
        direction: TipDirection.BOTTOM,
        position: {
          top: refPosition.y,
          left: isDesktop ? refPosition.x : refPosition.x - refPosition.width,
        },
        content: (
          <p>
            Filter the student list by award letter, upload status and
            affordability.
          </p>
        ),
        handlerOnClick: () =>
          setTipsState({
            table: false,
            detail: true,
            filters: false,
            advancedMenu: false,
          }),
      });
    } else if (tipsState.advancedMenu) {
      let refPosition;
      if (isDesktop)
        refPosition = document
          .getElementById("box-student-item-menu-button-2")
          .getBoundingClientRect();
      else
        refPosition = document
          .getElementById("student-menu-button-1")
          .getBoundingClientRect();

      setTip({
        direction: TipDirection.RIGHT,
        position: { top: refPosition.y, left: refPosition.x - 10 },
        content: (
          <p>
            Access the advanced menu at these three dots to remove or message an
            individual student.
          </p>
        ),
        handlerOnClick: () => {
          onFinish();
          setTipsState({
            table: false,
            detail: false,
            filters: false,
            advancedMenu: false,
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipsState]);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return (
    <Tip
      handlerOnSkip={onSkip}
      position={tip.position}
      direction={tip.direction}
      handlerOnNext={tip.handlerOnClick}
    >
      {tip.content}
    </Tip>
  );
}

StudentsTips.propTypes = {
  role: PropTypes.string,
  onSkip: PropTypes.func,
  onFinish: PropTypes.func,
};

export default StudentsTips;
