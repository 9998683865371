import PropTypes from "prop-types";

import { Panel } from "../../compose";
import { FirstYearBreakdown } from "../";

import { AwardStatus } from "../../utils";

function FirstYearBreakdownCard({
  id,
  open,
  colors,
  chosenSchool,
  studentState,
  externalToggle,
  callbackToggle,
  handleUpdateAward,
  firstYearButtonsComponent,
}) {
  const { award } = chosenSchool;

  const errorPending = award?.pendingError?.errorType;
  const successfulAward = award?.status === AwardStatus.READY;
  const inReview =
    award?.status === AwardStatus.PENDING ||
    award?.status === AwardStatus.MANUAL_REVIEW;

  return (
    <Panel
      id={id}
      className="pt-4 mb-2"
      initiallyOpened={open}
      title="first year breakdown"
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      classNameClose="p-4 mb-2 bg-white shadow-lg"
      titleClassName="
        flex
        flex-1
        flex-row
        capitalize
        font-semibold
      "
      expandableContent={
        <>
          <FirstYearBreakdown
            colors={colors}
            chosenSchool={chosenSchool}
            studentState={studentState}
            handleUpdateAward={handleUpdateAward}
          />
          {!successfulAward && !errorPending && (
            <Panel
              title="Grants & Scholarships"
              className="p-4 my-4 bg-white"
              titleClassName="font-semibold capitalize"
            >
              <div className="flex flex-1 flex-col">
                <span className="mt-4 text-sm font-base">
                  {inReview ? (
                    <>
                      One of our team members will check out your award as soon
                      as possible. We'll email you when your results are ready.
                    </>
                  ) : !award ? (
                    <>
                      Add you award letter to view your potential grants and
                      scholarships from this university.
                    </>
                  ) : (
                    <>
                      There were no grants & scholarships listed on your{" "}
                      <span className="font-semibold">
                        financial aid award letter
                      </span>
                      .
                    </>
                  )}
                </span>
              </div>
            </Panel>
          )}
          {firstYearButtonsComponent}
        </>
      }
    />
  );
}

FirstYearBreakdownCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  colors: PropTypes.shape(),
  studentState: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  handleUpdateAward: PropTypes.func,
  firstYearButtonsComponent: PropTypes.any,
};

export default FirstYearBreakdownCard;
