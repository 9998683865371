import PropTypes from "prop-types";

function MagnifyGlass({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 47 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={color}
        d="M18.8541 30.5276C21.8364 32.4828 25.3806 33.5357 29.098 33.5357C33.8822 33.5357 38.3796 31.792 41.7622 28.6244C48.744 22.0869 48.744 11.4494 41.7612 4.91323C38.3791 1.74567 33.8817 0.00195312 29.0976 0.00195312C24.3125 0.00195312 19.8155 1.74567 16.4339 4.91323C13.0513 8.07991 11.1877 12.2903 11.1877 16.769C11.1877 20.2438 12.3099 23.558 14.3932 26.348L0 39.8233L4.46299 44.0005L18.8541 30.5276ZM19.7328 8.00071C22.2337 5.65903 25.5599 4.36983 29.098 4.36983C32.6353 4.36983 35.9619 5.65947 38.4637 8.00027C40.9655 10.3428 42.3436 13.4558 42.3436 16.7682C42.3436 20.0805 40.9655 23.1948 38.4642 25.5369C35.9624 27.8782 32.6362 29.1678 29.0985 29.1678C25.5599 29.1678 22.2346 27.8782 19.7328 25.5369C14.5685 20.7022 14.5685 12.8354 19.7328 8.00071Z"
      />
      <path
        className={color}
        d="M29.372 16.492V12.91H27.446V16.492H23.864V18.418H27.446V22H29.372V18.418H32.954V16.492H29.372Z"
      />
    </svg>
  );
}

MagnifyGlass.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default MagnifyGlass;
