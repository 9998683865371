import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Dropdown } from "../../../core";
import { LoadingButton } from "../../../core/buttons";
import { Colors } from "../../../core/utils";
import { interactionEventDataLayer } from "../../../utils/DataLayers";

function AssignAdvisor({ students, assingAdvisors, onAssingAdvisor }) {
  const [isLoading, setIsLoading] = useState(false);
  const [assignedAdvisor, setAssignedAdvisor] = useState(null);
  const [assignedAdvisorError, setAssingAdvisorError] = useState(null);

  useEffect(() => {
    if (students && students.length === 1) {
      const student = students[0];
      const assignedAdvisor = assingAdvisors.find(
        (advisor) => advisor.id === student.assignedAdvisor?.id
      );
      if (assignedAdvisor) setAssignedAdvisor(assignedAdvisor);
      else setAssignedAdvisor(assingAdvisors[assingAdvisors.length - 1]);
    } else if (students && students.length > 1) {
      let assignedAdvisors = students
        .filter((student) => student.assignedAdvisor)
        .reduce((array, student) => {
          if (!array.includes(student.assignedAdvisor.id))
            array.push(student.assignedAdvisor.id);
          return array;
        }, []);

      if (assignedAdvisors.length === 1)
        setAssignedAdvisor(
          assingAdvisors.find((advisor) => advisor.id === assignedAdvisors[0])
        );
      else if (assignedAdvisors.length > 1)
        setAssignedAdvisor({ label: "Select an Advisor" });
      else setAssignedAdvisor(assingAdvisors[assingAdvisors.length - 1]);
    } else setAssignedAdvisor(assingAdvisors[assingAdvisors.length - 1]);
  }, [students, assingAdvisors]);

  const handleOnClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await onAssingAdvisor(assignedAdvisor);
      if (assignedAdvisor.id === null) {
        interactionEventDataLayer({
          eventName: "all_st_unassigned_field",
          params: {
            user_id: students.map((student) => student.userId),
          },
        });
      }
      interactionEventDataLayer({
        eventName: "assign_adv_save_changes_button",
      });
    } catch (error) {
      setIsLoading(false);
      setAssingAdvisorError(error.errors[0].message);
    }
  };

  return (
    <>
      <div className="px-1 h-full w-full overflow-auto">
        <p className="mt-12 text-2xl font-medium">
          Select an advisor to assign to
          {students?.length === 1 ? " this student " : " these students "} from
          the drop down list below
        </p>
        <div className="mt-8 w-1/2">
          <Dropdown
            color={Colors.BLUE}
            options={assingAdvisors}
            currentOption={assignedAdvisor}
            id="assigned-advisor-dropdown-modal"
            handleOnSelect={(data) => setAssignedAdvisor(data)}
          />
        </div>
        {assignedAdvisorError && (
          <div className="mt-8 space-y-2 text-primary-red">
            <p>Something went wrong. {assignedAdvisorError}</p>
          </div>
        )}
      </div>
      <div className="py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="assigned-advisor-button-modal"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          save changes
        </LoadingButton>
      </div>
    </>
  );
}

AssignAdvisor.propTypes = {
  onAssingAdvisor: PropTypes.func,
  students: PropTypes.arrayOf(PropTypes.shape()),
  assingAdvisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default AssignAdvisor;
