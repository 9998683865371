import PropTypes from "prop-types";

import { FilterTag } from "../../../Common/FiltersList";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

import { getEnrollmentIcon } from "../../../../core/utils";

function EnrollmentStatusFilter({ onSelect }) {
  const size = 18;

  const onSelectHandler = (key) => {
    onSelect(key);
    switch (key.key) {
      case FilterTag.ENROLLMENT_1_INTERESTED:
        interactionEventDataLayer({ eventName: "all_st_interested_filter" });
        break;
      case FilterTag.ENROLLMENT_2_APPLIED:
        interactionEventDataLayer({
          eventName: "all_st_applied_filter",
        });
        break;
      case FilterTag.ENROLLMENT_3_ACCEPTED:
        interactionEventDataLayer({
          eventName: "all_st_accepted_filter",
        });
        break;
      case FilterTag.ENROLLMENT_4_UPLOADED_LETTER:
        interactionEventDataLayer({
          eventName: "all_st_uploaded_letter_filter",
        });
        break;
      case FilterTag.ENROLLMENT_5_ENROLLED:
        interactionEventDataLayer({
          eventName: "all_st_enrolled_filter",
        });
        break;
      case FilterTag.NO_COLLEGE:
        interactionEventDataLayer({
          eventName: "all_st_no_college_added_filter",
        });
        break;
      default:
        return;
    }
  };

  return (
    <div
      className="
        flex
        text-sm
        flex-col
        divide-y
        space-y-4
        capitalize
        divide-solid
        divide-black
      "
    >
      <div className="flex flex-col space-y-3">
        <div
          onClick={() =>
            onSelectHandler({ key: FilterTag.ENROLLMENT_1_INTERESTED })
          }
          className="flex flex-row mx-2 space-x-4 items-center"
        >
          {getEnrollmentIcon({
            size,
            enrollment: FilterTag.ENROLLMENT_1_INTERESTED,
          })}
          <span className="pl-2">Interested</span>
        </div>
        <div
          onClick={() =>
            onSelectHandler({ key: FilterTag.ENROLLMENT_2_APPLIED })
          }
          className="flex flex-row mx-2 space-x-4 items-center"
        >
          {getEnrollmentIcon({
            size,
            enrollment: FilterTag.ENROLLMENT_2_APPLIED,
          })}
          <span className="pl-2">Applied</span>
        </div>
        <div
          onClick={() =>
            onSelectHandler({ key: FilterTag.ENROLLMENT_3_ACCEPTED })
          }
          className="flex flex-row mx-2 space-x-4 items-center"
        >
          {getEnrollmentIcon({
            size,
            enrollment: FilterTag.ENROLLMENT_3_ACCEPTED,
          })}
          <span className="pl-2">Accepted</span>
        </div>
        <div
          onClick={() =>
            onSelectHandler({ key: FilterTag.ENROLLMENT_4_UPLOADED_LETTER })
          }
          className="flex flex-row mx-2 space-x-4 items-center"
        >
          {getEnrollmentIcon({
            size,
            enrollment: FilterTag.ENROLLMENT_4_UPLOADED_LETTER,
          })}
          <span className="pl-2">Uploaded letter</span>
        </div>
        <div
          onClick={() =>
            onSelectHandler({ key: FilterTag.ENROLLMENT_5_ENROLLED })
          }
          className="flex flex-row mx-2 space-x-4 items-center"
        >
          {getEnrollmentIcon({
            size,
            enrollment: FilterTag.ENROLLMENT_5_ENROLLED,
          })}
          <span className="pl-2">Enrolled</span>
        </div>
      </div>
      <div className="pt-2 flex flex-col">
        <div
          className="py-2 flex flex-row"
          onClick={() => onSelectHandler({ key: FilterTag.NO_COLLEGE })}
        >
          <div className="w-10">
            <div className="h-full w-full bg-light-gray"></div>
          </div>
          <span className="pl-4">No college added</span>
        </div>
      </div>
    </div>
  );
}

EnrollmentStatusFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default EnrollmentStatusFilter;
