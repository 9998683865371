import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { Button, LoadingButton } from "../../../core/buttons";

function DeleteStudent({ onClick, student, deleteStudents, finishCloseModal }) {
  const firstText = (
    <span>
      Are you sure you want to delete
      {student ? (
        <span className="text-black">{` ${student.firstName} ${student.lastName} `}</span>
      ) : (
        ` ${deleteStudents} students `
      )}
      from your organization?
    </span>
  );

  const secondText = (
    <span className="text-2xl font-medium">
      Deleting {deleteStudents === 1 ? "this student " : "these students "}
      will remove them from your organization.
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onClick();
  };

  return (
    <>
      <div className="px-1 overflow-auto">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="py-5 flex flex-row mt-auto space-x-5">
        <Button
          onClick={finishCloseModal}
          id="remove-student-cancel-button"
          className="
            mb-5
            w-1/2
            bg-white
            text-primary-blue
            border-primary-blue
            hover:text-white
            active:text-white
            hover:bg-primary-blue
            active:bg-primary-blue
            mobile:text-sm
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="remove-student-remove-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:text-sm
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          delete {deleteStudents === 1 ? "student" : "students"}
        </LoadingButton>
      </div>
    </>
  );
}

DeleteStudent.propTypes = {
  student: PropTypes.shape(),
  deleteStudents: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  finishCloseModal: PropTypes.func.isRequired,
};

export default DeleteStudent;
