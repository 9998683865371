import PropTypes from "prop-types";

function AffordabilityThumbs({ theme, show = true, className }) {
  return (
    <div className="-mt-2 flex flex-row space-x-4">
      <p className={`self-end font-semibold ${className}`}>{theme.label}</p>
      {show && <div>{theme.icon}</div>}
    </div>
  );
}

AffordabilityThumbs.propTypes = {
  theme: PropTypes.shape(),
  className: PropTypes.string
};

export default AffordabilityThumbs;
