import PropTypes from "prop-types";
import { useState } from "react";

import Box from "./Box";

export const TipDirection = {
  TOP: "TOP",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  BOTTOM: "BOTTOM"
};

function Tip({
  children,
  position,
  direction,
  showControls = true,
  handlerOnSkip,
  handlerOnNext
}) {
  const [offSet, setOffSet] = useState(0);

  const getTipDirection = () => {
    const controlButotns = showControls ? (
      <div className="mt-4 flex flow-row justify-between">
        <div
          role="button"
          onClick={() => handlerOnSkip()}
          className="text-sm text-white underline"
        >
          Skip tutorial
        </div>
        <div
          role="button"
          onClick={() => handlerOnNext()}
          className="
            px-2
            border
            text-sm
            bg-white
            border-white
            text-primary-blue
            hover:text-white
            active:text-white
            hover:bg-primary-blue
            active:bg-primary-blue
          "
        >
          Next
        </div>
      </div>
    ) : null;

    switch (direction) {
      case TipDirection.TOP:
        return (
          <div
            style={{
              width: "256px",
              top: position?.top || 0,
              left: position?.left || 0
            }}
            className="flex flex-col absolute items-center"
          >
            <Box className="w-full" showControls={showControls}>
              <div className="text-sm text-white font-bold">{children}</div>
              {controlButotns}
            </Box>
          </div>
        );
      case TipDirection.RIGHT:
        return (
          <div
            style={{
              width: "270px",
              marginLeft: "-270px",
              top: position?.top || 0,
              left: position?.left || 0
            }}
            className="flex flex-row absolute items-center"
          >
            <Box className="w-full" showControls={showControls}>
              <div className="text-sm text-white font-bold">{children}</div>
              {controlButotns}
            </Box>
          </div>
        );
      case TipDirection.LEFT:
        return (
          <div
            style={{
              width: "270px",
              top: position?.top || 0,
              left: position?.left || 0
            }}
            className="flex flex-row absolute items-center"
          >
            <Box className="w-full" showControls={showControls}>
              <div className="text-sm text-white font-bold">{children}</div>
              {controlButotns}
            </Box>
          </div>
        );
      case TipDirection.BOTTOM:
        return (
          <div
            className="flex flex-col absolute items-center"
            ref={(node) => {
              if (node) setOffSet(node.getBoundingClientRect().height);
            }}
            style={{
              width: "256px",
              top: position?.top || 0,
              left: position?.left || 0,
              marginTop: `-${offSet}px`
            }}
          >
            <Box className="w-full" showControls={showControls}>
              <div className="text-sm text-white font-bold">{children}</div>
              {controlButotns}
            </Box>
          </div>
        );

      default:
        return null;
    }
  };

  return <div className="z-10 flex flex-col">{getTipDirection()}</div>;
}

Tip.propTypes = {
  position: PropTypes.shape(),
  direction: PropTypes.string,
  showControls: PropTypes.bool,
  handlerOnSkip: PropTypes.func,
  handlerOnNext: PropTypes.func
};

export default Tip;
