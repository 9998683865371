import PropTypes from "prop-types";

import { Loader } from "../../core";

function FloatLoader({ show }) {
  return (
    <div
      className="
        flex
        inset-0
        absolute
        items-center
        justify-center
        bg-transparent-black
      "
    >
      <Loader show={show} />
    </div>
  );
}

FloatLoader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default FloatLoader;
