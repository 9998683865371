import PropTypes from "prop-types";

function InfoRow({
  id = "",
  text,
  label,
  className = "capitalize",
  labelColor = "text-black"
}) {
  return (
    <div className="p-2 flex w-full flex-col text-black">
      <p className={`text-sm font-bold ${className} ${labelColor}`}>{label}</p>
      <p id={`info-row-${id}`} className="text-sm font-medium">
        {text}
      </p>
    </div>
  );
}

InfoRow.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  labelColor: PropTypes.string
};

export default InfoRow;
