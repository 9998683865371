import Types from "./types";

const INITIAL_STATE = {
  pages: [],
  error: null,
  pageCount: 0,
  itemsCount: 0,
  isLoading: false,
};

export const archivedStudentListsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.RESET:
      return { ...INITIAL_STATE };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Types.SET_PAGE: {
      const pages = [...state.pages];
      pages[action.page.pageNumber - 1] = action.items;
      return {
        ...state,
        itemsCount: action.itemsCount,
        pageCount: action.pageCount,
        pages,
      };
    }
    default:
      return state;
  }
};
