import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import LayoutManager from "../Common/LayoutManager";
import { LayoutCenterContent, LayoutDesktop } from "../Common/Layout";

import { Button, LoadingButton } from "../../core/buttons";

import Api from "../../services/Api";
import { useAdvisor } from "../../redux/Advisor/hooks";
import { fetchAdvisor } from "../../redux/Advisor/actions";
import { saveState, Items } from "../../redux/storePersist";
import {
  setLists,
  fetchOrganizations,
  setSelectedOrganizationId
} from "../../redux/Organizations/actions";

function Invitation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invitationId } = useParams();

  const advisor = useAdvisor();

  const {
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  const [error, setError] = useState();
  const [profile, setProfile] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [invitation, setInvitation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const [errorMutation, setErrorMutation] = useState();
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setErrorMutation();
    setIsLoadingMutation(true);
    try {
      await Api.acceptInvitation({ invitationId });

      const organizationId = invitation.organization_id;
      dispatch(fetchOrganizations());
      dispatch(setLists({ studentLists: [], advisorLists: [] }));
      dispatch(setSelectedOrganizationId(`${organizationId}`));
      dispatch(fetchAdvisor({ organizationId: `${organizationId}` }));

      saveState({ internalState: {} }, Items.APP_STATE);
      navigate("/");
    } catch (error) {
      setErrorMutation(error);
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        if (isAuthenticated) {
          const claims = await getIdTokenClaims();
          setProfile(claims);
          if (!advisor.isLoggedIn) {
            const getToken = async () => {
              await getAccessTokenSilently();
              const claims = await getIdTokenClaims();
              return claims.__raw;
            };
            Api.configure({
              token: getToken
            });

            const exists = await Api.checkUserExists();
            setUserExists(exists);
          } else {
            setUserExists(true);
          }
        }
        setIsLoading(false);
      } catch (e) {
        setError(e);
      }
    })();
  }, [
    isAuthenticated,
    advisor.isLoggedIn,
    getIdTokenClaims,
    getAccessTokenSilently
  ]);

  useEffect(() => {
    const fetchInvitation = async () => {
      if (invitationId) {
        try {
          const invitation = await Api.fetchInvitation({ invitationId });

          if (isAuthenticated) {
            if (!userExists || !profile.email_verified) {
              loginWithRedirect();
              return;
            }

            if (invitation.status === "ACCEPTED") {
              setAccepted(true);
            }
            setInvitation(invitation);
            setIsReady(true);
          } else {
            if (invitation.expired) {
              setError({ errors: { message: "Invitation has expired!" } });
              setIsReady(true);
            } else {
              if (invitation.recipient_is_registered) await loginWithRedirect();
              else
                await loginWithRedirect({
                  authorizationParams: { screen_hint: "signup" }
                });
            }
          }
        } catch (error) {
          setError(error);
          setIsReady(true);
        }
      }
    };

    if (!isLoading) fetchInvitation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!isReady)
    return <div className="w-full font-bold text-center">Loading...</div>;
  else
    return (
      <LayoutManager showHeader={true} isHeaderSimple={true}>
        <LayoutDesktop height="min-h-screen-footer">
          <LayoutCenterContent className="shadow-lg relative overflow-hidden">
            <div className="flex flex-1 flex-row">
              <p
                className="
                  mb-8
                  text-3xl
                  font-bold
                  leading-none
                  tablet:px-6
                  tablet:py-10
                  tablet-sm:px-6
                  tablet-sm:py-10
                  laptop:text-4xl
                  desktop:text-5xl
                "
              >
                Join us in DecidED
              </p>
            </div>
            <div className="flex flex-1 flex-col">
              <div className="my-12">
                <div className="text-4xl text-primary-blue font-extrabold">
                  {error ? error.errors?.message : invitation?.subject}
                </div>
                <div className="text-2xl text-black my-12 mb-4">
                  {invitation?.body}
                </div>
              </div>

              {errorMutation && (
                <p className="pb-2 text-primary-red">
                  {errorMutation.errors[0].message}
                </p>
              )}

              <div
                className={`
                  flex
                  flex-row
                  flex-1
                  space-x-4
                    ${(error || accepted) && "hidden"}
                `}
              >
                <div className="w-1/2">
                  <Button
                    className="
                      w-full
                      bg-white
                      text-primary-blue
                      border-primary-blue
                      hover:text-white
                      active:text-white
                      hover:bg-primary-blue
                      active:bg-primary-blue
                    "
                    onClick={() => skipOnClick()}
                  >
                    Skip
                  </Button>
                </div>
                <div className="w-1/2">
                  <LoadingButton
                    isLoading={isLoadingMutation}
                    onClick={() => acceptOnClick()}
                    id="delete-group-save-button"
                    className="
                      w-full
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    Accept
                  </LoadingButton>
                </div>
              </div>
              <div
                className={`
                  flex
                  flex-1
                  flex-row
                  ${!accepted && !error && "hidden"}
                `}
              >
                <div className="w-full">
                  <Button
                    onClick={() => skipOnClick()}
                    className="
                      mb-5
                      w-full
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    Return
                  </Button>
                </div>
              </div>
            </div>
          </LayoutCenterContent>
        </LayoutDesktop>
      </LayoutManager>
    );
}

export default Invitation;
