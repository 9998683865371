import PropTypes from "prop-types";

function Close({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path strokeWidth="2" className={color} d="M1 1L20.3552 21" />
      <path strokeWidth="2" className={color} d="M21 1L1.64482 21" />
    </svg>
  );
}

Close.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Close;
