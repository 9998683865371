import PropTypes from "prop-types";

function QuestionShape({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 23 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M14.7108 18.9923L14.7047 18.9997L14.6988 19.0074C14.0696 19.8333 13.8132 20.6625 13.7626 21.8929H8.36301C8.39529 19.9032 8.626 18.6775 9.04609 17.7625C9.49641 16.7818 10.187 16.1053 11.2468 15.2036L11.2573 15.1946L11.2674 15.1851C11.7295 14.7455 12.2304 14.3711 12.7397 14.0048C12.819 13.9477 12.8989 13.8906 12.9791 13.8333C13.4043 13.5293 13.8362 13.2206 14.2222 12.8914C15.1651 12.0872 15.9073 11.0988 15.9073 9.51923C15.9073 8.31512 15.4382 7.32454 14.6459 6.6417C13.8616 5.96568 12.795 5.6195 11.6332 5.6195C10.1881 5.6195 8.93084 6.10132 8.0347 7.01619C7.23671 7.83086 6.76043 8.95266 6.66604 10.2885H0.520149C0.688029 7.43547 1.75951 5.05713 3.58169 3.36368C5.51345 1.56839 8.33549 0.5 11.944 0.5C15.1599 0.5 17.8023 1.41411 19.6315 2.94973C21.4535 4.47944 22.5 6.65022 22.5 9.24725C22.5 11.2828 21.9723 12.6881 21.2365 13.7367C20.4934 14.7956 19.5161 15.5237 18.5494 16.1743C18.1759 16.4194 17.8247 16.6447 17.4981 16.8543C17.0273 17.1564 16.6075 17.4257 16.2454 17.6744C15.623 18.1018 15.1109 18.5072 14.7108 18.9923Z"
      />
      <rect x="9" y="25" width="4" height="4" className={color} />
    </svg>
  );
}

QuestionShape.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default QuestionShape;
