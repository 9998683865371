import PropTypes from "prop-types";

import { DiversityCard as CommonDiversityCard } from "../../../../../core/compose/cards";

function DiversityCard({
  open,
  ethnicity,
  chosenSchool,
  externalToggle,
  callbackToggle,
}) {
  return (
    <CommonDiversityCard
      open={open}
      ethnicity={ethnicity}
      chosenSchool={chosenSchool}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
    />
  );
}

DiversityCard.propTypes = {
  open: PropTypes.bool,
  ethnicity: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default DiversityCard;
