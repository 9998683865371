import { useState } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import { Close } from "../../../../../../../core/icons";

import {
  dollarFormat,
  localStringToNumber,
} from "../../../../../../Modals/AddAdditionalFinancialAidModal/Utils";

function Item({
  name,
  edit,
  value,
  isNew,
  added,
  handlerOnEdit,
  handlerOnRemove,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [invalidValue, setInvalidValue] = useState(false);

  const handlerOnEditDebounce = debounce((value) => handlerOnEdit(value), 500);

  const handlerOnChange = (value) => {
    if (value !== "") {
      const valueNumber = localStringToNumber(value);
      if (!isNaN(valueNumber) && valueNumber >= 0) {
        setInvalidValue(false);
        setLocalValue(valueNumber);
        handlerOnEditDebounce(valueNumber);
      } else setInvalidValue(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="p-2 flex flex-1 flex-row items-center">
        {(isNew || added) && (
          <span className="mr-2 text-sm font-bold text-primary-blue">New!</span>
        )}
        <p className="font-semibold w-full">{name}</p>
        {added || edit ? (
          <>
            <div className="p-1 ml-2 border border-primary-blue">
              <input
                autoCapitalize="none"
                id="box-edit-aid-input"
                value={dollarFormat.format(localValue)}
                onChange={(event) => handlerOnChange(event.target.value)}
                className={`
                  w-full
                  text-sm
                  text-right
                  font-medium
                  text-black
                  outline-none
                `}
              />
            </div>
            <div role="button" className="ml-2" onClick={handlerOnRemove}>
              <Close size={15} />
            </div>
          </>
        ) : (
          <p className="font-semibold justify-end">
            ${value.toLocaleString("en-US")}
          </p>
        )}
      </div>
      {invalidValue && (
        <p className="text-sm text-right font-semibold text-primary-red">
          You can add only positive numbers!
        </p>
      )}
    </div>
  );
}

Item.propTypes = {
  edit: PropTypes.bool,
  added: PropTypes.bool,
  isNew: PropTypes.bool,
  handlerOnEdit: PropTypes.func,
  handlerOnRemove: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default Item;
