import PropTypes from "prop-types";

import { Link } from "../";
import { ShortArrow } from "../icons";
import {
  Colors,
  getColors,
  getCollegeEnvironment,
  decidedRoute,
} from "../utils";

function CampusEnvironment({ color, college, bodySize, locationType }) {
  const colors = getColors(color);

  return (
    <div className="flex flex-col space-y-6">
      <Link
        icon={true}
        color={color}
        target="_blank"
        internal={false}
        textClassName="text-left font-bold"
        text="How a Campus Impacts your College Experience"
        href={decidedRoute("/guides/campus-environment-decided/")}
        customIcon={
          <div className="transform place-self-end rotate-180">
            <ShortArrow size={16} color="stroke-current" />
          </div>
        }
      />
      <div className={`mt-3 flex flex-1 flex-row ${colors.primary.text}`}>
        <div
          data-testid={college}
          className="flex flex-1 flex-col items-center justify-center"
        >
          {getCollegeEnvironment[college]}
        </div>
        <div
          data-testid={locationType}
          className="flex flex-1 flex-col items-center justify-center"
        >
          {getCollegeEnvironment[locationType]}
        </div>
        <div
          data-testid={bodySize}
          className="flex flex-1 flex-col items-center justify-center"
        >
          {getCollegeEnvironment[bodySize]}
        </div>
      </div>
    </div>
  );
}

CampusEnvironment.propTypes = {
  college: PropTypes.string,
  bodySize: PropTypes.string,
  locationType: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default CampusEnvironment;
