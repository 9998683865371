import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../../Common/Confirm";
import UnarchiveList from "../UnarchiveList";

import Api from "../../../../services/Api";

function UnarchiveAdvisorListModal({
  lists,
  filters,
  onClose,
  unarchiveLists,
  organizationId,
  setConfirmModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const sharedList = lists?.some(
    (list) => list.isShared || list.sharedWithOrganization
  );

  const archiveListOnClick = async () => {
    await Api.archiveAdvisorLists({
      organizationId,
      archived: false,
      lists: {
        filter: lists?.length > 0 ? null : filters,
        ids: lists?.length > 0 ? lists.map((list) => list.id) : null,
      },
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const firstTextConfirmation =
    lists?.length > 0 ? (
      <div>
        <p>You have unarchived:</p>
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {lists.map((list) => (
              <li key={list.id} className="list-disc list-inside">
                <span>{list.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ) : (
      <p>You have unarchived {unarchiveLists} lists</p>
    );

  const secondTextConfirmation = sharedList ? (
    unarchiveLists === 1 ? (
      <span className="text-2xl font-medium">
        This list will now be visible for all the admins that it is shared with.
      </span>
    ) : (
      <span className="text-2xl font-medium">
        These lists will now be visible for all the admins that they are shared
        with.
      </span>
    )
  ) : unarchiveLists === 1 ? (
    <span className="text-2xl font-medium">
      This list will now appear in your advisor lists.
    </span>
  ) : (
    <span className="text-2xl font-medium">
      These lists will now appear in your advisor lists.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <UnarchiveList
          lists={lists}
          onSubmit={archiveListOnClick}
          unarchiveLists={unarchiveLists}
          secondText={secondTextConfirmation}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to Archived Lists"
        />
      )}
    </div>
  );
}

UnarchiveAdvisorListModal.propTypes = {
  onClose: PropTypes.func,
  lists: PropTypes.arrayOf(PropTypes.shape()),
  filters: PropTypes.shape(),
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  unarchiveLists: PropTypes.number,
};

export default UnarchiveAdvisorListModal;
