import { useState, useEffect } from "react";
import PropTypes from "prop-types";

function Notification({
  size = 48,
  className = "",
  notifications = 0,
  iconColor = "fill-black",
  textColor = "fill-black",
  badgetColor = "fill-primary-orange",
  ...restProps
}) {
  const [showBadge, setShowBadget] = useState(false);

  useEffect(() => {
    if (notifications > 0) setShowBadget(true);
    else setShowBadget(false);
  }, [notifications]);

  return (
    <div className={className} {...restProps}>
      {showBadge ? (
        <svg
          fill="none"
          width={size}
          height={size}
          viewBox="0 0 41 42"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.3969 13.5H2.08217C1.66255 13.5 1.26012 13.6667 0.963408 13.9634C0.666693 14.2601 0.5 14.6626 0.5 15.0822V34.0683C0.5 34.4879 0.666693 34.8903 0.963408 35.187C1.26012 35.4837 1.66255 35.6504 2.08217 35.6504H8.59018L13.5371 41.4517C13.6866 41.6239 13.8713 41.7619 14.0788 41.8565C14.2862 41.9511 14.5116 42 14.7396 42C14.9676 42 15.1929 41.9511 15.4003 41.8565C15.6078 41.7619 15.7925 41.6239 15.942 41.4517L20.8889 35.6715H27.3969C27.6065 35.6715 27.814 35.6299 28.0073 35.5491C28.2007 35.4683 28.376 35.3498 28.5232 35.2007C28.6704 35.0515 28.7865 34.8746 28.8648 34.6802C28.9431 34.4858 28.9819 34.2778 28.9791 34.0683V15.0822C28.9791 14.6626 28.8124 14.2601 28.5157 13.9634C28.219 13.6667 27.8166 13.5 27.3969 13.5ZM25.8148 32.4861H20.1611C19.9347 32.4835 19.7104 32.5292 19.503 32.6201C19.2957 32.711 19.1101 32.8451 18.9587 33.0135L14.7396 37.9393L10.5204 33.0135C10.3709 32.8413 10.1862 32.7032 9.97878 32.6086C9.77133 32.514 9.54599 32.465 9.31798 32.465H3.66435V16.6643H25.8148V32.4861ZM6.82869 21.4109C6.82869 20.9912 6.99539 20.5888 7.2921 20.2921C7.58882 19.9954 7.99125 19.8287 8.41087 19.8287H21.0683C21.4879 19.8287 21.8903 19.9954 22.187 20.2921C22.4837 20.5888 22.6504 20.9912 22.6504 21.4109C22.6504 21.8305 22.4837 22.2329 22.187 22.5296C21.8903 22.8263 21.4879 22.993 21.0683 22.993H8.41087C7.99125 22.993 7.58882 22.8263 7.2921 22.5296C6.99539 22.2329 6.82869 21.8305 6.82869 21.4109ZM6.82869 27.7396C6.82869 27.3199 6.99539 26.9175 7.2921 26.6208C7.58882 26.3241 7.99125 26.1574 8.41087 26.1574H14.7396C15.1592 26.1574 15.5616 26.3241 15.8583 26.6208C16.155 26.9175 16.3217 27.3199 16.3217 27.7396C16.3217 28.1592 16.155 28.5616 15.8583 28.8583C15.5616 29.155 15.1592 29.3217 14.7396 29.3217H8.41087C7.99125 29.3217 7.58882 29.155 7.2921 28.8583C6.99539 28.5616 6.82869 28.1592 6.82869 27.7396Z"
            fill="black"
          />
          <circle r="11" cx="30" cy="11" className={badgetColor} />
          <text
            y={notifications > 15 ? "15.5" : "16"}
            x={notifications < 10 ? "25" : notifications > 15 ? "20" : "21"}
            className={`
              font-bold
              uppercase
              ${textColor}
              ${notifications > 15 && "text-xs"}
            `}
          >
            {notifications > 15 ? "15+" : notifications}
          </text>
        </svg>
      ) : (
        <svg
          fill="none"
          width={size - 5}
          height={size - 5}
          viewBox="0 0 29 29"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={iconColor}
            d="M27.3969 0.5H2.08217C1.66255 0.5 1.26012 0.666693 0.963408 0.963408C0.666693 1.26012 0.5 1.66255 0.5 2.08217V21.0683C0.5 21.4879 0.666693 21.8903 0.963408 22.187C1.26012 22.4837 1.66255 22.6504 2.08217 22.6504H8.59018L13.5371 28.4517C13.6866 28.6239 13.8713 28.7619 14.0788 28.8565C14.2862 28.9511 14.5116 29 14.7396 29C14.9676 29 15.1929 28.9511 15.4003 28.8565C15.6078 28.7619 15.7925 28.6239 15.942 28.4517L20.8889 22.6715H27.3969C27.6065 22.6715 27.814 22.6299 28.0073 22.5491C28.2007 22.4683 28.376 22.3498 28.5232 22.2007C28.6704 22.0515 28.7865 21.8746 28.8648 21.6802C28.9431 21.4858 28.9819 21.2778 28.9791 21.0683V2.08217C28.9791 1.66255 28.8124 1.26012 28.5157 0.963408C28.219 0.666693 27.8166 0.5 27.3969 0.5ZM25.8148 19.4861H20.1611C19.9347 19.4835 19.7104 19.5292 19.503 19.6201C19.2957 19.711 19.1101 19.8451 18.9587 20.0135L14.7396 24.9393L10.5204 20.0135C10.3709 19.8413 10.1862 19.7032 9.97878 19.6086C9.77133 19.514 9.54599 19.465 9.31798 19.465H3.66435V3.66435H25.8148V19.4861ZM6.82869 8.41087C6.82869 7.99125 6.99539 7.58882 7.2921 7.2921C7.58882 6.99539 7.99125 6.82869 8.41087 6.82869H21.0683C21.4879 6.82869 21.8903 6.99539 22.187 7.2921C22.4837 7.58882 22.6504 7.99125 22.6504 8.41087C22.6504 8.83048 22.4837 9.23292 22.187 9.52963C21.8903 9.82635 21.4879 9.99304 21.0683 9.99304H8.41087C7.99125 9.99304 7.58882 9.82635 7.2921 9.52963C6.99539 9.23292 6.82869 8.83048 6.82869 8.41087ZM6.82869 14.7396C6.82869 14.3199 6.99539 13.9175 7.2921 13.6208C7.58882 13.3241 7.99125 13.1574 8.41087 13.1574H14.7396C15.1592 13.1574 15.5616 13.3241 15.8583 13.6208C16.155 13.9175 16.3217 14.3199 16.3217 14.7396C16.3217 15.1592 16.155 15.5616 15.8583 15.8583C15.5616 16.155 15.1592 16.3217 14.7396 16.3217H8.41087C7.99125 16.3217 7.58882 16.155 7.2921 15.8583C6.99539 15.5616 6.82869 15.1592 6.82869 14.7396Z"
          />
        </svg>
      )}
    </div>
  );
}

Notification.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  badgetColor: PropTypes.string,
  notifications: PropTypes.number
};

export default Notification;
