import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import CollegesContainer from "./CollegesContainer";

function StudentDetailBody({ student, schools, programLength }) {
  const [colleges, setColleges] = useState([]);
  const [favorite, setFavorite] = useState(null);
  const [localProgramLength, setLocalProgramLength] = useState(null);

  const initialHeights = {
    title: 0,
    majors: 0,
    affordability: 0,
    graduationRate: 0,
  };
  const [heights, setHeights] = useState(initialHeights);
  const [sectionsState, setSectionsState] = useState({
    majors: true,
    graduationRate: true,
    earnings: false,
    diversity: false,
    campusEnvironment: false,
    firstYearBreakdown: true,
  });

  useEffect(() => {
    const localSchools = schools.filter(
      (school) => school.school.programLength === localProgramLength
    );

    const favorite = localSchools.find((school) => school.isFavorite);
    if (favorite) {
      setFavorite(favorite);
      setColleges(localSchools.filter((school) => !school.isFavorite));
    } else {
      setFavorite(null);
      setColleges(localSchools);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localProgramLength]);

  useEffect(() => {
    setColleges([]);
    setFavorite(null);
    setHeights(initialHeights);
    setLocalProgramLength(programLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programLength]);

  const setGlobalHeights = useCallback(
    (newHeights) =>
      setHeights((state) => ({
        title: newHeights.title > state.title ? newHeights.title : state.title,
        majors:
          newHeights.majors > state.majors ? newHeights.majors : state.majors,
        affordability:
          newHeights.affordability > state.affordability
            ? newHeights.affordability
            : state.affordability,
        graduationRate:
          newHeights.graduationRate > state.graduationRate
            ? newHeights.graduationRate
            : state.graduationRate,
      })),
    []
  );

  const setGlobalSectionState = useCallback(
    (newSectionState) => setSectionsState(newSectionState),
    []
  );

  return (
    <CollegesContainer
      heights={heights}
      schools={colleges}
      favorite={favorite}
      studentState={student.state}
      studentName={student.firstName}
      ethnicity={student.ethnicity}
      sectionsState={sectionsState}
      programLength={programLength}
      setGlobalHeights={setGlobalHeights}
      setGlobalSectionState={setGlobalSectionState}
    />
  );
}

StudentDetailBody.propTypes = {
  programLength: PropTypes.string,
  student: PropTypes.shape().isRequired,
  schools: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default StudentDetailBody;
