import { useState } from "react";
import PropTypes from "prop-types";

import ArchiveList from "../ArchiveList";
import Confirm from "../../Common/Confirm";

import Api from "../../../../services/Api";

function ArchiveStudentListModal({
  lists,
  filters,
  onClose,
  archiveLists,
  organizationId,
  setConfirmModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const list = archiveLists === 1 ? lists[0] : null;
  const sharedList = lists?.some(
    (list) => list.isShared || list.sharedWithOrganization
  );

  const archiveListOnClick = async () => {
    await Api.archiveStudentLists({
      organizationId,
      archived: true,
      lists: {
        filter: lists?.length > 0 ? null : filters,
        ids: lists?.length > 0 ? lists.map((list) => list.id) : null,
      },
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const firstTextConfirmation = (
    <span>
      Great! You have archived <span className="text-black">"{list.name}"</span>
      .
    </span>
  );
  const secondTextConfirmation = sharedList ? (
    <span className="text-2xl font-medium">
      Archiving this list will archive it for all the advisors it is shared
      with. You can find this list in Archived Lists.
    </span>
  ) : (
    <span className="text-2xl font-medium">
      This change will only be viewable in your account dashboard. You can find
      this list in Archived Lists.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <ArchiveList
          list={list}
          onSubmit={archiveListOnClick}
          secondText={secondTextConfirmation}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to All Students"
        />
      )}
    </div>
  );
}

ArchiveStudentListModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  archiveLists: PropTypes.number,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  lists: PropTypes.arrayOf(PropTypes.shape()),
};

export default ArchiveStudentListModal;
