import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import AdvisorsTips from "./Advisors";
import StudentsTips from "./Students";
import WelcomeModal from "./WelcomeModal";

import { useAdvisor } from "../../../redux/Advisor/hooks";
import { updateAdvisor } from "../../../redux/Advisor/actions";
import { AdvisorRoles } from "../../Dashboard/Advisors/Utils";

function Tips({ onboardingProcess, onChangeTab }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useAdvisor();

  const [showModal, setShowModal] = useState(false);
  const [activeTips, setActiveTips] = useState(null);
  const [modalAlreadyShowed, setModalAlreadyShowed] = useState(false);

  useEffect(() => {
    if (
      !modalAlreadyShowed &&
      (onboardingProcess.tab === 0 || onboardingProcess.click)
    ) {
      setShowModal(true);
      setModalAlreadyShowed(true);
    }

    switch (onboardingProcess.tab) {
      case 0:
        if (onboardingProcess.role === AdvisorRoles.ADMIN)
          setActiveTips(
            <AdvisorsTips onFinish={handlerOnFinish} onSkip={handlerOnSkip} />
          );
        else
          setActiveTips(
            <StudentsTips
              onSkip={handlerOnSkip}
              onFinish={handlerOnFinish}
              role={onboardingProcess.role}
            />
          );
        break;
      case 1:
        if (onboardingProcess.role === AdvisorRoles.ADMIN)
          setActiveTips(
            <StudentsTips
              onSkip={handlerOnSkip}
              onFinish={handlerOnFinish}
              role={onboardingProcess.role}
            />
          );
        break;

      default:
        setActiveTips(null);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingProcess]);

  const handlerOnSkip = () => {
    if (!profile.onboardingAdmin || !profile.onboardingAdvisor) {
      let mutationData = {};
      switch (onboardingProcess.role) {
        case AdvisorRoles.ADMIN:
          if (!profile.onboardingAdmin && !profile.onboardingAdvisor) {
            mutationData.onboardingAdmin = true;
            mutationData.onboardingAdvisor = true;
          } else if (!profile.onboardingAdmin && profile.onboardingAdvisor) {
            mutationData.onboardingAdmin = true;
          }
          break;

        default:
          if (!profile.onboardingAdvisor) mutationData.onboardingAdvisor = true;
          break;
      }

      if (Object.keys(mutationData).length > 0) {
        dispatch(updateAdvisor(mutationData));
      }
    }
    navigate("/");
  };

  const handlerOnFinish = () => {
    if (
      !onboardingProcess.complete ||
      onboardingProcess.tab >= onboardingProcess.totalTabs - 1
    )
      handlerOnSkip();
    else onChangeTab(onboardingProcess.tab + 1);
  };

  const handlerOnContinue = () => setShowModal(false);

  return (
    <div className="z-30 top-0 left-0 right-0 bottom-0 fixed">
      <div
        className="
          top-0
          fixed
          left-0
          right-0
          bottom-0
          bg-black
          opacity-40
        "
      ></div>
      <div className="flex flex-1 w-full h-full flex-col">
        {showModal ? (
          <div className="flex w-full h-full items-center justify-center">
            <WelcomeModal
              isOpen={showModal}
              handlerOnClose={handlerOnSkip}
              handlerOnContinue={handlerOnContinue}
            />
          </div>
        ) : (
          activeTips
        )}
      </div>
    </div>
  );
}

Tips.propTypes = {
  onChangeTab: PropTypes.func,
  onboardingProcess: PropTypes.shape(),
};

export default Tips;
