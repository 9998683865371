import PropTypes from "prop-types";

function HamburgerMenu({
  size = 40,
  color = "fill-black",
  className = "",
  ...restProps
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        className={color}
        d="M1 25.6809C1 24.3998 1.99085 23.3613 3.21311 23.3613H28.7869C30.0092 23.3613 31 24.3998 31 25.6809C31 26.962 30.0092 28.0005 28.7869 28.0005H3.21312C1.99085 28.0005 1 26.962 1 25.6809Z"
      />
      <path
        className={color}
        d="M1 15.4993C1 14.2182 1.99085 13.1797 3.21311 13.1797H28.7869C30.0092 13.1797 31 14.2182 31 15.4993C31 16.7803 30.0092 17.8188 28.7869 17.8188H3.21312C1.99085 17.8188 1 16.7803 1 15.4993Z"
      />
      <path
        className={color}
        d="M1 5.31958C1 4.03851 1.99085 3 3.21311 3H28.7869C30.0092 3 31 4.03851 31 5.31958C31 6.60064 30.0092 7.63915 28.7869 7.63915H3.21312C1.99085 7.63915 1 6.60064 1 5.31958Z"
      />
    </svg>
  );
}

HamburgerMenu.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string
};

export default HamburgerMenu;
