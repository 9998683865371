import PropTypes from "prop-types";

import { Close, MagnifyGlass } from "../../icons";

function YourMajorsLabel({
  schoolName,
  showSearch,
  showActions,
  setShowSearch,
}) {
  return (
    <div className="mt-2 flex flex-row justify-between">
      <p className="text-sm font-bold truncate normal-case">
        Your majors at {schoolName}
      </p>
      {showActions && (
        <div className="flex flex-row min-w-9rem justify-end">
          {!showSearch ? (
            <>
              <p className="mr-2 text-sm font-bold capitalize">Search Again?</p>
              <div
                role="button"
                className="place-self-center"
                onClick={() => setShowSearch(true)}
              >
                <MagnifyGlass size={20} />
              </div>
            </>
          ) : (
            <>
              <p className="mr-2 text-sm font-bold capitalize">Close Search</p>
              <div
                role="button"
                className="place-self-center"
                onClick={() => setShowSearch(false)}
              >
                <Close size={17} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

YourMajorsLabel.propTypes = {
  showSearch: PropTypes.bool,
  showActions: PropTypes.bool,
  schoolName: PropTypes.string,
  setShowSearch: PropTypes.func,
};

export default YourMajorsLabel;
