import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { LoadingButton } from "../../../core/buttons";

function DeleteList({
  lists,
  onSubmit,
  sharedList,
  deleteLists,
  isAdvisorList = false
}) {
  const firstText = (
    <>
      <div
        className={`
          pt-4
          text-3xl
          font-black
          leading-10
          text-primary-blue
        `}
      >
        Are you sure you want to delete
        {deleteLists === 1
          ? " this list"
          : lists?.length > 0
          ? " these lists"
          : ` ${deleteLists} lists`}
        ?
      </div>
      {lists?.length > 0 && (
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {lists.map((list) => (
              <li key={list.id} className="list-disc list-inside">
                <span>{list.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );

  const singularSecondText = (
    <span className="text-2xl font-medium">
      This list will no longer be visible for all organization
      {isAdvisorList ? " admins" : " advisors"} that it is shared with and it
      will no longer appear in your
      {isAdvisorList ? " advisor " : " student "}
      lists. This action cannot be undone.
    </span>
  );

  const pluralSecondText = (
    <span className="text-2xl font-medium">
      At least one list is shared. These lists will no longer be visible for all
      organization {isAdvisorList ? " admins" : " advisors"} that they are
      shared with and they will no longer appear in your
      {isAdvisorList ? " advisor " : " student "}
      lists. This action cannot be undone.
    </span>
  );

  const secondText = sharedList ? (
    <span className="text-2xl font-medium">
      {deleteLists === 1 ? singularSecondText : pluralSecondText}
    </span>
  ) : (
    <span className="text-2xl font-medium">
      {deleteLists === 1 ? "This list " : "These lists "}will no longer appear
      in your {isAdvisorList ? "advisor" : "student"} lists and cannot be
      undone.
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onSubmit();
  };

  return (
    <>
      <div className="overflow-auto px-1">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="archive-group-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          Delete {deleteLists === 1 ? "list" : "lists"}
        </LoadingButton>
      </div>
    </>
  );
}

DeleteList.propTypes = {
  sharedList: PropTypes.bool,
  isAdvisorList: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  deleteLists: PropTypes.number.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape())
};

export default DeleteList;
