import { useState, useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import PropTypes from "prop-types";

import CustomSwitch from "../Common/CustomSwitch";
import PrivateRoute from "../Common/PrivateRoute";

import SideBar from "./SideBar";
import StudentTable from "./Students/Table";
import AdvisorTable from "./Advisors/Table";
import StudentDetail from "./Students/Detail";
import StudentInvitesTable from "./Invites/Students";
import AdvisorInvitesTable from "./Invites/Advisors";
import ArchivedStudentListTable from "./Students/ArchiveTable";
import ArchivedAdvisorListTable from "./Advisors/ArchiveTable";
import UploadLetterRoutes from "./Students/UploadLetter";

function StudentRoutes() {
  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <StudentTable />
          </PrivateRoute>
        }
      />
      <Route
        path=":studentId"
        element={
          <PrivateRoute>
            <StudentDetail />
          </PrivateRoute>
        }
      />
      <Route
        path=":studentId/:collegeId/upload-award/*"
        element={
          <PrivateRoute>
            <UploadLetterRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="list/:listId"
        element={
          <PrivateRoute>
            <StudentTable />
          </PrivateRoute>
        }
      />
      <Route
        path="lists/archive"
        element={
          <PrivateRoute>
            <ArchivedStudentListTable />
          </PrivateRoute>
        }
      />
      <Route
        path="items/archive"
        element={
          <PrivateRoute>
            <StudentTable isArchiveList={true} />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function AdvisorRoutes() {
  return (
    <CustomSwitch>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <AdvisorTable />
          </PrivateRoute>
        }
      />
      <Route
        path="list/:listId"
        element={
          <PrivateRoute>
            <AdvisorTable />
          </PrivateRoute>
        }
      />
      <Route
        path="lists/archive"
        element={
          <PrivateRoute>
            <ArchivedAdvisorListTable />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function InvitesRoutes() {
  return (
    <CustomSwitch>
      <Route
        path="students"
        element={
          <PrivateRoute>
            <StudentInvitesTable />
          </PrivateRoute>
        }
      />
      <Route
        path="advisors"
        element={
          <PrivateRoute>
            <AdvisorInvitesTable />
          </PrivateRoute>
        }
      />
    </CustomSwitch>
  );
}

function Dashboard({ sections }) {
  const initialHeight = window.innerHeight - 72;
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    const heightListener = () => {
      const container = document.getElementById("dashboard-container");
      setHeight((height) => {
        const newHeight = container.getBoundingClientRect().height;

        return height > newHeight ? initialHeight : newHeight;
      });
    };

    window.addEventListener("mousemove", heightListener);
    return () => window.removeEventListener("mousemove", heightListener);
  }, [initialHeight]);

  return (
    <div className="flex flex-1 flex-row bg-white min-h-screen-footer-md">
      <div
        style={{ maxHeight: `${height}px` }}
        className="w-60 flex flex-col shadow-md"
      >
        <SideBar sections={sections} />
      </div>
      <div
        id="dashboard-container"
        className="
          flex
          flex-1
          flex-col
          border-l
          shadow-md
          overflow-auto
          border-light-gray
        "
      >
        <CustomSwitch className="min-h-screen-footer-md">
          <Route
            path="/"
            element={<Navigate to={`/dashboard/${sections[0]?.id}`} />}
          />
          <Route path="/students/*" element={<StudentRoutes />} />
          <Route path="/advisors/*" element={<AdvisorRoutes />} />
          <Route path="/invites/*" element={<InvitesRoutes />} />
        </CustomSwitch>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Dashboard;
