import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import NoteItem from "./NoteItem";
import DotsButton from "../DotsButton";

import { InfiniteScroll } from "../../../../core";
import { ShortArrow } from "../../../../core/icons";

function NoteList({
  notes,
  order,
  toggleOrder,
  handlerPagination,
  handlerOnExpandNote,
}) {
  const [styleScroll, setStyleScroll] = useState({});

  useEffect(() => {
    calculateMaxHeight();
    window.addEventListener("resize", calculateMaxHeight);
    return () => window.removeEventListener("resize", calculateMaxHeight);
  }, []);

  const calculateMaxHeight = () => {
    const containerHeight = document
      .getElementById("share-notes-container")
      ?.getBoundingClientRect().height;
    const headerHeight = document
      .getElementById("share-note-list-header")
      ?.getBoundingClientRect().height;

    setStyleScroll({ maxHeight: `${containerHeight - headerHeight}px` });
  };

  return (
    <>
      <div
        id="share-note-list-header"
        className="px-2 py-4 flex flex-1 flex-row items-center"
      >
        <div className="flex flex-1 flex-row items-center">
          <div className="mr-2 -rotate-90 transform">
            <ShortArrow size={25} color="stroke-gray-500" />
          </div>
          <span className="text-sm">
            Most recently updated notes displayed first
          </span>
        </div>
        <div className="self-end">
          <DotsButton order={order} toggleOrder={toggleOrder} />
        </div>
      </div>
      <InfiniteScroll
        style={styleScroll}
        querySelector="screenViewportNotes"
        handlePagination={handlerPagination}
        className="
          pt-2
          px-4
          mb-5
          divide-y
          divide-light-gray
        "
      >
        {notes.map((note, index) => (
          <div key={index} className="pt-2">
            <NoteItem
              text={note.text}
              date={note.insertTime}
              advisorName={note.advisorName}
              handlerOnExpand={() => handlerOnExpandNote(note)}
            />
          </div>
        ))}
      </InfiniteScroll>
    </>
  );
}

NoteList.propTypes = {
  notes: PropTypes.array,
  order: PropTypes.string,
  toggleOrder: PropTypes.func,
  handlerPagination: PropTypes.func,
  handlerOnExpandNote: PropTypes.func,
};

export default NoteList;
