import PropTypes from "prop-types";

function StatusCell({ invitation }) {
  const invitationDate = invitation.insertTime.toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="text-center">
      <p className="text-sm font-bold capitalize">{invitation.status}</p>
      <p className="text-xs font-bold text-gray-500">{invitationDate}</p>
    </div>
  );
}

StatusCell.propTypes = {
  invitation: PropTypes.shape(),
};

export default StatusCell;
