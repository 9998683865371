import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import { Button } from "../../buttons";
import { NotificationStatus } from "./utils";
import "../../styles/markdown.css";

function Item({
  date,
  colors,
  status,
  content,
  action,
  sentBy,
  viewResultsDataLayer,
  ...restProps
}) {
  const actionCallback = () => {
    action.callback();
    viewResultsDataLayer && viewResultsDataLayer();
  };

  return (
    <div className="py-2 flex flex-col cursor-pointer" {...restProps}>
      {status === NotificationStatus.NEW && (
        <div className="flex flex-1 flex-row space-x-2 font-bold">
          <span className="text-primary-orange">New!</span>
        </div>
      )}
      <ReactMarkdown children={content} className="markdown-body" />
      {action?.button && (
        <div className="mt-4">
          <Button
            onClick={actionCallback}
            className={`
              py-1
              px-4
              text-xs
              ${colors.composeBg}
              ${colors.primary.border}
            `}
          >
            {action.text}
          </Button>
        </div>
      )}
      <div className="mt-2 flex flex-1 flex-row text-xs font-bold">
        <div className="flex flex-1 flex-row">From: {sentBy}</div>
        <div className="flex flex-row justify-end">
          <span className={colors.primary.text}>
            {date.toLocaleDateString("en-US")}
          </span>
        </div>
      </div>
    </div>
  );
}

Item.propTypes = {
  sentBy: PropTypes.string,
  colors: PropTypes.shape(),
  action: PropTypes.shape(),
  markAsRead: PropTypes.func,
  status: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

export default Item;
