import { useEffect, useState, useContext, useMemo } from "react";

import SpinnerContext from "../../../contexts/SpinnerContext";

import Api from "../../../services/Api";
import { Order } from "../../../utils/order";
import { SortingType } from "../../Dashboard/Advisors/Utils";

export const DOTS = "...";

export function useSpinner(callback) {
  const setSpinner = useContext(SpinnerContext);
  return async function withSpinner(...args) {
    try {
      setSpinner(true);
      return await callback(...args);
    } finally {
      setSpinner(false);
    }
  };
}

export function usePagination({
  pageSize,
  itemsCount,
  currentPage,
  siblingCount,
}) {
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(itemsCount / pageSize);

    const totalPageNumbers = siblingCount + 3;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return range(1, totalPageCount);
  }, [pageSize, itemsCount, currentPage, siblingCount]);

  return paginationRange;
}

export function useFetchOrganizationAdvisors(organizationId) {
  const [advisors, setAdvisors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getAllOrganizationAdvisors(organizationId) {
    const pageSize = 50;
    let page = { pageNumber: 1, pageSize };
    const orderBy = { [SortingType.NAME]: Order.asc };
    const { pageCount, items } = (await Api.fetchOrganizationAdvisorsBasic({
      page,
      orderBy,
      organizationId,
    })) || { pageCount: 0, items: [] };
    let advisors = items;
    for (let index = 2; index <= pageCount; index++) {
      page = { pageNumber: index, pageSize };
      const { items } = await Api.fetchOrganizationAdvisorsBasic({
        page,
        orderBy,
        organizationId,
      });
      advisors = advisors.concat(items);
    }
    return advisors.map((item) => {
      return {
        id: item.id,
        role: item.role,
        userId: item.advisor.userId,
        name: `${item.advisor.firstName} ${item.advisor.lastName}`,
      };
    });
  }

  useEffect(() => {
    let suscribed = true;
    async function fetch() {
      const data = await getAllOrganizationAdvisors(organizationId);
      if (suscribed) {
        setAdvisors(data);
        setIsLoading(false);
      }
    }
    fetch();
    setIsLoading(true);

    return () => (suscribed = false);
  }, [organizationId]);

  return { isLoading, advisors };
}
