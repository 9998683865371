import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";

function HomeLayout({ children, isSimple }) {
  return (
    <>
      <Header isSimple={isSimple} />
      <div className="bg-white relative min-h-screen-footer-md">{children}</div>
      <Footer />
    </>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.any
};

export default HomeLayout;
