import { useState, useEffect, useCallback } from "react";
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { Form } from "../../../core";
import { LoadingButton } from "../../../core/buttons";
import { Colors } from "../../../core/utils";

function EditOrgForm({
  id,
  onSubmit,
  defaultValues = {},
  requestedClose,
  organizationId,
  saveChangesError,
  requestCloseHandle
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [anyChanges, setAnyChanges] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const [formValid, setFormValid] = useState(false);

  const { watch, setError, handleSubmit, formState, control } = useForm({
    mode: "onChange",
    defaultValues
  });

  const [orgName] = watch(["orgName"]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      name: orgName,
      organizationId
    });
  }, [orgName, organizationId, requestCloseHandle]);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  useEffect(() => {
    if (saveChangesError) {
      if (saveChangesError.includes("orgName"))
        setError("orgName", {
          message: "Organization name is required."
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChangesError]);

  useEffect(() => {
    if (isEqual(orgName, defaultValues.orgName)) {
      setAnyChanges(false);
    } else setAnyChanges(true);
  }, [orgName, defaultValues]);

  useEffect(() => setFormValid(formState.isValid), [formState.isValid]);

  const buttonDisabled = !anyChanges || !formValid;

  return (
    <Form
      id={id}
      onSubmit={handleSubmit(async (form) => {
        setIsLoading(true);
        setSomethingWentWrong(false);
        try {
          const formCreateListData = {
            organizationId,
            name: form.orgName
          };
          await onSubmit(formCreateListData);
        } catch (error) {
          setSomethingWentWrong(true);
        }
      })}
    >
      <div className="px-1 h-full pt-6 overflow-auto">
        <Form.Input
          required={true}
          name="orgName"
          control={control}
          color={Colors.BLUE}
          id="org-name-input"
          errors={formState.errors}
          label="Update your organization name below."
          rules={{
            required: {
              value: true,
              message: "Organization Name is required."
            }
          }}
          className="normal-case"
        />
        <Form.ErrorMessage errors={formState.errors} name="orgName" />

        <p className="text-sm font-semibold text-black">
          This new name will appear for all advisors in your organization
        </p>

        <div className="mt-1">
          {somethingWentWrong && (
            <p className="text-sm font-semibold text-primary-red">
              Something went wrong, please try again later!
            </p>
          )}
        </div>
      </div>
      <div className="py-5 flex flex-row mt-auto">
        <LoadingButton
          form={id}
          type="submit"
          value="submit"
          isLoading={isLoading}
          disabled={buttonDisabled}
          id="create-edit-list-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          Save changes
        </LoadingButton>
      </div>
    </Form>
  );
}

EditOrgForm.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  requestedClose: PropTypes.bool,
  organizationId: PropTypes.string,
  defaultValues: PropTypes.shape(),
  requestCloseHandle: PropTypes.func,
  saveChangesError: PropTypes.string
};

export default EditOrgForm;
