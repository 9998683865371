import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Link } from "../../../core";
import { Button } from "../../../core/buttons";
import { GaugeChart } from "../../../core/charts";
import { TwoPeople, ShortArrow, TwoStudents } from "../../../core/icons";

import { decidedRouteSupport } from "../../../core/utils";

import Api from "../../../services/Api";
import { Items, saveState } from "../../../redux/storePersist";
import { GetInvitationStatus, InvitationStatus } from "../Invites/Utils";

function InvitesInsight({
  refetchData,
  setRefetchData,
  organizationId,
  invitesInsights,
  handlerOnViewInsight,
}) {
  const navigate = useNavigate();

  const isMounted = useRef(false);
  const [content, setContent] = useState(null);

  const filters = useMemo(
    () => [
      {
        key: InvitationStatus.ACCEPTED,
        name: GetInvitationStatus(InvitationStatus.ACCEPTED),
      },
      {
        key: InvitationStatus.PENDING,
        name: GetInvitationStatus(InvitationStatus.PENDING),
      },
      {
        key: InvitationStatus.EXPIRED,
        name: GetInvitationStatus(InvitationStatus.EXPIRED),
      },
    ],
    []
  );

  useEffect(() => {
    isMounted.current = true;

    const fetchData = async () => {
      setRefetchData(false);
      const {
        percentInvites,
        totalInvitesCount,
        filterInvitesCount,
        totalStudentsCount,
      } = await Api.fetchStudentInviteInsights({
        organizationId,
        filter: filters.slice(1, 3).map((filter) => filter.key),
      });

      if (isMounted.current) {
        const handlerOnClick = () => {
          const computedFilters = filters.slice(1, 3);
          if (!invitesInsights) {
            saveState(
              { internalState: { filters: computedFilters } },
              Items.APP_STATE
            );

            navigate("/dashboard/invites/students");
          } else handlerOnViewInsight(computedFilters, false);
        };

        const handlerOnClick2 = () => {
          if (!invitesInsights) {
            saveState({ internalState: { modal: true } }, Items.APP_STATE);

            navigate("/dashboard/invites/students");
          } else handlerOnViewInsight(null, true);
        };

        if (
          (totalInvitesCount === 0 && totalStudentsCount === 0) ||
          (totalInvitesCount > 0 &&
            filterInvitesCount === 0 &&
            totalStudentsCount === 0)
        )
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="flex w-1/3 justify-center">
                  <TwoStudents size={60} color="fill-gray-500" />
                </div>
                <div className="w-2/3">
                  <p>You haven't added any students yet.</p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-xs font-bold">
                  <Button
                    onClick={handlerOnClick2}
                    className="
                      w-fit
                      normal-case
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    Invite Students
                  </Button>
                </div>
              </div>
            </>
          );
        else if (filterInvitesCount > 0 && totalStudentsCount === 0)
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="flex w-1/3 justify-center">
                  <TwoPeople size={60} color="fill-gray-500" />
                </div>
                <div className="w-2/3">
                  <p>None of your students have accepted your invite yet.</p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-xs font-bold">
                  <Button
                    onClick={handlerOnClick}
                    className="
                      w-fit
                      normal-case
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    Re-send Invites
                  </Button>
                </div>
              </div>
            </>
          );
        else if (filterInvitesCount === 0 && totalStudentsCount > 0)
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="flex w-1/3 justify-center">
                  <p className="text-6xl font-bold text-alternative-blue">
                    {totalStudentsCount}
                  </p>
                </div>
                <div className="w-2/3">
                  <p>You're working with {totalStudentsCount} students.</p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-sm font-bold">
                  <Link
                    icon={true}
                    target="_blank"
                    internal={false}
                    text="Message students"
                    href={decidedRouteSupport(
                      "/articles/8097522663700-Messaging-students-with-notifications"
                    )}
                    customIcon={
                      <div className="-ml-1 transform place-self-end rotate-180">
                        <ShortArrow size={12} color="stroke-current" />
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          );
        else if (filterInvitesCount > 0 && totalStudentsCount > 0)
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="w-1/3">
                  <GaugeChart percent={percentInvites} />
                </div>
                <div className="w-2/3">
                  <p>
                    <span className="font-extrabold">{percentInvites}%</span> of
                    your students have accepted your invite.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-sm font-bold">
                  <Link
                    icon={true}
                    onClick={handlerOnClick}
                    text="View pending invites"
                    customIcon={
                      <div className="-ml-1 transform place-self-end rotate-180">
                        <ShortArrow size={12} color="stroke-current" />
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          );
      }
    };

    fetchData();
    return () => (isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refetchData, organizationId]);

  return <div className="flex flex-1 flex-col">{content}</div>;
}

InvitesInsight.propTypes = {
  refetchData: PropTypes.bool,
  setRefetchData: PropTypes.func,
  invitesInsights: PropTypes.bool,
  organizationId: PropTypes.string,
  handlerOnViewInsight: PropTypes.func,
};

export default InvitesInsight;
