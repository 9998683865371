import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import ChangeOwner from "../ChangeOwner";
import Confirm from "../../Common/Confirm";
import CreateListForm from "../CreateListForm";

import Api from "../../../../services/Api";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";

function CreateListStudentModal({
  onClose,
  filters,
  students,
  elements,
  organizationId,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const formID = "create-student-list-form";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useAdvisor();

  const [save, setSave] = useState(false);
  const [listId, setListId] = useState(null);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeOwner, setChangeOwner] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState("");
  const [saveChangesError, setSaveChangesError] = useState(null);
  const [confirmChangeOwner, setConfirmChangeOwner] = useState(false);

  const getTextConfirmation = (wholeOrg, dataMutation) => {
    if (wholeOrg) {
      return (
        <span className="text-3xl text-black font-semibold">
          Great! You created a new student list called
          <span className="text-primary-blue"> {dataMutation.name}</span> and
          shared it with your whole organization. Advisors can find the list
          under Org. Lists.
        </span>
      );
    } else if (dataMutation.sharedWith.length > 0) {
      return (
        <span className="text-3xl text-black font-semibold">
          Great! You've created a new student list called
          <span className="text-primary-blue"> {dataMutation.name}</span> and
          shared it with some advisors in your organization. They can find this
          list under Org. Lists.
        </span>
      );
    } else {
      return (
        <span className="text-3xl text-black font-semibold">
          Great! You've created a new private student list called
          <span className="text-primary-blue"> {dataMutation.name}</span>. You
          can find this under My Lists.
        </span>
      );
    }
  };

  const createListOnSubmit = async (form) => {
    if (!confirmChangeOwner && form.owner.id !== profile.id) {
      setConfirmChangeOwner(true);
      setChangeOwner(true);
      setSaveData(form);
      if (save) {
        setSave(false);
        setOnRequestClose(false);
      }
      return;
    }

    const sharedWith =
      form?.sharedWith
        .filter((advisor) => advisor.id !== "nobody")
        .filter((advisor) => advisor.id !== "all")
        .map((advisor) => advisor.id) || null;
    const wholeOrg =
      form?.sharedWith.find((advisor) => advisor.id === "all") || null;
    const dataMutation = {
      filters,
      sharedWith,
      organizationId,
      name: form.listName,
      ownerId: form.owner.id,
      sharedWithOrganization: wholeOrg ? true : false,
      students:
        students || filters
          ? {
              filter: students?.length > 0 ? null : filters,
              studentIds: students?.length > 0 ? students : null,
            }
          : null,
    };

    const { id } = await Api.createStudentList(dataMutation);
    await dispatch(fetchOrganizations());
    setListId(id);

    setTextConfirmation(getTextConfirmation(wholeOrg, dataMutation));
    setConfirm(true);
    setConfirmModal(true);
    if (confirmChangeOwner) setConfirmChangeOwner(false);
  };

  const saveChangeOwner = async () => {
    setIsLoading(true);
    await createListOnSubmit(saveData);

    setIsLoading(false);
    setChangeOwner(false);
  };

  const saveChanges = async () => {
    const errors = [];
    setIsLoading(true);
    if (saveData.listName === "") errors.push("listName");
    if (!saveData.sharedWith) errors.push("sharedWith");

    if (errors.length > 0) setSaveChangesError(errors);
    else await createListOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const backChangeOwnerHandle = () => {
    setChangeOwner(false);
    setConfirmChangeOwner(false);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
    if (confirmChangeOwner) setChangeOwner(true);
  };

  const requestCloseHandle = (form) => {
    setSaveChangesError(null);
    setSaveData(form);
    setSave(true);
  };

  const requestCloseHandle2 = () => {
    setSave(true);
    setChangeOwner(false);
  };

  const handleOnClose = useCallback(() => {
    navigate(`/dashboard/students/list/${listId}`);
    onClose();
  }, [listId, navigate, onClose]);

  const defaultValues = {
    listName: saveData.listName || "",
    sharedWith: saveData.sharedWith || null,
    owner: saveData.owner || {
      id: profile.id,
      value: profile.id,
      label: `${profile.firstName} ${profile.lastName}`,
    },
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && !changeOwner && (
        <>
          {elements === 0 && (
            <div className="flex flex-row w-4/5">
              <p className="text-sm">
                <span className="font-bold">Did you mean to add students?</span>{" "}
                Go back to select one or more students from "All Students"
                first. It's also easy to add students after creating a list.
              </p>
            </div>
          )}
          <CreateListForm
            id={formID}
            defaultValues={defaultValues}
            onSubmit={createListOnSubmit}
            requestedClose={requestedClose}
            organizationId={organizationId}
            saveChangesError={saveChangesError}
            requestCloseHandle={requestCloseHandle}
          />
        </>
      )}
      {!confirm && !save && changeOwner && (
        <ChangeOwner
          isLoading={isLoading}
          requestedClose={requestedClose}
          saveChangeOwner={saveChangeOwner}
          requestCloseHandle={requestCloseHandle2}
          backChangeOwnerHandle={backChangeOwnerHandle}
        />
      )}
      {confirm && !save && !changeOwner && (
        <Confirm
          buttonText="View List"
          onClick={handleOnClose}
          firstText={textConfirmation}
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

CreateListStudentModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  setOnRequestClose: PropTypes.func,
  students: PropTypes.arrayOf(PropTypes.string),
};

export default CreateListStudentModal;
