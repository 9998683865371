import PropTypes from "prop-types";

function UploadedLetter({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 70 73"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M53.5299 72.3099H0.919922V13.1599L13.1999 0.449951H53.5299V72.3099ZM5.26992 67.9999H49.1799V4.79993H15.0399L5.26992 14.9099V67.9999Z"
      />
      <path
        className={color}
        d="M16.1918 16.2099H3.0918V11.8599H11.8518V2.62988H16.1918V16.2099Z"
      />
      <path
        className={color}
        d="M44.5616 11.8599H20.8516V16.2099H44.5616V11.8599Z"
      />
      <path
        className={color}
        d="M44.5516 21.5701H9.93164V25.9201H44.5516V21.5701Z"
      />
      <path className={color} d="M44.5512 31.28H9.53125V35.63H44.5512V31.28Z" />
      <path className={color} d="M44.55 40.99H31V45.34H44.55V40.99Z" />
      <path className={color} d="M44.55 50.7H31V55.0499H44.55V50.7Z" />
      <path
        className={color}
        d="M44.5518 60.4099H30.8418V64.7599H44.5518V60.4099Z"
      />
      <path
        className={color}
        d="M18.8002 62.38C15.0602 62.38 12.7402 60.05 12.7402 56.29H17.0802C17.0802 57.77 17.5302 58.04 18.8002 58.04C20.3102 58.04 20.3102 57.37 20.3102 56.97C20.3102 55.79 19.9702 55.49 17.7602 54.71C15.8802 54.06 12.7602 52.95 12.7602 49.11C12.7602 45.67 15.1902 43.36 18.8202 43.36C19.5911 43.3434 20.3574 43.4837 21.0724 43.7723C21.7875 44.061 22.4364 44.4919 22.9797 45.039C23.5231 45.5861 23.9496 46.238 24.2333 46.955C24.5171 47.672 24.6521 48.4392 24.6302 49.21H22.4802H20.3102C20.3102 47.67 19.2002 47.67 18.8002 47.67C17.2902 47.67 17.0802 48.38 17.0802 49.07C17.0802 49.76 17.0802 49.83 19.2002 50.57C21.3202 51.31 24.6302 52.53 24.6302 57C24.6302 60.21 22.3002 62.38 18.8002 62.38Z"
      />
      <path className={color} d="M20.8715 41.04H16.5215V45.53H20.8715V41.04Z" />
      <path className={color} d="M20.8715 60.21H16.5215V64.71H20.8715V60.21Z" />
    </svg>
  );
}

UploadedLetter.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default UploadedLetter;
