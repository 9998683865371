import { useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

function NoteItem({ date, text, advisorName, handlerOnExpand }) {
  const noteDate = new Date(date);
  const [showMore, setShowMore] = useState(false);

  const onRefChange = (node) => {
    if (node) {
      const conatinerHeight = document
        .getElementById("note-item-container")
        .getBoundingClientRect().height;
      const headerHeight = document
        .getElementById("note-item-header")
        .getBoundingClientRect().height;
      const bodyHeight = node.getBoundingClientRect().height;

      if (bodyHeight + headerHeight > conatinerHeight) setShowMore(true);
      else setShowMore(false);
    }
  };

  return (
    <div
      id="note-item-container"
      className="h-16 flex flex-col relative overflow-hidden truncate"
    >
      <div id="note-item-header">
        <div
          className="
          flex
          flex-1
          text-sm
          flex-row
          divide-x
          divide-gray-500
          text-gray-500
        "
        >
          <span className="pr-2">{noteDate.toLocaleDateString("en-US")}</span>
          <span className="pl-2">Advisor: {advisorName}</span>
        </div>
      </div>
      <div
        ref={onRefChange}
        className="
          -mb-4
          text-sm
          markdown-body
        "
      >
        <ReactMarkdown children={text} />
      </div>
      {showMore && (
        <div
          onClick={handlerOnExpand}
          className="right-0 bottom-0 absolute bg-white"
        >
          <span
            role="button"
            className="
            text-sm
            underline
            font-bold
            hover:text-primary-blue
          "
          >
            Read More
          </span>
        </div>
      )}
    </div>
  );
}

NoteItem.propTypes = {
  date: PropTypes.string,
  text: PropTypes.string,
  advisorName: PropTypes.string,
  handlerOnExpand: PropTypes.func,
};

export default NoteItem;
