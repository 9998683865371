import PropTypes from "prop-types";

import Box from "./Box";

import { Button } from "../../../core/buttons";
import { Info, Close } from "../../../core/icons";

import { useIsDesktopMQL } from "../../../core/hooks";

function WelcomeModal({
  handlerOnClose = () => {},
  handlerOnContinue = () => {}
}) {
  const isDesktop = useIsDesktopMQL();

  return (
    <div
      style={{ width: isDesktop ? "500px" : "100%" }}
      className="z-30 flex flex-col absolute items-center"
    >
      <Box className="w-full">
        <div className="flex flex-1 flex-row items-center justify-between">
          <div
            role="button"
            id="button-skip-tutorial-modal"
            onClick={() => handlerOnClose()}
            className="text-sm underline font-light text-white"
          >
            Skip tutorial
          </div>
          <div
            role="button"
            className="place-self-center"
            id="button-close-tutorial-modal"
            onClick={() => handlerOnClose()}
          >
            <Close size={20} color="stroke-white" />
          </div>
        </div>
        <div className="my-4 flex flex-col items-center justify-center">
          <div className="text-2xl text-center font-bold text-white">
            <p>Welcome to the</p>
            <p>DecidED Advisor Dashboard!</p>
          </div>
          <div className="my-2 text-lg text-center font-normal text-white">
            <p>
              Follow this tutorial to learn how to get started working with
              students right away. You can always access this tutorial by
              clicking on the information button{" "}
              <span className="mx-1 inline-block">
                <Info size={20} color="fill-white" />
              </span>{" "}
              in the top left corner of each tab.
            </p>
          </div>
          <div className="mt-4 flex w-1/2 flex-row items-center">
            <Button
              onClick={() => handlerOnContinue()}
              id="onboarding-modal-continue-button"
              className="
                w-full
                text-xs
                bg-white
                border-white
                text-primary-blue
                hover:text-white
                active:text-white
                hover:bg-primary-blue
                active:bg-primary-blue
              "
            >
              continue
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
}

WelcomeModal.propTypes = {
  handlerOnClose: PropTypes.func,
  handlerOnContinue: PropTypes.func
};

export default WelcomeModal;
