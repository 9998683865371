import { Logo } from "../../core/icons";
import { Button } from "../../core/buttons";

import { decidedRoute } from "../../core/utils";

function Footer() {
  return (
    <section className="p-4 flex flex-1 flex-row bg-black items-center">
      <div className="flex flex-1">
        <Logo size={30} color="fill-white" />
      </div>
      <Button
        onClick={() => window.open(decidedRoute("/about"), "_blank")}
        className="
          mx-2
          justify-end
          text-white
          border-black
          mobile:mx-0
          mobile-sm:mx-0
          tablet-sm:mx-0
          hover:underline
          active:underline
        "
      >
        about
      </Button>
      <Button
        onClick={() => window.open(decidedRoute("/legal"), "_blank")}
        className="
          mx-2
          justify-end
          text-white
          border-black
          mobile:mx-0
          mobile-sm:mx-0
          tablet-sm:mx-0
          hover:underline
          active:underline
        "
      >
        legal
      </Button>
      <Button
        onClick={() => window.open("https://support.decided.org/hc/en-us", "_blank")}
        className="
          mx-2
          justify-end
          text-white
          border-black
          mobile:hidden
          mobile-sm:hidden
          tablet-sm:hidden
          hover:underline
          active:underline
        "
      >
        help
      </Button>
      <div className="mr-16" />
    </section>
  );
}

export default Footer;
