import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Api from "../../services/Api";
import { unexpectedErrorToast } from "../../utils/unexpectedErrorToast";
import { useLogin } from "../../utils/auth";
import { Items, removeState } from "../../redux/storePersist";

const CASES = [
  {
    paragraph:
      "You’re almost ready to start using the using DecidED Advisor. We just want to make sure you are able to set up or join the correct organization to add your students to.",
    boxes: [
      {
        id: 1,
        text: "Create a new organization in DecidED",
        route: "create-org"
      },
      {
        id: 2,
        text: "Join to an existing organization in DecidED",
        route: "link-org"
      },
      {
        id: 3,
        text: "Accept a pending invitation",
        route: "accept-org-invitation"
      }
    ]
  },
  {
    paragraph:
      "You’re almost ready to start using DecidED Advisor. First let’s set up the organization account your students will join.",
    boxes: [
      {
        id: 1,
        text: "Create a new organization in DecidED",
        route: "create-org"
      }
    ]
  },
  {
    paragraph:
      "You’re almost ready to start using the using DecidED Advisor. We just want to make sure you are able to set up or join the correct organization to add your students to. It looks like you are already part of an organization in DecidED",
    boxes: [
      {
        id: 1,
        text: "Create a new organization in DecidED",
        route: "create-org"
      },
      {
        id: 2,
        text: "Join to an existing organization in DecidED",
        route: "link-org"
      }
    ]
  },
  {
    paragraph:
      "You’re almost ready to start using the DecidED Advisor. We just want to make sure you are able to set up or join the correct organization to add your students to.",
    boxes: [
      {
        id: 1,
        text: "Create a new organization in DecidED",
        route: "create-org"
      },
      {
        id: 2,
        text: "Accept a pending invitation",
        route: "accept-org-invitation"
      }
    ]
  }
];

function OrgOptionsScreen() {
  const navigate = useNavigate();
  const { auth0 } = useLogin();

  const [selectedBox, setSelectedBox] = useState(null);
  const [hasOrganizations, setHasOrganizations] = useState(false);
  const [hasInvitations, setHasInvitations] = useState(false);
  const [currentCase, setCurrentCase] = useState(CASES[1]);

  const goToSignIn = async () => {
    removeState(Items.GTM_STATE);
    return await auth0.logout({
      logoutParams: { returnTo: window.location.origin }
    });
  };

  useEffect(() => {
    const fetchOrganizationsAndInvitations = async () => {
      try {
        const [organizations, invitations] = await Promise.all([
          Api.organizationsToJoin(),
          Api.myPendingInvitations()
        ]);

        setHasOrganizations(organizations && organizations.length > 0);
        const invitationsArray = invitations.map(
          (org) => org.sender.organization
        );
        setHasInvitations(invitationsArray && invitationsArray.length > 0);
      } catch (error) {
        unexpectedErrorToast({
          message: (
            <>
              <p>Error fetching organizations or invitations.</p>
              <p>Please try again later.</p>
            </>
          )
        });
      }
    };

    fetchOrganizationsAndInvitations();
  }, []);

  useEffect(() => {
    if (hasOrganizations && hasInvitations) setCurrentCase(CASES[0]);
    else if (!hasOrganizations && !hasInvitations) setCurrentCase(CASES[1]);
    else if (hasOrganizations && !hasInvitations) setCurrentCase(CASES[2]);
    else if (!hasOrganizations && hasInvitations) setCurrentCase(CASES[3]);
  }, [hasOrganizations, hasInvitations]);

  const handleBoxSelect = (boxId) => {
    setSelectedBox(boxId);
  };

  const handleNextClick = () => {
    const selectedRoute = currentCase.boxes.find(
      (box) => box.id === selectedBox
    ).route;
    navigate(`/org-associations/${selectedRoute}`);
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start py-20 min-h-screen bg-white">
      <div className="flex flex-col w-full items-center text-center">
        <div className="flex flex-col items-center text-center w-1/2">
          <h1
            className="text-primary-blue font-bold text-3xl"
            id="welcome-title"
          >
            Welcome!
          </h1>
          <p className="my-4 font-bold text-lg w-2/3">
            {currentCase.paragraph}
          </p>
        </div>
        <div className="flex flex-row w-full justify-center my-4">
          {currentCase.boxes.map((box) => (
            <div
              key={box.id}
              id={`box-${box.id}`}
              className={`
                flex
                relative
                w-1/6
                pb-1/6
                items-center
                justify-center
                max-w-1/5
                h-44
                border-2
                border-primary-blue
                shadow-md
                p-4
                m-2
                hover:bg-primary-blue
                hover:text-white
                ${selectedBox === box.id ? "bg-primary-blue text-white" : ""}`}
              onClick={() => handleBoxSelect(box.id)}
            >
              <span className="font-bold w-2/3 text-lg">{box.text}</span>
            </div>
          ))}
        </div>
        <button
          id="org-associations-next-button"
          className="w-1/3 h-14 mt-4 mb-2 bg-primary-blue text-white font-bold px-4 py-2 hover:drop-shadow-md"
          onClick={handleNextClick}
          disabled={!selectedBox}
        >
          Next
        </button>
        <p
          className="text-primary-blue font-bold cursor-pointer"
          onClick={goToSignIn}
        >
          Not an Advisor User? Click here
        </p>
      </div>
    </div>
  );
}

export default OrgOptionsScreen;
