import React, { useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import EarningsCard from "../Detail/Cards/EarningsCard";
import DiversityCard from "../Detail/Cards/DiversityCard";
import AffordabilityCard from "../Detail/Cards/AffordabilityCard";
import CollegeMajorsCard from "../Detail/Cards/CollegeMajorsCard";
import GraduationRateCard from "../Detail/Cards/GraduationRateCard";
import CampusEnvironmentCard from "../Detail/Cards/CampusEnvironmentCard";
import FirstYearBreakdownCard from "../Detail/Cards/FirstYearBreakdownCard";

import { Dropdown } from "../../../../core";
import { SmallStar, UploadedLetter } from "../../../../core/icons";

import { EnrollmentDictionary } from "../Utils";
import { getEnrollmentIcon } from "../../../../core/utils";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function CollegeCard({
  school,
  ethnicity,
  marginRight,
  studentName,
  studentState,
  padding = true,
  width = "w-24rem",
  heights = {
    title: 0,
    majors: 0,
    affordability: 0,
    graduationRate: 0,
    affordabilityGuides: 0
  },
  sectionsState = {
    majors: true,
    earnings: false,
    diversity: false,
    graduationRate: true,
    campusEnvironment: false,
    firstYearBreakdown: true
  },
  setHeights = () => {},
  setSectionsState = () => {}
}) {
  const scrollRef = useRef(null);
  const heightRefTitle = useRef(null);
  const heightRefMajors = useRef(null);
  const heightRefAffordability = useRef(null);
  const heightRefGraduationRate = useRef(null);

  const navigate = useNavigate();
  const { studentId } = useParams();

  useEffect(() => {
    setTimeout(() => {
      const newHeights = {
        title: heightRefTitle.current?.clientHeight || 0,
        majors: heightRefMajors.current?.clientHeight || 0,
        affordability: heightRefAffordability.current?.clientHeight || 0,
        graduationRate: heightRefGraduationRate.current?.clientHeight || 0
      };
      setHeights(newHeights);
    }, [500]);
  }, [setHeights]);

  const callbackToggle = (toggle, name) => {
    const newSectionState = {
      ...sectionsState,
      [name]: toggle
    };
    setSectionsState(newSectionState);
  };

  const executeScroll = () => scrollRef.current.scrollIntoView();

  const awardOptions = [
    {
      id: 0,
      label: "Upload Letter",
      route: `/dashboard/students/${studentId}/${school.ipedsId}/upload-award`,
      enabled: true
    },
    {
      id: 1,
      label: "Reupload Letter",
      route: `/dashboard/students/${studentId}/${school.ipedsId}/upload-award`,
      enabled: true
    },
    {
      id: 2,
      label: "Edit Letter Results",
      route: `/dashboard/students/${studentId}/${school.ipedsId}/upload-award/view-award-letter`,
      routeParams: {
        isEdit: true
      },
      enabled: true
    },
    {
      id: 3,
      label: "Download Letter",
      downloadLink: school.award?.downloadUrl,
      enabled: school.award?.downloadUrl ? true : false
    }
  ];

  const filteredOptions = !school.award?.downloadUrl
    ? awardOptions.filter((option) => option.id === 0)
    : awardOptions.filter(
        (option) =>
          option.id !== 0 &&
          !(option.id === 2 && school.award?.status !== "READY")
      );

  const handleDownload = (downloadLink) => {
    const link = document.createElement("a");
    link.href = downloadLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelect = (option) => {
    if (option.route) {
      navigate(option.route, {
        state: {
          studentName,
          school: school?.school,
          pairedId: school.pairedId,
          sortOrder: school.sortOrder,
          ...option.routeParams
        }
      });
    } else if (option.downloadLink) {
      interactionEventDataLayer({
        eventName: "download_award_letter_button",
        params: { ipeds: school.ipedsId }
      });
      handleDownload(option.downloadLink);
    }
  };

  return (
    <div
      className={`
        flex
        flex-col
        space-y-4
        outline-none
        bg-light-gray
        ${width}
        ${padding && "p-4"}
        ${marginRight ? marginRight : "mr-6"}
      `}
    >
      <div
        ref={heightRefTitle}
        style={{ minHeight: `${heights.title}px` }}
        className="mb-2 flex flex-row px-1 justify-between items-start"
      >
        <h2 className="font-bold text-2xl w-11/12">{school.school.name}</h2>
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-end space-x-2">
            <div
              id="enrollment-icon-label"
              data-tooltip-id="enrollment-icon-label"
              data-tooltip-content={
                EnrollmentDictionary[school.enrollmentStatus]
              }
            >
              {getEnrollmentIcon({ enrollment: school.enrollmentStatus })}
            </div>
            <Dropdown
              options={filteredOptions}
              id="student-award-options-dropdown"
              handleOnSelect={handleSelect}
              defaultLabel={
                <>
                  <UploadedLetter width={40} height={25} />
                </>
              }
              displayInline
            />
          </div>
          <div
            className={`
              m-4
              flex
              justify-end
              ${!school.isFavorite && "hidden"}
            `}
          >
            <SmallStar fillColor="fill-alternative-yellow" />
          </div>
        </div>
      </div>
      <div
        ref={heightRefAffordability}
        style={{ minHeight: `${heights.affordability}px` }}
      >
        <AffordabilityCard
          chosenSchool={school}
          executeScroll={executeScroll}
        />
      </div>
      <div
        ref={heightRefMajors}
        style={{
          minHeight: `${sectionsState.majors ? heights.majors : 0}px`
        }}
      >
        <CollegeMajorsCard
          chosenSchool={school}
          open={sectionsState.majors}
          externalToggle={sectionsState.majors}
          callbackToggle={(toggle) => callbackToggle(toggle, "majors")}
        />
      </div>
      <div
        ref={heightRefGraduationRate}
        style={{
          minHeight: `${
            sectionsState.graduationRate ? heights.graduationRate : 0
          }px`
        }}
      >
        <GraduationRateCard
          chosenSchool={school}
          ethnicityStudent={ethnicity}
          open={sectionsState.graduationRate}
          externalToggle={sectionsState.graduationRate}
          callbackToggle={(toggle) => callbackToggle(toggle, "graduationRate")}
        />
      </div>

      <EarningsCard
        chosenSchool={school}
        open={sectionsState.earnings}
        externalToggle={sectionsState.earnings}
        callbackToggle={(toggle) => callbackToggle(toggle, "earnings")}
      />
      <DiversityCard
        ethnicity={ethnicity}
        chosenSchool={school}
        open={sectionsState.diversity}
        externalToggle={sectionsState.diversity}
        callbackToggle={(toggle) => callbackToggle(toggle, "diversity")}
      />
      <CampusEnvironmentCard
        chosenSchool={school}
        open={sectionsState.campusEnvironment}
        externalToggle={sectionsState.campusEnvironment}
        callbackToggle={(toggle) => callbackToggle(toggle, "campusEnvironment")}
      />
      <div ref={scrollRef}>
        <FirstYearBreakdownCard
          chosenSchool={school}
          studentState={studentState}
          open={sectionsState.firstYearBreakdown}
          externalToggle={sectionsState.firstYearBreakdown}
          callbackToggle={(toggle) =>
            callbackToggle(toggle, "firstYearBreakdown")
          }
        />
      </div>
    </div>
  );
}

CollegeCard.propTypes = {
  width: PropTypes.string,
  bgColor: PropTypes.string,
  ethnicity: PropTypes.string,
  marginRight: PropTypes.string,
  studentState: PropTypes.string,
  programLength: PropTypes.string,
  school: PropTypes.shape().isRequired,
  setHeights: PropTypes.func.isRequired,
  setSectionsState: PropTypes.func.isRequired,
  sectionsState: PropTypes.shape().isRequired,
  heights: PropTypes.shape({
    title: PropTypes.number,
    affordability: PropTypes.number,
    graduationRate: PropTypes.number
  }).isRequired
};

export default CollegeCard;
