import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

function FileList({ files = [], onRemoveFile = () => {} }) {
  const divContainerRef = useRef();

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const container = divContainerRef.current;

    const handleScroll = (element) => {
      if (element.scrollTop > 0) setHasScroll(true);
      else setHasScroll(false);
    };

    container.addEventListener("scroll", () => handleScroll(container));
    return () => {
      container.removeEventListener("scroll", () => handleScroll(container));
    };
  }, []);

  return (
    <ol
      id="file-list"
      ref={divContainerRef}
      className={`
        max-h-52
        divide-y
        overflow-y-auto
        overflow-x-hidden
        divide-gray-300
        ${hasScroll && "border-t border-black"}
      `}
    >
      {files.map((file) => (
        <li className="py-3 px-5" key={file.name}>
          <div className="flex text-sm flex-1 flex-row space-x-2">
            <div className="flex flex-1 font-bold overflow-hidden">
              <span className="truncate">{file.name}</span>
            </div>
            <div
              onClick={() => onRemoveFile(file)}
              className="cursor-pointer text-gray-500"
            >
              Delete
            </div>
          </div>
        </li>
      ))}
    </ol>
  );
}

FileList.propTypes = {
  onRemoveFile: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.shape())
};

export default FileList;
