import PropTypes from "prop-types";

import PacmanLoader from "react-spinners/PacmanLoader";

function Loader({ show, size = 25, margin = 2 }) {
  return (
    <div className="flex justify-center py-4">
      <PacmanLoader size={size} margin={margin} loading={show} />
    </div>
  );
}

Loader.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.number,
  margin: PropTypes.number
};

export default Loader;
