import PropTypes from "prop-types";

import { Diversity } from "../";
import { Panel } from "../../compose";

function DiversityCard({
  id,
  open,
  ethnicity,
  chosenSchool,
  externalToggle,
  callbackToggle,
}) {
  const { award, school, schoolCosts } = chosenSchool;

  return (
    <Panel
      id={id}
      title="diversity"
      initiallyOpened={open}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      className="p-4 mb-2 bg-white shadow-lg"
      titleClassName="
        flex
        flex-1
        flex-row
        capitalize
        font-semibold
      "
      expandableContent={
        <Diversity
          className="mt-4"
          ethnicity={ethnicity}
          financialAidPercentage={school.percentLowIncome}
          diversityPercentage={schoolCosts.diversityPercentage}
          affordability={award?.postAwardSchoolCosts?.affordability}
          studentDiversityPercentage={schoolCosts.studentDiversityPercentage}
        />
      }
    />
  );
}

DiversityCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  ethnicity: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default DiversityCard;
