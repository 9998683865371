import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { LoadingButton } from "../../../core/buttons";

function RemoveAdvisorList({
  list,
  onClick,
  removeAdvisors,
  secondTextContent,
}) {
  const firstText = (
    <span>
      You are removing
      {removeAdvisors === 1 ? " one advisor " : ` ${removeAdvisors} advisors `}
      from the list<span className="text-black"> "{list.name}"</span>.
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onClick();
  };

  return (
    <>
      <div className="px-1 overflow-auto">
        <Content firstText={firstText} secondText={secondTextContent} />
      </div>
      <div className="py-5 flex flex-row mt-auto space-x-5">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="remove-advisor-remove-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:text-sm
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          remove {removeAdvisors === 1 ? "advisor" : "advisors"}
        </LoadingButton>
      </div>
    </>
  );
}

RemoveAdvisorList.propTypes = {
  list: PropTypes.shape(),
  removeAdvisors: PropTypes.number,
  secondTextContent: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

export default RemoveAdvisorList;
