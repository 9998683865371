import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import CollegeCard from "../Common/CollegeCard";

import { ItemsCounter } from "../../../../core";
import { ArrowNav } from "../../../../core/icons";

import { AffordabilitySquare } from "../../../../core/compose/affordability";

function CollegesContainer({
  schools,
  heights,
  favorite,
  ethnicity,
  studentName,
  studentState,
  sectionsState,
  programLength,
  setGlobalHeights,
  setGlobalSectionState,
}) {
  const scroll = useRef(null);
  const [offsetIndex, setOffsetIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [schoolsLocal, setSchoolsLocal] = useState([]);
  const [favoriteLocal, setFavoriteLocal] = useState(null);

  useEffect(() => {
    setSchoolsLocal(schools);
    setFavoriteLocal(favorite);
  }, [schools, favorite]);

  useEffect(() => {
    if (scroll.current) {
      const scrollableWidth =
        (scroll.current.getBoundingClientRect().width - 24) / 408;

      setOffsetIndex(scrollableWidth);
    }
  }, [schoolsLocal]);

  useEffect(() => {
    if (scroll.current) {
      const scrollOffset = currentIndex * 408;
      scroll.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  }, [scroll, currentIndex]);

  const next = () =>
    setCurrentIndex((index) => {
      return index < schoolsLocal.length - offsetIndex ? index + 1 : index;
    });

  const previous = () =>
    setCurrentIndex((index) => {
      return index !== 0 ? index - 1 : index;
    });

  const navigateTo = (index) =>
    setCurrentIndex(
      index < schoolsLocal.length - offsetIndex
        ? index
        : schoolsLocal.length - offsetIndex,
    );

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-row items-center overflow-hidden">
        <div className="flex flex-row space-x-2 items-center">
          <div
            role="button"
            onClick={previous}
            className="px-2 py-1 my-1"
            aria-label="arrow-left-nav"
          >
            <ArrowNav size={20} />
          </div>
          <div
            role="button"
            onClick={next}
            aria-label="arrow-right-nav"
            className="px-2 py-1 my-1 transform rotate-180"
          >
            <ArrowNav size={20} />
          </div>
          <ItemsCounter
            pluralDescriptionItems="Schools"
            singularDescriptionItems="School"
            numberItems={
              favoriteLocal ? schoolsLocal.length + 1 : schoolsLocal.length
            }
          />
        </div>
        <div
          className="
            flex
            flex-row
            divide-x-2
            items-center
            divide-black
            overflow-hidden
          "
        >
          {favoriteLocal && (
            <div>
              <AffordabilitySquare
                className="mr-2"
                data-tooltip-content={favoriteLocal.school.name}
                data-tooltip-id="college-thumbnail-squares"
                coverage={favoriteLocal.award?.postAwardSchoolCosts?.coverage}
                affordability={
                  favoriteLocal.award?.postAwardSchoolCosts?.affordability
                }
              />
            </div>
          )}
          <div
            className={`
              flex
              flex-row
              items-center
              overflow-x-auto
              ${favoriteLocal && "pl-2"}
            `}
          >
            {schoolsLocal.map((school, index) => (
              <div key={index}>
                <AffordabilitySquare
                  className="cursor-pointer"
                  data-tooltip-content={school.school.name}
                  onClick={() => navigateTo(index)}
                  data-tooltip-id="college-thumbnail-squares"
                  coverage={school.award?.postAwardSchoolCosts?.coverage}
                  affordability={
                    school.award?.postAwardSchoolCosts?.affordability
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="
          flex
          flex-1
          flex-row
          divide-x-2
          divide-black
          overflow-hidden
        "
      >
        {favoriteLocal && (
          <div className="py-3">
            <CollegeCard
              heights={heights}
              marginRight="mr-4"
              ethnicity={ethnicity}
              school={favoriteLocal}
              studentName={studentName}
              studentState={studentState}
              sectionsState={sectionsState}
              setHeights={setGlobalHeights}
              programLength={programLength}
              setSectionsState={setGlobalSectionState}
            />
          </div>
        )}
        <div
          ref={scroll}
          className={`
            py-3
            flex
            flex-row
            box-border
            align-center
            justify-start
            overflow-x-scroll
            ${favoriteLocal && "pl-4"}
          `}
        >
          {schools.map((school, index) => {
            return (
              <div key={index} className="outline-none">
                <CollegeCard
                  school={school}
                  heights={heights}
                  ethnicity={ethnicity}
                  studentName={studentName}
                  studentState={studentState}
                  sectionsState={sectionsState}
                  setHeights={setGlobalHeights}
                  programLength={programLength}
                  setSectionsState={setGlobalSectionState}
                  marginRight={
                    index === schoolsLocal.length - 1 ? "mr-0" : null
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

CollegesContainer.propTypes = {
  heights: PropTypes.shape(),
  favorite: PropTypes.shape(),
  ethnicity: PropTypes.string,
  studentState: PropTypes.string,
  programLength: PropTypes.string,
  sectionsState: PropTypes.shape(),
  setGlobalHeights: PropTypes.func,
  setGlobalSectionState: PropTypes.func,
  schools: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CollegesContainer;
