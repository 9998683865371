import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Onboarding from "./components/Onboarding";
import OrgAssociationsRoutes from "./components/OrgAssociations";
import Invitation from "./components/Invitation";
import EmailUpdate from "./components/EmailUpdate";
import CreateAccount from "./components/CreateAccount";
import HomeLayout from "./components/Common/HomeLayout";
import FloatLoader from "./components/Common/FloatLoader";
import CustomSwitch from "./components/Common/CustomSwitch";
import SubscriptionSettings from "./components/SubscriptionSettings";

// import { useAdvisor } from "./redux/Advisor/hooks";
import { removeState, Items } from "./redux/storePersist";
import { usePermissions } from "./redux/Permissions/hooks";
import { useOrganizations } from "./redux/Organizations/hooks";
import {
  setListUpdating,
  setSelectedOrganizationId
} from "./redux/Organizations/actions";
import {
  fetchPermissions,
  resetPermissionsState
} from "./redux/Permissions/actions";

// import { AdvisorRoles } from "./components/Advisor/Utils";
import { getAppSections, getPermissions } from "./utils/permissions";

import "./styles/tailwind.output.css";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/Common/PrivateRoute";

function SingUp() {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const redirectToSignUp = async () => {
      if (!isLoading) {
        if (!isAuthenticated)
          await loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup"
            }
          });
        else navigate("/");
      }
    };

    redirectToSignUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return null;
}

function Logout() {
  const { logout } = useAuth0();
  const returnTo = window.SERVER_DATA.REACT_APP_DECIDED_URL;
  useEffect(() => {
    removeState(Items.APP_STATE);
    removeState(Items.GTM_STATE);
    removeState(Items.ORGS);
    logout({
      logoutParams: { returnTo }
    });
  });
  return null;
}

function ForceLogin() {
  const { logout } = useAuth0();
  useEffect(() => {
    removeState(Items.APP_STATE);
    removeState(Items.GTM_STATE);
    removeState(Items.ORGS);
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  });
  return null;
}

function Authenticate() {
  const location = useLocation();
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const qs = new URLSearchParams(location.search.slice(1));
    setRedirect(qs.get("next") || "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirect) return <Navigate to={redirect} />;
  return null;
}

function DashboardComponent({ isReady, userNotAllowed, appSections }) {
  return (
    <>
      <HomeLayout isSimple={userNotAllowed}>
        {!isReady && userNotAllowed && <OrgAssociationsRoutes />}
        {!isReady && !userNotAllowed && <FloatLoader show={true} />}
        {isReady && !userNotAllowed && <Dashboard sections={appSections} />}
      </HomeLayout>
    </>
  );
}

// function ValidateOnboarding() {
//   const navigate = useNavigate();

//   const advisorState = useAdvisor();
//   const { selectedOrganizationId } = useOrganizations();

//   useEffect(() => {
//     const onboardingAdmin = advisorState.profile?.onboardingAdmin;
//     const onboardingAdvisor = advisorState.profile?.onboardingAdvisor;

//     if (
//       (!onboardingAdmin || !onboardingAdvisor) &&
//       selectedOrganizationId &&
//       !advisorState.isLoading
//     ) {
//       const organization = advisorState.profile?.organizations?.items.find(
//         (org) => org.organization.id === selectedOrganizationId
//       );

//       const onboardingUrl = `/onboarding?role=${organization?.role}&complete=${
//         !onboardingAdmin && !onboardingAdvisor
//       }`;

//       if (organization?.role === AdvisorRoles.ADMIN && !onboardingAdmin)
//         navigate(onboardingUrl);
//       else if (organization?.role !== AdvisorRoles.ADMIN && !onboardingAdvisor)
//         navigate(onboardingUrl);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [advisorState.isLoading, selectedOrganizationId]);

//   return null;
// }

function App() {
  const dispatch = useDispatch();

  const [currentOrg, setCurrentOrg] = useState({});
  const [appSections, setAppSections] = useState([]);
  const [organizationId, setOrganizationId] = useState();

  const { isLoading, isAuthenticated } = useAuth0();
  const { isReady, permissions } = usePermissions();
  const {
    pages,
    studentLists,
    advisorLists,
    listUpdating,
    userNotAllowed,
    selectedOrganizationId,
    isLoading: isLoadingOrganizations
  } = useOrganizations();

  useEffect(() => {
    setAppSections([]);
    dispatch(resetPermissionsState());
    setOrganizationId(selectedOrganizationId);
  }, [dispatch, selectedOrganizationId]);

  useEffect(() => {
    if (organizationId && !isLoadingOrganizations) {
      const organization = pages[0].find(
        (org) => org.organization.id === organizationId
      );

      if (!organization) {
        dispatch(
          setSelectedOrganizationId(pages[0][0]?.organization?.id || null)
        );
      } else {
        const permissions = getPermissions(organization.role);
        dispatch(
          fetchPermissions({
            permissions
          })
        );
        setCurrentOrg({
          ...organization,
          studentLists,
          advisorLists
        });

        if (listUpdating) dispatch(setListUpdating(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listUpdating, organizationId, isLoadingOrganizations]);

  useEffect(() => {
    const { role } = currentOrg;
    if (role && permissions) {
      setAppSections(getAppSections(permissions, currentOrg));
    }
  }, [dispatch, currentOrg, permissions]);

  if (!isLoading && !isAuthenticated) {
    removeState(Items.APP_STATE);
    removeState(Items.ORGS);
  }

  return (
    <div className="min-w-65rem">
      <CustomSwitch>
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SingUp />} />
        <Route path="/force-login" element={<ForceLogin />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/invitation/:invitationId" element={<Invitation />} />
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route
          path="/onboarding"
          element={
            <PrivateRoute>
              <Onboarding />
            </PrivateRoute>
          }
        />
        <Route
          path="/authenticate"
          element={
            <PrivateRoute>
              <Authenticate />
            </PrivateRoute>
          }
        />
        <Route path="/org-associations/*" element={<OrgAssociationsRoutes />} />
        <Route
          path="/subscription-settings"
          element={
            <PrivateRoute>
              <SubscriptionSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirm-email-change/:updateId"
          element={
            <PrivateRoute>
              <EmailUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <DashboardComponent
                isReady={isReady}
                userNotAllowed={userNotAllowed}
                appSections={appSections}
              />
            </PrivateRoute>
          }
        />
      </CustomSwitch>
      <ToastContainer />
    </div>
  );
}

export default App;
