import PropTypes from "prop-types";

function Profile({
  size = 50,
  className = "",
  boxColor = "fill-black",
  bodyColor = "fill-black",
  headColor = "stroke-black",
  ...restProps
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        className={bodyColor}
        d="M19.6824 32.2354H22.7185C25.3695 32.2354 27.5493 33.8899 28.3165 36.1171H30.4009C29.5783 32.7448 26.4509 30.2354 22.7185 30.2354H19.6824C15.95 30.2354 12.8226 32.7448 12 36.1171H14.0844C14.8516 33.8899 17.0314 32.2354 19.6824 32.2354Z"
      />
      <path
        strokeWidth="2"
        className={`fill-white ${headColor}`}
        d="M21.1991 32.4119C24.3504 32.4119 26.905 29.8572 26.905 26.7059C26.905 23.5546 24.3504 21 21.1991 21C18.0478 21 15.4932 23.5546 15.4932 26.7059C15.4932 29.8572 18.0478 32.4119 21.1991 32.4119Z"
      />
      <path
        className={boxColor}
        d="M47 4.66V31.92L35.85 42.96H33.58H9.27H7V40.69V16.13V13.86L18.09 3H45.31L31.9 16.13H9.26V40.69H33.58V17.81L47 4.66Z"
      />
    </svg>
  );
}

Profile.propTypes = {
  size: PropTypes.number,
  boxColor: PropTypes.string,
  headColor: PropTypes.string,
  bodyColor: PropTypes.string,
  className: PropTypes.string
};

export default Profile;
