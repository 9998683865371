import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import { getChartColors } from "../utils";

function AffordabilityChartMobile({ percent, affordability }) {
  const divSvgRef = useRef();
  const constainerRef = useRef();

  const [radius, setRadius] = useState(0);

  useEffect(() => {
    const radiansPercent =
      Math.min(Math.max(0, affordability ? percent : 100 || 0), 100) / 100;

    const strokeWidth = 1.5;
    const innerRadiusFactor = 0.5;
    const outerRadiusFactor = 0.95;
    const offsetRadiusFactor = 0.7;

    const xyDonut = radius;
    const svgSize = radius * 2;

    const outerRadius = radius * outerRadiusFactor * offsetRadiusFactor;
    const innerRadius = radius * innerRadiusFactor * offsetRadiusFactor;

    const divSvg = d3.select(divSvgRef.current);
    if (svgSize > 0) {
      const donutColor = getChartColors(affordability);

      const svg = divSvg
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${svgSize} ${svgSize}`);

      const donutContainer = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(Math.PI * 2);

      const donutContent = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(Math.PI * 2 * radiansPercent);

      const group = svg
        .append("g")
        .attr("id", "donut-affordability")
        .attr("transform", `translate(${xyDonut}, ${xyDonut}) rotate(0)`);

      group
        .append("path")
        .attr("d", donutContainer)
        .attr("id", "container-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .style("stroke", "#F3F3F3")
        .style("fill", "#F3F3F3");

      group
        .append("path")
        .attr("d", donutContent)
        .attr("stroke-linecap", "round")
        .attr("id", "content-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .attr(
          "class",
          `fill-current stroke-current ${
            affordability ? donutColor.primary : donutColor.secondary
          }`
        );

      group
        .append("path")
        .attr("d", donutContainer)
        .attr("id", "container-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .style("fill", "transparent")
        .style("stroke", "#000000");
    }

    return () => divSvg.selectAll("*").remove();
  }, [percent, affordability, radius]);

  useEffect(() => {
    getRadius();

    window.addEventListener("resize", getRadius);
    return () => window.removeEventListener("resize", getRadius);
  }, []);

  const getRadius = () => {
    if (constainerRef.current) {
      const node = constainerRef.current;
      setRadius(node.getBoundingClientRect().width / 2);
    }
  };

  return (
    <div ref={constainerRef}>
      <div ref={divSvgRef} />
    </div>
  );
}

AffordabilityChartMobile.propTypes = {
  percent: PropTypes.number,
  affordability: PropTypes.string,
};

export default AffordabilityChartMobile;
