import PropTypes from "prop-types";

function InfoRow({ text, label, className }) {
  return (
    <div key={`${label}-${text}`} className={`my-2 text-sm ${className}`}>
      <p className="mb-2 font-semibold text-primary-blue">{label}</p>
      <div className="py-1 px-2 border border-black">
        <p className="m-1 font-medium">{text}</p>
      </div>
    </div>
  );
}

InfoRow.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
export default InfoRow;
