import PropTypes from "prop-types";

function StudentDisplay({ student, className = "" }) {
  const studentFullName = `${student?.firstName} ${student?.lastName}`;

  return (
    <div className={`flex flex-col ${className}`}>
      <h1 className="text-3xl font-bold">{studentFullName}</h1>
      <div className="flex flex-row justify-between">
        <p className="text-sm font-semibold">{student?.email}</p>
        <p className="text-sm font-semibold">
          Last Activity: {student?.lastLoginHumanize}
        </p>
      </div>
    </div>
  );
}

StudentDisplay.propTypes = {
  student: PropTypes.shape(),
  className: PropTypes.string,
};

export default StudentDisplay;
