import { useState } from "react";

export default function SelectorBar({ onSelect, className, optionList }) {
  const [dataArray, setDataArray] = useState(optionList);
  const listLength = optionList.length;

  const renderItem = ({ item }) => {
    return (
      <div className={`w-1/${listLength}`} key={item.id}>
        <div
          className={`
            py-2
            border
            capitalize
            font-black
            text-center
            tracking-wide
            cursor-pointer
            border-primary-blue
            ${item.id < listLength - 1 && "border-r-0"}
            ${
              item.isSelected
                ? "text-white bg-primary-blue"
                : "text-primary-blue"
            }
          `}
          onClick={() => onPressHandler({ item })}
        >
          {item.label}
        </div>
      </div>
    );
  };

  const onPressHandler = ({ item }) => {
    const optionsArray = dataArray.map((i) => {
      return { ...i, isSelected: false };
    });

    if (!item.isSelected) {
      const updatedItem = optionsArray.find((i) => i.id === item.id);
      updatedItem.isSelected = true;
    } else {
      optionsArray[item.id].isSelected = true;
    }

    setDataArray(optionsArray);
    onSelect(item.id);
  };

  return (
    <div className={`flex flex-1 flex-row ${className}`}>
      {dataArray.map((item) => renderItem({ item }))}
    </div>
  );
}
