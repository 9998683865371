import { useEffect, useState } from "react";
import PropTypes from "prop-types";

function SearchTextSimple({ id, placeholder, defaultValue, handlerOnSubmit }) {
  const [search, setSearch] = useState("");

  useEffect(() => setSearch(defaultValue || ""), [defaultValue]);

  const handlerOnSearch = (event) => {
    event.persist();
    setSearch(event.currentTarget.value);
  };

  const handlerOnKeyDown = (event) => {
    if (event.key === "Enter") handlerOnClick();
  };

  const handlerOnClick = () => handlerOnSubmit(search);

  return (
    <div className="pr-2 flex flex-1 flex-row">
      <div
        className="
          p-2
          flex
          flex-1
          flex-row
          items-center
          bg-light-gray
          focus-within:shadow-black
        "
      >
        <input
          id={id}
          type="text"
          value={search}
          aria-label="searchInput"
          placeholder={placeholder}
          onChange={handlerOnSearch}
          onKeyDown={handlerOnKeyDown}
          className="
            flex
            flex-1
            text-sm
            outline-none
            font-semibold
            bg-light-gray
          "
        />
      </div>
    </div>
  );
}

SearchTextSimple.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  handlerOnSubmit: PropTypes.func,
};

export default SearchTextSimple;
