import PropTypes from "prop-types";

function SchoolTools({ size = 50, fillColor = "fill-alternative-yellow" }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 110 110"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <filter
          x="0"
          y="0"
          width="110"
          height="110"
          id="filter1_f"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur" />
        </filter>
      </defs>
      <g filter="url(#filter1_f)">
        <circle cx="55" cy="55" r="40" className={fillColor} fill="none" />
      </g>
      <g transform="translate(30.000000,80.000000) scale(0.05,-0.05)">
        <path
          d="M110 815 l-95 -95 129 -129 129 -129 -134 -136 -133 -137 95 -95 94
    -94 135 135 135 135 127 -127 128 -128 95 95 95 95 -127 127 -128 128 101 102
    101 103 17 90 c9 50 19 102 22 116 l5 26 -118 -19 -118 -20 -103 -101 -102
    -102 -128 128 -127 127 -95 -95z m255 -254 l-54 -55 -26 24 -25 24 37 38 c36
    37 36 38 18 58 l-19 21 -38 -38 c-37 -36 -38 -36 -58 -18 l-20 18 26 28 c24
    25 25 30 13 46 -7 10 -15 20 -16 22 -2 3 -17 -8 -33 -24 l-30 -29 -22 22 -22
    22 54 55 54 55 108 -107 108 -108 -55 -54z m459 232 c-17 -90 -14 -89 -68 -34
    l-48 49 53 10 c30 5 58 10 62 11 4 0 5 -16 1 -36z m-143 -39 c11 -13 -19 -47
    -222 -250 -206 -207 -235 -232 -248 -219 -14 13 -10 20 29 62 103 110 415 423
    421 423 4 0 12 -7 20 -16z m80 -80 c11 -13 -19 -47 -221 -249 -223 -223 -235
    -234 -253 -218 -17 16 -7 28 215 250 128 128 236 233 239 233 4 0 12 -7 20
    -16z m14 -523 c-53 -54 -54 -55 -75 -36 l-20 18 26 29 c27 27 27 28 9 48 l-18
    20 -29 -27 -28 -27 -25 24 -25 24 37 38 c36 37 36 38 18 58 l-19 21 -38 -38
    -38 -37 -22 22 -22 22 54 55 54 55 108 -107 108 -108 -55 -54z m-545 -36 l-36
    -35 -54 55 -54 55 34 35 34 35 56 -55 55 -55 -35 -35z"
        />
      </g>
    </svg>
  );
}

SchoolTools.propTypes = {
  size: PropTypes.number
};

export default SchoolTools;
