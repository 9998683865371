import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import CategoryLabel from "./CategoryLabel";
import CategoryGroup from "./CategoryGroup";

import { Link, SmallModal } from "../../../../core";
import { Close } from "../../../../core/icons";
import { Colors } from "../../../../core/utils";

import { ModalTypes as StudentModalTypes } from "../../Students/Utils";
import { ModalTypes as AdvisorModalTypes } from "../../Advisors/Utils";

import ModalContext from "../../../../contexts/ModalContext";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { useOrganizations } from "../../../../redux/Organizations/hooks";

function Category({ icon, label, index, list, pathname }) {
  const navigate = useNavigate();
  const location = useLocation();

  const modalContext = useContext(ModalContext);

  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const [myListsData, setMyListsData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [orgListsData, setOrgListsData] = useState([]);
  const [isAllActive, setIsAllActive] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [orgToggle, setOrgToggle] = useState(false);
  const [orgShowMore, setOrgShowMore] = useState(false);
  const [archiveToggle, setArchiveToggle] = useState(true);
  const [myListsToggle, setMyListsToggle] = useState(false);
  const [myListsShowMore, setMyListsShowMore] = useState(false);
  const [archiveShowMore, setArchiveShowMore] = useState(false);

  useEffect(() => {
    setMyListsData([]);
    setOrgListsData([]);
  }, [selectedOrganizationId]);

  useEffect(() => {
    if (!list) return;

    const myLists = list.filter(
      (list) => !list.archived && !list.isShared && list.owner.id === profile.id
    );
    setMyListsData(myLists);

    const orgLists = list.filter(
      (list) =>
        !list.archived && (list.isShared || list.owner.id !== profile.id)
    );
    setOrgListsData(orgLists);

    setArchiveData([
      {
        name: "Lists",
        id: `archived-${pathname}-lists`,
        directPath: `/dashboard/${pathname}/lists/archive`,
      },
      {
        name: "Students",
        id: `archived-${pathname}-items`,
        directPath: `/dashboard/${pathname}/items/archive`,
      },
    ]);

    if (orgLists.length > 0) setOrgToggle(true);
    else setOrgToggle(false);
    if (myLists.length > 0) setMyListsToggle(true);
    else setMyListsToggle(false);
  }, [list, pathname, profile.id]);

  useEffect(() => {
    if (location.pathname === `/dashboard/${pathname}`) {
      setIsAllActive(true);
    } else {
      setIsAllActive(false);
    }
  }, [location.pathname, pathname]);

  const handleOnOpenModal = () => {
    navigate(`/dashboard/${pathname}`);
    setShowModal(true);
  };

  const handleOnOpenCreateListModal = () => {
    if (pathname === "students") {
      modalContext.openModalHandler(
        { organizationId: selectedOrganizationId },
        StudentModalTypes.CREATE_LIST
      );
    } else if (pathname === "advisors") {
      modalContext.openModalHandler(
        { organizationId: selectedOrganizationId },
        AdvisorModalTypes.CREATE_LIST
      );
    }

    setShowModal(false);
  };

  const hoverStyle =
    "hover:font-semibold hover:text-primary-blue hover:bg-secondary-blue";

  return (
    <div className="flex w-full flex-col">
      <NavLink
        id={`nav-tab-${index}`}
        to={`/dashboard/${pathname}`}
        className={`
          px-4
          flex
          flex-1
          flex-row
          border-t
          border-b
          space-x-2
          items-center
          justify-center
          ${
            isAllActive
              ? "bg-primary-blue text-white"
              : "bg-white text-primary-blue"
          }
          ${hoverStyle}
        `}
      >
        <h1 className="py-2 flex flex-1 text-2xl font-bold">{label}</h1>
        <div>{icon}</div>
      </NavLink>
      {list && (
        <div className="pb-2 pt-4 px-4 flex flex-col">
          <div className="pl-4 flex flex-col">
            <CategoryLabel
              name="My Lists"
              toggle={myListsToggle}
              onClick={() => {
                if (myListsData.length > 0) {
                  setMyListsShowMore(false);
                  setMyListsToggle(!myListsToggle);
                } else handleOnOpenModal();
              }}
            />
            {myListsToggle && (
              <CategoryGroup
                list={myListsData}
                pathname={pathname}
                toggle={myListsShowMore}
                toggleOnClick={() => setMyListsShowMore(!myListsShowMore)}
              />
            )}
          </div>
          <div className="pl-4 flex flex-col">
            <CategoryLabel
              name="Org Lists"
              toggle={orgToggle}
              onClick={() => {
                if (orgListsData.length > 0) {
                  setOrgShowMore(false);
                  setOrgToggle(!orgToggle);
                } else handleOnOpenModal();
              }}
            />
            {orgToggle && (
              <CategoryGroup
                list={orgListsData}
                pathname={pathname}
                toggle={orgShowMore}
                toggleOnClick={() => setOrgShowMore(!orgShowMore)}
              />
            )}
          </div>
          <div className="pl-4 flex flex-col">
            <CategoryLabel
              name="Archived"
              toggle={archiveToggle}
              classNameLabel="text-gray-500"
              onClick={() => {
                setArchiveShowMore(false);
                setArchiveToggle(!archiveToggle);
              }}
            />
            {archiveToggle && (
              <CategoryGroup
                pathname={pathname}
                toggle={archiveShowMore}
                toggleOnClick={() => setArchiveShowMore(!archiveShowMore)}
                list={
                  pathname === "advisors" && archiveData.length > 0
                    ? [archiveData[0]]
                    : archiveData
                }
              />
            )}
          </div>
        </div>
      )}

      {showModal && (
        <SmallModal open={showModal}>
          <div className="flex flex-1 flex-col relative">
            <div
              role="button"
              className="top-1 right-0 absolute"
              onClick={() => setShowModal(false)}
            >
              <Close />
            </div>
            <p className="text-4xl font-black text-black">
              Create your first list
            </p>
            <div className="mt-4 text-2xl font-medium space-y-8">
              <div>
                <p>
                  You can create private or shared lists here to sort and group
                  your {pathname}.
                </p>
              </div>
              <div>
                <span>
                  Try it out by clicking the{" "}
                  <div className="inline-block">
                    <Link
                      text="Create List"
                      color={Colors.BLUE}
                      textClassName="font-medium"
                      onClick={() => handleOnOpenCreateListModal()}
                    />
                  </div>{" "}
                  button.
                </span>
              </div>
            </div>
          </div>
        </SmallModal>
      )}
    </div>
  );
}

Category.propTypes = {
  icon: PropTypes.any,
  index: PropTypes.number,
  label: PropTypes.string,
  pathname: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape()),
};

export default Category;
