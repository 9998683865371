import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { Button, LoadingButton } from "../../../core/buttons";

function ArchiveStudent({
  onClick,
  students,
  archiveStudents,
  finishCloseModal,
}) {
  const firstText = (
    <>
      <div
        className={`
          pt-4
          text-3xl
          font-black
          leading-10
          text-primary-blue
        `}
      >
        Are you sure you want to archive
        {archiveStudents === 1
          ? " this student"
          : students?.length > 0
          ? " these students"
          : ` ${archiveStudents} students`}
        ?
      </div>
      {students?.length > 0 && (
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {students.map((student) => (
              <li key={student.studentId} className="list-disc list-inside">
                <span>
                  {student.firstName} {student.lastName}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );

  const secondText = (
    <span className="text-2xl font-medium">
      Archiving {archiveStudents > 1 ? "these students" : "this student"} will
      archive them for all the advisors in your organization. You can find{" "}
      {archiveStudents > 1 ? "these students" : "this student"} on the Archived
      Students page.
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onClick();
  };

  return (
    <>
      <div className="px-1 overflow-auto">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="py-5 flex flex-row mt-auto space-x-5">
        <Button
          onClick={finishCloseModal}
          id="remove-student-cancel-button"
          className="
            mb-5
            w-1/2
            bg-white
            text-primary-blue
            border-primary-blue
            hover:text-white
            active:text-white
            hover:bg-primary-blue
            active:bg-primary-blue
            mobile:text-sm
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="remove-student-remove-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:text-sm
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          archive {archiveStudents === 1 ? "student" : "students"}
        </LoadingButton>
      </div>
    </>
  );
}

ArchiveStudent.propTypes = {
  archiveStudents: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  finishCloseModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape()),
};

export default ArchiveStudent;
