import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { ShortArrow } from "./icons";
import { Colors, getColors, scrollIntoViewIfNeeded } from "./utils";

function Dropdown({
  id,
  options,
  disabled,
  displayInline,
  currentOption,
  onClickFunction,
  color = Colors.DEFAULT,
  defaultLabel = "No Selection",
  handleOnSelect = (option) => {}
}) {
  const divRef = useRef(null);
  const divRefScroll = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showDropdown &&
        divRef &&
        divRef.current &&
        !divRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const getComposeBgOptions = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "hover:bg-secondary-red active:bg-secondary-red";
      case "bg-primary-blue":
        return "hover:bg-secondary-blue active:bg-secondary-blue";
      case "bg-primary-green":
        return "hover:bg-secondary-green active:bg-secondary-green";
      case "bg-primary-orange":
        return "hover:bg-secondary-orange active:bg-secondary-orange";

      default:
        return "hover:bg-light-gray active:bg-light-gray";
    }
  };

  const getComposeBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return `
          text-white
          bg-primary-red
          hover:bg-white
          active:bg-white
          border-primary-red
          hover:text-primary-red
          active:text-primary-red
        `;
      case "bg-primary-blue":
        return `
          text-white
          hover:bg-white
          active:bg-white
          bg-primary-blue
          border-primary-blue
          hover:text-primary-blue
          active:text-primary-blue
        `;
      case "bg-primary-green":
        return `
          text-white
          hover:bg-white
          active:bg-white
          bg-primary-green
          border-primary-green
          hover:text-primary-green
          active:text-primary-green
        `;
      case "bg-primary-orange":
        return `
          text-white
          hover:bg-white
          active:bg-white
          bg-primary-orange
          border-primary-orange
          hover:text-primary-orange
          active:text-primary-orange
        `;

      default:
        return `
          bg-white
          text-black
          border-black
          hover:bg-light-gray
          active:bg-light-gray
        `;
    }
  };

  const colors = getColors(color);

  return (
    <div ref={divRef} className="relative">
      <div
        id={id}
        role="button"
        disabled={disabled}
        onClick={() => {
          setShowDropdown(!showDropdown);
          scrollIntoViewIfNeeded(divRefScroll);
          onClickFunction && onClickFunction();
        }}
        className={`
          py-2
          px-3
          flex
          w-full
          flex-1
          border
          flex-row
          space-x-4
          items-center
          ${showDropdown && "shadow-black"}
          ${getComposeBg(colors.primary.bg)}
          ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
        `}
      >
        <div className="flex flex-1 flex-row">
          {currentOption?.label || defaultLabel}
        </div>
        <div
          className={`
            transform
            transition
            duration-300
            ${showDropdown ? "-rotate-180" : "rotate-0"}
          `}
        >
          <div className="transform -rotate-90">
            <ShortArrow size={20} color="stroke-current" />
          </div>
        </div>
      </div>
      {showDropdown && (
        <div
          id={`${id}-container`}
          ref={divRefScroll}
          className={`
            z-10
            absolute
            border-b
            border-l
            border-r
            inset-x-0
            bg-white
            max-h-15rem
            shadow-black
            overflow-y-auto
            ${colors.primary.border}
          `}
          style={displayInline && { width: "200%", left: "-100%" }}
        >
          {options && options.length > 0 ? (
            options.map((option, index) => (
              <div
                role="button"
                key={option.id}
                id={`${id}-option-${index}`}
                className={`
                  p-2
                  ${getComposeBgOptions(colors.primary.bg)}
                  ${option.id === currentOption?.id && colors.primary.text}
                  ${!option.enabled && "opacity-40 cursor-not-allowed"}
                `}
                onClick={
                  option.enabled
                    ? () => {
                        handleOnSelect(option);
                        setShowDropdown(false);
                      }
                    : null
                }
              >
                {option.label}
              </div>
            ))
          ) : (
            <div
              className={`
                p-2
                flex
                items-center
                justify-center
                ${colors.primary.text}
              `}
            >
              <span>No Options</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  defaultLabel: PropTypes.any,
  displayInline: PropTypes.bool,
  handleOnSelect: PropTypes.func,
  currentOption: PropTypes.shape({
    id: PropTypes.any,
    label: PropTypes.any,
    enabled: PropTypes.bool
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      label: PropTypes.any,
      enabled: PropTypes.bool
    })
  ),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Dropdown;
