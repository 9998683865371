import PropTypes from "prop-types";

function Applied({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 72 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M47.7299 34.3001L34.8199 32.3201L22.4199 32.7401L23.1499 19.5701L27.3599 19.7901L26.8899 28.3701L35.0699 28.0901L43.6599 29.4101L43.9299 20.4001L48.1499 20.5301L47.7299 34.3001Z"
      />
      <path
        className={color}
        d="M35.2617 26.4201L14.5117 16.8101L36.0117 8.62012L57.5517 18.1301L35.2617 26.4201ZM25.3617 17.1901L35.4417 21.8601L46.3517 17.7901L35.8917 13.1701L25.3617 17.1901Z"
      />
      <path
        className={color}
        d="M65.0416 43.0599H6.85156V0.629883H65.0416V43.0599ZM11.0416 38.8399H60.8216V4.83987H11.0616L11.0416 38.8399Z"
      />
      <path
        className={color}
        d="M71.9218 57.0499H0.0917969L7.0018 40.1499L10.9118 41.7499L6.3718 52.8299H65.6018L60.9918 41.7599L64.8818 40.1399L71.9218 57.0499Z"
      />
      <path
        className={color}
        d="M54.3016 45.8401H17.6816V50.0601H54.3016V45.8401Z"
      />
    </svg>
  );
}

Applied.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Applied;
