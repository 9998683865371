// import { useMemo } from "react";

// import { Students } from "../MockData";
// import { getColumns as getColumnsMobile } from "../../Student/Table/Mobile/columns";
// import { getColumns as getColumnsDesktop } from "../../Student/Table/Desktop/columns";

function StudentsScreenOnboarding({ permissions }) {
  // const students = Students.map((record) => {
  //   let enrollmentStatus = "";
  //   return {
  //     record: record,
  //     affordability: record.student.chosenSchools.map((chosenSchool) => {
  //       if (chosenSchool.enrollmentStatus > enrollmentStatus) {
  //         enrollmentStatus = chosenSchool.enrollmentStatus;
  //       }
  //       return {
  //         schoolName: chosenSchool.school.name,
  //         affordability:
  //           chosenSchool.award?.postAwardSchoolCosts?.affordability,
  //         coverage: chosenSchool.award?.postAwardSchoolCosts?.coverage,
  //       };
  //     }),
  //     student: {
  //       enrollmentStatus,
  //       firstName: record.student.firstName,
  //       lastName: record.student.lastName,
  //       email: record.student.email,
  //       isPell: record.student.isPell,
  //       studentId: record.student.studentId,
  //       lastLogin: record.student.lastLogin,
  //       lastLoginHumanize: record.student.lastLoginHumanize,
  //     },
  //     groups: record.groups,
  //   };
  // });

  // const columnsDesktop = useMemo(
  //   () =>
  //     getColumnsDesktop({
  //       groups: [],
  //       permissions,
  //       openModal: () => {},
  //       organizationId: "null",
  //       onStudentSelect: () => {},
  //       toggleSortingOrder: () => {},
  //       onFilterGroupsSelect: () => {},
  //       onFilterAffordabilitySelect: () => {},
  //       onFilterEnrollmentStatusSelect: () => {},
  //     }),
  //   [permissions]
  // );

  // const columnsMobile = useMemo(
  //   () =>
  //     getColumnsMobile({
  //       onStudentSelect: () => {},
  //       toggleSortingOrder: () => {},
  //       onFilterAffordabilitySelect: () => {},
  //     }),
  //   []
  // );

  return (
    <div
      className="
        py-6
        px-16
        mobile:px-4
        mobile-sm:px-3
        tablet-sm:px-4
        tablet:px-6
        laptop:px-10
      "
    >
      {/* <StudentTable
        data={students}
        studentSearch=""
        onRemove={() => {}}
        onChange={() => {}}
        openModal={() => {}}
        filterTagsGroups={[]}
        permissions={permissions}
        handlePagination={() => {}}
        filterTagsAffordability={[]}
        columnsMobile={columnsMobile}
        columnsDesktop={columnsDesktop}
        filterTagsEnrollmentStatus={[]}
        studentsState={{ itemsCount: students.length, isLoading: false }}
      /> */}
    </div>
  );
}

export default StudentsScreenOnboarding;
