import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchNotifications } from "./actions";
import { useRefreshFlag } from "../../core/hooks";

export function useNotifications() {
  return useSelector((state) => state.notifications);
}

export function useRefetchNotifications({ refreshTimeout = 120 * 1000 }) {
  const dispatch = useDispatch();
  const { refreshFlag } = useRefreshFlag({ refreshTimeout });

  useEffect(() => {
    (async () => {
      await dispatch(fetchNotifications({ refetch: true }));
    })();
  }, [dispatch, refreshFlag]);
}
