import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { useLogin } from "../../utils/auth";

import { useAdvisor } from "../../redux/Advisor/hooks";
import { fetchAdvisor } from "../../redux/Advisor/actions";
import { useOrganizations } from "../../redux/Organizations/hooks";
import { fetchOrganizations } from "../../redux/Organizations/actions";

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const advisor = useAdvisor();
  const { isLoading, userExists, error } = useLogin();
  const { selectedOrganizationId } = useOrganizations();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isLoading && !error) {
        if (userExists) {
          if (!advisor.isLoggedIn) {
            await dispatch(fetchOrganizations());
            await dispatch(
              fetchAdvisor({ organizationId: selectedOrganizationId })
            );
          }
          setReady(true);
        } else {
          const qs = new URLSearchParams(location.search.slice(1));
          let redirectUrl = "/create-account";
          if (qs.get("next")) {
            redirectUrl += `?next=${encodeURIComponent(qs.get("next"))}`;
          }
          navigate(redirectUrl);
        }
      }
    })();
  }, [
    isLoading,
    userExists,
    advisor.isLoggedIn,
    error,
    dispatch,
    location,
    selectedOrganizationId,
    navigate
  ]);

  if (ready) {
    return children;
  } else {
    // FIXME: Display something
    return null;
  }
}

export default PrivateRoute;
