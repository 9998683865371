import PropTypes from "prop-types";
import { Link as LinkIcon } from "./icons";
import { Colors, getColors } from "./utils";

function Link({
  text,
  href,
  customIcon,
  textClassName,
  icon = false,
  iconSize = 20,
  internal = true,
  disabled = false,
  target = "_self",
  color = Colors.DEFAULT,
  ...restProps
}) {
  const getComposeText = (text) => {
    switch (text) {
      case "text-alternative-red":
        return "hover:text-alternative-red";
      case "text-alternative-blue":
        return "hover:text-alternative-blue";
      case "text-alternative-green":
        return "hover:text-alternative-green";
      case "text-alternative-orange":
        return "hover:text-alternative-orange";
      default:
        return "hover:text-primary-blue";
    }
  };

  const colors = getColors(color);
  const hoverText = getComposeText(colors.alternative.text);

  const renderText = () => {
    if (typeof text === "string") {
      return <span className={textClassName}>{text}</span>;
    }
    return text;
  };

  if (!internal)
    return (
      <a
        href={href}
        target={target}
        rel="noreferrer"
        className={`
          flex
          flex-row
          space-x-2
          items-center
          hover:underline
          ${hoverText}
          ${colors.primary.text}
          ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
        `}
        {...restProps}
      >
        {renderText()}
        {icon && (
          <div className="ml-1 self-center">
            {customIcon || <LinkIcon size={iconSize} color="fill-current" />}
          </div>
        )}
      </a>
    );

  return (
    <button
      disabled={disabled}
      className={`
        flex
        flex-row
        space-x-2
        items-center
        hover:underline
        ${hoverText}
        ${colors.primary.text}
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
      {...restProps}
    >
      {renderText()}
      {icon && (
        <div className="ml-1 self-center">
          {customIcon || <LinkIcon size={iconSize} color="fill-current" />}
        </div>
      )}
    </button>
  );
}

Link.propTypes = {
  icon: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  internal: PropTypes.bool,
  disabled: PropTypes.bool,
  customIcon: PropTypes.any,
  iconSize: PropTypes.number,
  textClassName: PropTypes.string,
  target: PropTypes.oneOf(["_self", "_blank"]),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Link;
