import Types from "./types";

import Api from "../../services/Api";

function setReset() {
  return {
    type: Types.RESET,
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setPage({ page, items, pageCount, itemsCount }) {
  return {
    type: Types.SET_PAGE,
    page,
    items,
    pageCount,
    itemsCount,
  };
}

export function resetArchivedAdvisorListsState() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchOrganizationArchivedAdvisorLists({
  page,
  filter,
  orderBy,
  organizationId,
}) {
  return async (dispatch) => {
    try {
      dispatch(setError(null));
      dispatch(setIsLoading(true));

      const archivedLists = await Api.fetchOrganizationAdvisorLists({
        page,
        filter,
        orderBy,
        basic: true,
        organizationId,
      });
      dispatch(setPage({ page, ...archivedLists }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
