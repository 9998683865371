import { useState } from "react";
import PropTypes from "prop-types";

import NoteList from "./NoteList";
import NoteDetail from "./NoteDetail";

function NotesShare({ notes, order, toggleOrder, handlerPagination }) {
  const [noteSelected, setNodeSelected] = useState(null);

  const handlerOnExpandNote = (note) => setNodeSelected(note);

  return (
    <div
      id="share-notes-container"
      className="h-full divide-y divide-black overflow-hidden"
    >
      {!noteSelected ? (
        <NoteList
          notes={notes}
          order={order}
          toggleOrder={toggleOrder}
          handlerPagination={handlerPagination}
          handlerOnExpandNote={handlerOnExpandNote}
        />
      ) : (
        <NoteDetail
          note={noteSelected}
          handlerOnCloseNote={handlerOnExpandNote}
        />
      )}
    </div>
  );
}

NotesShare.propTypes = {
  notes: PropTypes.array,
  order: PropTypes.string,
  toggleOrder: PropTypes.func,
  handlerPagination: PropTypes.func,
};

export default NotesShare;
