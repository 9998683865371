import { useRef, useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import { FloatBox } from "../../../../core";

function Ellipsis({
  children,
  color = "bg-white",
  className = "right-0 bottom-0"
}) {
  return (
    <div className={`z-10 flex flex-row absolute ${className}`}>
      <div className={`px-4 ${color}`}>{children}</div>
    </div>
  );
}

Ellipsis.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

function EllipsisDropdown({
  text,
  lines,
  more = "...",
  right = true,
  classMore = "",
  separator = null,
  titleDropdown = ""
}) {
  const containerRef = useRef(null);
  const [limit, setLimit] = useState(1000);
  const [showMore, setShowMore] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = () => setShowDropdown(!showDropdown);

  const handleOutsideClick = (state) => setShowDropdown(state);

  const handleResize = () => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      const limitStr = window
        .getComputedStyle(containerRef.current)
        .getPropertyValue("line-height");
      setLimit(Number(limitStr.split("px")[0]) * lines);
      if (!showMore || height < limit) setShowMore(height > limit);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useLayoutEffect(() => handleResize());

  return (
    <>
      <div ref={containerRef} className="relative">
        <p
          className="overflow-hidden overflow-ellipsis"
          style={{ maxHeight: showMore ? `${limit}px` : "" }}
        >
          {text}
        </p>
        {showMore &&
          (right ? (
            <Ellipsis>
              <span
                onClick={handleClick}
                className={`cursor-pointer ${classMore}`}
              >
                {more}
              </span>
            </Ellipsis>
          ) : (
            <Ellipsis className="left-0 bottom-0">
              <span
                onClick={handleClick}
                className={`cursor-pointer ${classMore}`}
              >
                {more}
              </span>
            </Ellipsis>
          ))}
        <FloatBox
          open={showDropdown}
          containerRef={containerRef}
          handleOnClickOutside={handleOutsideClick}
          className={`
            p-4
            mt-2
            bg-white
            border-black
            ${right ? "right-0 right" : "left-0 left"}
          `}
        >
          {titleDropdown !== "" && (
            <p className="font-semibold tablet:text-sm">{titleDropdown}</p>
          )}
          {separator !== null ? (
            <ul className="max-h-15rem overflow-y-auto">
              {text.split(separator).map((item, index) => (
                <li
                  className="list-disc list-inside font-medium"
                  key={`${index}-${item}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p>{text}</p>
          )}
        </FloatBox>
      </div>
    </>
  );
}

EllipsisDropdown.propTypes = {
  right: PropTypes.bool,
  more: PropTypes.string,
  classMore: PropTypes.string,
  separator: PropTypes.string,
  titleDropdown: PropTypes.string,
  text: PropTypes.string.isRequired,
  lines: PropTypes.number.isRequired
};

export default EllipsisDropdown;
