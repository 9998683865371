import PropTypes from "prop-types";

import { OkayHand } from "../../core/icons";

function NotFound({ className = "min-h-screen" }) {
  return (
    <div
      className={`
        p-4
        flex
        flex-1
        h-full
        w-full
        flex-col
        bg-white
        items-center
        overflow-auto
        justify-center
        ${className}
      `}
    >
      <OkayHand
        size={250}
        strokeColor="stroke-white"
        fillColor="fill-primary-blue"
      />
      <div
        className="
          mb-5
          text-6xl
          text-center
          leading-none
          font-extrabold
          text-primary-blue
        "
      >
        <span>Page Not Found!</span>
      </div>
      <div
        className="
          flex
          text-xl
          flex-row
          font-bold
          items-center
          justify-center
          text-primary-blue
          hover:underline
          hover:text-alternative-blue
        "
      >
        <a href="/dashboard">Go Home</a>
      </div>
    </div>
  );
}

NotFound.propTypes = {
  className: PropTypes.string
};

export default NotFound;
