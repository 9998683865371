import PropTypes from "prop-types";

import { Link } from "../../";
import { ShortArrow } from "../../icons";

import { Colors, decidedRoute } from "../../utils";

function Description({ color = Colors.DEFAULT }) {
  return (
    <div>
      <p className="my-4">
        Schools with higher graduation rates tend to provide more tutoring,
        one-on-one academic planning, and other support programs to make sure
        you graduate on time. And graduating in fewer years means spending less
        money.
      </p>

      <Link
        icon={true}
        color={color}
        target="_blank"
        internal={false}
        textClassName="font-bold"
        text="Why Graduation Rate Matters"
        href={decidedRoute("/guides/why-graduation-rate-matters")}
        customIcon={
          <div className="transform place-self-end rotate-180">
            <ShortArrow size={16} color="stroke-current" />
          </div>
        }
      />
    </div>
  );
}

Description.propTypes = {
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Description;
