import PropTypes from "prop-types";

import { Link } from "../../";
import { ShortArrow } from "../../icons";
import { Colors, decidedRoute } from "../../utils";

function Description({ color = Colors.DEFAULT }) {
  return (
    <>
      <p className="my-4">
        How much you will make in your first years out of college depends a lot
        on your major and field of work. Estimating your future salary helps you
        decide the maximum amount of loans you will be able to repay.
      </p>

      <Link
        icon={true}
        color={color}
        target="_blank"
        internal={false}
        textClassName="font-bold"
        text="Ultimate Guide to Loans"
        href={decidedRoute("/guides/ultimate-guide-to-loans")}
        customIcon={
          <div className="transform place-self-end rotate-180">
            <ShortArrow size={16} color="stroke-current" />
          </div>
        }
      />

      <Link
        icon={true}
        color={color}
        target="_blank"
        internal={false}
        textClassName="font-bold text-left"
        text="How much is too much in loans?"
        href={decidedRoute("/guides/how-much-is-too-much-in-loans")}
        customIcon={
          <div className="transform place-self-end rotate-180">
            <ShortArrow size={16} color="stroke-current" />
          </div>
        }
      />
    </>
  );
}

Description.propTypes = {
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Description;
