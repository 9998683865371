import PropTypes from "prop-types";

function Advisors({
  size = 25,
  fillColor = "fill-black",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 19 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_5905_16529">
        <path d="M11.0675 11.3345H7.93252C4.66993 11.3345 1.85268 13.7049 1.02643 17.0002H5.72205e-06C0.849571 13.1784 4.07871 10.3345 7.93252 10.3345H11.0675C14.9213 10.3345 18.1504 13.1784 19 17.0002H17.9736C17.1473 13.7049 14.3301 11.3345 11.0675 11.3345Z" />
      </mask>
      <path
        className={fillColor}
        d="M11.0675 11.3345H7.93252C4.66993 11.3345 1.85268 13.7049 1.02643 17.0002H5.72205e-06C0.849571 13.1784 4.07871 10.3345 7.93252 10.3345H11.0675C14.9213 10.3345 18.1504 13.1784 19 17.0002H17.9736C17.1473 13.7049 14.3301 11.3345 11.0675 11.3345Z"
      />
      <path
        className={fillColor}
        mask="url(#path-1-inside-1_5905_16529)"
        d="M1.02643 17.0002L1.9964 17.2434L1.80665 18.0002H1.02643V17.0002ZM5.72205e-06 17.0002V18.0002H-1.2467L-0.976166 16.7832L5.72205e-06 17.0002ZM19 17.0002L19.9762 16.7832L20.2467 18.0002H19V17.0002ZM17.9736 17.0002V18.0002H17.1934L17.0036 17.2434L17.9736 17.0002ZM7.93252 10.3345H11.0675V12.3345H7.93252V10.3345ZM0.0564575 16.757C0.982477 13.0638 4.16464 10.3345 7.93252 10.3345V12.3345C5.17523 12.3345 2.72289 14.346 1.9964 17.2434L0.0564575 16.757ZM1.02643 18.0002H5.72205e-06V16.0002H1.02643V18.0002ZM-0.976166 16.7832C-0.036087 12.5542 3.56035 9.33447 7.93252 9.33447V11.3345C4.59707 11.3345 1.73523 13.8026 0.976179 17.2172L-0.976166 16.7832ZM7.93252 9.33447H11.0675V11.3345H7.93252V9.33447ZM11.0675 9.33447C15.4397 9.33447 19.0361 12.5542 19.9762 16.7832L18.0238 17.2172C17.2648 13.8026 14.4029 11.3345 11.0675 11.3345V9.33447ZM19 18.0002H17.9736V16.0002H19V18.0002ZM11.0675 10.3345C14.8354 10.3345 18.0175 13.0638 18.9435 16.757L17.0036 17.2434C16.2771 14.346 13.8248 12.3345 11.0675 12.3345V10.3345Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M9.50019 12.1671C6.49758 12.1671 4.141 9.51045 4.141 6.33353C4.141 3.15661 6.49758 0.5 9.50019 0.5C12.5028 0.5 14.8594 3.15661 14.8594 6.33353C14.8594 9.51045 12.5028 12.1671 9.50019 12.1671Z"
      />
    </svg>
  );
}

Advisors.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default Advisors;
