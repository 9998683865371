const SET_IS_LOADING = "ADVISOR/SET_IS_LOADING";
const SET_ERROR = "ADVISOR/SET_ERROR";
const SET_PROFILE = "ADVISOR/SET_PROFILE";
const SET_IS_LOGGED_IN = "ADVISOR/SET_IS_LOGGED_IN";

const Types = {
  SET_IS_LOADING,
  SET_ERROR,
  SET_PROFILE,
  SET_IS_LOGGED_IN,
};

export default Types;
