import PropTypes from "prop-types";

function College({
  size = 25,
  color = "stroke-black",
  fillColor = "fill-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        strokeLinecap="round"
        strokeMiterlimit="1.30541"
        d="M24.0002 10.5992L35.9976 17.6969L47.995 24.7945H24.0002H0.00537109L12.0028 17.6969L24.0002 10.5992Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M41.3695 28.9202V41.7333H43.3232V45.8173H33.8198V41.7333H35.7735V28.9202H33.8198V24.8362H43.3232V28.9202H41.3695Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M26.6244 28.9202V41.7333H28.5781V45.8173H19.0747V41.7333H21.0284V28.9202H19.0747V24.8362H28.5781V28.9202H26.6244Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M12.2264 28.9202V41.7333H14.1801V45.8173H4.67676V41.7333H6.63043V28.9202H4.67676V24.8362H14.1801V28.9202H12.2264Z"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M6.12646 28.8993H12.346"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M20.6958 28.8993H26.9153"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M35.1509 28.8993H41.3704"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M6.12646 41.7435H12.346"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M20.6958 41.7435H26.9153"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M35.1509 41.7435H41.3704"
      />
      <path
        className={color}
        strokeMiterlimit="10"
        d="M24.0107 10.5213V5.52283"
      />
      <path
        strokeMiterlimit="10"
        className={`${color} ${fillColor}`}
        d="M33.1452 2H24.0107V5.86058H33.1452V2Z"
      />
      <path d="M0 46H48" className={color} strokeMiterlimit="10" />
    </svg>
  );
}

College.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  fillColor: PropTypes.string
};

export default College;
