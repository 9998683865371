import PropTypes from "prop-types";

function Browser({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 101.23 101.23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M48.85,37.52L13.08,51.16l13.97,6.47-1.12,20.49,20.91-.7,21.79,3.35,.66-21.69,6.27-2.33-.48,17.53,6.17,.17,.55-20.01,2.89-1.08-35.84-15.82Zm-.17,6.67l19.64,8.67-20.46,7.62-18.91-8.76,19.74-7.52Zm14,29.42l-15.47-2.38-14.75,.5,.62-11.31,14.52,6.73,15.46-5.75-.37,12.21Z"
      />
      <path
        className={color}
        d="M101.23,0H0V101.23H101.23V0Zm-6.17,6.17v15.19H6.17V6.17H95.07ZM6.17,95.07V27.52H95.07V95.07H6.17Z"
      />
      <rect className={color} x="10.46" y="10.58" width="57.57" height="6.17" />
      <rect className={color} x="82.93" y="10.58" width="7.99" height="6.17" />
      <rect className={color} x="71.66" y="10.58" width="7.99" height="6.17" />
    </svg>
  );
}

Browser.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Browser;
