import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { LoadingButton } from "../../../core/buttons";

function UnarchiveList({ lists = [], onSubmit, secondText, unarchiveLists }) {
  const firstText = (
    <>
      <div
        className={`
          pt-4
          text-3xl
          font-black
          leading-10
          text-primary-blue
        `}
      >
        Are you sure you want to unarchive
        {unarchiveLists === 1
          ? " this list"
          : lists?.length > 0
          ? " these lists"
          : ` ${unarchiveLists} lists`}
        ?
      </div>
      {lists?.length > 0 && (
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {lists.map((list) => (
              <li key={list.id} className="list-disc list-inside">
                <span>{list.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onSubmit();
  };

  return (
    <>
      <div className="overflow-auto px-1">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="archive-group-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          unarchive {unarchiveLists === 1 ? "list" : "lists"}
        </LoadingButton>
      </div>
    </>
  );
}

UnarchiveList.propTypes = {
  unarchiveLists: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  secondText: PropTypes.any.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape())
};

export default UnarchiveList;
