import { Affordability } from "../utils";

export const getChartColors = (affordability) => {
  switch (affordability) {
    case Affordability.AFFORDABLE:
      return {
        primary: "text-primary-green",
        secondary: "text-secondary-green",
        alternative: "text-alternative-green"
      };
    case Affordability.NOT_AFFORDABLE:
      return {
        primary: "text-primary-red",
        secondary: "text-secondary-red",
        alternative: "text-alternative-red"
      };
    case Affordability.SOMEWHAT_AFFORDABLE:
      return {
        primary: "text-primary-orange",
        secondary: "text-secondary-orange",
        alternative: "text-alternative-orange"
      };

    default:
      return {
        primary: "text-primary-blue",
        secondary: "text-secondary-blue",
        alternative: "text-alternative-blue"
      };
  }
};
