import PropTypes from "prop-types";

function Sign({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 85.26 100.66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M74.86,47.93l10.39-10.77-10.42-10.42h-28.8V0h-6.17V4.87H10.39L0,15.64l10.42,10.42h29.45v23.8H10.39L0,60.64l10.42,10.42h29.45v23.43H17.44v6.17h49.73v-6.17h-21.13V47.93h28.83Zm-2.59-15.02l4.33,4.33-4.36,4.52h-26.21v-8.86h26.24ZM12.98,19.9l-4.33-4.33,4.36-4.52h26.86v8.86H12.98Zm0,45l-4.33-4.33,4.37-4.53h26.86v8.86H12.98Z"
      />
    </svg>
  );
}

Sign.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Sign;
