import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";

import NotFound from "../NotFound";

import { Items, loadState, saveState } from "../../redux/storePersist";

import { interactionEventDataLayer } from "../../utils/DataLayers";

function CustomSwitch({ children, className }) {
  useEffect(() => {
    const { date, firstCall, isLoggedIn, validationTime } = loadState(
      Items.GTM_STATE,
    );

    const currentDate = new Date();
    const loadedDate = date ? new Date(date) : new Date();

    const invalidDate =
      date && Math.abs(currentDate - loadedDate) > validationTime;

    if (!firstCall || (invalidDate && !isLoggedIn)) {
      interactionEventDataLayer({ eventName: "session_start_advisor" });
      saveState(
        {
          firstCall: true,
          isLoggedIn: false,
          validationTime: 5 * 60 * 1000,
          date: new Date().toISOString(),
        },
        Items.GTM_STATE,
      );
    }
  });

  return (
    <Routes>
      {children}
      <Route path="*" element={<NotFound className={className} />} />
    </Routes>
  );
}

CustomSwitch.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default CustomSwitch;
