import PropTypes from "prop-types";

function Category({ name }) {
  return (
    <div className="py-4 px-10 w-full text-white bg-primary-blue">
      <p className="font-semibold">{name}</p>
    </div>
  );
}

Category.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Category;
