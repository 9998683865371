import PropTypes from "prop-types";

import { Panel } from "../../compose";
import { CampusEnvironment } from "../";
import { getTheme } from "../../utils";

function CampusEnvironmentCard({
  id,
  open,
  chosenSchool,
  externalToggle,
  callbackToggle,
}) {
  const { award, school } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });

  return (
    <Panel
      id={id}
      initiallyOpened={open}
      title="campus environment"
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      className="p-4 mb-2 bg-white shadow-lg"
      titleClassName="
        flex
        flex-1
        flex-row
        capitalize
        font-semibold
      "
      expandableContent={
        <CampusEnvironment
          color={theme.color}
          bodySize={school.size}
          college={school.control}
          locationType={school.areaType}
        />
      }
    />
  );
}

CampusEnvironmentCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default CampusEnvironmentCard;
