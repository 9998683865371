import PropTypes from "prop-types";

import { ArrowNav } from "../../../../../../core/icons";
import { Button } from "../../../../../../core/buttons";

function ImagesButtonsControl({
  disablePrev,
  disableNext,
  imagesCount,
  setCurrentImage,
}) {
  return (
    <div className="flex w-full flex-row space-x-4">
      <div className="w-1/3">
        <Button
          disabled={disablePrev}
          onClick={() =>
            setCurrentImage((current) => {
              if (current > 0) return current - 1;
              return current;
            })
          }
          className="
            py-2
            px-4
            w-full
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
          "
        >
          <div className="flex flex-1 flex-row items-center justify-start">
            <div>
              <ArrowNav size={20} color="text-current" />
            </div>
            <p className="mx-auto capitalize">prev</p>
          </div>
        </Button>
      </div>
      <div className="w-1/3" />
      <div className="w-1/3">
        <Button
          disabled={disableNext}
          onClick={() =>
            setCurrentImage((current) => {
              if (current < imagesCount - 1) return current + 1;
              return current;
            })
          }
          className="
            py-2
            px-4
            w-full
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
          "
        >
          <div className="flex flex-1 flex-row items-center justify-end">
            <p className="mx-auto capitalize">next</p>
            <div className="transform rotate-180">
              <ArrowNav size={20} color="text-current" />
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
}

ImagesButtonsControl.propTypes = {
  disablePrev: PropTypes.bool.isRequired,
  disableNext: PropTypes.bool.isRequired,
  imagesCount: PropTypes.number.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
};

export default ImagesButtonsControl;
