import React from "react";
import { Route } from "react-router-dom";

import CustomSwitch from "../Common/CustomSwitch";
import PrivateRoute from "../Common/PrivateRoute";
import OrgOptionsScreen from "./OrgOptionsScreen";
import CreateOrganization from "./CreateOrganization";
import LinkOrganization from "./LinkOrganization";
import AcceptOrgInvitation from "./AcceptOrgInvitation";

function APIAssociationsRoutes() {
  return (
    <>
      <CustomSwitch>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <OrgOptionsScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="create-org"
          element={
            <PrivateRoute>
              <CreateOrganization />
            </PrivateRoute>
          }
        />
        <Route
          path="link-org"
          element={
            <PrivateRoute>
              <LinkOrganization />
            </PrivateRoute>
          }
        />
        <Route
          path="accept-org-invitation"
          element={
            <PrivateRoute>
              <AcceptOrgInvitation />
            </PrivateRoute>
          }
        />
      </CustomSwitch>
    </>
  );
}

export default APIAssociationsRoutes;
