import PropTypes from "prop-types";

import { ShortArrow } from "../../../../core/icons";

function CategoryLabel({
  name,
  toggle,
  classNameLabel,
  active = true,
  rotation = true,
  onClick = () => {},
  icon = (
    <div className="transform -rotate-90">
      <ShortArrow size={16} color="stroke-black" />
    </div>
  )
}) {
  const getArrowRotation = (toggle) => (toggle ? "-rotate-180" : "rotate-0");

  return (
    <div
      role="button"
      onClick={onClick}
      className={`
        py-2
        flex
        flex-row
        justify-between
        ${!active ? "opacity-40 pointer-events-none" : ""}
      `}
    >
      <p
        className={`w-full border-b font-black border-light-gray ${classNameLabel}`}
      >
        {name}
      </p>
      <div
        className={`
          transform
          transition
          place-self-center
          ${rotation ? getArrowRotation(toggle) : ""}
        `}
      >
        {icon}
      </div>
    </div>
  );
}

CategoryLabel.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string,
  active: PropTypes.bool,
  toggle: PropTypes.bool,
  onClick: PropTypes.func,
  rotation: PropTypes.bool
};

export default CategoryLabel;
