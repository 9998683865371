import Api from "../../services/Api";
import { Order } from "../../utils/order";
import Types from "./types";

function setError(error) {
  return {
    error,
    type: Types.SET_ERROR,
  };
}

function setIsLoading(isLoading) {
  return {
    isLoading,
    type: Types.SET_IS_LOADING,
  };
}

function setPage({ page, itemsCount, pageCount, items }) {
  return {
    page,
    items,
    pageCount,
    itemsCount,
    type: Types.SET_PAGE,
  };
}

export function setLists({ studentLists, advisorLists }) {
  return {
    studentLists,
    advisorLists,
    type: Types.SET_LISTS,
  };
}

export function setListUpdating(listUpdating) {
  return {
    listUpdating,
    type: Types.SET_LIST_UPDATING,
  };
}

export function setUserNotAllowed(userNotAllowed) {
  return {
    userNotAllowed,
    type: Types.SET_USER_NOT_ALLOWED,
  };
}

export function setSelectedOrganizationId(organizationId) {
  return {
    organizationId,
    type: Types.SET_SELECTED_ORGANIZATION_ID,
  };
}

export function fetchOrganizations({ page } = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setUserNotAllowed(false));
      dispatch(setIsLoading(true));
      dispatch(setError(null));

      const organizations = await Api.fetchOrganizations({ page });
      dispatch(setPage({ page: page || { pageNumber: 1 }, ...organizations }));

      if (getState()?.organizations?.selectedOrganizationId) {
        const organizationId =
          getState()?.organizations?.selectedOrganizationId;
        const studentLists = await fetchOrganizationStudentLists({
          organizationId,
        });
        const advisorLists = await fetchOrganizationAdvisorLists({
          organizationId,
        });
        dispatch(setLists({ studentLists, advisorLists }));
        dispatch(setListUpdating(true));
      }
      if (!getState()?.organizations?.selectedOrganizationId) {
        const orgsWithStudents = organizations?.items
          .filter(
            (org) => org?.organization?.organizationStudents?.itemsCount > 0
          )
          .sort(
            (a, b) =>
              b?.organization?.organizationStudents?.itemsCount -
              a?.organization?.organizationStudents?.itemsCount
          );

        let organizationId = null;

        if (orgsWithStudents.length > 0) {
          organizationId = orgsWithStudents[0]?.organization?.id || null;
          dispatch(setSelectedOrganizationId(organizationId));
        } else {
          organizationId = organizations?.items[0]?.organization?.id || null;
          dispatch(setSelectedOrganizationId(organizationId));
        }

        const studentLists = await fetchOrganizationStudentLists({
          organizationId,
        });
        const advisorLists = await fetchOrganizationAdvisorLists({
          organizationId,
        });
        dispatch(setLists({ studentLists, advisorLists }));
      }

      if (!getState()?.organizations?.selectedOrganizationId)
        dispatch(setUserNotAllowed(true));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

async function fetchOrganizationStudentLists({ organizationId }) {
  const pageSize = 50;
  const orderBy = { name: Order.asc };
  let page = { pageNumber: 1, pageSize };
  const { pageCount, items } = (await Api.fetchOrganizationStudentLists({
    page,
    orderBy,
    basic: true,
    organizationId,
  })) || { pageCount: 0, items: [] };
  let studentLists = items;
  for (let index = 2; index <= pageCount; index++) {
    page = { pageNumber: index, pageSize };
    const { items } = await Api.fetchOrganizationStudentLists({
      page,
      orderBy,
      basic: true,
      organizationId,
    });
    studentLists = studentLists.concat(items);
  }
  return studentLists;
}

async function fetchOrganizationAdvisorLists({ organizationId }) {
  const pageSize = 50;
  const orderBy = { name: Order.asc };
  let page = { pageNumber: 1, pageSize };
  const { pageCount, items } = (await Api.fetchOrganizationAdvisorLists({
    page,
    orderBy,
    basic: true,
    organizationId,
  })) || { pageCount: 0, items: [] };
  let studentLists = items;
  for (let index = 2; index <= pageCount; index++) {
    page = { pageNumber: index, pageSize };
    const { items } = await Api.fetchOrganizationAdvisorLists({
      page,
      orderBy,
      basic: true,
      organizationId,
    });
    studentLists = studentLists.concat(items);
  }
  return studentLists;
}
