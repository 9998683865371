import PropTypes from "prop-types";

function NameCell({ name }) {
  return (
    <div className="flex flex-row select-all items-center overflow-hidden">
      <p className="text-sm truncate font-bold">{name}</p>
    </div>
  );
}

NameCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NameCell;
