import PropTypes from "prop-types";

import { MyNotes, AllNotes } from "../../../core/icons";

const NoteTabs = {
  MY_NOTES: 0,
  ALL_NOTES: 1,
};

function NotesTabs({ activeTab, studentName, setActiveTab }) {
  const tabs = [
    {
      id: NoteTabs.MY_NOTES,
      name: `My Notes for ${studentName}`,
      icon: (
        <div className="mr-2 mobile-sm:hidden">
          <MyNotes color="fill-current" />
        </div>
      ),
    },
    {
      id: NoteTabs.ALL_NOTES,
      name: `All Notes for ${studentName}`,
      icon: (
        <div className="mr-2 mobile-sm:hidden">
          <AllNotes color="fill-current" />
        </div>
      ),
    },
  ];

  const getTabClassName = (tabId, activeTab) => {
    return tabId === activeTab
      ? tabId === 0
        ? "border-r text-primary-blue"
        : "border-l text-primary-blue"
      : "border-b shadow-custom-inner";
  };

  return (
    <div className="mb-5 flex flex-1 flex-row font-bold">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`
            px-1
            py-2
            flex
            w-1/2
            flex-row
            items-center
            border-black
            justify-center
            cursor-pointer
            ${getTabClassName(tab.id, activeTab)}
          `}
        >
          {tab.icon}
          <span
            className="
              text-lg
              text-center
              mobile:text-base
              mobile-sm:text-base
            "
          >
            {tab.name}
          </span>
        </div>
      ))}
    </div>
  );
}

NotesTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
};

export { NoteTabs };
export default NotesTabs;
