const SET_PAGE = "ORGANIZATIONS/SET_PAGE";
const SET_LISTS = "ORGANIZATIONS/SET_LISTS";
const SET_ERROR = "ORGANIZATIONS/SET_ERROR";
const SET_IS_LOADING = "ORGANIZATIONS/SET_IS_LOADING";
const SET_LIST_UPDATING = "ORGANIZATIONS/SET_LIST_UPDATING";
const SET_USER_NOT_ALLOWED = "ORGANIZATIONS/SET_USER_NOT_ALLOWED";
const SET_SELECTED_ORGANIZATION_ID =
  "ORGANIZATIONS/SET_SELECTED_ORGANIZATION_ID";

const Types = {
  SET_PAGE,
  SET_LISTS,
  SET_ERROR,
  SET_IS_LOADING,
  SET_LIST_UPDATING,
  SET_USER_NOT_ALLOWED,
  SET_SELECTED_ORGANIZATION_ID,
};

export default Types;
