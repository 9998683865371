import PropTypes from "prop-types";

function ArrowNav({ size = 25, color = "text-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current stroke-current ${color}`}
    >
      <path
        strokeWidth="1"
        d="M13,26a1,1,0,0,1-.71-.29l-9-9a1,1,0,0,1,0-1.42l9-9a1,1,0,1,1,1.42,1.42L5.41,16l8.3,8.29a1,1,0,0,1,0,1.42A1,1,0,0,1,13,26Z"
      />
      <path strokeWidth="1" d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
    </svg>
  );
}

ArrowNav.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default ArrowNav;
