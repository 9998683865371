import PropTypes from "prop-types";

import { ButtonTooltip } from "../";
import { Colors, getColors } from "../../utils";

function CoveredOutOf({
  color = Colors.DEFAULT,
  tooltip = true,
  className = "",
  totalAward,
  gaOnClickFunc,
  totalSchoolCosts,
  tooltipClassName
}) {
  let first = "$-";
  let second = "$-";

  first = `$${totalAward.toLocaleString("en-US")}`;
  second = `$${totalSchoolCosts.toLocaleString("en-US")}`;

  const colors = getColors(color);

  return (
    <div id="covered-out-of" className="flex flex-row relative items-center">
      <div className={className}>
        <span className={`font-bold ${colors.primary.text}`}>{`${first}`}</span>
        <span className="pl-2 font-medium">covered out of {`${second}`}</span>
      </div>
      {tooltip && (
        <ButtonTooltip
          tooltipColor={color}
          tooltipCloseButton={true}
          tooltipCloseOnClickOutside={true}
          tooltipClassName={tooltipClassName}
          gaOnClickFunc={gaOnClickFunc}
          text="DecidED calculates the affordability of your award letter based on
            how much money you would have to come up with to cover college
            costs."
        />
      )}
    </div>
  );
}

CoveredOutOf.propTypes = {
  tooltip: PropTypes.bool,
  award: PropTypes.shape(),
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  totalSchoolCosts: PropTypes.number,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default CoveredOutOf;
