import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { getChartColors } from "./utils";

function DonutChart({ percent, affordability }) {
  const divSvgRef = useRef();
  const containerRef = useRef();

  const [radius, setRadius] = useState(0);
  const isPercentAvailable = percent > 0;

  useEffect(() => {
    const radiansPercent = Math.min(Math.max(0, percent || 0), 100) / 100;

    const strokeWidth = 1.5;
    const svgSize = radius * 2;
    const offsetHeightSize = 0.8;
    const innerRadiusFactor = 0.35;
    const outerRadiusFactor = 0.95;
    const offsetRadiusFactor = 0.7;

    const yDonut = radius;
    const xDonut = radius * 0.7;
    const xText = radius * 1.25;
    const yText = radius * offsetHeightSize * 0.55;

    const textSignSize = radius * 0.22;
    const textValueSize = radius * 0.425;

    const outerRadius = radius * outerRadiusFactor * offsetRadiusFactor;
    const innerRadius = radius * innerRadiusFactor * offsetRadiusFactor;

    const divSvg = d3.select(divSvgRef.current);
    if (svgSize > 0) {
      const donutColor = getChartColors(affordability);

      const svg = divSvg
        .append("svg")
        .style("overflow", "visible")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${svgSize} ${svgSize * offsetHeightSize}`);

      const donutContainer = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(Math.PI * 2);

      const donutContent = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(Math.PI * 2 * radiansPercent);

      const group = svg
        .append("g")
        .attr("id", "donut-affordability")
        .attr(
          "transform",
          `translate(${xDonut}, ${yDonut * offsetHeightSize}) rotate(0)`
        );

      group
        .append("path")
        .attr("d", donutContainer)
        .attr("id", "container-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .attr("class", `fill-current stroke-current ${donutColor.secondary}`);

      group
        .append("path")
        .attr("d", donutContent)
        .attr("stroke-linecap", "round")
        .attr("id", "content-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .attr("class", `fill-current ${donutColor.primary}`)
        .style("stroke", "#000000");

      group
        .append("path")
        .attr("d", donutContainer)
        .attr("id", "container-affordability")
        .style("stroke-width", `${strokeWidth}px`)
        .style("fill", "transparent")
        .style("stroke", "#000000");

      const textPercentValue = svg
        .insert("text")
        .text(isPercentAvailable ? `${percent}` : "N/A")
        .attr("fill", "#000000")
        .attr("font-weight", "bold")
        .attr("font-size", textValueSize)
        .attr("transform", `translate(${xText}, ${yText})`);
      if (isPercentAvailable) {
        svg
          .insert("text")
          .text("%")
          .attr("fill", "#000000")
          .attr("font-weight", "bold")
          .attr("font-size", textSignSize)
          .attr(
            "transform",
            `translate(${
              xText + textPercentValue.node().getComputedTextLength()
            }, ${yText})`
          );
      }
    }

    return () => divSvg.selectAll("*").remove();
  }, [percent, affordability, radius]);

  useEffect(() => {
    getRadius();

    window.addEventListener("resize", getRadius);
    return () => window.removeEventListener("resize", getRadius);
  }, []);

  const getRadius = () => {
    if (containerRef.current) {
      const node = containerRef.current;
      setRadius(node.getBoundingClientRect().width / 2);
    }
  };

  return (
    <div ref={containerRef}>
      <div ref={divSvgRef} />
    </div>
  );
}

DonutChart.propTypes = {
  percent: PropTypes.number,
  affordability: PropTypes.string
};

export default DonutChart;
