import Types from "./types";

const INITIAL_STATE = {
  pages: [],
  error: null,
  pageCount: 0,
  itemsCount: 0,
  studentLists: [],
  advisorLists: [],
  isLoading: false,
  listUpdating: false,
  userNotAllowed: null,
  selectedOrganizationId: null,
};

export const organizationsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Types.SET_LISTS:
      return {
        ...state,
        studentLists: action.studentLists,
        advisorLists: action.advisorLists,
      };
    case Types.SET_PAGE: {
      const pages = [...state.pages];
      pages[action.page.pageNumber - 1] = action.items;
      return {
        ...state,
        pages,
        pageCount: action.pageCount,
        itemsCount: action.itemsCount,
      };
    }
    case Types.SET_LIST_UPDATING:
      return {
        ...state,
        listUpdating: action.listUpdating,
      };
    case Types.SET_USER_NOT_ALLOWED:
      return {
        ...state,
        userNotAllowed: action.userNotAllowed,
      };
    case Types.SET_SELECTED_ORGANIZATION_ID:
      return {
        ...state,
        selectedOrganizationId: action.organizationId,
      };
    default:
      return state;
  }
};
