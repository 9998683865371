import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Tips from "./Tips";
import OnboardingLayout from "./Layout";

import { AdvisorRoles } from "../Dashboard/Advisors/Utils";
import { getPermissions, getAppTabsOnboarding } from "../../utils/permissions";

function Onboarding() {
  const location = useLocation();

  const [appTabs, setAppTabs] = useState([]);
  const [onboardingProcess, setOnboardingProcess] = useState({
    tab: 0,
    totalTabs: 0,
    click: false,
    complete: true,
    role: AdvisorRoles.ADVISOR,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const tab = Number(params.get("tab") || 0);
    const click = params.get("click") || false;
    const complete = params.get("complete") || true;
    const role = params.get("role") || AdvisorRoles.ADVISOR;

    const permissions = getPermissions(role);
    const appTabs = getAppTabsOnboarding(role, permissions);
    setOnboardingProcess({
      tab,
      role,
      click,
      complete,
      totalTabs: appTabs.length,
    });
    setAppTabs(appTabs);

    document.body.classList.add("overflow-hidden");
    return () => document.body.classList.remove("overflow-hidden");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerOnChangeTab = useCallback(
    (newTab) =>
      setOnboardingProcess((onboardingProcess) => ({
        ...onboardingProcess,
        tab: newTab,
      })),
    []
  );

  return (
    <>
      <OnboardingLayout tab={onboardingProcess.tab} appTabs={appTabs} />
      <Tips
        onChangeTab={handlerOnChangeTab}
        onboardingProcess={onboardingProcess}
      />
    </>
  );
}

export default Onboarding;
