import { useState } from "react";
import PropTypes from "prop-types";

import SearchMajors from "./SearchMajors";
import YourMajorsLabel from "./YourMajorsLabel";

import { Close } from "../../icons";
import { Colors } from "../../utils";

function YourMajors({
  color,
  gaOnClick,
  schoolName,
  showActions = true,
  selectMajor,
  majorsSelected,
  withSearchDefault,
  deleteSelectedMajor,
  searchMajorsResults
}) {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <>
      {withSearchDefault && (
        <SearchMajors
          color={color}
          gaOnClick={gaOnClick}
          selectMajor={(major) => {
            selectMajor(major);
            setShowSearch(false);
          }}
          searchMajorsResults={searchMajorsResults}
        />
      )}
      {!withSearchDefault && showActions && showSearch && (
        <>
          <YourMajorsLabel
            schoolName={schoolName}
            showSearch={showSearch}
            showActions={showActions}
            setShowSearch={setShowSearch}
          />
          <SearchMajors
            color={color}
            gaOnClick={gaOnClick}
            selectMajor={(major) => {
              selectMajor(major);
              setShowSearch(false);
            }}
            searchMajorsResults={searchMajorsResults}
          />
        </>
      )}
      {!showSearch && (
        <div className="pb-2 bg-white">
          <YourMajorsLabel
            schoolName={schoolName}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            showActions={showActions && !withSearchDefault}
          />
        </div>
      )}
      <div
        className="
          flex
          flex-1
          flex-col
          max-h-10rem
          overflow-y-auto
        "
      >
        {majorsSelected?.map((major, index) => (
          <div
            key={major.code}
            className={`
              p-2
              flex
              content-center
              justify-between
              ${index % 2 ? "bg-white" : "bg-light-gray"}
            `}
          >
            <p className="font-medium text-bold-black w-11/12">{major?.name}</p>
            {showActions && (
              <div
                role="button"
                className="w-1/12 place-self-center"
                onClick={() => {
                  deleteSelectedMajor(major);
                  setShowSearch(false);
                }}
              >
                <Close size={17} />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

YourMajors.propTypes = {
  showActions: PropTypes.bool,
  selectMajor: PropTypes.func,
  schoolName: PropTypes.string,
  withSearchDefault: PropTypes.bool,
  deleteSelectedMajor: PropTypes.func,
  searchMajorsResults: PropTypes.func,
  majorsSelected: PropTypes.arrayOf(PropTypes.shape()),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default YourMajors;
