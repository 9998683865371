import React from "react";
import PropTypes from "prop-types";

import SchoolTools from "../icons/SchoolTools";
import { decidedRoute } from "../utils";

function PurgedLetter({ iconSize = 250, color = "green" }) {
  return (
    <div
      className={`flex h-full flex-col bg-secondary-${color} items-center justify-center`}
    >
      <SchoolTools size={iconSize} />
      <p className="w-2/3 text-center font-extrabold text-4xl">
        This letter is no longer available
      </p>
      <div className="flex flex-col py-4 w-1/2 text-center text-xs space-y-4">
        <p>
          DecidED only stores financial aid letters for one year. In order to
          view this letter, you will need to re-upload it.
        </p>
        <p>
          View our
          <a
            target="_blank"
            rel="noreferrer"
            href={decidedRoute("/legal/privacy-policy/")}
            className="font-semibold cursor-pointer"
          >
            &nbsp;Privacy Policy&nbsp;
          </a>
          for more information
        </p>
      </div>
    </div>
  );
}

PurgedLetter.propTypes = {
  iconSize: PropTypes.number,
  color: PropTypes.string
};

export default PurgedLetter;
