import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Form } from "../../../core";
import { Button } from "../../../core/buttons";
import { Colors, retryTimeout, decidedRoute } from "../../../core/utils";

import Api from "../../../services/Api";
import { useSpinner } from "../../Common/Hooks";
import { interactionEventDataLayer } from "../../../utils/DataLayers";

function CreateAccountForm({ redirectUri, defaultValues }) {
  const formID = "create-account-form";

  const navigate = useNavigate();

  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const { setError, handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues
  });

  const disabledSubmitButton =
    !formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

  const onSubmitWithSpinner = retryTimeout(
    useSpinner(async (data) => {
      setSomethingWentWrong(false);
      try {
        const response = await Api.signUp({
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: `+1${data.phoneNumber}`,
          zip: data.zip,
          title: data.title,
          emailReceive: data.emailReceive
        });

        if (response.ok) {
          const { id } = await response.json();
          interactionEventDataLayer({
            eventName: "conversion_advisor_signup",
            params: { user_id: `${id}` }
          });
          return navigate(redirectUri);
        } else {
          const { first_name, last_name, phone_number, zip, title, ...errors } =
            await response.json();

          if (first_name) {
            setError("firstName", {
              message: "Please enter a valid first name"
            });
          }

          if (last_name) {
            setError("lastName", {
              message: "Please enter a valid last name"
            });
          }

          if (phone_number) {
            setError("phoneNumber", {
              message: "Please enter a valid US phone number"
            });
          }

          if (zip) {
            setError("zip", {
              message: "Please enter a valid US zip code"
            });
          }

          if (title) {
            setError("title", {
              message: "Please enter a valid Job title"
            });
          }

          if (Object.keys(errors).length) {
            setSomethingWentWrong(true);
          }
        }
      } catch (e) {
        console.error(e);
        setSomethingWentWrong(true);
      }
    })
  );

  return (
    <Form id={formID} onSubmit={handleSubmit(onSubmitWithSpinner)}>
      <Form.Input
        required={true}
        name="firstName"
        control={control}
        label="first name"
        color={Colors.BLUE}
        errors={formState.errors}
        rules={{
          maxLength: {
            value: 255,
            message: "First Name is too long."
          },
          required: {
            value: true,
            message: "First Name is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="firstName" />

      <Form.Input
        required={true}
        name="lastName"
        label="last name"
        control={control}
        color={Colors.BLUE}
        errors={formState.errors}
        rules={{
          maxLength: {
            value: 255,
            message: "Last Name is too long."
          },
          required: {
            value: true,
            message: "Last Name is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="lastName" />

      <Form.Input
        required={true}
        control={control}
        name="phoneNumber"
        label="phone number"
        color={Colors.BLUE}
        errors={formState.errors}
        rules={{
          maxLength: {
            value: 10,
            message:
              "Must be a valid 10-digit Phone Number. Please enter your number with no symbols or dashes."
          },
          pattern: {
            value: /[0-9]{10}/,
            message:
              "Must be a valid 10-digit Phone Number. Please enter your number with no symbols or dashes."
          },
          required: {
            value: true,
            message: "Phone number is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="phoneNumber" />

      <Form.Input
        name="zip"
        required={true}
        label="zip code"
        control={control}
        color={Colors.BLUE}
        errors={formState.errors}
        rules={{
          pattern: {
            value: /^[0-9]{5}$/,
            message: "Zip Code must be a 5-digit number."
          },
          required: {
            value: true,
            message: "Zip code is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="zip" />

      <Form.Input
        name="title"
        required={true}
        label="your title"
        control={control}
        color={Colors.BLUE}
        errors={formState.errors}
        rules={{
          required: {
            value: true,
            message: "Job title is required."
          }
        }}
      />
      <Form.ErrorMessage errors={formState.errors} name="title" />

      <Button
        type="submit"
        form={formID}
        value="submit"
        id="create-account-button"
        disabled={!formState.isValid}
        className={`
          mt-6
          text-white
          bg-primary-blue
          border-primary-blue
          hover:bg-white
          active:bg-white
          hover:text-primary-blue
          active:text-primary-blue
          ${disabledSubmitButton}
        `}
      >
        Create Your Account
      </Button>

      <div className="mt-1">
        {somethingWentWrong && (
          <p className="text-sm font-semibold text-primary-red">
            Something went wrong, please try again later!
          </p>
        )}
      </div>

      <div className="py-6 text-xsm text-center">
        In order to help you evaluate college affordability, DecidED needs to
        collect data. By creating an account you are accepting our{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={decidedRoute("/legal/terms-of-service/")}
          className="font-semibold underline cursor-pointer"
        >
          terms of service
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={decidedRoute("/legal/privacy-policy/")}
          className="font-semibold underline cursor-pointer"
        >
          privacy policy
        </a>
        .
      </div>
      <Form.Checkbox
        control={control}
        name="emailReceive"
        color={Colors.BLUE}
        className="ml-2 text-sm font-medium text-primary-green"
        label="I agree to receive product updates and email from DecidED."
      />
    </Form>
  );
}
CreateAccountForm.propTypes = {
  redirectUri: PropTypes.string,
  defaultValues: PropTypes.shape()
};

export default CreateAccountForm;
