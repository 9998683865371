import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Option from "./Option";

import { SmallModal } from "../../../../../../core";
import { Button } from "../../../../../../core/buttons";
import {
  Dorms,
  House,
  Close,
  Apartment,
  ShortArrow,
  QuestionShape,
} from "../../../../../../core/icons";

import { LivingChoice as LivingChoiceEnum } from "../../../../../../core/utils";

function LivingChoice({
  onBack,
  onSelect,
  universityName,
  hasOnCampusHousing,
}) {
  const [selection, setSelection] = useState("");
  const [showNotSureModal, setShowNotSureModal] = useState(false);

  const { studentId } = useParams();
  const navigate = useNavigate();

  const onCampus = LivingChoiceEnum.ON_CAMPUS;
  const offCampus = LivingChoiceEnum.OFF_CAMPUS;
  const withFamily = LivingChoiceEnum.WITH_FAMILY;
  const notSure = "notsure";

  const toggleOnCampus = () =>
    setSelection(selection !== onCampus ? onCampus : "");
  const toggleOffCampus = () =>
    setSelection(selection !== offCampus ? offCampus : "");
  const toggleWithFamily = () =>
    setSelection(selection !== withFamily ? withFamily : "");
  const toggleNotSure = () =>
    setSelection(selection !== notSure ? notSure : "");

  const handleOnSelect = () => {
    if (selection === "notsure") {
      setShowNotSureModal(true);
    } else {
      onSelect(selection);
    }
  };

  const handleOnGotIt = () => {
    setShowNotSureModal(false);
    onSelect(hasOnCampusHousing ? onCampus : offCampus);
  };

  const housingOptions = [
    {
      show: hasOnCampusHousing,
      selected: selection === onCampus,
      text: "Living in a dorm on campus",
      icon: <Dorms size={40} color="stroke-current" />,
      toggle: toggleOnCampus,
    },
    {
      show: true,
      selected: selection === withFamily,
      text: "Living with family at home",
      icon: <House size={40} color="stroke-current" />,
      toggle: toggleWithFamily,
    },
    {
      show: true,
      selected: selection === offCampus,
      text: "Living in an apartment off campus",
      icon: <Apartment size={40} color="stroke-current" />,
      toggle: toggleOffCampus,
    },
    {
      show: true,
      selected: selection === notSure,
      text: "Not sure!",
      icon: <QuestionShape size={40} color="stroke-current" />,
      toggle: toggleNotSure,
    },
  ];

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div role="button" onClick={onBack}>
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>
          One Last Question
        </span>
        <div
          role="button"
          onClick={() => navigate(`/dashboard/students/${studentId}`)}
        >
          <Close />
        </div>
      </div>
      <div
        className="
          p-8
          flex
          flex-1
          flex-col
        "
      >
        <div className="mt-4 flex flex-1 flex-col justify-between">
          <p className="text-xl font-bold text-primary-blue ">
            Where is {universityName} expecting your student to live?
          </p>
          <div
            className="
              flex
              py-6
              my-6
              flex-row
              space-x-16
            "
          >
            <div
              className="
                flex
                flex-1
                flex-row
                flex-wrap
              "
            >
              {housingOptions.map((housingOption, index) => (
                <div
                  key={index}
                  className={`
                    p-5
                    w-1/2
                    ${!housingOption.show && "hidden"}
                  `}
                >
                  <Option
                    icon={housingOption.icon}
                    text={housingOption.text}
                    onClick={housingOption.toggle}
                    selected={housingOption.selected}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className="
              flex
              flex-1
              flex-row
              space-x-10
            "
          >
            <div className="w-1/2"></div>
            <div className="pl-16 w-1/2">
              <Button
                onClick={handleOnSelect}
                disabled={selection === "" && !showNotSureModal}
                className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                next
              </Button>
            </div>
          </div>

          {showNotSureModal && (
            <SmallModal open={showNotSureModal}>
              <>
                <p className="text-xl font-bold text-primary-blue">
                  Not sure where you’re going to live yet?
                </p>
                <p className="my-8">
                  It's ok! We ask this question to calculate your college costs.
                  For now, we'll use the safest cost estimate and assume that
                  you'll{" "}
                  {hasOnCampusHousing
                    ? "live on campus"
                    : "live in off campus housing"}
                  . When you decide where you'll live, come back and update your
                  information.
                </p>

                <div className="flex flex-row justify-center">
                  <Button
                    onClick={handleOnGotIt}
                    className="
                      mx-auto
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    got it
                  </Button>
                </div>
              </>
            </SmallModal>
          )}
        </div>
      </div>
    </div>
  );
}

LivingChoice.propTypes = {
  onBack: PropTypes.func,
  onSelect: PropTypes.func,
  universityName: PropTypes.string,
  hasOnCampusHousing: PropTypes.bool,
};

export default LivingChoice;
