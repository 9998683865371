import {
  NameHeader,
  AdvisorHeader,
  EnrollmentHeader,
  LastActivityHeader,
  AffordabilityHeader,
  GradeYearHeader
} from "./Header";
import {
  NameCell,
  AdvisorCell,
  EnrollmentCell,
  LastActivityCell,
  AffordabilityCell,
  GradeYearCell
} from "./Cell";

import { SortingType } from "../Utils";
import { clearSort } from "../../../Common/utils";

export function getColumns({
  orderBy,
  assingAdvisors,
  onClickStudent,
  toggleSortingOrder,
  onFilterAdvisorSelect,
  onFilterGradeYearSelect,
  onFilterAffordabilitySelect,
  onFilterEnrollmentStatusSelect
}) {
  return [
    {
      accessor: "name",
      Header: (
        <NameHeader
          clearSort={clearSort({ orderBy, field: SortingType.NAME })}
          onSort={(order) => toggleSortingOrder(SortingType.NAME, order)}
        />
      ),
      Cell: ({ row }) => (
        <NameCell
          data-tooltip-id="student-row"
          data-tooltip-content="View Details"
          student={row.original.student}
          onClick={() => onClickStudent(row)}
        />
      ),
      width: "15%"
    },
    {
      accessor: "gradeYear",
      Header: <GradeYearHeader onFilter={onFilterGradeYearSelect} />,
      Cell: ({ row }) => (
        <GradeYearCell
          student={row.original.student}
          onClick={() => onClickStudent(row)}
        />
      ),
      width: "20%"
    },
    {
      accessor: "status",
      Header: <EnrollmentHeader onFilter={onFilterEnrollmentStatusSelect} />,
      Cell: ({ row }) => (
        <EnrollmentCell
          onClick={() => onClickStudent(row)}
          enrollmentStatus={row.original.student.enrollmentStatus}
        />
      ),
      width: "15%"
    },
    {
      accessor: "affordability",
      Header: <AffordabilityHeader onFilter={onFilterAffordabilitySelect} />,
      Cell: ({ row }) => (
        <AffordabilityCell
          onClick={() => onClickStudent(row)}
          chosenSchools={row.original.affordability}
        />
      ),
      width: "15%"
    },
    {
      accessor: "advisor",
      Header: (
        <AdvisorHeader
          onFilter={onFilterAdvisorSelect}
          advisors={assingAdvisors.map((advisor) => ({
            ...advisor,
            key: `advisor-${advisor.id}`
          }))}
        />
      ),
      Cell: ({ row }) => (
        <AdvisorCell
          onClick={() => onClickStudent(row)}
          advisor={row.original.student.assignedAdvisor?.advisor}
        />
      ),
      width: "15%"
    },
    {
      accessor: "lastActivity",
      Header: (
        <LastActivityHeader
          clearSort={clearSort({ orderBy, field: SortingType.LAST_LOGIN })}
          onSort={(order) => toggleSortingOrder(SortingType.LAST_LOGIN, order)}
        />
      ),
      Cell: ({ row }) => (
        <LastActivityCell
          student={row.original.student}
          onClick={() => onClickStudent(row)}
        />
      ),
      width: "20%"
    }
  ];
}
