import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import StudentForm from "./StudentForm";
import ConfirmContent from "./ConfirmContent";

import Api from "../../../services/Api";

import { useOrganizations } from "../../../redux/Organizations/hooks";

function InviteStudentModal({
  onClose,
  assingAdvisors,
  requestedClose,
  organizationId,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [firstText, setFirstText] = useState("");
  const [secondText, setSecondText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingInvites, setPendingInvites] = useState(null);
  const [saveChangesError, setSaveChangesError] = useState(null);

  const { selectedOrganizationId, pages } = useOrganizations();

  const currentOrg = pages[0].find(
    (org) => org.organization.id === selectedOrganizationId
  );

  const defaultValues = {
    currentOrg,
    advisor: saveData.advisor || null,
    students: saveData?.students || [],
  };

  const inviteStudentOnSubmit = async (form) => {
    const dataMutation = {
      organizationId: selectedOrganizationId,
      assignedAdvisorId: form?.advisor?.id || null,
      emails: form.students.map((student) => student.label),
    };
    const {
      invitationsSent,
      invitationsAlreadyPending,
      alreadyBelongToOrganization,
    } = await Api.inviteStudents(dataMutation);
    const plural = invitationsSent.length > 1;
    const plural2 = alreadyBelongToOrganization.length > 1;
    const plural3 = invitationsAlreadyPending.length > 1;

    if (invitationsSent.length > 0) {
      setFirstText(
        <span>
          You've invited
          {plural
            ? ` ${invitationsSent.length} new students`
            : " a new student"}
          !
        </span>
      );
    } else if (
      invitationsSent.length === 0 &&
      alreadyBelongToOrganization.length > 0
    ) {
      setFirstText(
        <span>
          {plural2 ? "These students have " : "This student has "}already joined
          your organization and won't receive another invitation:
        </span>
      );
    } else if (
      invitationsSent.length === 0 &&
      invitationsAlreadyPending.length > 0
    ) {
      setFirstText(
        <span>
          {plural3 ? "The following students have " : "This person has "}already
          been invited to your organization:
        </span>
      );
    }
    setSecondText(() => (
      <ConfirmContent
        plural={plural}
        plural2={plural2}
        plural3={plural3}
        invitationsSent={invitationsSent}
        alreadyBelongToOrganization={alreadyBelongToOrganization}
        invitationsAlreadyPending={invitationsAlreadyPending.map(
          (item) => item.recipient
        )}
      />
    ));
    setConfirm(true);
    if (!invitationsAlreadyPending.length > 0) setConfirmModal(true);
    else setPendingInvites(invitationsAlreadyPending);
  };

  const requestCloseHandle = (form) => {
    setSaveChangesError(null);
    setSaveData(form);
    setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (saveData.students.length === 0) setSaveChangesError("students");
    else await inviteStudentOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const addAnotherHandle = () => {
    setSaveData({});
    setFirstText("");
    setSecondText("");
    setConfirmModal(false);
    setConfirm(false);
    setSave(false);
  };

  const resendInvitesHandle = useCallback(async () => {
    const dataMutation = {
      organizationId,
      invitations: { ids: pendingInvites?.map((item) => item.id) },
    };
    await Api.resendStudentInvites(dataMutation);

    const firstTextSingular = "You have re-sent a pending invite.";
    const firstTextPlural = "You have re-sent the pending invites.";

    const firstText =
      pendingInvites.length === 1 ? firstTextSingular : firstTextPlural;

    setFirstText(<span className="text-3xl">{firstText}</span>);
    setSecondText(
      <span className="text-2xl font-medium">
        Make sure to let your student(s) know.
      </span>
    );
    setPendingInvites(null);
    setConfirmModal(true);
  }, [organizationId, setConfirmModal, pendingInvites]);

  const noResendInvitesHandle = () => {
    onClose();
    setPendingInvites(null);
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <StudentForm
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          assingAdvisors={assingAdvisors}
          onSubmit={inviteStudentOnSubmit}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          secondButton={true}
          firstText={firstText}
          secondText={secondText}
          onClick={() => (pendingInvites ? resendInvitesHandle() : onClose())}
          secondOnClick={() =>
            pendingInvites ? noResendInvitesHandle() : addAnotherHandle()
          }
          secondButtonText={
            pendingInvites ? "Do Not Send" : "Add Another Student"
          }
          buttonText={
            pendingInvites
              ? `Re-send ${pendingInvites.length > 1 ? "Invites" : "Invite"}`
              : "Go Back to Manage Invites"
          }
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

InviteStudentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  setOnRequestClose: PropTypes.func.isRequired,
  assingAdvisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default InviteStudentModal;
