import PropTypes from "prop-types";

import { getGradeYearLabel } from "../../../../../core/utils";

function GradeYearCell({ student, ...restProps }) {
  const gradeYearLabel = getGradeYearLabel(student.gradeYear);
  return (
    <div role="button" className="text-center" {...restProps}>
      <p
        className="
          text-sm
          font-bold
          capitalize
          tablet-sm:text-xs
        "
      >
        {gradeYearLabel}
      </p>
    </div>
  );
}

GradeYearCell.propTypes = {
  student: PropTypes.shape().isRequired
};

export { GradeYearCell };
