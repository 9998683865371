import PropTypes from "prop-types";

function Apartment({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="5.5" width="15" height="26" className={color} />
      <rect x="16.5" y="9.5" width="15" height="22" className={color} />
      <rect x="21.5" y="24.5" width="5" height="7" className={color} />
      <rect x="0.5" y="2.5" width="17" height="3" className={color} />
      <rect x="4.5" y="9.5" width="3" height="3" className={color} />
      <rect x="4.5" y="15.5" width="3" height="3" className={color} />
      <rect x="10.5" y="9.5" width="3" height="3" className={color} />
      <rect x="10.5" y="15.5" width="3" height="3" className={color} />
      <rect x="8.5" y="0.5" width="2" height="2" className={color} />
      <rect x="12.5" y="0.5" width="2" height="2" className={color} />
      <rect x="10.5" y="21.5" width="3" height="3" className={color} />
      <rect x="4.5" y="21.5" width="3" height="3" className={color} />
      <rect x="22" y="15" width="4" height="4" className={color} />
    </svg>
  );
}

Apartment.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Apartment;
