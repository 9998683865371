import PropTypes from "prop-types";

function ItemsCounter({
  color = "text-gray-500",
  numberItems = 0,
  pluralDescriptionItems,
  singularDescriptionItems
}) {
  const description =
    numberItems !== 1 ? pluralDescriptionItems : singularDescriptionItems;

  return (
    <div>
      <p
        className={`
          font-bold
          min-w-6rem
          mobile:min-w-4rem
          mobile-sm:min-w-4rem
          ${color}
        `}
      >
        {`${numberItems} ${description}`}
      </p>
    </div>
  );
}

ItemsCounter.propTypes = {
  color: PropTypes.string,
  numberItems: PropTypes.number.isRequired,
  pluralDescriptionItems: PropTypes.string.isRequired,
  singularDescriptionItems: PropTypes.string.isRequired
};

export default ItemsCounter;
