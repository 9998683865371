import { useState } from "react";
import PropTypes from "prop-types";

import Content from "../Common/Content";

import { LoadingButton } from "../../../core/buttons";

function ArchiveList({ list, onSubmit, secondText }) {
  const firstText = (
    <span>
      Are you sure you want to archive
      <span className="text-black"> "{list.name}"</span>?
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (event) => {
    event.preventDefault();
    setIsLoading(true);
    onSubmit();
  };

  return (
    <>
      <div className="overflow-auto px-1">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleOnClick}
          id="archive-group-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          archive list
        </LoadingButton>
      </div>
    </>
  );
}

ArchiveList.propTypes = {
  list: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  secondText: PropTypes.any.isRequired,
};

export default ArchiveList;
