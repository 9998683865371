import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";

import { CollegeType } from "../../../../core/utils";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function CollegeYearButtons({ student, programLength, setProgramLength }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const twoYears = student.chosenSchools.some(
      (chosenSchool) =>
        chosenSchool.school.programLength === CollegeType.TWO_YEARS
    );
    const fourYears = student.chosenSchools.some(
      (chosenSchool) =>
        chosenSchool.school.programLength === CollegeType.FOUR_YEARS
    );

    setShow(twoYears && fourYears);
  }, [student.chosenSchools]);

  const handlerOnChangeCollegesType = (newCollegesType) => {
    interactionEventDataLayer({
      eventName:
        newCollegesType === CollegeType.FOUR_YEARS
          ? "advisor_four_year_selected"
          : "advisor_two_year_selected",
    });
    if (newCollegesType !== programLength) setProgramLength(newCollegesType);
  };

  if (show)
    return (
      <div id="toggle-year-buttons-container" className="flex flex-row">
        <Button
          id="toggle-four-year-button"
          onClick={() => handlerOnChangeCollegesType(CollegeType.FOUR_YEARS)}
          className={`
            py-1
            px-2
            flex
            flex-row
            border-r-0
            border-primary-blue
            ${
              programLength === CollegeType.FOUR_YEARS
                ? "text-white bg-primary-blue"
                : "bg-white text-primary-blue"
            }
          `}
        >
          <span className="place-self-center font-semibold">four year</span>
        </Button>
        <Button
          id="toggle-two-year-button"
          onClick={() => handlerOnChangeCollegesType(CollegeType.TWO_YEARS)}
          className={`
            py-1
            px-2
            flex
            flex-row
            border-primary-blue
            ${
              programLength === CollegeType.TWO_YEARS
                ? "text-white bg-primary-blue"
                : "bg-white text-primary-blue"
            }
          `}
        >
          <span className="place-self-center font-semibold">two year</span>
        </Button>
      </div>
    );

  return null;
}

CollegeYearButtons.propTypes = {
  student: PropTypes.shape(),
  programLength: PropTypes.string,
  setProgramLength: PropTypes.func,
};

export default CollegeYearButtons;
