import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import Item from "./Item";

import { Link } from "../../../../../core";
import { Button } from "../../../../../core/buttons";
import {
  Close,
  Wallet,
  Letter,
  College,
  ShortArrow,
} from "../../../../../core/icons";

import { Colors, decidedRoute } from "../../../../../core/utils";

function DontHaveAward({ onBack, onBackToHome }) {
  const { studentId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div role="button" onClick={onBack}>
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>
          One Last Question
        </span>
        <div
          role="button"
          onClick={() => navigate(`/dashboard/students/${studentId}`)}
        >
          <Close />
        </div>
      </div>
      <div className="p-8 flex flex-1 flex-col">
        <p className="mb-4 text-xl font-bold text-primary-blue">
          Don't have your award yet? A few tips on what to do next:
        </p>

        <div className="my-8 flex flex-1 flex-row">
          <Item
            icon={
              <Letter
                size={50}
                iconColor="fill-primary-blue"
                fillColor="fill-secondary-blue"
              />
            }
            text={
              <div>
                <p>
                  Colleges usually send your financial aid award after your
                  acceptance letter. This is important, so keep checking to see
                  if you've received it.
                </p>
                <Link
                  icon={true}
                  target="_blank"
                  internal={false}
                  color={Colors.BLUE}
                  textClassName="text-sm text-left font-bold"
                  href={decidedRoute("/guide/find-your-financial-aid-award/")}
                  text="Read the DecidED Guide for more details on how to Find your Financial Aid Award"
                  customIcon={
                    <div className="transform place-self-end rotate-180">
                      <ShortArrow size={16} color="stroke-current" />
                    </div>
                  }
                />
              </div>
            }
          />
          <Item
            icon={
              <Wallet
                size={50}
                iconColor="fill-primary-blue"
                fillColor="fill-secondary-blue"
              />
            }
            text={
              <div>
                <p>
                  To get your award, you may have to verify your family's
                  finances. Check for an email telling you to submit additional
                  documents or that you are selected for verification. If so,
                  complete the necessary steps.
                </p>
                <Link
                  icon={true}
                  target="_blank"
                  internal={false}
                  color={Colors.BLUE}
                  textClassName="text-sm text-left font-bold"
                  text="How to verify your family's financial information"
                  href={decidedRoute(
                    "/guide/what-you-need-to-know-about-verification/"
                  )}
                  customIcon={
                    <div className="transform place-self-end rotate-180">
                      <ShortArrow size={16} color="stroke-current" />
                    </div>
                  }
                />
              </div>
            }
          />
          <Item
            icon={<College size={50} fillColor="fill-primary-blue" />}
            text={
              <p>
                If you think you should have gotten your award but haven't, call
                the college's Financial Aid Office.
              </p>
            }
          />
        </div>
        <div className="flex flex-1 flex-row laptop:space-x-8 desktop:space-x-8">
          <div className="w-1/2"></div>
          <div className="w-1/2 mt-auto">
            <Button
              onClick={onBackToHome}
              className="
                    w-full
                    normal-case
                    text-white
                    bg-primary-blue
                    border-primary-blue
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-blue
                    active:text-primary-blue
                  "
            >
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

DontHaveAward.propTypes = {
  onBack: PropTypes.func,
  onBackToHome: PropTypes.func,
};

export default DontHaveAward;
