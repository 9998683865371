import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getOrderIcon } from "../../../../Common/utils";
import { Order, toggleOrder } from "../../../../../utils/order";

function AdvisorsHeader({ onSort, clearSort = () => {} }) {
  const [order, setOrder] = useState(Order.asc);

  useEffect(() => {
    if (clearSort) setOrder("");
  }, [clearSort]);

  return (
    <div
      role="button"
      id="archive-advisor-list-sort-by-advisors-button"
      className="flex flex-row items-center justify-center"
      onClick={() => {
        const newOrder = toggleOrder(order);
        setOrder(newOrder);
        onSort(newOrder);
      }}
    >
      <span className="text-sm"># of Advisors</span>
      {getOrderIcon(order)}
    </div>
  );
}

AdvisorsHeader.propTypes = {
  onSort: PropTypes.func,
  clearSort: PropTypes.bool
};

export default AdvisorsHeader;
