import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HomeLayout from "../Common/HomeLayout";
import SubscriptionSettingsForm from "./SubscriptionSettingsForm";

import { BackButton } from "../../core/buttons";

import { useAdvisor } from "../../redux/Advisor/hooks";
import { updateAdvisor } from "../../redux/Advisor/actions";
import { useOrganizations } from "../../redux/Organizations/hooks";
import { interactionEventDataLayer } from "../../utils/DataLayers";

function SubscriptionSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const [defaultValues, setDefaultValues] = useState(null);

  const onSubmit = async (data) => {
    const subscriptionSettings = {
      emailReceive: data.emailReceive,
      emailNotifications: data.emailNotifications,
    };
    await dispatch(
      updateAdvisor({
        ...subscriptionSettings,
        organizationId: selectedOrganizationId,
      })
    );
  };

  const unsubscribeFromAll = async () => {
    const subscriptionSettings = {
      emailReceive: false,
      emailNotifications: false,
    };
    await dispatch(
      updateAdvisor({
        ...subscriptionSettings,
        organizationId: selectedOrganizationId,
      })
    );
  };

  useEffect(() => {
    setDefaultValues({
      emailReceive: profile.emailReceive,
      emailNotifications: profile.emailNotifications,
    });
  }, [profile]);

  useEffect(() => {
    const organization = profile?.organizations?.items?.find(
      (org) => org.organization.organizationId === selectedOrganizationId
    );

    if (organization)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          screen_name: "SubscriptionSettings",
          userId: profile.advisorId,
          organizationName: organization.organization.name,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  if (!defaultValues) return null;

  return (
    <HomeLayout>
      <div
        className="
          p-4
          flex
          flex-1
          flex-col
          bg-white
          min-h-screen-footer-lg
        "
      >
        <div className="divide-y divide-solid divide-light-gray">
          <div className="flex flex-row space-between">
            <div className="pb-3">
              <BackButton onClick={() => navigate("/")} />
            </div>
          </div>
          <div className="py-4">
            <h1 className="text-2xl font-semibold tablet:text-xl tablet-sm:text-xl">
              Update your subscription settings:
            </h1>
          </div>
        </div>
        <SubscriptionSettingsForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          formID="subscription-settings-from-id"
          unsubscribeFromAll={unsubscribeFromAll}
        />
      </div>
    </HomeLayout>
  );
}

export default SubscriptionSettings;
