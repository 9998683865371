import PropTypes from "prop-types";

function Wallet({
  size = 25,
  fillColor = "fill-white",
  iconColor = "fill-light-gray"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <rect
          x="0.703233"
          y="-0.0200546"
          stroke="black"
          width="9.14239"
          height="14.0207"
          className={iconColor}
          transform="matrix(0.682975 -0.730442 0.723492 0.690333 9.38276 8.8307)"
        />
        <rect
          x="0.703508"
          y="0.00290319"
          stroke="black"
          width="10.5796"
          height="15.442"
          className={iconColor}
          transform="matrix(0.70644 -0.707773 0.700575 0.713579 1.20449 8.69407)"
        />
        <rect
          x="1.5"
          y="11.4717"
          stroke="black"
          width="26.1517"
          height="20.0284"
          className={fillColor}
        />
        <rect
          x="19.601"
          y="16.957"
          stroke="black"
          width="10.7657"
          height="8.14278"
          className={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Wallet.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  iconColor: PropTypes.string
};

export default Wallet;
