import PropTypes from "prop-types";

import Content from "../Common/Content";

import { Button, LoadingButton } from "../../../core/buttons";

function Confirm({
  onClick,
  firstText,
  secondText,
  secondOnClick,
  isLoading = false,
  buttonText = "Close",
  secondButton = false,
  secondButtonText = "Back"
}) {
  return (
    <>
      <div className="p-1 overflow-auto">
        <Content firstText={firstText} secondText={secondText} />
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <div className="flex flex-row flex-1 space-x-4">
          <div
            className={`
              w-1/2
              ${!secondButton && "mobile:hidden mobile-sm:hidden"}
            `}
          >
            {secondButton && (
              <Button
                className="
                  mb-5
                  w-full
                  bg-white
                  normal-case
                  text-primary-blue
                  border-primary-blue
                  mobile:text-sm
                  mobile-sm:text-sm
                  tablet-sm:text-sm
                  hover:text-white
                  active:text-white
                  hover:bg-primary-blue
                  active:bg-primary-blue
                "
                onClick={secondOnClick}
              >
                {secondButtonText}
              </Button>
            )}
          </div>
          <div
            className={`
              w-1/2
              ${!secondButton && "mobile:w-full mobile-sm:w-full"}
            `}
          >
            <LoadingButton
              onClick={onClick}
              isLoading={isLoading}
              className="
                mb-5
                w-full
                text-white
                normal-case
                bg-primary-blue
                border-primary-blue
                mobile:text-sm
                mobile-sm:text-sm
                tablet-sm:text-sm
                hover:bg-white
                active:bg-white
                hover:text-primary-blue
                active:text-primary-blue
              "
            >
              {buttonText}
            </LoadingButton>
          </div>
        </div>
      </div>
    </>
  );
}

Confirm.propTypes = {
  firstText: PropTypes.any,
  secondText: PropTypes.any,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  secondButton: PropTypes.bool,
  secondOnClick: PropTypes.func,
  secondButtonText: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default Confirm;
