export function getConfirmationText({
  listName,
  nameUpdate,
  ownerUpdate,
  sharedUptade,
}) {
  console.log();
  const sharedText = "made changes to who can see this list";
  const ownerText =
    "changed the list owner. You will no longer be able to edit this list";

  const confirmationTexts = { main: "", complement: "", options: [] };
  if (nameUpdate) {
    confirmationTexts.main = (
      <>
        Great! You've updated the list name to
        <span className="text-primary-blue"> "{listName}"</span>
      </>
    );
    if (sharedUptade && ownerUpdate) {
      confirmationTexts.complement = " and you have:";
      confirmationTexts.options.push(`${sharedText}.`);
      confirmationTexts.options.push(`${ownerText}.`);
    } else if (sharedUptade && !ownerUpdate) {
      confirmationTexts.complement = ` and you have ${sharedText}`;
    } else if (!sharedUptade && ownerUpdate) {
      confirmationTexts.complement = ` and you have ${ownerText}`;
    }

    return confirmationTexts;
  } else if (sharedUptade) {
    confirmationTexts.main = (
      <>
        Great! You've updated the settings for the list
        <span className="text-primary-blue"> "{listName}"</span>. You have
        <span> {sharedText}</span>
      </>
    );
    if (ownerUpdate) confirmationTexts.complement = ` and ${ownerText}`;

    return confirmationTexts;
  } else {
    confirmationTexts.main = (
      <>
        Great! You've updated the settings for the list
        <span className="text-primary-blue"> "{listName}"</span>. You
        <span> {ownerText}</span>
      </>
    );

    return confirmationTexts;
  }
}
