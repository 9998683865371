import PropTypes from "prop-types";

import Table from "../../../Common/Table";

function ArchivedAdvisorListTableBody({
  data,
  columns,
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  handlerOnSelectArchiveAdvisorLists,
}) {
  return (
    <Table
      data={data}
      columns={columns}
      pageSize={pageSize}
      itemsCount={itemsCount}
      currentPage={currentPage}
      addSelectionColumn={true}
      onPageChange={onPageChange}
      tableSelector="viewport-archived-advisor-table"
      handlerOnSelectRows={handlerOnSelectArchiveAdvisorLists}
      propsBody={{ className: "min-h-15rem h-screen-footer-xl" }}
    />
  );
}

ArchivedAdvisorListTableBody.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handlerOnSelectArchiveAdvisorLists: PropTypes.func.isRequired,
};

export default ArchivedAdvisorListTableBody;
