import PropTypes from "prop-types";

function Earnings({ earnings = 0, className }) {
  const isEarningsAvailable = earnings > 0;
  const formattedEarnings = isEarningsAvailable
    ? earnings.toLocaleString("en-US")
    : "N/A";

  return (
    <div className={className}>
      <div className="flex flex-row items-end justify-center">
        <span
          className="
            text-4xl
            font-extrabold
            mobile:text-3xl
            tablet-sm:text-3xl
            mobile-sm:text-2xl
          "
        >
          {isEarningsAvailable ? "$" : ""}
        </span>
        <span
          className="
            text-6xl
            font-extrabold
            mobile:text-5xl
            tablet-sm:text-5xl
            mobile-sm:text-4xl
          "
        >
          {formattedEarnings}
        </span>
      </div>
      {isEarningsAvailable ? (
        <p className="mx-4">
          6 years after starting college, students earn a median of $
          {formattedEarnings}
        </p>
      ) : (
        <p className="mx-4">
          This school has not reported students' average earnings.
        </p>
      )}
    </div>
  );
}

Earnings.propTypes = {
  earnings: PropTypes.number,
  className: PropTypes.string
};

export default Earnings;
