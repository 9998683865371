import { useEffect } from "react";
import PropTypes from "prop-types";

import DefaultScreen from "../../../Common/DefaultScreen";

function Hmm({ timeout, onTimeout }) {
  useEffect(() => {
    const id = setTimeout(onTimeout, timeout);
    return () => {
      if (typeof id === "number") clearTimeout(id);
    };
  }, [timeout, onTimeout]);

  return (
    <DefaultScreen title="Letter Received">
      <div
        className="
          mt-2
          flex
          px-20
          text-xl
          flex-col
          space-y-8
          text-center
          items-center
          laptop:max-w-60rem
          desktop:max-w-75rem
        "
      >
        <div className="space-y-5 desktop:px-20 desktop:mx-20">
          <p className="px-20 text-xl font-bold">
            One of our team members needs to take a closer look at your award
            letter to verify the information.
          </p>
          <p className="px-16 text-xl font-bold">
            We’ll send you an email when your results are ready. Expect to hear
            from us in 1 to 3 business days.
          </p>
        </div>
      </div>
    </DefaultScreen>
  );
}

Hmm.propTypes = {
  onTimeout: PropTypes.func,
  timeout: PropTypes.number,
};

export default Hmm;
