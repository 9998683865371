import PropTypes from "prop-types";

import { Advisors } from "../../../core/icons";

function AdvisorFilter({ advisors, onSelect }) {
  return (
    <div className="max-h-15rem overflow-y-auto">
      {advisors.map((advisor) => (
        <div
          role="button"
          key={advisor.id}
          className="
            my-2
            flex
            flex-1
            flex-row
            space-x-4
            font-medium
            items-center
          "
          onClick={() => onSelect(advisor)}
        >
          <div>
            <Advisors fillColor="fill-current" strokeColor="stroke-current" />
          </div>
          <span className="truncate">{advisor.name}</span>
        </div>
      ))}
    </div>
  );
}

AdvisorFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default AdvisorFilter;
