import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import InfoRow from "../Common/InfoRow";

import { Form } from "../../../core";
import { PillList } from "../../../core/compose";
import { LoadingButton } from "../../../core/buttons";
import { PillText, Select } from "../../../core/inputs";
import { Colors } from "../../../core/utils";

import { extractEmails } from "../Common/Utils";
import { GetAccess } from "../../Dashboard/Advisors/Utils";

function AdvisorForm({
  onSubmit,
  defaultValues = {},
  requestedClose,
  saveChangesError,
  requestCloseHandle
}) {
  const FORM_ID = "invite-new-advisor-from-id";

  const [emails, setEmails] = useState([]);
  const [access, setAccess] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [noAccessSelected, setNoAccessSelected] = useState(false);

  useEffect(() => {
    setEmails(defaultValues.advisors);
  }, [defaultValues.advisors]);

  useEffect(() => {
    setAccess(defaultValues.access);
  }, [defaultValues.access]);

  useEffect(() => {
    if (saveChangesError === "advisors")
      setError(saveChangesError, {
        message: "At least an advisor's email is required."
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChangesError]);

  const {
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues
  });

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      access: access,
      advisors: emails,
      role: access.value
    });
  }, [access, emails, requestCloseHandle]);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  const handlerOnSelectAccess = (data) => {
    setAccess(data);
    setNoAccessSelected(false);
  };

  const fetchAccessRoles = (search, callback) => {
    const accessRoles = GetAccess().map((access) => ({
      value: access.role,
      label: access.name.split(" ")[0]
    }));

    callback(() =>
      accessRoles.filter((access) =>
        access.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const handlerOnEmailChange = (value) => {
    clearErrors("advisors");
    setCurrentEmail(value);
  };

  const handlerOnEmailsPaste = (emailsPasted) => {
    clearErrors("advisors");
    let localEmails = [];
    let badEmails = emailsPasted;
    let newEmailsPasted = extractEmails(emailsPasted);

    newEmailsPasted.forEach((email) => {
      badEmails = badEmails.replace(email, "\n");
      if (!localEmails.some((emailItem) => emailItem === email))
        localEmails.push(email);
    });
    localEmails = localEmails
      .filter(
        (emailItem) => !emails.map((email) => email.label).includes(emailItem)
      )
      .map((emailItem) => ({ label: emailItem }));
    setEmails((emails) => [...emails, ...localEmails]);
    setCurrentEmail(
      badEmails
        .split("\n")
        .filter((email) => email.length > 2)
        .join(" ")
    );
  };

  const handlerOnEmailAdd = (email) => {
    email = email.trim();
    clearErrors("advisors");
    const exists = emails.some((emailItem) => emailItem.label === email);
    if (exists) {
      setError("advisors", {
        message: "This email already exists."
      });
    } else if (email.match(/^[\w+-.%]+@[\w-.]+\.[A-Za-z]{0,4}$/) && !exists) {
      setEmails((emails) => [...emails, { label: email }]);
      setCurrentEmail("");
    } else {
      if (email && !exists)
        setError("advisors", {
          message: "There is an invalid email."
        });
    }
  };

  const handlerOnRemovePill = (oldEmail) => {
    setEmails((emails) =>
      emails.filter((email) => email.label !== oldEmail.label)
    );
  };

  return (
    <>
      <div className="p-1 flex flex-1 flex-col overflow-auto">
        <Form
          id={FORM_ID}
          onSubmit={handleSubmit(async (form) => {
            if (emails.length === 0) {
              setError("advisors", {
                message: "At least an advisor's email is required."
              });
              return;
            }
            if (!access.value) {
              setNoAccessSelected(true);
              return;
            }

            setIsLoading(true);
            try {
              const formAdvisorData = {
                advisors: emails,
                role: access.value
              };
              await onSubmit(formAdvisorData);
            } finally {
              setIsLoading(false);
            }
          })}
        >
          <div className="my-4 flex flex-col">
            <p
              className="
                mb-2
                text-sm
                capitalize
                font-semibold
                text-primary-blue
              "
            >
              Advisor Emails
            </p>
            <div className="p-2 border border-black focus-within:shadow-black">
              <div className="overflow-auto" style={{ maxHeight: "80px" }}>
                <PillList
                  data={emails}
                  color={Colors.BLUE}
                  pillWidthFull={true}
                  onRemovePill={handlerOnRemovePill}
                />
              </div>
              <div className="overflow-hidden">
                <PillText
                  value={currentEmail}
                  handlerOnAdd={handlerOnEmailAdd}
                  handlerOnPaste={handlerOnEmailsPaste}
                  handlerOnChange={handlerOnEmailChange}
                  placeholder="jandoe@email.edu, johndoe@email.edu"
                />
              </div>
            </div>
          </div>
          <Form.ErrorMessage errors={errors} name="advisors" />
          <Select
            label="Role"
            value={access}
            color={Colors.BLUE}
            defaultValue={access}
            loadOptions={fetchAccessRoles}
            onChange={handlerOnSelectAccess}
            placeholder="Choose one"
          />
          {noAccessSelected && (
            <p className="text-primary-red font-bold mt-2">
              You must select an access level.
            </p>
          )}
          <div className="mt-4">
            <p className="text-sm">
              <span className="font-bold">Administrator -</span> can invite and
              view any new advisors and set access for other advisors in their
              organization
            </p>
            <p className="text-sm">
              <span className="font-bold">Advisor -</span> can view and invite
              any student in their organization
            </p>
          </div>
          <InfoRow
            label="organization"
            className="capitalize mt-4"
            text={`${defaultValues.currentOrg.organization.name}`}
          />
        </Form>
      </div>
      <div className="bottom-0 py-5 flex flex-row">
        <LoadingButton
          type="submit"
          value="submit"
          form={FORM_ID}
          isLoading={isLoading}
          id="invite-advisors-groups-form-send-invitation-button"
          disabled={emails.length > 0 ? false : true}
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          send invites
        </LoadingButton>
      </div>
    </>
  );
}

AdvisorForm.propTypes = {
  onSubmit: PropTypes.func,
  requestedClose: PropTypes.bool,
  defaultValues: PropTypes.shape(),
  saveChangesError: PropTypes.string,
  requestCloseHandle: PropTypes.func
};

export default AdvisorForm;
