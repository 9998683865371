import PropTypes from "prop-types";

function Computer({ width = 50, height = 35, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 48 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M47.7705 31.3364L44.7975 26.4069V0.748707C44.7975 0.333366 44.4587 0 44.0488 0H3.92465C3.50931 0 3.17595 0.338831 3.17595 0.748707V26.4015L0.110074 31.4839C-0.0320165 31.708 -0.0374817 31.9977 0.0991437 32.2436C0.230304 32.4676 0.481695 32.6152 0.754945 32.6152H47.2185C47.224 32.6152 47.2349 32.6152 47.2458 32.6152C47.6612 32.6152 48 32.2818 48 31.8665C48 31.6588 47.9126 31.4621 47.7705 31.3364ZM4.67336 1.50288H43.2946V25.8714H4.67336V1.50288ZM2.07748 31.1123L4.34546 27.3633H43.6225L45.885 31.1123H2.07748Z"
      />
      <path
        className={color}
        d="M28.5826 29.1611H19.3795C18.9642 29.1611 18.6254 29.5054 18.6254 29.9208C18.6254 30.3361 18.9697 30.6749 19.3795 30.6749H28.5826C29.0034 30.6749 29.3423 30.3306 29.3423 29.9153C29.3423 29.5 29.0034 29.1611 28.5826 29.1611Z"
      />
    </svg>
  );
}

Computer.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number
};

export default Computer;
