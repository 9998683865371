import PropTypes from "prop-types";
import { useEffect } from "react";

import Confirm from "../Common/Confirm";

function ChangeOwner({
  isLoading,
  requestedClose,
  saveChangeOwner,
  requestCloseHandle,
  backChangeOwnerHandle,
}) {
  useEffect(() => {
    if (requestedClose) requestCloseHandle();
  }, [requestedClose, requestCloseHandle]);

  const changeOwnerConfirmation = (
    <span>
      By removing yourself as a List Owner you will lose access to editing this
      list, are you sure you would like to proceed?
    </span>
  );

  return (
    <Confirm
      secondButton={true}
      buttonText="Proceed"
      isLoading={isLoading}
      secondButtonText="Return"
      onClick={saveChangeOwner}
      firstText={changeOwnerConfirmation}
      secondOnClick={backChangeOwnerHandle}
    />
  );
}

ChangeOwner.propTypes = {
  isLoading: PropTypes.bool,
  requestedClose: PropTypes.bool,
  saveChangeOwner: PropTypes.func,
  requestCloseHandle: PropTypes.func,
  backChangeOwnerHandle: PropTypes.func,
};

export default ChangeOwner;
