import PropTypes from "prop-types";

function NameCell({ advisor }) {
  const name = `${advisor.firstName} ${advisor.lastName}`;
  return (
    <div className="flex flex-col">
      <p className="text-sm font-bold">{name}</p>
    </div>
  );
}

NameCell.propTypes = {
  advisor: PropTypes.shape().isRequired,
};

export default NameCell;
