import { useEffect } from "react";
import { useTable, useRowSelect } from "react-table";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import Pagination from "./Pagination";

import { Checkbox } from "../../core/inputs";
import { Colors } from "../../core/utils";

import { useTable as useTableRedux } from "../../redux/Table/hooks";
import {
  updateWholeListOption,
  updateTableCheckboxOption
} from "../../redux/Table/actions";

const propsTablesTypes = {
  style: {},
  className: "",
  classNameRow: "",
  classNameCell: ""
};

const tablesTypes = {
  style: PropTypes.shape(),
  className: PropTypes.string,
  classNameRow: PropTypes.string,
  classNameCell: PropTypes.string
};

function setSelectColumn(hooks) {
  hooks.visibleColumns.push((columns) => [
    {
      id: "selection",
      Header: ({ toggleAllRowsSelected, getToggleAllRowsSelectedProps }) => (
        <SelectColumn
          toggleAll={toggleAllRowsSelected}
          {...getToggleAllRowsSelectedProps()}
        />
      ),
      Cell: ({ row }) => <SelectColumn {...row.getToggleRowSelectedProps()} />,
      width: "5%"
    },
    ...columns
  ]);
}

function SelectColumn({ toggleAll, indeterminate, ...rest }) {
  const dispatch = useDispatch();
  const { wholeList, tableCheckbox } = useTableRedux();

  useEffect(() => {
    if (toggleAll && !tableCheckbox) toggleAll(wholeList);
    if (tableCheckbox) dispatch(updateTableCheckboxOption(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAll, wholeList]);

  return (
    <Checkbox
      color={Colors.BLUE}
      value={rest.checked}
      id="select-all-items"
      checked={rest.checked}
      half={!rest.checked && indeterminate}
      onClick={() => {
        if (toggleAll && wholeList) dispatch(updateWholeListOption(false));
        else if (!toggleAll && rest.checked && wholeList) {
          dispatch(updateTableCheckboxOption(true));
          dispatch(updateWholeListOption(false));
        }
      }}
      {...rest}
    />
  );
}

function Table({
  data,
  columns,
  pageSize,
  itemsCount,
  hideColumn,
  currentPage,
  siblingCount,
  addSelectionColumn,
  onPageChange = () => {},
  propsBody = propsTablesTypes,
  propsHeader = propsTablesTypes,
  handlerOnSelectRows = () => {},
  tableSelector = "screen-viewport-table",
  noElementsComponent = () => null
}) {
  const propsH = { ...propsTablesTypes, ...propsHeader };
  const propsB = { ...propsTablesTypes, ...propsBody };

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    selectedFlatRows,
    toggleHideColumn,
    getTableBodyProps
  } = useTable(
    {
      data,
      columns
    },
    useRowSelect,
    addSelectionColumn ? (hooks) => setSelectColumn(hooks) : () => {}
  );

  useEffect(() => {
    if (hideColumn) toggleHideColumn(hideColumn.id, hideColumn.value);
  }, [hideColumn, toggleHideColumn]);

  useEffect(() => {
    handlerOnSelectRows(selectedFlatRows.map((selected) => selected.original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  return (
    <>
      <table className="w-full table-fixed" {...getTableProps()}>
        <thead className={`table w-full table-fixed ${propsH.className}`}>
          {headerGroups.map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={propsH.classNameRow}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  className={`
                    m-0
                    p-2
                    border-b
                    font-bold
                    text-left
                    bg-white
                    border-light-gray
                    ${propsH.classNameCell}
                  `}
                  width={column.width}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          id={tableSelector}
          style={propsB.style}
          {...getTableBodyProps()}
          className={`block overflow-y-auto ${propsB.className}`}
        >
          {itemsCount > 0
            ? rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.id}
                    className={`
                      table
                      w-full
                      table-fixed
                      hover:bg-light-gray
                      ${propsB.classNameRow}
                    `}
                    {...row.getRowProps()}
                    id={`table-item-${row.id}`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        key={cell.id}
                        className={`
                          px-2
                          py-4
                          border-b
                          border-light-gray
                          ${propsB.classNameCell}
                        `}
                        {...cell.getCellProps()}
                        width={cell.column.width}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            : noElementsComponent && noElementsComponent()}
        </tbody>
      </table>
      <Pagination
        pageSize={pageSize}
        itemsCount={itemsCount}
        currentPage={currentPage}
        siblingCount={siblingCount}
        onPageChange={onPageChange}
      />
    </>
  );
}

Table.propTypes = {
  onPageChange: PropTypes.func,
  hideColumn: PropTypes.shape(),
  siblingCount: PropTypes.number,
  data: PropTypes.array.isRequired,
  addSelectionColumn: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  noElementsComponent: PropTypes.func,
  handlerOnSelectRows: PropTypes.func,
  pageSize: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  propsBody: PropTypes.shape(tablesTypes),
  currentPage: PropTypes.number.isRequired,
  propsHeader: PropTypes.shape(tablesTypes)
};

export default Table;
