const RESET = "ARCHIVED_STUDENT_LISTS/RESET";
const SET_PAGE = "ARCHIVED_STUDENT_LISTS/SET_PAGE";
const SET_ERROR = "ARCHIVED_STUDENT_LISTS/SET_ERROR";
const SET_IS_LOADING = "ARCHIVED_STUDENT_LISTS/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING,
};

export default Types;
