import { createContext, useState } from "react";

import Modal from "../components/Common/Modal";

const ModalContext = createContext({
  show: false,
  modalData: {},
  modalResponse: "",
  openModalHandler: () => {},
  closeModalHandler: () => {},
  clearModalRespons: () => {},
});

export default ModalContext;

export function ModalContextProvider({ children }) {
  const openModalHandler = (data, type, permissions) => {
    setModalContext((state) => ({
      ...state,
      show: true,
      modalData: { data, type, permissions },
    }));
  };

  const closeModalHandler = (modalType, modalData) => {
    setModalContext((state) => ({
      ...state,
      show: false,
      modalResponse: modalType,
      modalResponseData: modalData,
      modalData: { type: "", data: {}, className: "" },
    }));
  };

  const clearModalRespons = () => {
    setModalContext((state) => ({
      ...state,
      modalResponse: "",
    }));
  };

  const [modalContext, setModalContext] = useState({
    show: false,
    modalData: {},
    openModalHandler,
    closeModalHandler,
    clearModalRespons,
    modalResponse: "",
  });

  return (
    <ModalContext.Provider value={modalContext}>
      {children}
      <ModalContext.Consumer>
        {({ show, modalData, clearModalRespons, closeModalHandler }) => (
          <Modal
            show={show}
            modalData={modalData}
            closeModal={closeModalHandler}
            clearReponse={clearModalRespons}
          />
        )}
      </ModalContext.Consumer>
    </ModalContext.Provider>
  );
}
