import PropTypes from "prop-types";

function Accepted({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 66 62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M65.2511 9.48987L54.5211 0.109863L43.6311 12.3999C38.2244 8.62846 31.6339 6.94483 25.0808 7.66095C18.5277 8.37707 12.4563 11.4444 7.99182 16.2945C3.5273 21.1447 0.972307 27.4488 0.800301 34.0386C0.628295 40.6285 2.85094 47.0573 7.05636 52.1338C11.2618 57.2102 17.1648 60.5901 23.6716 61.6471C30.1784 62.7041 36.8478 61.3666 42.4439 57.8823C48.04 54.3981 52.1833 49.0034 54.1061 42.698C56.0289 36.3925 55.6007 29.6038 52.9011 23.5899L65.2511 9.48987ZM59.0011 9.90988L28.3811 44.9099L13.4111 32.9999L16.3111 29.2799L27.0611 37.8099L54.9211 6.32986L59.0011 9.90988ZM26.5211 31.7499L15.5211 23.0499L7.17106 33.7499L28.9311 50.9999L49.6311 27.3199C51.4 32.4512 51.2881 38.0442 49.3151 43.1006C47.3422 48.157 43.6366 52.3478 38.8598 54.925C34.0831 57.5023 28.5459 58.2983 23.2366 57.1709C17.9273 56.0436 13.1913 53.0663 9.87391 48.7704C6.55654 44.4744 4.87364 39.1395 5.12556 33.7177C5.37749 28.2958 7.54786 23.1399 11.2493 19.1702C14.9507 15.2004 19.9424 12.6751 25.3334 12.0449C30.7244 11.4148 36.1639 12.7208 40.6811 15.7299L26.5211 31.7499Z"
      />
    </svg>
  );
}

Accepted.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Accepted;
