import Api from "../../services/Api";
import Types from "./types";

import { NotificationStatus } from "../../core/compose/Notifications/utils";

function setPage({ page, items, pageCount, itemsCount, newItemsCount }) {
  return {
    type: Types.SET_PAGE,
    page,
    items,
    pageCount,
    itemsCount,
    newItemsCount,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setReset() {
  return {
    type: Types.RESET,
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

export function resetNotificationsState() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchNotifications({
  refetch = false,
  page = { pageNumber: 1, pageSize: 15 },
}) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(null));
      const data = await Api.fetchNotifications({ page, refetch });
      const notification = {
        newItemsCount: data.newItemsCount,
        itemsCount: data.itemsCount,
        pageCount: data.pageCount,
        items: data.items,
        page: page,
      };
      dispatch(setPage(notification));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function markNotificationAsRead({ notificationId }) {
  return (dispatch, getState) => {
    const notifications = getState()?.notifications;

    let page = { pageNumber: 1 };
    const pages = notifications.pages.map((page, index) =>
      page.map((notificacion) => {
        if (notificacion.id === notificationId) {
          page.pageNumber = index + 1;
          return {
            ...notificacion,
            status: NotificationStatus.READ,
          };
        }

        return notificacion;
      })
    );

    const notification = {
      ...notifications,
      newItemsCount: notifications.newItemsCount - 1,
      items: pages[page.pageNumber - 1],
      page,
    };
    dispatch(setPage(notification));
  };
}
