import PropTypes from "prop-types";

function CollegeCosts({ year }) {
  return (
    <div className="flex flex-1 flex-col">
      <span className="text-sm font-medium">
        This estimate is for the {year}-{year + 1} school year. Your actual
        costs may be different
      </span>
    </div>
  );
}

CollegeCosts.propTypes = {
  year: PropTypes.number,
};

export default CollegeCosts;
