import { Languages } from "../../utils";

const NotificationTypes = {
  // STUDENT
  LETTER_INVALID: "LETTER_INVALID",
  LETTER_REVIEWED: "LETTER_REVIEWED",
  ERROR_REPORT_REVIEWED: "ERROR_REPORT_REVIEWED",
  INVITATION_TO_ORGANIZATION: "INVITATION_TO_ORGANIZATION",
  CUSTOM_MESSAGE_FROM_ADVISOR: "CUSTOM_MESSAGE_FROM_ADVISOR",
  LETTER_UPLOADED_BY_ADVISOR: "LETTER_UPLOADED_BY_ADVISOR",
  LETTER_TIMEOUT: "LETTER_TIMEOUT",
  // ADVISOR
  STUDENTS_JOINED_GROUP: "STUDENTS_JOINED_GROUP",
  ADVISOR_INVITATIONS_BOUNCED: "ADVISOR_INVITATIONS_BOUNCED",
  STUDENT_INVITATIONS_BOUNCED: "STUDENT_INVITATIONS_BOUNCED",
  // STUDENT AND ADVISOR
  CUSTOM_MESSAGE_FROM_DECIDED: "CUSTOM_MESSAGE_FROM_DECIDED"
};

const NotificationStatus = {
  NEW: "NEW",
  READ: "READ"
};

function getCloseNotificationsDrawer(type, data) {
  switch (type) {
    case NotificationTypes.CUSTOM_MESSAGE_FROM_ADVISOR:
    case NotificationTypes.CUSTOM_MESSAGE_FROM_DECIDED:
      return /(http|https):\/\//.test(data.message);
    default:
      return false;
  }
}

function getContent(type, data, language = Languages.ENGLISH) {
  if (language === Languages.ENGLISH) {
    switch (type) {
      case NotificationTypes.STUDENTS_JOINED_GROUP:
        switch (data.studentNames.length) {
          case 1:
            return (
              `${data.studentNames[0]} has joined your organization ` +
              `${data.organizationName}.`
            );
          case 2:
            return (
              `${data.studentNames[0]} and 1 other student have joined ` +
              `your organization ${data.organizationName}.`
            );
          default:
            return (
              `${data.studentNames[0]} and ${data.studentNames.length - 1} ` +
              `other students have joined your organization ${data.organizationName}.`
            );
        }
      case NotificationTypes.ADVISOR_INVITATIONS_BOUNCED:
      case NotificationTypes.STUDENT_INVITATIONS_BOUNCED:
        return (
          `We encountered issues sending invitations to your organization ${data.organizationName}. \n\n` +
          `Some emails bounced back, possibly due to invalid addresses, full inboxes, or server issues. Please check your email for more details on which addresses failed and try resending.`
        );
      case NotificationTypes.LETTER_REVIEWED:
        if (data.studentName) {
          return `Great news! We have reviewed your student ${data.studentName}'s letter from ${data.schoolName}. Check out their award results.`;
        } else {
          return `Great news! We have reviewed your letter from ${data.schoolName}. Check out your award results.`;
        }
      case NotificationTypes.LETTER_INVALID:
        return `We have reviewed your letter from ${data.schoolName}, but didn't find any financial aid awards.`;
      case NotificationTypes.LETTER_UPLOADED_BY_ADVISOR:
        return `Great news! Your Advisor ${data.advisorName} has just uploaded the financial aid letter for ${data.schoolName} to your Decided profile. Check out your free financial aid results and college affordability.`;
      case NotificationTypes.LETTER_TIMEOUT:
        return `There was a problem while uploading your letter for ${data.schoolName}. Please try again.`;
      case NotificationTypes.ERROR_REPORT_REVIEWED:
      case NotificationTypes.CUSTOM_MESSAGE_FROM_ADVISOR:
      case NotificationTypes.CUSTOM_MESSAGE_FROM_DECIDED:
        return data.message;
      case NotificationTypes.INVITATION_TO_ORGANIZATION:
        return data.text;
      default:
        break;
    }
  } else if (language === Languages.SPANISH) {
  }
}

function getNotificationList(
  data,
  callbacks = {},
  language = Languages.ENGLISH
) {
  return data?.map((item) => {
    const content = JSON.parse(item.content);
    const close = getCloseNotificationsDrawer(item.type, content);
    const notification = {
      id: item.id,
      status: item.status,
      date: item.insertTime,
      sentBy:
        item.type === NotificationTypes.CUSTOM_MESSAGE_FROM_ADVISOR
          ? content?.advisor_name
          : "DecidED.org",
      markAsRead: (notificationId, status) =>
        callbacks.markAsRead(notificationId, status, close),
      content: getContent(item.type, content, language)
    };

    const actionMap = {
      [NotificationTypes.LETTER_INVALID]: {
        button: true,
        text: "View Updated Results",
        callback: () => callbacks.letterReviewed(content)
      },
      [NotificationTypes.LETTER_TIMEOUT]: {
        button: true,
        text: "View College",
        callback: () => callbacks.letterReviewed(content?.ipedsId)
      },
      [NotificationTypes.LETTER_REVIEWED]: {
        button: true,
        text: "View Updated Results",
        callback: () => callbacks.letterReviewed(content)
      },
      [NotificationTypes.LETTER_UPLOADED_BY_ADVISOR]: {
        button: true,
        text: "View Results",
        callback: () => callbacks.letterReviewed(content?.ipedsId)
      },
      [NotificationTypes.INVITATION_TO_ORGANIZATION]: {
        button: true,
        text: "View Invitation",
        callback: () => callbacks.viewInvitation(content?.invitation_id)
      }
    };

    return {
      ...notification,
      action: actionMap[item.type] || null
    };
  });
}

export { NotificationStatus, getNotificationList };
