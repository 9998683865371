import React from "react";
import PropTypes from "prop-types";

import { getTheme, getColors } from "../../utils";

function getPercent(affordability, percent) {
  if (affordability) {
    if (percent < 0) return 0;
    else if (percent > 100) return 100;
    return percent;
  }
  return 100;
}

function AffordabilitySquare({
  coverage,
  className = "",
  affordability,
  ...restProps
}) {
  const theme = getTheme({ affordability });
  const colors = getColors(theme.color);
  const percent = getPercent(affordability, coverage);

  return (
    <div
      className={`
        flex
        m-0.5
        border
        w-1.875rem
        h-1.563rem
        border-black
        bg-transparent
        tablet:h-1.25rem
        tablet:w-1.406rem
        mobile:h-1.25rem
        mobile:w-1.406rem
        mobile-sm:h-1.25rem
        mobile-sm:w-1.406rem
        tablet-sm:h-1.25rem
        tablet-sm:w-1.406rem
        ${className}
      `}
      {...restProps}
    >
      <p
        style={{ height: `${percent}%` }}
        className={`w-full self-end ${colors.primary.bg}`}
      />
    </div>
  );
}

AffordabilitySquare.propTypes = {
  coverage: PropTypes.number,
  className: PropTypes.string,
  affordability: PropTypes.string
};

export default AffordabilitySquare;
