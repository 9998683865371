import { useEffect } from "react";
import PropTypes from "prop-types";

import Confirm from "../../Common/Confirm";

import Api from "../../../../services/Api";

function ReSentStudentInvitesModal({
  onClose,
  filters,
  invites,
  reSentInvites,
  organizationId,
  setConfirmModal,
}) {
  useEffect(() => {
    const reSentInvites = async () => {
      const dataMutation = {
        organizationId,
        invitations: {
          ids: invites?.length > 0 ? invites : null,
          filter: invites?.length > 0 ? null : filters,
        },
      };

      await Api.resendStudentInvites(dataMutation);

      setConfirmModal(true);
    };

    reSentInvites();
  }, [filters, invites, organizationId, setConfirmModal]);

  const singular = reSentInvites === 1;
  const firstText = `You have re-sent ${
    singular ? "an invite" : "invites"
  } to the selected ${singular ? "Student" : "Students"}.`;

  return (
    <div className="flex flex-col h-full">
      <Confirm
        onClick={onClose}
        firstText={firstText}
        buttonText="Go Back to Manage Invites"
      />
    </div>
  );
}

ReSentStudentInvitesModal.propTypes = {
  filters: PropTypes.shape(),
  reSentInvites: PropTypes.number,
  organizationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  invites: PropTypes.arrayOf(PropTypes.string),
};

export default ReSentStudentInvitesModal;
