import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import StudentDetailBody from "./StudentDetailBody";
import FloatLoader from "../../../Common/FloatLoader";
import StudentDetailHeader from "./StudentDetailHeader";

import Api from "../../../../services/Api";
import { CollegeType } from "../../../../core/utils";
import ModalContext from "../../../../contexts/ModalContext";
import { useFetchOrganizationAdvisors } from "../../../Common/Hooks";

import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { useOrganizations } from "../../../../redux/Organizations/hooks";
import { ModalTypes } from "../Utils";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function StudentDetail() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { studentId } = useParams();

  const modalContext = useContext(ModalContext);

  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const { advisors } = useFetchOrganizationAdvisors(selectedOrganizationId);

  const [student, setStudent] = useState(null);
  const [localSchools, setLocalSchools] = useState([]);
  const [assignedAdvisorError, setAssingAdvisorError] = useState(null);
  const [localStudentId, setLocalStudentId] = useState(studentId || null);
  const [programLength, setProgramLength] = useState(CollegeType.FOUR_YEARS);
  const [organizationId, setOrganizationId] = useState(selectedOrganizationId);

  const assingAdvisors = useMemo(() => {
    const noAdvisor = {
      id: null,
      name: "Unassigned",
    };
    const advisor = {
      id: profile.id,
      name: `${profile.firstName} ${profile.lastName}`,
    };

    const sortedAdvisors = [advisor, ...advisors].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    return [...sortedAdvisors, noAdvisor];
  }, [profile, advisors]);

  const onReturnStudentList = useCallback(() => {
    const qs = new URLSearchParams(search.slice(1));
    let studentsUrl = "/dashboard/students";
    if (qs.get("listId")) studentsUrl += `/list/${qs.get("listId")}`;
    navigate(studentsUrl);
  }, [navigate, search]);

  const onAssingAdvisor = useCallback(
    async (advisor) => {
      if (advisor.id === null) {
        interactionEventDataLayer({
          eventName: "st_det_view_unassigned_field",
          params: {
            user_id: student.userId,
          },
        });
      }
      try {
        const { ok } = await Api.assignAdvisorToStudents({
          organizationId,
          advisorId: advisor.id,
          students: {
            studentIds: [student.studentId],
          },
        });
        if (ok) {
          setStudent((student) => ({
            ...student,
            assignedAdvisor: { id: advisor.id },
          }));
          modalContext.openModalHandler(
            {
              confirmAssign: {
                advisor,
                student: { name: `${student.firstName} ${student.lastName}` },
              },
            },
            ModalTypes.ASSIGN_ADVISOR,
          );
        }
      } catch (error) {
        setAssingAdvisorError(error.errors[0].message);
      }
    },
    [student, organizationId, modalContext],
  );

  useEffect(() => setLocalStudentId(studentId || null), [studentId]);

  useEffect(
    () => setOrganizationId(selectedOrganizationId),
    [selectedOrganizationId],
  );

  useEffect(() => {
    let isSubscribed = true;

    const getStudentDetail = async () => {
      const { student, assignedAdvisor, includedStudents } =
        await Api.fetchAdvisorStudentDetail({
          organizationId,
          filter: {
            studentId: localStudentId,
          },
        });

      if (isSubscribed) {
        if (student.chosenSchools.length > 0)
          setProgramLength(student.chosenSchools[0].school.programLength);
        setLocalSchools(() => {
          setStudent({
            ...student,
            studentId: localStudentId,
            assignedAdvisor: assignedAdvisor || { id: null },
            studentListNames: includedStudents.map(
              (studentList) => studentList.studentListName,
            ),
          });
          return student.chosenSchools.map((school) => {
            return {
              ...school,
              ipedsId: school.school.ipedsId,
            };
          });
        });
      }
    };

    getStudentDetail();
    return () => (isSubscribed = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, localStudentId]);

  return (
    <div className="px-4 pt-8 relative min-h-screen-footer">
      {student ? (
        <>
          {assignedAdvisorError && (
            <div className="mt-8 space-y-2 text-primary-red">
              <p>Something went wrong. {assignedAdvisorError}</p>
            </div>
          )}
          <StudentDetailHeader
            student={student}
            advisors={assingAdvisors}
            programLength={programLength}
            organizationId={organizationId}
            onAssingAdvisor={onAssingAdvisor}
            setProgramLength={setProgramLength}
            showToggle={localSchools?.length > 0}
            openModal={modalContext.openModalHandler}
            onReturnStudentList={onReturnStudentList}
          />
          {localSchools.length > 0 && (
            <StudentDetailBody
              student={student}
              schools={localSchools}
              programLength={programLength}
            />
          )}
        </>
      ) : (
        <FloatLoader show={true} />
      )}

      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="college-thumbnail-squares"
      />

      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="enrollment-icon-label"
        getContent={(statusName) => {
          return (
            <div className="text-center">
              <p className="text-xs font-bold normal-case">{statusName}</p>
            </div>
          );
        }}
      />
    </div>
  );
}

export default StudentDetail;
