import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { debounce, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import NoResults from "../../../Common/NoResults";
import FloatLoader from "../../../Common/FloatLoader";
import AdvisorInvitesTableBody from "./AdvisorInvitesTableBody";
import AdvisorInvitesTableHeader from "./AdvisorInvitesTableHeader";

import { getColumns } from "./columns";
import { Order } from "../../../../utils/order";
import { ModalTypes } from "../../Advisors/Utils";
import { SortingType, GetInvitationStatus, GetInvitationRole } from "../Utils";
import ModalContext from "../../../../contexts/ModalContext";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { updateWholeListOption } from "../../../../redux/Table/actions";
import { useOrganizations } from "../../../../redux/Organizations/hooks";
import { useAdvisorInvites } from "../../../../redux/AdvisorInvites/hooks";
import { useFetchOrganizationAdvisors } from "../../../Common/Hooks";
import { resetAdvisorInvitesState } from "../../../../redux/AdvisorInvites/actions";

import { fetchAdvisorInvites } from "../../../../redux/AdvisorInvites/actions";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function containsObject(obj, list) {
  for (var x in list) {
    if (list.hasOwnProperty(x) && list[x] === obj) return true;
  }
  return false;
}

function getData(invites) {
  const localInvitations = invites || [];

  return localInvitations.map((invitation) => ({
    id: invitation.id,
    email: invitation.recipient,
    role: GetInvitationRole(invitation.role),
    insertTime: new Date(invitation.insertTime),
    status: GetInvitationStatus(invitation.status),
    sender: invitation.sender,
  }));
}

function AdvisorInvitesTable() {
  const dispatch = useDispatch();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { pages, selectedOrganizationId } = useOrganizations();
  const { advisors } = useFetchOrganizationAdvisors(selectedOrganizationId);

  const invitesState = useAdvisorInvites();
  const modalContext = useContext(ModalContext);

  const pageSize = 20;

  const [search, setSearch] = useState("");
  const [invites, setInvites] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [doFetch, setDoFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRole, setCurrentRole] = useState(null);
  const [getDataFlag, setGetDataFlag] = useState(false);
  const [filterTagRole, setFilterTagRole] = useState([]);
  const [inviteFilters, setInvitesFilters] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterTagsStatus, setFilterTagsStatus] = useState([]);
  const [filterTagsSender, setFilterTagsSender] = useState([]);
  const [selectedInvitations, setSelectedInvitations] = useState([]);
  const [orderBy, setOrderBy] = useState({ [SortingType.EMAIL]: Order.asc });
  const [organizationId, setOrganizationId] = useState(selectedOrganizationId);

  const defaultFilters = {
    role: [],
    status: [],
    recipient: null,
    senders: [],
  };
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    if (orgId && getDataFlag)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          organization_id: orgId,
          user_id: profile.userId,
          search_input: filters.recipient,
          screen_name: "ManageAdvisorsInvites",
          count_of_search_results: invitesState?.itemsCount,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, filters.recipient, getDataFlag]);

  useEffect(() => {
    const organization = pages[0].find(
      (org) => org.organization.id === selectedOrganizationId
    );

    setInvites([]);
    setCurrentPage(1);
    setOrganizationId(selectedOrganizationId);
    setCurrentRole(organization?.role || null);
    setOrgId(organization?.organization?.id || null);
    setDoFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchInvitations = async () => {
      await dispatch(
        fetchAdvisorInvites({
          orderBy,
          organizationId,
          filter: filters,
          page: { pageSize, pageNumber: currentPage },
        })
      );
      if (isSubscribed) {
        setDoFetch(false);
        setGetDataFlag(true);
      }
    };

    if (doFetch) fetchInvitations();
    return () => {
      isSubscribed = false;
      if (wholeList) dispatch(updateWholeListOption(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch]);

  useEffect(() => {
    if (getDataFlag) {
      if (!invitesState.isLoading) {
        setInvites(getData(invitesState?.pages[currentPage - 1]));
        setGetDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitesState.isLoading, invitesState.pages, currentPage, getDataFlag]);

  useEffect(() => {
    dispatch(resetAdvisorInvitesState());

    const filters = {
      recipient: search || null,
      role: filterTagRole.map((filter) => filter.key),
      status: filterTagsStatus.map((filter) => filter.key),
      senders: filterTagsSender.map((filter) => filter.id),
    };

    if (isEqual(defaultFilters, filters)) setFiltersApplied(false);
    else setFiltersApplied(true);

    setDoFetch(() => {
      setInvites([]);
      setCurrentPage(1);
      setInvitesFilters([
        ...filterTagsStatus,
        ...filterTagRole,
        ...filterTagsSender,
      ]);
      setFilters(filters);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterTagRole, filterTagsStatus, filterTagsSender]);

  const closeModal = useCallback(
    (modalType) => {
      if (modalType) {
        switch (modalType) {
          case ModalTypes.DELETE_INVITE:
          case ModalTypes.INVITE_ADVISOR:
          case ModalTypes.RE_SEND_INVITE:
            if (wholeList) dispatch(updateWholeListOption(false));
            setInvites([]);
            setCurrentPage(1);
            setDoFetch(true);
            break;
          default:
            break;
        }
      }
    },
    [dispatch, wholeList]
  );

  useEffect(() => {
    closeModal(modalContext.modalResponse);
  }, [closeModal, modalContext.modalResponse]);

  const assingAdvisors = useMemo(() => {
    const noAdvisor = {
      id: null,
      role: null,
      name: "Unassigned",
    };
    const advisor = {
      id: profile.id,
      role: currentRole,
      name: `${profile.firstName} ${profile.lastName}`,
    };

    const sortedAdvisors = [advisor, ...advisors].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return [...sortedAdvisors, noAdvisor];
  }, [profile, advisors, currentRole]);

  const toggleSortingOrderDebounce = useMemo(
    () =>
      debounce((sortingType, fieldOrder) => {
        setDoFetch(() => {
          setInvites([]);
          setCurrentPage(1);
          setOrderBy({ [sortingType]: fieldOrder });
          return true;
        });
      }, 500),
    []
  );

  const toggleSortingOrder = useCallback(toggleSortingOrderDebounce, [
    toggleSortingOrderDebounce,
  ]);

  const handlerOnSelectInvitations = useCallback(
    (selectedInvitations) => setSelectedInvitations(selectedInvitations),
    []
  );

  const handlerOnChangeSearchDebounce = useMemo(
    () => debounce((value) => setSearch(value), 500),
    []
  );

  const handlerOnChangeSearch = useCallback(handlerOnChangeSearchDebounce, [
    handlerOnChangeSearchDebounce,
  ]);

  const handlerOnRemoveFilterTag = (filter) => {
    setFilterTagRole(filterTagRole.filter((x) => x.key !== filter.key));
    setFilterTagsStatus(filterTagsStatus.filter((x) => x.key !== filter.key));
    setFilterTagsSender(filterTagsSender.filter((x) => x.key !== filter.key));
  };

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    setDoFetch(true);
  };

  const columns = useMemo(
    () =>
      getColumns({
        orderBy,
        assingAdvisors,
        toggleSortingOrder,
        onFilterStatusSelect: (filter) => {
          if (!containsObject(filter, filterTagsStatus)) {
            setFilterTagsStatus([...filterTagsStatus, filter]);
          }
        },
        onFilterRoleSelect: (filter) => setFilterTagRole([filter]),
        onFilterSenderSelect: (filter) => {
          const mappedFilters = filterTagsSender.map((filter) => filter.key);
          if (!mappedFilters.includes(filter.key)) {
            setFilterTagsSender([...filterTagsSender, filter]);
          }
        },
      }),
    [
      orderBy,
      assingAdvisors,
      toggleSortingOrder,
      filterTagsStatus,
      filterTagsSender,
    ]
  );

  const onClearAllFilters = () => {
    setFilterTagsStatus([]);
    setFilterTagRole([]);
    setFilterTagsSender([]);
    setSearch("");
  };

  const onAdvisorError = (error) => {
    toast.error(error, {
      progress: 1,
      theme: "colored",
      closeOnClick: true,
      position: "top-right",
      hideProgressBar: false,
    });
  };

  if (invitesState.error) onAdvisorError(invitesState.error);

  return (
    <div className="px-4 pt-8 relative min-h-screen-footer-md">
      <AdvisorInvitesTableHeader
        filters={filters}
        onClear={onClearAllFilters}
        inviteSearch={search}
        invitesState={invitesState}
        inviteFilters={inviteFilters}
        organizationId={organizationId}
        onChange={handlerOnChangeSearch}
        onRemove={handlerOnRemoveFilterTag}
        selectedInvitations={selectedInvitations}
        openModal={modalContext.openModalHandler}
      />
      <AdvisorInvitesTableBody
        data={invites}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handleOnPageChange}
        itemsCount={invitesState.itemsCount}
        handlerOnSelectInvitations={handlerOnSelectInvitations}
      />
      {inviteFilters.isLoading && (
        <FloatLoader show={inviteFilters.isLoading} />
      )}
      {invites.length === 0 && filtersApplied && !inviteFilters.isLoading && (
        <NoResults
          trigger={filters}
          tableSelector="viewport-advisor-invites-table"
        />
      )}
    </div>
  );
}

export default AdvisorInvitesTable;
