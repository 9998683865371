import PropTypes from "prop-types";

import { AffordabilityCard as CommonAffordabilityCard } from "../../../../../core/compose/cards";

function AffordabilityCard({ chosenSchool, executeScroll }) {
  if (!chosenSchool) return null;

  const reuploadAwardComponent = <div className="my-2"></div>;

  const affordableButtonsComponent = <div className="my-2"></div>;

  const addYourMoneyComponent = (
    <div
      className="
        my-2
        py-2
        flex
        flex-1
        border
        text-sm
        flex-row
        font-bold
        text-white
        justify-center
        bg-primary-blue
        border-primary-blue
      "
    >
      <span>Student has not uploaded an award letter yet</span>
    </div>
  );

  return (
    <CommonAffordabilityCard
      chosenSchool={chosenSchool}
      executeScroll={executeScroll}
      showAddYourMoneyComponent={false}
      addYourMoneyComponent={addYourMoneyComponent}
      reuploadAwardComponent={reuploadAwardComponent}
      affordableButtonsComponent={affordableButtonsComponent}
    />
  );
}

AffordabilityCard.propTypes = {
  executeScroll: PropTypes.func,
  chosenSchool: PropTypes.shape({
    award: PropTypes.shape(),
    schoolCosts: PropTypes.shape()
  })
};

export default AffordabilityCard;
