function DateCreatedHeader() {
  return (
    <div
      role="button"
      id="advisor-sort-by-activity-button"
      className="flex flex-row items-center justify-center"
    >
      <span className="text-sm">Date Created</span>
    </div>
  );
}

export default DateCreatedHeader;
