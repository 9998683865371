import Api from "../../services/Api";
import Types from "./types";

function setReset() {
  return {
    type: Types.RESET,
  };
}

function setError(error) {
  return {
    error,
    type: Types.SET_ERROR,
  };
}

function setIsLoading(isLoading) {
  return {
    isLoading,
    type: Types.SET_IS_LOADING,
  };
}

function setPage({ page, itemsCount, pageCount, items }) {
  return {
    page,
    items,
    pageCount,
    itemsCount,
    type: Types.SET_PAGE,
  };
}

export function resetAdvisorsState() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchOrganizationAdvisors({
  page,
  filter,
  orderBy,
  organizationId,
}) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(null));

      const advisors = await Api.fetchAdminOrganizationAdvisors({
        page,
        filter,
        orderBy,
        organizationId,
      });
      dispatch(setPage({ page: page || { pageNumber: 1 }, ...advisors }));
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
