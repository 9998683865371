import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";
import { SearchText, Checkbox } from "../../../../core/inputs";
import { ItemsCounter, Dropdown } from "../../../../core";
import { Colors } from "../../../../core/utils";
import { ButtonTooltip } from "../../../../core/compose";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { updateWholeListOption } from "../../../../redux/Table/actions";
import { useOrganizations } from "../../../../redux/Organizations/hooks";

import { ModalTypes } from "../Utils";
import Api from "../../../../services/Api";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function AdvisorTableHeader({
  filters,
  onChange,
  openModal,
  advisorListId,
  advisorSearch,
  advisorsState,
  organizationId,
  selectedAdvisors,
  setGlobalIsOwner,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { advisorLists } = useOrganizations();

  const [list, setList] = useState(null);
  const [isOwner, setIsOwner] = useState(true);
  const [tooltipOptions, setTooltipOptions] = useState({});

  useEffect(() => {
    if (advisorListId) {
      setTooltipOptions({
        text: "This is the total number of active advisors in your filtered list. You can delete advisors who are no longer active.",
        open: false,
        onClose: null,
      });
    } else if (advisorsState.itemsCount <= 0) {
      setTooltipOptions({
        text: "You have not added any advisors. Click the Invite button in the top left corner to invite advisors to your organzation.",
        open: false,
        onClose: null,
      });
    } else {
      setTooltipOptions({
        text: "This is the total number of active advisors in your organization. You can delete advisors who are no longer active.",
        open: false,
        onClose: null,
      });
    }
  }, [advisorsState.itemsCount, tooltipOptions.open, advisorListId]);

  useEffect(() => {
    const getList = async () => {
      if (advisorListId) {
        const list = advisorLists.find((list) => list.id === advisorListId);

        if (list) {
          if (list.owner.id === profile.id) setIsOwner(true);
          else setIsOwner(false);
          const {
            items: [fetchList],
          } = await Api.fetchOrganizationAdvisorLists({
            organizationId,
            filter: { ids: [list.id] },
          });
          setList({ ...list, ...fetchList });
        } else navigate("/dashboard/advisors");
      } else {
        setIsOwner(true);
        setList(null);
      }
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, advisorLists, advisorListId, organizationId]);

  useEffect(() => {
    if (list) {
      if (isOwner && !list.isShared) {
        interactionEventDataLayer({
          eventName: "advisor_my_list",
          params: {
            list_id: advisorListId,
          },
        });
      } else {
        interactionEventDataLayer({
          eventName: "advisor_org_list",
          params: {
            list_id: advisorListId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    setGlobalIsOwner(isOwner);
  }, [isOwner, setGlobalIsOwner]);

  const getActionLabel = (count) => {
    return count === 1 ? `${count} Advisor` : `${count} Advisors`;
  };

  const handlerOnCreateList = () => {
    openModal(
      {
        organizationId,
        filters: wholeList ? filters : null,
        advisors:
          !wholeList && selectedAdvisors.length > 0
            ? selectedAdvisors.map((item) => item.advisor.advisorId)
            : null,
      },
      ModalTypes.CREATE_LIST,
    );
    interactionEventDataLayer({
      eventName: "create_advisor_list",
    });
  };

  const handlerOnAddToList = () => {
    const data = {
      filters,
      organizationId,
      advisors: !wholeList
        ? selectedAdvisors.map((item) => item.advisor.advisorId)
        : null,
    };
    openModal(data, ModalTypes.ADD_TO_LIST);
  };

  const handlerOnClickEditRole = () => {
    openModal(
      {
        organizationId,
        advisors: selectedAdvisors.map((item) => item.advisor),
      },
      ModalTypes.EDIT_ACCESS_ADVISOR,
    );
    interactionEventDataLayer({
      eventName: "all_adv_edit_role_button",
      params: {
        user_id: selectedAdvisors.map((item) => item.advisor.userId),
      },
    });
  };

  const handlerOnClickDelete = () => {
    const data = {
      organizationId,
      advisors: !wholeList
        ? selectedAdvisors.map((item) => item.advisor)
        : null,
      deleteAdvisors: !wholeList
        ? selectedAdvisors.length
        : advisorsState.itemsCount,
    };

    openModal(data, ModalTypes.DELETE_ADVISOR);
  };

  const handlerOnEditList = () => {
    openModal({ list, organizationId }, ModalTypes.EDIT_LIST);
    interactionEventDataLayer({
      eventName: "advisor_edit_list_settings_button",
    });
  };

  const handlerOnDeleteList = () => {
    openModal(
      {
        lists: [list],
        deleteLists: 1,
        organizationId,
      },
      ModalTypes.DELETE_LIST,
    );
    interactionEventDataLayer({
      eventName: "delete_list_button",
      params: {
        list_id: advisorListId,
      },
    });
  };

  const handlerOnArchiveList = () => {
    openModal(
      {
        lists: [list],
        organizationId,
        archiveLists: 1,
      },
      ModalTypes.ARCHIVE_LIST,
    );
    interactionEventDataLayer({
      eventName: "advisor_archive_list_button",
    });
  };

  const handlerOnClickRemoveList = () => {
    const data = {
      filters,
      list: list,
      organizationId,
      advisors: !wholeList
        ? selectedAdvisors.map((item) => item.advisor.advisorId)
        : null,
      removeAdvisors: !wholeList
        ? selectedAdvisors.length
        : advisorsState.itemsCount,
    };
    openModal(data, ModalTypes.REMOVE_ADVISOR_FROM_LIST);
  };

  const handleOnSelectOption = (data) => data.handlerOnClick();

  const dropdownOptions = [
    {
      id: "add",
      enabled:
        advisorLists.length > 0 &&
        advisorLists.some(
          (list) => list.owner.id === profile.id && !list.archived,
        ) &&
        (selectedAdvisors.length > 0 || wholeList),
      handlerOnClick: () => handlerOnAddToList(),
      label: (
        <div className="text-center text-primary-blue">
          Add To List{" "}
          {getActionLabel(
            wholeList ? advisorsState?.itemsCount : selectedAdvisors.length,
          )}
        </div>
      ),
    },
    {
      id: "edit",
      enabled: selectedAdvisors.length > 0,
      handlerOnClick: () => handlerOnClickEditRole(),
      label: (
        <div className="text-center text-primary-blue">
          Edit Role{" "}
          {getActionLabel(
            wholeList ? advisorsState?.itemsCount : selectedAdvisors.length,
          )}
        </div>
      ),
    },
    {
      id: "delete",
      enabled: selectedAdvisors.length > 0,
      handlerOnClick: () => handlerOnClickDelete(),
      label: (
        <div className="text-center text-primary-blue">
          Delete{" "}
          {getActionLabel(
            wholeList ? advisorsState?.itemsCount : selectedAdvisors.length,
          )}
        </div>
      ),
    },
  ];

  const dropdownOptionsList = [
    {
      id: "remove",
      enabled: selectedAdvisors.length > 0 || wholeList,
      handlerOnClick: () => handlerOnClickRemoveList(),
      label: (
        <div className="text-center text-primary-blue">
          {`Remove ${getActionLabel(
            wholeList ? advisorsState?.itemsCount : selectedAdvisors.length,
          )} from List`}
        </div>
      ),
    },
  ];

  const createdDate = new Date(list?.insertTime).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const onClickFunctionDropdown = () => {
    !advisorListId &&
      interactionEventDataLayer({
        eventName: "action_button_all_advisors",
      });
  };

  return (
    <>
      {list && (
        <div className="flex flex-row justify-between">
          <p className="mb-2 text-xl font-semibold text-primary-blue">
            {list.name}
          </p>
          <p className="text-sm font-semibold text-gray-500">
            List owned by {list.owner.advisor?.firstName}{" "}
            {list.owner.advisor?.lastName} created on {createdDate}
          </p>
        </div>
      )}
      <div className="mb-2 flex flex-1 text-sm flow-row space-x-4 justify-end">
        <Button
          id="delete-list-button"
          onClick={() => handlerOnDeleteList()}
          className={`
              my-1
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${advisorListId && isOwner ? "block" : "hidden"}
            `}
        >
          Delete List
        </Button>
        <div>
          <Button
            id="archive-list-button"
            onClick={() => handlerOnArchiveList()}
            className={`
              my-1
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${advisorListId && isOwner ? "block" : "hidden"}
            `}
          >
            Archive List
          </Button>
        </div>
      </div>
      <div className="flex flex-1 text-sm flex-row justify-start">
        <div className="flex flex-1 flex-row justify-start">
          <SearchText
            id="advisors-search-bar"
            handlerOnSubmit={onChange}
            placeholder="Advisor Search"
            defaultValue={advisorSearch}
          />
        </div>
        <div className="flex flex-1 flex-row space-x-4 justify-end">
          <Button
            id="create-list-button"
            onClick={() =>
              advisorListId ? handlerOnEditList() : handlerOnCreateList()
            }
            className={`
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${!isOwner && "hidden"}
            `}
          >
            {advisorListId ? "Edit List Settings" : "Create List"}
          </Button>
          <div className={`min-w-15rem font-bold ${!isOwner && "hidden"}`}>
            <Dropdown
              color={Colors.BLUE}
              defaultLabel="Action"
              handleOnSelect={handleOnSelectOption}
              onClickFunction={onClickFunctionDropdown}
              options={advisorListId ? dropdownOptionsList : dropdownOptions}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex text-sm flex-row items-center">
        <ItemsCounter
          pluralDescriptionItems={
            selectedAdvisors.length > 0 || wholeList
              ? "Advisors Selected"
              : "Advisors"
          }
          singularDescriptionItems={
            selectedAdvisors.length > 0 || wholeList
              ? "Advisor Selected"
              : "Advisor"
          }
          numberItems={
            !wholeList && selectedAdvisors.length > 0
              ? selectedAdvisors.length
              : advisorsState?.itemsCount || 0
          }
        />
        <ButtonTooltip
          questionSize={20}
          tooltipColor={Colors.BLUE}
          tooltipCloseButton={true}
          tooltipDirection={"TOP-LEFT"}
          tooltipClassName="-left-2 top-16"
          tooltipCloseOnClickOutside={true}
          tooltipOptions={tooltipOptions}
        />
        {selectedAdvisors.length > 0 &&
          advisorsState.itemsCount !== selectedAdvisors.length && (
            <div className="w-fit">
              <Checkbox
                color={Colors.BLUE}
                value={wholeList}
                checked={wholeList}
                label={`Select All (${advisorsState.itemsCount} Advisors)`}
                onChange={() => {
                  dispatch(updateWholeListOption(!wholeList));
                }}
              />
            </div>
          )}
      </div>
    </>
  );
}

AdvisorTableHeader.propTypes = {
  openModal: PropTypes.func,
  filters: PropTypes.shape(),
  advisorListId: PropTypes.string,
  organizationId: PropTypes.string,
  setGlobalIsOwner: PropTypes.func,
  advisorsState: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  advisorSearch: PropTypes.string.isRequired,
};

export default AdvisorTableHeader;
