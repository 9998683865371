import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Upload from "./Upload";
import LivingChoice from "./LivingChoice";

import { AwardStatus } from "../../../../../core/utils";
import { waitForTimeout } from "../../../../../core/hooks";

import Api from "../../../../../services/Api";
import { useOrganizations } from "../../../../../redux/Organizations/hooks";
import { useSpinner } from "../../../../Common/Hooks";

function UploadImages() {
  const [currentView, setCurrentView] = useState("upload");
  const [archiveToUpload, setArchiveToUpload] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { studentId, collegeId } = useParams();
  const { selectedOrganizationId } = useOrganizations();

  const handleOnDoneUpload = (archive) => {
    setArchiveToUpload(archive);
    setCurrentView("living-choice");
  };

  const handleOnSelectLivingChoice = useSpinner(async (livingChoice) => {
    const pairedId = location.state.pairedId;
    try {
      await Api.uploadAward({
        pairedId,
        file: archiveToUpload,
        organizationId: selectedOrganizationId,
        livingChoice,
      });

      const status = await Api.waitForAward({
        pairedId,
        retryInterval: 10 * 1000,
        maxRetries: 4,
        filter: {
          studentId: studentId,
        },
        organizationId: selectedOrganizationId,
      });

      await waitForTimeout(10 * 1000);

      if (status === AwardStatus.READY) {
        await navigate(
          `/dashboard/students/${studentId}/${collegeId}/upload-award/success`,
        );
      } else {
        await navigate(
          `/dashboard/students/${studentId}/${collegeId}/upload-award/hmm`,
        );
      }
    } catch (err) {
      await navigate(
        `/dashboard/students/${studentId}/${collegeId}/upload-award/hmm`,
      );
    }
  });

  return (
    <div className="h-full w-full">
      {currentView === "upload" && (
        <Upload
          onDone={handleOnDoneUpload}
          onBack={() => navigate(-1)}
          studentName={location.state?.studentName || "your student"}
        />
      )}

      {currentView === "living-choice" && (
        <LivingChoice
          pairedId={collegeId}
          onSelect={handleOnSelectLivingChoice}
          onBack={() => setCurrentView("upload")}
          universityName={location.state.school.name || ""}
          hasOnCampusHousing={location.state.school.hasOnCampusHousing}
        />
      )}
    </div>
  );
}

export default UploadImages;
