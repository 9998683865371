export const SortingType = {
  EMAIL: "email",
};

export const InvitationStatus = {
  SENT: "SENT",
  PENDING: "PENDING",
  EXPIRED: "EXPIRED",
  ACCEPTED: "ACCEPTED",
};

export const InvitationRole = {
  ADMIN: "ADMIN",
  ADVISOR: "ADVISOR",
};

export const GetInvitationRole = (role) => {
  switch (role) {
    case InvitationRole.ADMIN:
      return "Admin";
    case InvitationRole.ADVISOR:
      return "Advisor";
    default:
      return "No Role";
  }
};

export const GetInvitationStatus = (status) => {
  switch (status) {
    case InvitationStatus.SENT:
      return "Sent";
    case InvitationStatus.PENDING:
      return "Pending";
    case InvitationStatus.EXPIRED:
      return "Expired";
    case InvitationStatus.ACCEPTED:
      return "Accepted";
    default:
      return "No status";
  }
};
