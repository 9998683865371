import PropTypes from "prop-types";

import { GraduationRate } from "../";
import { Panel } from "../../compose";
import Description from "../GraduationRate/Description";
import { getTheme } from "../../utils";

function GraduationRateCard({
  id,
  open,
  chosenSchool,
  externalToggle,
  callbackToggle,
  ethnicityStudent
}) {
  const { award, school, schoolCosts } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability
  });

  return (
    <Panel
      id={id}
      initiallyOpened={open}
      title="graduation rate"
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
      className="p-4 mb-2 bg-white shadow-lg"
      titleClassName="
        flex
        flex-1
        flex-row
        capitalize
        font-semibold
      "
      expandableContent={
        <>
          <GraduationRate
            ethnicityStudent={ethnicityStudent}
            schoolProgramLength={school.programLength}
            graduationRate={schoolCosts.graduationRate}
            ethnicityGroup={schoolCosts.ethnicityGroup}
            affordability={award?.postAwardSchoolCosts?.affordability}
          />
          <Description color={theme.color} />
        </>
      }
    />
  );
}

GraduationRateCard.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  ethnicityStudent: PropTypes.string
};

export default GraduationRateCard;
