import PropTypes from "prop-types";

import HomeLayout from "../../Common/HomeLayout";

function OnboardingLayout({ tab, appTabs }) {
  return (
    <HomeLayout>
      <div>
        <ol
          className="
            flex
            w-full
            flex-row
            border-t
            border-r
            bg-white
            border-black
          "
        >
          {appTabs.map((tabItem, index) => {
            return null;
          })}
        </ol>
        <div
          className="
            border-l
            border-b
            border-r
            bg-white
            border-black
          "
        >
          {appTabs.length > 0 && appTabs[tab]?.component}
        </div>
      </div>
    </HomeLayout>
  );
}

OnboardingLayout.propTypes = {
  tab: PropTypes.number,
  appTabs: PropTypes.array,
};

export default OnboardingLayout;
