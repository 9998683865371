import { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import AdvisorForm from "./AdvisorForm";
import ConfirmContent from "./ConfirmContent";

import Api from "../../../services/Api";
import {
  GetRole,
  GetAccess,
  AdvisorRoles,
} from "../../Dashboard/Advisors/Utils";

import { useOrganizations } from "../../../redux/Organizations/hooks";

export const getRoleWord = ({ role, plural }) => {
  return plural
    ? role === AdvisorRoles.ADMIN
      ? "admins"
      : "advisors"
    : role === AdvisorRoles.ADMIN
    ? "admin"
    : "advisor";
};

function InviteAdvisorModal({
  onClose,
  requestedClose,
  organizationId,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [firstText, setFirstText] = useState("");
  const [secondText, setSecondText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState("");
  const [pendingInvites, setPendingInvites] = useState(null);
  const [saveChangesError, setSaveChangesError] = useState(null);

  const { selectedOrganizationId, pages } = useOrganizations();

  const currentOrg = pages[0].find(
    (org) => org.organization.id === selectedOrganizationId
  );

  const initAccess = GetAccess().map((access) => ({
    value: access.role,
    label: access.name.split(" ")[0],
  }))[1];

  const defaultValues = {
    currentOrg,
    access: saveData.access || initAccess,
    advisors: saveData.advisors ? saveData.advisors : [],
  };

  const inviteNewAdvisorOnSubmit = async (form) => {
    const dataMutation = {
      role: form.role,
      organizationId: selectedOrganizationId,
      emails: form.advisors.map((advisor) => advisor.label),
    };
    setRoleSelected(form.role);
    const { invitationsSent, invitationsAlreadyPending, alreadyBelongToOrg } =
      await Api.inviteAdvisors(dataMutation);
    const plural = invitationsSent.length > 1;
    const plural2 = alreadyBelongToOrg.length > 1;
    const plural3 = invitationsAlreadyPending.length > 1;

    if (invitationsSent.length > 0) {
      setFirstText(
        <span>
          You've invited
          {plural
            ? ` ${invitationsSent.length} new ${getRoleWord({
                plural,
                role: form.role,
              })}`
            : ` a new ${getRoleWord({ plural, role: form.role })}`}
          !
        </span>
      );
    } else if (invitationsSent.length === 0 && alreadyBelongToOrg.length > 0) {
      setFirstText(
        <span>
          {plural2
            ? `These ${getRoleWord({ role: form.role, plural: plural2 })} have `
            : `This ${getRoleWord({ role: form.role, plural: plural2 })} has `}
          already joined your organization and won't receive another invitation:
        </span>
      );
    } else if (
      invitationsSent.length === 0 &&
      invitationsAlreadyPending.length > 0
    ) {
      setFirstText(
        <span>
          {plural3
            ? `The following ${getRoleWord({
                role: form.role,
                plural: plural3,
              })} have `
            : "This person has "}
          already been invited to your organization:
        </span>
      );
    }
    setSecondText(() => (
      <ConfirmContent
        plural={plural}
        role={form.role}
        plural2={plural2}
        plural3={plural3}
        invitationsSent={invitationsSent}
        alreadyBelongToOrg={alreadyBelongToOrg}
        invitationsAlreadyPending={invitationsAlreadyPending.map(
          (item) => item.recipient
        )}
      />
    ));
    setConfirm(true);
    if (!invitationsAlreadyPending.length > 0) setConfirmModal(true);
    else setPendingInvites(invitationsAlreadyPending);
  };

  const requestCloseHandle = (form) => {
    setSaveChangesError(null);
    setSaveData(form);
    setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (saveData.advisors.length === 0) setSaveChangesError("advisors");
    else await inviteNewAdvisorOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const addAnotherHandle = () => {
    setSaveData({});
    setFirstText("");
    setSecondText("");
    setConfirmModal(false);
    setConfirm(false);
    setSave(false);
  };

  const resendInvitesHandle = useCallback(async () => {
    const dataMutation = {
      organizationId,
      invitations: { ids: pendingInvites?.map((item) => item.id) },
    };
    await Api.resendAdvisorInvites(dataMutation);

    const firstTextSingular = "You have re-sent a pending invite.";
    const firstTextPlural = "You have re-sent the pending invites.";

    const firstText =
      pendingInvites.length === 1 ? firstTextSingular : firstTextPlural;

    setFirstText(<span className="text-3xl">{firstText}</span>);
    setSecondText(
      <span className="text-2xl font-medium">
        Make sure to let your advisor(s) know.
      </span>
    );
    setPendingInvites(null);
    setConfirmModal(true);
  }, [organizationId, setConfirmModal, pendingInvites]);

  const noResendInvitesHandle = () => {
    onClose();
    setPendingInvites(null);
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <AdvisorForm
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          onSubmit={inviteNewAdvisorOnSubmit}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          secondButton={true}
          firstText={firstText}
          secondText={secondText}
          onClick={() => (pendingInvites ? resendInvitesHandle() : onClose())}
          secondOnClick={() =>
            pendingInvites ? noResendInvitesHandle() : addAnotherHandle()
          }
          buttonText={
            pendingInvites
              ? `Re-send ${pendingInvites.length > 1 ? "Invites" : "Invite"}`
              : "Go Back to Manage Invites"
          }
          secondButtonText={
            pendingInvites
              ? "Do Not Send"
              : `Add Another ${GetRole(roleSelected)}`
          }
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

InviteAdvisorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  setOnRequestClose: PropTypes.func.isRequired,
};

export default InviteAdvisorModal;
