import { useEffect } from "react";
import PropTypes from "prop-types";
import { Colors, getColors } from "./utils";

function FloatBox({
  id,
  open,
  children,
  className,
  containerRef,
  componentRef,
  handleOnClickOutside,
  width = 300,
  color = Colors.DEFAULT
}) {
  const colors = getColors(color);

  const outsideClick = (event) => {
    if (
      open &&
      containerRef &&
      containerRef.current &&
      !containerRef.current.contains(event.target)
    )
      handleOnClickOutside(false);
  };

  useEffect(() => {
    if (handleOnClickOutside)
      window.addEventListener("mousedown", outsideClick);
    return () => {
      if (handleOnClickOutside)
        window.removeEventListener("mousedown", outsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnClickOutside]);

  if (open)
    return (
      <div
        id={id}
        ref={componentRef}
        style={{ width: typeof width === "number" ? `${width}px` : width }}
        className={`
          z-20
          border
          absolute
          shadow-lg
          ${className}
          ${colors.primary.border}
        `}
      >
        {children}
      </div>
    );

  return null;
}

FloatBox.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.any,
  containerRef: PropTypes.any,
  componentRef: PropTypes.any,
  className: PropTypes.string,
  handleOnClickOutside: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default FloatBox;
