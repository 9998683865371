import PropTypes from "prop-types";

function SenderCell({ advisor, ...restProps }) {
  const name = `${advisor.firstName} ${advisor.lastName}`;

  return (
    <div
      role="button"
      className="flex flex-1 flex-row justify-center"
      {...restProps}
    >
      <p className="font-bold">{name}</p>
    </div>
  );
}

SenderCell.propTypes = {
  advisor: PropTypes.shape(),
};

export default SenderCell;
