import Types from "./types";

import Api from "../../services/Api";

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

function setProfile(profile) {
  return {
    type: Types.SET_PROFILE,
    profile,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setIsLoggedIn(isLoggedIn) {
  return {
    type: Types.SET_IS_LOGGED_IN,
    isLoggedIn,
  };
}

export function fetchAdvisor({ organizationId }) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(null));

      const advisor = await Api.fetchAdvisor({ organizationId });

      dispatch(setIsLoggedIn(true));
      dispatch(
        setProfile({
          ...advisor,
          id: advisor?.organizationAdvisors?.items[0]?.id,
        })
      );
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateAdvisor({
  zip,
  email,
  title,
  lastName,
  firstName,
  phoneNumber,
  emailReceive,
  organizationId,
  insightsVisible,
  onboardingAdmin,
  onboardingAdvisor,
  emailNotifications,
  onboardingStudentsTooltip,
  onboardingInsightsTooltip,
  onboardingStudentsFiltersTooltup,
  onboardingAdvisorInvitationsTooltip,
  onboardingStudentInvitationsTooltip,
  onboardingAdvisorGetStartedTooltip,
  onboardingStudentGetStartedTooltip,
}) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setError(null));

      const advisor = await Api.updateAdvisorProfile({
        zip,
        email,
        title,
        lastName,
        firstName,
        phoneNumber,
        emailReceive,
        organizationId,
        insightsVisible,
        onboardingAdmin,
        onboardingAdvisor,
        emailNotifications,
        onboardingStudentsTooltip,
        onboardingInsightsTooltip,
        onboardingStudentsFiltersTooltup,
        onboardingAdvisorInvitationsTooltip,
        onboardingStudentInvitationsTooltip,
        onboardingAdvisorGetStartedTooltip,
        onboardingStudentGetStartedTooltip,
      });

      dispatch(
        setProfile({
          ...advisor,
          id: advisor?.organizationAdvisors?.items[0]?.id,
        })
      );
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function updateAdvisorLocal(advisor) {
  return (dispatch) => {
    dispatch(setProfile(advisor));
  };
}
