import { useState } from "react";
import PropTypes from "prop-types";

import EditAccess from "./EditAccess";
import Confirm from "../Common/Confirm";

import Api from "../../../services/Api";
import { GetAccess, AdvisorRoles } from "../../Dashboard/Advisors/Utils";

const getConfirmationText = (role) => {
  switch (role) {
    case AdvisorRoles.ADMIN:
      return {
        firstText: (
          <span>
            You've successfully changed the selected role(s) from advisor to
            admin.
          </span>
        ),
        secondText: (
          <span className="text-2xl font-medium">
            Make sure to share this update with your admin(s). They will also
            get an email with next steps.
          </span>
        ),
      };
    case AdvisorRoles.ADVISOR:
      return {
        firstText: (
          <span>
            You've successfully changed the selected role(s) from admin to
            advisor.
          </span>
        ),
        secondText: (
          <span className="text-2xl font-medium">
            Make sure to share this update with your advisor(s). They will also
            get an email with next steps.
          </span>
        ),
      };
    default:
      return { firstText: "", secondText: "" };
  }
};

export default function EditAccessModal({
  onClose,
  advisors,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveChangesError, setSaveChangesError] = useState(null);
  const [firstTextConfirmation, setFirsttextConfirmation] = useState("");
  const [secondTextConfirmation, setSecondTextConfirmation] = useState("");

  const roles = GetAccess();

  const defaultValue = {
    roles: roles || [],
    access: saveData.access || null,
  };

  const editAccessOnClick = async (dataMutation) => {
    await Api.updateAdvisorRole(dataMutation);

    const confirmationText = getConfirmationText(dataMutation.role);
    setFirsttextConfirmation(confirmationText.firstText);
    setSecondTextConfirmation(confirmationText.secondText);
    setConfirm(true);
    setConfirmModal(true);
  };

  const requestCloseHandle = (data) => {
    setSaveChangesError(null);
    setSaveData(data);
    setSave(true);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (!saveData.access?.value) setSaveChangesError("access");
    else await editAccessOnClick(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <EditAccess
          advisors={advisors}
          onClick={editAccessOnClick}
          defaultValues={defaultValue}
          requestedClose={requestedClose}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to All Advisors"
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

EditAccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  setOnRequestClose: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
