import PropTypes from "prop-types";

import { FilterTag } from "../../../Common/FiltersList";

import { getGradeYearLabel, GradeYear } from "../../../../core/utils";

function GradeYearFilter({ onSelect }) {
  const onSelectHandler = (key) => {
    onSelect(key);
  };

  return (
    <div
      className="
        flex
        text-sm
        flex-col
        divide-y
        space-y-2
        capitalize
        divide-solid
        divide-black
      "
    >
      <div className="flex flex-col items-center">
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.HIGH_SCHOOL_SENIOR })}
        >
          <span>{getGradeYearLabel(GradeYear.HIGH_SCHOOL_SENIOR)}</span>
        </div>
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.HIGH_SCHOOL_JUNIOR })}
        >
          <span>{getGradeYearLabel(GradeYear.HIGH_SCHOOL_JUNIOR)}</span>
        </div>
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() =>
            onSelectHandler({ key: FilterTag.HIGH_SCHOOL_SOPHOMORE })
          }
        >
          <span>{getGradeYearLabel(GradeYear.HIGH_SCHOOL_SOPHOMORE)}</span>
        </div>
      </div>
      <div className="pt-2 flex flex-col items-center">
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.OTHER })}
        >
          <span>{getGradeYearLabel(GradeYear.OTHER)}</span>
        </div>
      </div>
    </div>
  );
}

GradeYearFilter.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default GradeYearFilter;
