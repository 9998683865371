import { AwardStatus } from "../core/utils";

import { Order } from "../utils/order";
import { forceUserToSignIn } from "../utils/auth";
import {
  apiVersionErrorToast,
  unexpectedErrorToast
} from "../utils/unexpectedErrorToast";

const ResponseStatusCodes = {
  ok: 200,
  forbidden: 403,
  notFound: 404,
  serverRefuse: 418
};

const ErrorTypes = {
  authError: "AuthError",
  apiVersionError: "ApiVersionError",
  graphQLError: "GraphQLError",
  apiRestError: "apiRestError"
};

const advisorFields = `
  zip
  email
  title
  userId
  lastName
  firstName
  phoneNumber
  emailReceive
  onboardingAdmin
  insightsVisible
  onboardingAdvisor
  emailNotifications
  emailChangeRequestSent
  onboardingInsightsTooltip
  onboardingAdvisorGetStartedTooltip
  onboardingStudentGetStartedTooltip
  onboardingStudentsTooltip
  onboardingStudentsFiltersTooltup
  onboardingAdvisorInvitationsTooltip
  onboardingStudentInvitationsTooltip
  banner {
    desktopContent
    mobileContent
  }
  organizationAdvisors(organizationId: $organizationId) {
    items {
      id
      role
      mustSeeArchiveStudentsPopup
      organization {
        id
        name
      }
    }
  }
`;

const studentFields = `
  email
  state
  isPell
  userId
  lastName
  firstName
  ethnicity
  lastLogin
  lastLoginHumanize
`;

const chosenSchoolsFields = `
  pairedId
  sortOrder
  isFavorite
  livingChoice
  enrollmentStatus
  estimatedPostAwardCosts {
    totalAward
    lifeBudget
    coverage
  }
`;

const chosenSchoolsSchoolFields = `
  name
  size
  state
  control
  ipedsId
  earnings
  areaType
  programLength
  percentLowIncome
  hasOnCampusHousing
`;

const chosenSchoolsSchoolCostsFields = `
  lifeBudget
  totalCollegeCosts
  ethnicityGroup
  graduationRate
  transportation
  tuitionAndFees
  housingAndMeals
  personalExpenses
  booksAndSupplies
  totalCost
  tuitionAndFeesYear
  transportationYear
  housingAndMealsYear
  diversityPercentage
  personalExpensesYear
  booksAndSuppliesYear
  studentDiversityPercentage
`;

const chosenSchoolsAwardFields = `
  status
  aidAmounts {
    id
    isNew
    value
    category
    subCategory
    description
    categorySource
  }
`;

const chosenSchoolsAwardPostAwardSchoolCostsFields = `
  coverage
  lifeBudget
  totalAward
  schoolCosts
  affordability
`;

const chosenSchoolsAwardPendingErrorFields = `
  errorType
`;

class DecidedApi {
  constructor({ token, baseUrl = null, clientId = null } = {}) {
    this.configure({ token, baseUrl, clientId });
  }

  configure({ token, baseUrl, clientId }) {
    this.token = token;
    this.baseUrl = baseUrl || window.SERVER_DATA.REACT_APP_BACKEND_URL;
    this.clientId = clientId || window.SERVER_DATA.REACT_APP_AUTH0_CLIENT_ID;
    this.graphqlUrl = `${this.baseUrl}/advisors/graphql/`;
    this.signinUrl = `${this.baseUrl}/advisors/api/signin`;
    this.signupUrl = `${this.baseUrl}/advisors/api/signup`;
    this.newVerifyLinkUrl = `${this.baseUrl}/advisors/api/send-email-verification-link`;
  }

  async downloadFile(body, endpoint, filename) {
    function download(fileBlob) {
      const url = window.URL.createObjectURL(fileBlob);

      let a = document.createElement("a");
      a.style.display = "none";
      a.download = filename;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }

    try {
      const token = await this.token();
      const url = `${this.baseUrl}/${endpoint}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { Authorization: `JWT ${token}` },
        body: body && JSON.stringify(body)
      });

      if (response.status === ResponseStatusCodes.ok) {
        response.blob().then((fileBlob) => download(fileBlob));
      } else {
        unexpectedErrorToast({});
      }
    } catch (error) {
      throw error;
    }
  }

  async fetchInvitation({ invitationId }) {
    const url = `${this.baseUrl}/advisors/api/invitations/${invitationId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Api-Revision": window.SERVER_DATA.REACT_APP_REVISION
      }
    });

    if (response.status === ResponseStatusCodes.serverRefuse) {
      apiVersionErrorToast();
      throw Object.assign(new Error(), { type: ErrorTypes.apiVersionError });
    } else if (response.status === ResponseStatusCodes.notFound) {
      throw Object.assign(new Error(), {
        type: ErrorTypes.apiRestError,
        errors: { message: "Invitation not found!" }
      });
    }

    const responseBody = await response.json();
    return responseBody;
  }

  async fetch(url, body, options) {
    const getToken = async () => {
      try {
        return await this.token(options?.refetch);
      } catch (e) {
        forceUserToSignIn();
        throw e;
      }
    };

    const token = await getToken();

    const response = await fetch(url, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `JWT ${token}`,
        "Api-Revision": window.SERVER_DATA.REACT_APP_REVISION
      },
      body: body && JSON.stringify(body)
    });

    if (response.status === ResponseStatusCodes.serverRefuse) {
      apiVersionErrorToast();
      throw Object.assign(new Error(), { type: ErrorTypes.apiVersionError });
    }

    return response;
  }

  async gqlQuery(body, refetch) {
    const response = await this.fetch(this.graphqlUrl, body, {
      refetch,
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (response.status === ResponseStatusCodes.forbidden) {
      forceUserToSignIn();
      throw Object.assign(new Error(), { type: ErrorTypes.authError });
    }

    const responseBody = await response.json();
    if (responseBody?.errors) {
      const message = responseBody.errors[0]?.message || "Unknown Error";
      throw Object.assign(new Error(message), {
        type: ErrorTypes.graphQLError,
        errors: responseBody.errors
      });
    }

    return responseBody;
  }

  async checkUserExists() {
    const response = await this.fetch(this.signinUrl, undefined, {
      method: "POST"
    });
    return response.ok;
  }

  async newVerifyLink(resultUrl) {
    const response = await this.fetch(
      this.newVerifyLinkUrl,
      { result_url: resultUrl },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return response.ok;
  }

  async signUp({ firstName, lastName, phoneNumber, zip, title, emailReceive }) {
    const body = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      zip: zip,
      title: title,
      email_receive: emailReceive
    };
    const response = await this.fetch(this.signupUrl, body, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: body
    });
    return response;
  }

  async fetchOrganizations({ page }) {
    const query = `
      query AdvisorFetchOrganizations($page: Page) {
        advisor {
          organizationAdvisors(page: $page) {
            pageCount
            itemsCount
            items {
              role
              organization {
                id
                name
                code
                organizationStudents {
                  itemsCount
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: { page }
    });

    return data?.advisor?.organizationAdvisors;
  }

  async fetchOrganizationStudentLists({
    page,
    filter,
    orderBy,
    organizationId,
    basic = false
  }) {
    const fields = basic
      ? `
      id
      name
      isShared
      insertTime
      archived
      studentsCount
      sharedWithOrganization
      owner {
        id
        advisor {
          lastName
          firstName
        }
      }
    `
      : `
      id
      name
      isShared
      archived
      studentsCount
      sharedWithOrganization
      owner {
        id
        advisor {
          lastName
          firstName
        }
      }
      sharedWith {
        id
        advisor {
          lastName
          firstName
        }
      }
    `;

    const query = `
      query AdvisorFetchOrganizationStudentLists(
        $page: Page,
        $filter: ListFilter,
        $organizationId: ID,
        $orderBy: ListOrderBy,
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              studentLists(
                page: $page,
                filter: $filter
                orderBy: $orderBy,
              ) {
                pageCount,
                itemsCount,
                items {
                  ${fields}
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.advisor?.organizationAdvisors?.items[0]?.studentLists;
  }

  async fetchOrganizationAdvisorLists({
    page,
    filter,
    orderBy,
    organizationId,
    basic = false
  }) {
    const fields = basic
      ? `
      id
      name
      isShared
      insertTime
      archived
      advisorsCount
      owner {
        id
        advisor {
          firstName
          lastName
        }
      }
    `
      : `
      id
      name
      isShared
      archived
      advisorsCount
      owner {
        id
        advisor {
          lastName
          firstName
        }
      }
      sharedWith {
        id
        advisor {
          lastName
          firstName
        }
      }
      advisorsCount
      sharedWithOrganization
    `;

    const query = `
      query AdvisorFetchOrganizationAdvisorLists(
        $page: Page,
        $filter: ListFilter,
        $organizationId: ID,
        $orderBy: ListOrderBy
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              advisorLists(
                page: $page,
                filter: $filter,
                orderBy: $orderBy
              ) {
                pageCount,
                itemsCount,
                items {
                  ${fields}
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.advisor?.organizationAdvisors?.items[0]?.advisorLists;
  }

  async fetchAdvisor({ organizationId }) {
    const query = `
      query FetchAdvisor($organizationId: ID) {
        advisor {
          ${advisorFields}
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: { organizationId }
    });

    return data?.advisor;
  }

  async updateAdvisorProfile({
    zip,
    email,
    title,
    lastName,
    firstName,
    phoneNumber,
    emailReceive,
    organizationId,
    insightsVisible,
    onboardingAdmin,
    onboardingAdvisor,
    emailNotifications,
    onboardingStudentsTooltip,
    onboardingInsightsTooltip,
    onboardingStudentsFiltersTooltup,
    onboardingAdvisorInvitationsTooltip,
    onboardingStudentInvitationsTooltip,
    onboardingAdvisorGetStartedTooltip,
    onboardingStudentGetStartedTooltip
  }) {
    const mutation = `
      mutation UpdateAdvisor(
        $organizationId: ID,
        $input: UpdateAdvisorInput!
      ) {
        updateAdvisor(input: $input){
          ${advisorFields}
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        input: {
          zip,
          email,
          title,
          lastName,
          firstName,
          phoneNumber,
          emailReceive,
          insightsVisible,
          onboardingAdmin,
          onboardingAdvisor,
          emailNotifications,
          onboardingStudentsTooltip,
          onboardingInsightsTooltip,
          onboardingStudentsFiltersTooltup,
          onboardingAdvisorInvitationsTooltip,
          onboardingStudentInvitationsTooltip,
          onboardingAdvisorGetStartedTooltip,
          onboardingStudentGetStartedTooltip
        }
      }
    });

    return data.updateAdvisor;
  }

  async fetchStudentInviteInsights({ filter, organizationId }) {
    const query = `
     query FetchStudentInviteInsights(
       $organizationId: ID,
       $filter: StudentInvitationFilter,
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              organization {
                filterInvitesCount: studentInvitations(filter: $filter) {
                  itemsCount
                }
                totalInvitesCount: studentInvitations {
                  itemsCount
                }
                totalStudentsCount: organizationStudents(
                  filter: { archived: false }
                ) {
                  itemsCount
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        filter: {
          status: filter
        }
      }
    });

    const filterInvitesCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.filterInvitesCount?.itemsCount;
    const totalInvitesCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.totalInvitesCount?.itemsCount;
    const totalStudentsCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.totalStudentsCount?.itemsCount;

    const percentInvites =
      totalInvitesCount > 0
        ? 100 - Math.ceil((filterInvitesCount * 100) / totalInvitesCount)
        : 0;

    return {
      percentInvites,
      totalInvitesCount,
      filterInvitesCount,
      totalStudentsCount
    };
  }

  async fetchStudentInsights({ filter, organizationId }) {
    const query = `
      query FetchAdvisorStudentInsights(
        $organizationId: ID,
        $filter: OrganizationStudentFilter,
        $filterInvites: StudentInvitationFilter,
      ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                filterStudentsCount: organizationStudents (filter: $filter) {
                  itemsCount
                }
                totalStudentsCount: organizationStudents {
                  itemsCount
                }
                filterInvitesCount: studentInvitations(filter: $filterInvites) {
                  itemsCount
                }
                totalInvitesCount: studentInvitations {
                  itemsCount
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        filterInvites: {
          status: ["PENDING", "EXPIRED"]
        },
        filter: {
          archived: false,
          enrollmentStatus: filter
        }
      }
    });

    const filterStudentsCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.filterStudentsCount?.itemsCount;
    const totalStudentsCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.totalStudentsCount?.itemsCount;
    const filterInvitesCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.filterInvitesCount?.itemsCount;
    const totalInvitesCount =
      data.advisor.organizationAdvisors?.items[0]?.organization
        ?.totalInvitesCount?.itemsCount;

    const percentStudents =
      totalStudentsCount > 0
        ? Math.ceil((filterStudentsCount * 100) / totalStudentsCount)
        : 0;

    return {
      percentStudents,
      totalInvitesCount,
      totalStudentsCount,
      filterInvitesCount,
      filterStudentsCount
    };
  }

  async fetchOrganizationStudents({ page, filter, orderBy, organizationId }) {
    const query = `
      query FetchAdvisorOrganizationStudents(
        $page: Page,
        $organizationId: ID,
        $filter: OrganizationStudentFilter,
        $orderBy: OrganizationStudentOrderBy
      ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                organizationStudents (
                  page: $page,
                  filter: $filter,
                  orderBy: $orderBy
                ) {
                  pageCount
                  itemsCount
                  items {
                    id,
                    student {
                      lastName
                      firstName
                      gradeYear
                      userId
                      lastLogin
                      lastLoginHumanize
                      chosenSchools {
                        enrollmentStatus
                        school {
                          name
                        }
                        award {
                          ${chosenSchoolsAwardFields}
                          postAwardSchoolCosts {
                            coverage
                            affordability
                            totalAward
                          }
                        }
                      }
                    }
                    assignedAdvisor {
                      id
                      advisor {
                        lastName
                        firstName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
   `;

    const [orderField] = Object.keys(orderBy);

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        organizationId,
        orderBy: {
          [orderField]: orderBy[orderField] || Order.asc
        },
        filter: {
          name: filter?.search || null,
          gradeYear: filter?.gradeYear || null,
          archived: filter?.archived || null,
          noAwardLetter: filter?.noAwardLetter || null,
          affordability: filter?.affordability || null,
          noCollegeAdded: filter?.noCollegeAdded || null,
          assignedAdvisor: filter?.assignedAdvisor || null,
          enrollmentStatus: filter?.enrollmentStatus || null,
          onlyStudentsFromList: filter?.onlyStudentsFromList || null,
          studentIds: filter?.studentId ? [filter?.studentId] : null
        }
      },
      fetchPolicy: "network-only"
    });

    return data?.advisor?.organizationAdvisors?.items[0]?.organization
      .organizationStudents;
  }

  async fetchOrganizationAdvisorsBasic({ page, orderBy, organizationId }) {
    const query = `
      query FetchOrganizationAdvisorsBasic(
        $page: Page,
        $organizationId: ID,
        $orderBy: OrganizationAdvisorOrderBy,
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              organization {
                organizationAdvisors(
                  page: $page,
                  orderBy: $orderBy
                ) {
                  pageCount
                  items {
                    id,
                    role,
                    advisor {
                      userId
                      lastName
                      firstName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        orderBy,
        organizationId
      }
    });

    return data?.advisor?.organizationAdvisors?.items[0]?.organization
      ?.organizationAdvisors;
  }

  async assignAdvisorToStudents({ students, advisorId, organizationId }) {
    const mutation = `
      mutation AssignAdvisorToStudents(
        $advisorId: ID,
        $organizationId: ID!,
        $students: OrganizationStudentFilter!,
      ) {
        assignAdvisorToStudents(
          students: $students,
          advisorId: $advisorId,
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        advisorId,
        organizationId,
        students: {
          name: students.filter?.search || null,
          archived: students.filter?.archived || null,
          noAwardLetter: students.filter?.noAwardLetter || null,
          affordability: students.filter?.affordability || null,
          noCollegeAdded: students.filter?.noCollegeAdded || null,
          enrollmentStatus: students.filter?.enrollmentStatus || null,
          studentIds: students.studentIds ? students.studentIds : null,
          onlyStudentsFromList: students.filter?.onlyStudentsFromList || null,
          assignedAdvisor: students.filter?.assignedAdvisor
        }
      }
    });

    return data?.assignAdvisorToStudents;
  }

  async createStudentList({
    name,
    ownerId,
    students,
    sharedWith,
    organizationId,
    sharedWithOrganization
  }) {
    const mutation = `
      mutation CreateStudentList(
        $ownerId: ID!,
        $name: String!,
        $sharedWith: [ID!],
        $organizationId: ID!,
        $sharedWithOrganization: Boolean!,
        $students: OrganizationStudentFilter,
      ) {
        createStudentList(
          name: $name,
          ownerId: $ownerId,
          students: $students,
          sharedWith: $sharedWith,
          organizationId: $organizationId,
          sharedWithOrganization: $sharedWithOrganization,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name,
        ownerId,
        sharedWith,
        organizationId,
        sharedWithOrganization,
        students: students
          ? {
              name: students.filter?.search || null,
              archived: students.filter?.archived || null,
              noAwardLetter: students.filter?.noAwardLetter || null,
              affordability: students.filter?.affordability || null,
              noCollegeAdded: students.filter?.noCollegeAdded || null,
              enrollmentStatus: students.filter?.enrollmentStatus || null,
              studentIds: students.studentIds ? students.studentIds : null,
              onlyStudentsFromList:
                students.filter?.onlyStudentsFromList || null,
              assignedAdvisor: students.filter?.assignedAdvisor
            }
          : null
      }
    });

    return data?.createStudentList;
  }

  async deleteStudentLists({ lists, organizationId }) {
    const mutation = `
      mutation DeleteStudentLists(
        $lists: ListFilter!,
        $organizationId: ID!
      ) {
        deleteStudentLists(
          lists: $lists,
          organizationId: $organizationId
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        lists: {
          ids: lists?.ids || null,
          search: lists?.filter?.search || null,
          isShared: lists?.filter?.isShared || null,
          archived: lists?.filter?.archived || null
        }
      }
    });

    return data?.deleteStudentLists;
  }

  async archiveStudentLists({ lists, archived, organizationId }) {
    const mutation = `
      mutation ArchiveStudentLists(
        $archived: Boolean!,
        $lists: ListFilter!,
        $organizationId: ID!
      ) {
        archiveStudentLists(
          lists: $lists,
          archived: $archived,
          organizationId: $organizationId
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        archived,
        organizationId,
        lists: {
          ids: lists?.ids || null,
          search: lists?.filter?.search || null,
          isShared: lists?.filter?.isShared || null,
          archived: lists?.filter?.archived || null
        }
      }
    });

    return data?.deleteStudentLists;
  }

  async updateStudentList({
    name,
    ownerId,
    archived,
    studentListId,
    addSharedWith,
    organizationId,
    removeSharedWith,
    sharedWithOrganization
  }) {
    const mutation = `
      mutation UpdateStudentList(
        $ownerId: ID,
        $name: String,
        $archived: Boolean,
        $studentListId: ID!,
        $organizationId: ID!,
        $addSharedWith: [ID!],
        $removeSharedWith: [ID!],
        $sharedWithOrganization: Boolean
      ) {
        updateStudentList(
          name: $name,
          ownerId: $ownerId,
          archived: $archived,
          studentListId: $studentListId,
          addSharedWith: $addSharedWith,
          organizationId: $organizationId,
          removeSharedWith: $removeSharedWith,
          sharedWithOrganization: $sharedWithOrganization,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name,
        ownerId,
        archived,
        studentListId,
        addSharedWith,
        organizationId,
        removeSharedWith,
        sharedWithOrganization
      }
    });

    return data?.updateStudentList;
  }

  async addOrRemoveStudentsToList({
    addStudents,
    removeStudents,
    studentListIds,
    organizationId
  }) {
    const mutation = `
      mutation AddOrRemoveStudentsToList(
        $organizationId: ID!,
        $studentListIds: [ID!]!,
        $addStudents: OrganizationStudentFilter,
        $removeStudents: OrganizationStudentFilter,
      ) {
        addOrRemoveStudentsToList(
          addStudents: $addStudents,
          removeStudents: $removeStudents,
          studentListIds: $studentListIds,
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        studentListIds,
        organizationId,
        addStudents: addStudents
          ? {
              name: addStudents?.filter?.search || null,
              archived: addStudents.filter?.archived || null,
              noAwardLetter: addStudents?.filter?.noAwardLetter || null,
              affordability: addStudents?.filter?.affordability || null,
              noCollegeAdded: addStudents?.filter?.noCollegeAdded || null,
              enrollmentStatus: addStudents?.filter?.enrollmentStatus || null,
              studentIds: addStudents?.studentIds
                ? addStudents.studentIds
                : null,
              onlyStudentsFromList:
                addStudents?.filter?.onlyStudentsFromList || null,
              assignedAdvisor: addStudents?.filter?.assignedAdvisor
            }
          : null,
        removeStudents: removeStudents
          ? {
              name: removeStudents?.filter?.search || null,
              archived: removeStudents.filter?.archived || null,
              noAwardLetter: removeStudents?.filter?.noAwardLetter || null,
              affordability: removeStudents?.filter?.affordability || null,
              noCollegeAdded: removeStudents?.filter?.noCollegeAdded || null,
              enrollmentStatus:
                removeStudents?.filter?.enrollmentStatus || null,
              studentIds: removeStudents?.studentIds
                ? removeStudents.studentIds
                : null,
              onlyStudentsFromList:
                removeStudents?.filter?.onlyStudentsFromList || null,
              assignedAdvisor: removeStudents?.filter?.assignedAdvisor
            }
          : null
      }
    });

    return data?.updateStudentList;
  }

  async createAdvisorsList({
    name,
    ownerId,
    advisors,
    sharedWith,
    organizationId,
    sharedWithOrganization
  }) {
    const mutation = `
      mutation CreateAdvisorList(
        $ownerId: ID!,
        $name: String!,
        $sharedWith: [ID!],
        $organizationId: ID!,
        $sharedWithOrganization: Boolean!,
        $advisors: OrganizationAdvisorFilter,
      ) {
        createAdvisorList(
          name: $name,
          ownerId: $ownerId,
          advisors: $advisors,
          sharedWith: $sharedWith,
          organizationId: $organizationId,
          sharedWithOrganization: $sharedWithOrganization,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name,
        ownerId,
        sharedWith,
        organizationId,
        sharedWithOrganization,
        advisors: advisors
          ? {
              role: advisors?.filter?.role || null,
              search: advisors?.filter?.search || null,
              onlyAdvisorsFromList:
                advisors?.filter?.onlyAdvisorsFromList || null,
              advisorIds: advisors.advisorIds ? advisors.advisorIds : null
            }
          : null
      }
    });

    return data?.createAdvisorList;
  }

  async deleteAdvisorLists({ lists, organizationId }) {
    const mutation = `
      mutation DeleteAdvisorLists(
        $lists: ListFilter!,
        $organizationId: ID!
      ) {
        deleteAdvisorLists(
          lists: $lists,
          organizationId: $organizationId
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        lists: {
          ids: lists?.ids || null,
          search: lists?.filter?.search || null,
          isShared: lists?.filter?.isShared || null,
          archived: lists?.filter?.archived || null
        }
      }
    });

    return data?.deleteAdvisorLists;
  }

  async archiveAdvisorLists({ lists, archived, organizationId }) {
    const mutation = `
      mutation ArchiveAdvisorLists(
        $archived: Boolean!,
        $lists: ListFilter!,
        $organizationId: ID!
      ) {
        archiveAdvisorLists(
          lists: $lists,
          archived: $archived,
          organizationId: $organizationId
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        archived,
        organizationId,
        lists: {
          ids: lists?.ids || null,
          search: lists?.filter?.search || null,
          isShared: lists?.filter?.isShared || null,
          archived: lists?.filter?.archived || null
        }
      }
    });

    return data?.archiveAdvisorList;
  }

  async updateAdvisorList({
    name,
    ownerId,
    advisorListId,
    addSharedWith,
    organizationId,
    removeSharedWith,
    sharedWithOrganization
  }) {
    const mutation = `
      mutation UpdateAdvisorList(
        $ownerId: ID,
        $name: String,
        $advisorListId: ID!,
        $organizationId: ID!,
        $addSharedWith: [ID!],
        $removeSharedWith: [ID!],
        $sharedWithOrganization: Boolean!,
      ) {
        updateAdvisorList(
          name: $name,
          ownerId: $ownerId,
          advisorListId: $advisorListId,
          addSharedWith: $addSharedWith,
          organizationId: $organizationId,
          removeSharedWith: $removeSharedWith,
          sharedWithOrganization: $sharedWithOrganization,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name,
        ownerId,
        advisorListId,
        addSharedWith,
        organizationId,
        removeSharedWith,
        sharedWithOrganization
      }
    });

    return data?.updateAdvisorList;
  }

  async fetchAdminOrganizationAdvisors({
    page,
    filter,
    orderBy,
    organizationId
  }) {
    const query = `
     query FetchAdminOrganizationAdvisors(
       $page: Page,
       $organizationId: ID!,
       $filter: OrganizationAdvisorFilter,
       $orderBy: OrganizationAdvisorOrderBy,
     ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                organizationAdvisors (
                  page: $page,
                  filter: $filter
                  orderBy: $orderBy
                ) {
                  pageCount,
                  itemsCount,
                  items {
                    id,
                    role,
                    assignedStudentsCount
                    advisor {
                      email,
                      userId,
                      lastName,
                      firstName,
                      lastLogin,
                      lastLoginHumanize
                    }
                  }
                }
              }
            }
          }
        }
      }
   `;

    const [orderField] = Object.keys(orderBy);

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        orderBy: {
          [orderField]: orderBy[orderField] || Order.asc
        },
        filter,
        page
      },
      fetchPolicy: "network-only"
    });

    return data?.advisor.organizationAdvisors.items[0].organization
      .organizationAdvisors;
  }

  async acceptInvitation({ invitationId }) {
    const mutation = `
      mutation AcceptInvitation($invitationId: ID!){
        acceptInvitation(invitationId: $invitationId) {
          status
          organizationId
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        invitationId
      }
    });

    return data?.acceptInvitation;
  }

  async inviteStudents({ emails, organizationId, assignedAdvisorId }) {
    const mutation = `
      mutation InviteStudentToGroup(
        $emails: [String!]!,
        $organizationId: ID!,
        $assignedAdvisorId: ID
      ){
        inviteStudentToGroup(
          emails: $emails,
          organizationId: $organizationId,
          assignedAdvisorId: $assignedAdvisorId,
        ){
          invitationsSent,
          alreadyBelongToOrganization,
          invitationsAlreadyPending {
            id,
            recipient
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        emails,
        organizationId,
        assignedAdvisorId
      }
    });

    return data?.inviteStudentToGroup;
  }

  async inviteAdvisors({ role, emails, organizationId }) {
    const mutation = `
      mutation InviteAdvisors(
        $role: _AdvisorRole,
        $emails: [String!]!,
        $organizationId: ID!,
      ){
        inviteAdvisorToOrg(
          role: $role
          emails: $emails,
          organizationId: $organizationId,
        ){
          invitationsSent,
          alreadyBelongToOrg,
          invitationsAlreadyPending {
            id,
            recipient
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        role,
        emails,
        organizationId
      }
    });

    return data?.inviteAdvisorToOrg;
  }

  async updateAdvisorRole({ advisorIds, organizationId, role }) {
    const mutation = `
      mutation UpdateAdvisorRole(
        $advisors: OrganizationAdvisorFilter!,
        $organizationId: ID!,
        $role: _AdvisorRole!
      ){
        updateAdvisorRoleInOrg(
          advisors: $advisors,
          organizationId: $organizationId,
          role: $role
        ){
          ok
        }
      }
    `;

    await this.gqlQuery({
      query: mutation,
      variables: {
        advisors: {
          advisorIds: advisorIds,
          search: null,
          role: null,
          onlyAdvisorsFromList: null
        },
        organizationId,
        role
      }
    });
  }

  async removeAdvisorFromOrg({ advisorIds, organizationId }) {
    const mutation = `
      mutation RemoveAdvisorsFromOrg(
        $organizationId: ID!,
        $advisors: OrganizationAdvisorFilter!,
      ){
        removeAdvisorsFromOrg(
          advisors: $advisors,
          organizationId: $organizationId,
        ){
          ok
        }
      }
    `;
    await this.gqlQuery({
      query: mutation,
      variables: {
        advisors: {
          role: null,
          search: null,
          advisorIds: advisorIds,
          onlyAdvisorsFromList: null
        },
        organizationId
      }
    });
  }

  async addOrRemoveAdvisorsToList({
    addAdvisors,
    removeAdvisors,
    advisorListIds,
    organizationId
  }) {
    const mutation = `
      mutation AddOrRemoveAdvisorsToList(
        $organizationId: ID!,
        $advisorListIds: [ID!]!,
        $addAdvisors: OrganizationAdvisorFilter,
        $removeAdvisors: OrganizationAdvisorFilter,
      ) {
        addOrRemoveAdvisorsToList(
          addAdvisors: $addAdvisors,
          removeAdvisors: $removeAdvisors,
          advisorListIds: $advisorListIds,
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    await this.gqlQuery({
      query: mutation,
      variables: {
        advisorListIds,
        organizationId,
        addAdvisors: addAdvisors
          ? {
              advisorIds: addAdvisors?.advisorIds || null,
              search: addAdvisors?.filter?.search || null,
              onlyAdvisorsFromList:
                addAdvisors?.filter?.onlyAdvisorsFromList || null
            }
          : null,
        removeAdvisors: removeAdvisors
          ? {
              advisorIds: removeAdvisors?.advisorIds || null,
              search: removeAdvisors?.filter?.search || null,
              onlyAdvisorsFromList:
                removeAdvisors?.filter?.onlyAdvisorsFromList || null
            }
          : null
      }
    });
  }

  async deleteAdvisor() {
    const mutation = `
      mutation DeleteAdvisor {
        deleteAdvisor {
          ok
        }
      }
    `;
    await this.gqlQuery({
      query: mutation
    });
  }

  async removeStudentFromOrg({ students, organizationId }) {
    const mutation = `
      mutation RemoveStudentFromOrg(
        $organizationId: ID!,
        $students: OrganizationStudentFilter!
      ){
        removeStudentsFromOrg(
          students: $students,
          organizationId: $organizationId,
        ){
          ok
        }
      }`;
    await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        students: {
          name: students.filter?.search || null,
          archived: students.filter?.archived || null,
          noAwardLetter: students.filter?.noAwardLetter || null,
          affordability: students.filter?.affordability || null,
          noCollegeAdded: students.filter?.noCollegeAdded || null,
          enrollmentStatus: students.filter?.enrollmentStatus || null,
          studentIds: students.studentIds ? students.studentIds : null,
          onlyStudentsFromList: students.filter?.onlyStudentsFromList || null,
          assignedAdvisor: students.filter?.assignedAdvisor
        }
      }
    });
  }

  async archiveOrUnarchiveStudents({ archived, students, organizationId }) {
    const mutation = `
      mutation ArchiveOrUnarchiveStudents(
        $archived: Boolean!,
        $organizationId: ID!,
        $students: OrganizationStudentFilter!
      ){
        archiveOrUnarchiveStudents(
          archived: $archived,
          students: $students,
          organizationId: $organizationId,
        ){
          ok
        }
      }`;
    await this.gqlQuery({
      query: mutation,
      variables: {
        archived,
        organizationId,
        students: {
          name: students.filter?.search || null,
          archived: students.filter?.archived || null,
          noAwardLetter: students.filter?.noAwardLetter || null,
          affordability: students.filter?.affordability || null,
          noCollegeAdded: students.filter?.noCollegeAdded || null,
          enrollmentStatus: students.filter?.enrollmentStatus || null,
          studentIds: students.studentIds ? students.studentIds : null,
          onlyStudentsFromList: students.filter?.onlyStudentsFromList || null,
          assignedAdvisor: students.filter?.assignedAdvisor
        }
      }
    });
  }

  async fetchNotifications({ page, refetch }) {
    const query = `
      query FetchNotifications(
        $page: Page
      ) {
        notifications(page: $page) {
          newItemsCount
          itemsCount
          pageCount
          items {
            id
            type
            status
            content
            insertTime
          }
        }
      }
    `;

    const { data } = await this.gqlQuery(
      {
        query: query,
        variables: {
          page
        },
        fetchPolicy: "network-only"
      },
      refetch
    );

    return data?.notifications;
  }

  async readNotifications({ notificationIds }) {
    const mutation = `
      mutation ReadNotifications(
        $notificationIds: [ID!]
      ) {
        readNotification(
          notificationIds: $notificationIds
        ) {
          readCount
        }
      }`;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        notificationIds
      },
      fetchPolicy: "network-only"
    });
    return data?.readNotification;
  }

  async emailChangeRequest({ updateId }) {
    const query = `
      query EmailChangeRequest(
        $authorizationCode: ID!
      ) {
        emailChangeRequest(
          authorizationCode: $authorizationCode
        ) {
          oldEmail
          newEmail
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        authorizationCode: updateId
      }
    });

    return data?.emailChangeRequest;
  }

  async confirmEmailChange({ updateId }) {
    const mutation = `
      mutation ConfirmEmailChange(
        $authorizationCode: ID!
      ) {
        confirmEmailChange(
          authorizationCode: $authorizationCode
        ) {
          newEmail
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        authorizationCode: updateId
      }
    });

    return data?.confirmEmailChange;
  }

  async fetchAdvisorStudentDetail({ filter, organizationId }) {
    const query = `
      query FetchAdvisorStudentDetail(
        $organizationId: ID,
        $filter: OrganizationStudentFilter,
      ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                organizationStudents (filter: $filter) {
                  pageCount
                  itemsCount
                  items {
                    id,
                    student {
                      ${studentFields}
                      chosenSchools {
                        ${chosenSchoolsFields}
                        school {
                          ${chosenSchoolsSchoolFields}
                        }
                        schoolCosts {
                          ${chosenSchoolsSchoolCostsFields}
                        }
                        award {
                          id
                          downloadUrl
                          filePurged
                          ${chosenSchoolsAwardFields}
                          postAwardSchoolCosts {
                            ${chosenSchoolsAwardPostAwardSchoolCostsFields}
                          }
                          pendingError {
                            ${chosenSchoolsAwardPendingErrorFields}
                          }
                        }
                        selectedMajors {
                          code
                          name
                        }
                      }
                    }
                    assignedAdvisor {
                      id
                    }
                    includedStudents {
                      studentListName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        organizationId,
        filter: {
          studentIds: [filter?.studentId]
        }
      }
    });

    return data?.advisor.organizationAdvisors.items[0].organization
      .organizationStudents.items[0];
  }

  async fetchAdvisorStudentNotes({
    page,
    filter,
    orderBy,
    notesFilter,
    organizationId
  }) {
    const query = `
      query FetchAdvisorStudentNotes(
        $page: Page,
        $organizationId: ID,
        $orderBy: AdvisorNoteOrderBy,
        $notesFilter: AdvisorNoteFilters,
        $filter: OrganizationStudentFilter,
      ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                organizationStudents (filter: $filter) {
                  items {
                    notes(
                      page: $page,
                      orderBy: $orderBy,
                      filter: $notesFilter
                    ) {
                      pageCount,
                      itemsCount,
                      items {
                        text
                        shared
                        insertTime
                        advisorName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        orderBy,
        organizationId,
        filter: {
          studentIds: [filter?.studentId]
        },
        notesFilter: {
          onlyMyNotes: notesFilter?.onlyMyNotes,
          onlySharedNotes: notesFilter?.onlySharedNotes
        }
      }
    });

    return data?.advisor.organizationAdvisors.items[0].organization
      .organizationStudents.items[0].notes;
  }

  async organizationsToJoin() {
    const query = `
      query OrganizationsToJoin {
        organizationsToJoin {
          name
          id
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {}
    });

    return data.organizationsToJoin;
  }

  async myPendingInvitations() {
    const query = `
      query MyPendingInvitations {
        myPendingInvitations {
          id
          recipient
          subject
          body
          status
          insertTime
          organizationId
          sender {
            organization { 
              id
              name
            }
          }
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {}
    });

    return data.myPendingInvitations;
  }

  async createOrganization({ name }) {
    const mutation = `
      mutation CreateOrganization(
        $name: String!,
      ) {
        createOrganization(
          name: $name,
        ) {
          id
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        name
      }
    });
    return data?.createOrganization;
  }

  async joinOrganization({ organizationId }) {
    const mutation = `
      mutation JoinOrganization(
        $organizationId: Int!,
      ) {
        joinOrganization(
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId
      }
    });

    return data?.joinOrganization;
  }

  async updateAdvisorNotes({ text, studentId, shared, organizationId }) {
    const mutation = `
      mutation UpdateAdvisorStudentNotes(
        $organizationId: ID!,
        $shared: Boolean,
        $studentId: ID!,
        $text: String
      ) {
        updateAdvisorNotes(
          organizationId: $organizationId,
          studentId: $studentId,
          shared: $shared,
          text: $text
        ) {
          text
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        studentId,
        shared,
        text
      }
    });

    return data?.updateAdvisorNotes;
  }

  async notifyStudents({ message, students, organizationId }) {
    const mutation = `
      mutation NotifyStudents(
        $message: String!,
        $organizationId: ID!,
        $students: OrganizationStudentFilter!
      ) {
        notifyStudents(
          message: $message,
          students: $students,
          organizationId: $organizationId,
        ) {
          ok
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        message,
        organizationId,
        students: {
          name: students.filter?.search || null,
          archived: students.filter?.archived || null,
          noAwardLetter: students.filter?.noAwardLetter || null,
          affordability: students.filter?.affordability || null,
          noCollegeAdded: students.filter?.noCollegeAdded || null,
          enrollmentStatus: students.filter?.enrollmentStatus || null,
          studentIds: students.studentIds ? students.studentIds : null,
          onlyStudentsFromList: students.filter?.onlyStudentsFromList || null,
          assignedAdvisor: students.filter?.assignedAdvisor
        }
      }
    });

    return data?.notifyStudents;
  }

  async fetchStudentInvites({ page, filter, orderBy, organizationId }) {
    const query = `
     query FetchStudentInvites(
       $page: Page,
       $organizationId: ID,
       $orderBy: InvitationOrderBy,
       $filter: StudentInvitationFilter,
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              organization {
                studentInvitations(
                  page: $page,
                  filter: $filter,
                  orderBy: $orderBy
                ) {
                  pageCount,
                  itemsCount,
                  items {
                    id,
                    status,
                    recipient,
                    insertTime,
                    assignedAdvisor,
                    sender {
                      id,
                      advisor {
                        firstName,
                        lastName,
                      }
                    }
                  },
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.advisor.organizationAdvisors.items[0].organization
      .studentInvitations;
  }

  async resendStudentInvites({ invitations, organizationId }) {
    const mutation = `
      mutation ResendStudentInvitations(
        $organizationId: ID!,
        $invitations: StudentInvitationFilter!,
      ) {
        resendStudentInvitations(
          invitations: $invitations,
          organizationId: $organizationId,
        ) {
          invitationsSent,
          alreadyBelongToOrganization
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        invitations: {
          status: invitations?.filter?.status || null,
          ids: invitations?.ids ? invitations.ids : null,
          recipient: invitations?.filter?.recipient || null,
          assignedAdvisor: invitations?.filter?.assignedAdvisor || null
        }
      }
    });

    return data?.resendStudentInvitations;
  }

  async deleteStudentInvites({ invitations, organizationId }) {
    const mutation = `
      mutation DeleteStudentInvitations(
        $organizationId: ID!,
        $invitations: StudentInvitationFilter!,
      ) {
        deleteStudentInvitations(
          invitations: $invitations,
          organizationId: $organizationId,
        ) {
          deletedCount,
          nonDeletedInvitations
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        invitations: {
          status: invitations?.filter?.status || null,
          ids: invitations?.ids ? invitations.ids : null,
          recipient: invitations?.filter?.recipient || null,
          assignedAdvisor: invitations?.filter?.assignedAdvisor || null,
          senders: invitations?.filter?.senders || null
        }
      }
    });

    return data?.deleteStudentInvitations;
  }

  async fetchAdvisorInvites({ page, filter, orderBy, organizationId }) {
    const query = `
     query FetchAdvisorInvites(
       $page: Page,
       $organizationId: ID,
       $orderBy: AdvisorInvitationOrderBy,
       $filter: AdvisorInvitationFilter,
      ) {
        advisor {
          organizationAdvisors(organizationId: $organizationId) {
            items {
              organization {
                advisorInvitations(
                  page: $page,
                  filter: $filter,
                  orderBy: $orderBy
                ) {
                  pageCount,
                  itemsCount,
                  items {
                    id,
                    status,
                    recipient,
                    insertTime,
                    role,
                    sender {
                      id,
                      advisor {
                        firstName,
                        lastName,
                      }
                    }
                  },
                }
              }
            }
          }
        }
      }
   `;

    const { data } = await this.gqlQuery({
      query: query,
      variables: {
        page,
        filter,
        orderBy,
        organizationId
      }
    });

    return data?.advisor.organizationAdvisors.items[0].organization
      .advisorInvitations;
  }

  async resendAdvisorInvites({ invitations, organizationId }) {
    const mutation = `
      mutation ResendAdvisorInvitations(
        $organizationId: ID!,
        $invitations: AdvisorInvitationFilter!,
      ) {
        resendAdvisorInvitations(
          invitations: $invitations,
          organizationId: $organizationId,
        ) {
          invitationsSent
          alreadyBelongToOrg
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        invitations: {
          role: invitations?.filter?.role || null,
          status: invitations?.filter?.status || null,
          ids: invitations?.ids ? invitations.ids : null,
          recipient: invitations?.filter?.recipient || null
        }
      }
    });

    return data?.resendAdvisorInvitations;
  }

  async deleteAdvisorInvites({ invitations, organizationId }) {
    const mutation = `
      mutation DeleteAdvisorInvitations(
        $organizationId: ID!,
        $invitations: AdvisorInvitationFilter!,
      ) {
        deleteAdvisorInvitations(
          invitations: $invitations,
          organizationId: $organizationId,
        ) {
          deletedCount,
          nonDeletedInvitations
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        invitations: {
          role: invitations?.filter?.role || null,
          status: invitations?.filter?.status || null,
          ids: invitations?.ids ? invitations.ids : null,
          recipient: invitations?.filter?.recipient || null,
          senders: invitations?.filter?.senders || null
        }
      }
    });

    return data?.deleteAdvisorInvitations;
  }

  async updateOrganization({ organizationId, name }) {
    const mutation = `
      mutation UpdateOrganization(
        $organizationId: ID!,
        $name: String!,
      ) {
        updateOrganization(
          name: $name,
          organizationId: $organizationId,
        ) {
          name
        }
      }
    `;

    const { data } = await this.gqlQuery({
      query: mutation,
      variables: {
        organizationId,
        name
      }
    });

    return data?.updateOrganization;
  }

  uploadFile(uploadUrl, file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("put", uploadUrl);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(file);
    });
  }

  async uploadAward({ file, pairedId, organizationId, livingChoice }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation AdvisorUploadAward(
          $input: UploadAwardInput!,
          $organizationId: ID!
        ) {
          uploadAward(input: $input, organizationId: $organizationId) {
            url
          }
        }
      `,
      variables: {
        organizationId,
        input: {
          pairedId,
          filename: file.name,
          livingChoice
        }
      }
    });

    await this.uploadFile(data.uploadAward.url, file);
  }

  waitForAward({
    pairedId,
    retryInterval,
    maxRetries,
    filter,
    organizationId
  }) {
    const fetchAwardStatus = async ({ pairedId }) => {
      const { data } = await this.gqlQuery({
        query: `
        query AdvisorWaitForLetter(
        $filter: OrganizationStudentFilter,
        $organizationId: ID!,
      ) {
        advisor {
          organizationAdvisors (organizationId: $organizationId) {
            items {
              organization {
                organizationStudents (
                  filter: $filter,
                ) {
                  items {
                    student {
                      chosenSchools {
                        pairedId
                        award {
                          status
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
        `,
        variables: {
          organizationId,
          filter: {
            name: filter?.search || null,
            archived: filter?.archived || null,
            noAwardLetter: filter?.noAwardLetter || null,
            affordability: filter?.affordability || null,
            noCollegeAdded: filter?.noCollegeAdded || null,
            assignedAdvisor: filter?.assignedAdvisor || null,
            enrollmentStatus: filter?.enrollmentStatus || null,
            onlyStudentsFromList: filter?.onlyStudentsFromList || null,
            studentIds: filter?.studentId ? [filter?.studentId] : null
          }
        }
      });

      const chosenSchool =
        data.advisor?.organizationAdvisors?.items?.[0]?.organization?.organizationStudents?.items?.[0]?.student?.chosenSchools?.find(
          (school) => school.pairedId === pairedId
        );
      return chosenSchool?.award?.status;
    };

    return new Promise((resolve, reject) => {
      let count = 0;
      const id = setInterval(() => {
        console.log(`Checking AwardLetter status... (${count})`);
        if (count <= maxRetries) {
          count += 1;

          fetchAwardStatus({ pairedId })
            .then((status) => {
              switch (status) {
                case AwardStatus.READY:
                case AwardStatus.MANUAL_REVIEW:
                  clearInterval(id);
                  resolve(status);
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              clearInterval(id);
              reject(error);
            });
        } else {
          clearInterval(id);
          reject(
            new Error({
              type: "Timeout"
            })
          );
        }
      }, retryInterval);
    });
  }

  async createAwardLetterErrorReport({ reportInput, organizationId }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation CreateAwardLetterErrorReport (
          $input: CreateAwardLetterErrorReportInput!
          $organizationId: ID!
        ) {
          createAwardLetterErrorReport(input: $input, organizationId: $organizationId) {
            status
            description
          }
        }
      `,
      variables: {
        organizationId,
        input: {
          awardId: reportInput.awardId,
          errorType: reportInput.errorType,
          description: reportInput.description
        }
      }
    });

    return data.createAwardLetterErrorReport;
  }

  async editAwardLetterResults({
    letterId,
    addAidAmounts,
    removeAidAmounts,
    organizationId
  }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation StudentEditAwardLetterResult (
          $letterId: ID!,
          $removeAidAmounts: [ID!],
          $addAidAmounts: [RawAidAmountType!],
          $organizationId: ID!
        ) {
          editAwardLetterResult(
            letterId: $letterId,
            addAidAmounts: $addAidAmounts,
            removeAidAmounts: $removeAidAmounts,
            organizationId: $organizationId,
          ) {
            ${chosenSchoolsAwardFields}
          }
        }
      `,
      variables: {
        letterId,
        addAidAmounts,
        removeAidAmounts,
        organizationId
      }
    });
    return data?.editAwardLetterResult;
  }

  async dismissArchiveStudentsPopup({ organizationId }) {
    const { data } = await this.gqlQuery({
      query: `
        mutation DismissArchiveStudentsPopup (
          $organizationId: ID!
        ) {
          dismissArchiveStudentsPopup(organizationId: $organizationId) {
            ok
          }
        }
      `,
      variables: {
        organizationId
      }
    });

    return data.dismissArchiveStudentsPopup;
  }
}

const Api = new DecidedApi();

export default Api;

export { DecidedApi, ErrorTypes };
