import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { LinkCustom } from "../../core/";
import { Close } from "../../core/icons/";
import { Button } from "../../core/buttons";
import { decidedRoute } from "../../core/utils";

function NavigationMenu({ open, onClose }) {
  const navigate = useNavigate();

  const logOutHandler = () => navigate("/logout");

  return (
    <nav
      className="
        p-4  
        flex
        w-full
        h-full
        flex-col
        shadow-md
        overflow-auto
        bg-primary-blue
      "
    >
      <div
        className="
          mb-6
          flex
          flex-row
          relative
          text-4xl
          font-bold
          text-white
          items-center
          justify-center
        "
      >
        <div
          role="button"
          onClick={onClose}
          tabIndex={!open ? -1 : null}
          id="button-close-navigation-menu"
        >
          <Close color="stroke-white" />
        </div>
        <span className="mx-auto">Menu</span>
      </div>
      <div
        className="
          flex
          flex-1
          flex-col
          text-2xl
          divide-y-2
          text-white
          justify-center
          divide-white
        "
      >
        <div>
          <LinkCustom
            target="_blank"
            href={decidedRoute("/guides")}
            id="navigation-menu-button-guides"
            className="
              py-2
              px-5
              w-full
              font-extrabold
              hover:text-primary-blue
            "
          >
            <p className="text-center">Guides</p>
          </LinkCustom>
        </div>
        <div>
          <LinkCustom
            target="_blank"
            href={decidedRoute("/help")}
            id="navigation-menu-button-help"
            className="
              py-2
              px-5
              w-full
              text-center
              font-extrabold
              hover:text-primary-blue
            "
          >
            <p className="text-center">Help</p>
          </LinkCustom>
        </div>
      </div>

      <Button
        onClick={logOutHandler}
        tabIndex={!open ? -1 : null}
        className="
          bg-white
          border-white
          text-primary-blue
          active:text-white
          active:bg-primary-blue
        "
      >
        Sign Out
      </Button>
    </nav>
  );
}

NavigationMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default NavigationMenu;
