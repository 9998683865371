import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { getOrderIcon } from "../../../../Common/utils";
import { Order, toggleOrder } from "../../../../../utils/order";

function EmailHeader({ onSort = () => {}, clearSort }) {
  const [order, setOrder] = useState(Order.asc);

  useEffect(() => {
    if (clearSort) setOrder("");
  }, [clearSort]);

  return (
    <div
      role="button"
      id="students-sort-by-email-button"
      className="flex flex-row items-center"
      onClick={() => {
        const newOrder = toggleOrder(order);
        setOrder(newOrder);
        onSort(newOrder);
      }}
    >
      <span className="text-sm">Student Email</span>
      {getOrderIcon(order)}
    </div>
  );
}

EmailHeader.propTypes = {
  onSort: PropTypes.func,
  clearSort: PropTypes.bool
};

export default EmailHeader;
