import { useEffect, useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Confirm from "../Common/Confirm";

import NotesShare from "./NotesShare";
import RichTextEditor from "./RichTextEditor";
import NotesTabs, { NoteTabs } from "./NotesTabs";

import { Toggle } from "../../../core/inputs";
import { LoadingButton } from "../../../core/buttons";
import { Colors } from "../../../core/utils";
import "../../../core/styles/markdown.css";

import Api from "../../../services/Api";
import { Order } from "../../../utils/order";
import { interactionEventDataLayer } from "../../../utils/DataLayers";

function NotesModal({
  userId,
  onClose,
  studentId,
  studentName,
  organizationId,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [shared, setShared] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [doFetch, setDoFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(Order.asc);
  const [currentPage, setCurrentPage] = useState(1);
  const [editorState, setEditorState] = useState(null);
  const [activeTab, setActiveTab] = useState(NoteTabs.MY_NOTES);
  const [notesData, setNotesData] = useState({
    items: [],
    pageCount: 0,
    itemsCount: 0,
    isLoading: false,
  });

  const fecthNotes = async ({ page, orderBy, notesFilter }) => {
    try {
      return await Api.fetchAdvisorStudentNotes({
        page,
        orderBy,
        notesFilter,
        organizationId,
        filter: { studentId },
      });
    } catch (error) {
      console.log(error);
    }

    return {};
  };

  useEffect(() => {
    const fetch = async () => {
      setConfirmModal(true);
      const { items: notes } = await fecthNotes({
        notesFilter: { onlyMyNotes: true },
      });

      if (notes.length > 0) {
        const shared = notes[0].shared;
        const markdownString = notes[0].text;
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);

        setEditorState(newEditorState);
        setShared(shared);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchShareNotes = async () => {
      setNotesData((notesData) => ({ ...notesData, isLoading: true }));

      const notes = await fecthNotes({
        orderBy: { advisorName: orderBy },
        notesFilter: { onlySharedNotes: true },
        page: {
          pageSize: 10,
          pageNumber: currentPage,
        },
      });

      if (currentPage === 1) {
        setNotesData({
          isLoading: false,
          items: notes.items || [],
          pageCount: notes.pageCount || 0,
          itemsCount: notes.itemsCount || 0,
        });
      } else {
        setNotesData((notesData) => ({
          ...notesData,
          isLoading: false,
          items: notesData.items.concat(notes.items || []),
        }));
      }
      setDoFetch(false);
    };

    if (doFetch) fetchShareNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch]);

  useEffect(() => {
    if (requestedClose) setSave(true);
  }, [requestedClose]);

  const onSubmit = async () => {
    if (!updated) return;

    let markdownString = "";
    if (editorState) {
      const content = editorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      markdownString = draftToMarkdown(rawObject);
    }

    setIsLoading(true);
    await Api.updateAdvisorNotes({
      shared,
      studentId,
      organizationId,
      text: markdownString,
    });
    setIsLoading(false);
    setConfirmModal(true);
    setOnRequestClose(false);
    onClose();
  };

  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setConfirmModal(false);
    setEditorState(editorState);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const saveChanges = async () => {
    interactionEventDataLayer({
      eventName: "save_notes_button",
      params: { user_id: userId },
    });
    await onSubmit();
  };

  const toggleTabs = (tab) => {
    if (tab === NoteTabs.ALL_NOTES && notesData.items.length === 0)
      setDoFetch(true);
    setActiveTab(tab);
  };

  const toggleOrderBy = (order) => {
    setNotesData((notesData) => ({ ...notesData, items: [] }));
    setCurrentPage(1);
    setOrderBy(order);
    setDoFetch(true);
  };

  const handlerPagination = (isIntersecting) => {
    if (
      !doFetch &&
      isIntersecting &&
      !notesData.isLoading &&
      currentPage <= notesData.pageCount + 1 &&
      notesData.items.length < notesData.itemsCount
    ) {
      setDoFetch(() => {
        setCurrentPage(currentPage + 1);
        return true;
      });
    }
  };

  return (
    <>
      {!save && (
        <>
          <div
            className="
              mt-2
              flex
              flex-1
              border
              flex-col
              border-black
              overflow-y-hidden
            "
          >
            <div>
              <NotesTabs
                activeTab={activeTab}
                studentName={studentName}
                setActiveTab={toggleTabs}
              />
            </div>
            <div className="flex flex-1 flex-col overflow-hidden">
              {activeTab === NoteTabs.MY_NOTES && (
                <RichTextEditor
                  editorState={editorState}
                  studentName={studentName}
                  onEditorStateChange={onEditorStateChange}
                />
              )}
              {activeTab === NoteTabs.ALL_NOTES && (
                <NotesShare
                  order={orderBy}
                  notes={notesData.items}
                  toggleOrder={toggleOrderBy}
                  handlerPagination={handlerPagination}
                />
              )}
            </div>
          </div>
          {activeTab === NoteTabs.MY_NOTES && (
            <div
              className="
                pt-5
                flex
                flex-row
                bottom-0
                space-x-1
                mobile:flex-col
                mobile:space-x-0
                mobile-sm:flex-col
                mobile-sm:space-x-0
              "
            >
              <div
                className="
                  flex
                  w-1/2
                  flex-1
                  flex-row
                  mobile:mb-2
                  items-center
                  justify-center
                  text-gray-500
                  mobile:w-full
                  mobile-sm:mb-2
                  mobile-sm:w-full
                "
              >
                <div className="flex flex-1 flex-row text-sm">
                  Make these notes public to other advisors in your
                  organization?
                </div>
                <div>
                  <Toggle
                    value={shared}
                    checked={shared}
                    color={Colors.BLUE}
                    onChange={(event) => {
                      setUpdated(true);
                      setConfirmModal(false);
                      setShared(event.target.checked);
                    }}
                  />
                </div>
              </div>
              <LoadingButton
                disabled={!updated}
                isLoading={isLoading}
                onClick={saveChanges}
                className={`
                  w-1/2
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                  mobile:w-full
                  mobile:text-sm
                  mobile-sm:w-full
                  mobile-sm:text-sm
                  tablet-sm:text-sm
                  ${activeTab !== NoteTabs.MY_NOTES && "ml-auto"}
                `}
              >
                save notes
              </LoadingButton>
            </div>
          )}
        </>
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </>
  );
}

export default NotesModal;
