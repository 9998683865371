import PropTypes from "prop-types";

function EmailCell({ email }) {
  return (
    <div className="flex flex-row select-all items-center overflow-hidden">
      <p className="text-sm truncate font-bold">{email}</p>
    </div>
  );
}

EmailCell.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailCell;
