import { useEffect, useState, useRef } from "react";

import { Logo } from "../../core/icons";
import { Button } from "../../core/buttons";
import { RocksPattern } from "../../core/icons/patterns";

import Api from "../../services/Api";
import { getResultUrl } from "../../core/utils";

function VerifyAccount() {
  const isInitialMount = useRef(true);
  const [linkRequested, setLinkRequested] = useState(false);

  const requestNewLink = () => {
    Api.newVerifyLink(getResultUrl());
    setLinkRequested(true);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      Api.newVerifyLink(getResultUrl());
      isInitialMount.current = false;
    }
  }, []);

  return (
    <>
      <div className="flex py-12 flex-1 flex-col items-center relative">
        <div className="z-10 bg-white">
          <Logo size={100} />
        </div>
        <div className="z-10 mx-10 flex flex-row justify-center">
          <div
            className="
              flex
              flex-col
              space-y-4
              items-center
              justify-center
            "
          >
            <p
              className="
                text-4xl
                capitalize
                text-center
                text-black
                font-extrabold
                mobile:text-2xl
                tablet-sm:text-3xl
              "
            >
              Welcome!
            </p>
            <div
              className="
                w-3/4
                space-y-2
                mobile:w-full
                mobile-sm:w-full
                tablet-sm:w-full
              "
            >
              <p className="text-xl font-bold text-black text-center">
                Please check your email to verify your account.
              </p>
              <p className="text-xl font-bold text-black text-center">
                Don't see the email?
              </p>
            </div>
          </div>
        </div>
        <Button
          disabled={linkRequested}
          id="request-new-link-button"
          onClick={() => requestNewLink()}
          className="
            mt-5
            z-10
            px-20
            w-1/2
            normal-case
            self-center
            text-white
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:w-full
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            hover:text-primary-blue
          "
        >
          Request a New Link
        </Button>
      </div>
      <p className="text-md text-black text-center z-10 relative">
        Contact us at{" "}
        <span className="text-black font-bold">support@decided.org</span> if you
        are having trouble logging in.
      </p>
      <div className="-top-14 -left-14 absolute z-0">
        <RocksPattern color="fill-primary-blue" />
      </div>
      <div className="mobile:hidden tablet:hidden mobile-sm:hidden tablet-sm:hidden -bottom-14 -right-14 absolute z-0">
        <RocksPattern
          color="fill-primary-blue"
          className="transform rotate-180"
        />
      </div>
    </>
  );
}

export default VerifyAccount;
