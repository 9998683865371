import { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import GrantList from "./GrantList";
import CollegeCosts from "./CollegeCosts";

import { Panel } from "../";
import { Colors, LivingChoice, AwardStatus } from "../../utils";

function housingTitle(livingChoice) {
  switch (livingChoice) {
    case LivingChoice.OFF_CAMPUS:
      return "Off-Campus Housing and Meals";
    case LivingChoice.ON_CAMPUS:
      return "On-Campus Housing and Meals";
    case LivingChoice.WITH_FAMILY:
      return "Housing and Meals with family";
    default:
      return "Housing and Meals";
  }
}

function tuitionTitle(studentState, collegeState) {
  if (Object.is(studentState, collegeState)) {
    return "In-State Tuition & Fees";
  } else {
    return "Out of State Tuition & Fees";
  }
}

function FirstYearBreakdown({
  color,
  colors,
  tooltip,
  isDesktop,
  studentState,
  chosenSchool,
  gaOnClickFunc,
  handleUpdateAward = () => {},
  tooltipCostClassName,
  tooltipBudgetClassName,
  tooltipGrantsClassName
}) {
  const loader = useRef(null);
  const { award, schoolCosts } = chosenSchool;
  const successfulAward = award?.status === AwardStatus.READY;

  const errorPending = award?.pendingError?.errorType;

  const {
    tuitionAndFees,
    tuitionAndFeesYear,
    housingAndMeals,
    housingAndMealsYear,
    booksAndSupplies,
    booksAndSuppliesYear,
    transportation,
    transportationYear,
    personalExpenses,
    personalExpensesYear
  } = schoolCosts;

  const newAidAmount = award?.aidAmounts.some((aidAmount) => aidAmount.isNew);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) =>
        handleUpdateAward(newAidAmount ? entry.isIntersecting : false),
      {
        root: null,
        threshold: 1,
        rootMargin: "0px"
      }
    );

    if (loader.current) observer.observe(loader.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      <Panel
        tooltip={tooltip}
        id="college-costs"
        tooltipColor={color}
        isDesktop={isDesktop}
        tooltipClassName={tooltipCostClassName}
        gaOnClickFunc={gaOnClickFunc}
        className="
          py-2
          px-4
          my-4
          bg-white
          mobile:shadow-lg
          mobile-sm:shadow-lg
          tablet-sm:shadow-lg
        "
        titleClassName="
          text-xs
          capitalize
          font-semibold
          tablet:text-base
          laptop:text-base
          desktop:text-base
        "
        title={`$${schoolCosts.totalCollegeCosts.toLocaleString(
          "en-US"
        )} college costs`}
        tooltipLabel="College costs include estimated tuition & fees + housing and meals (if you live on campus)"
      >
        <Panel
          titleClassName="flex flex-1 text-sm font-semibold"
          className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
          classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
          title={`$${tuitionAndFees.toLocaleString("en-US")} ${tuitionTitle(
            studentState,
            chosenSchool.school.state
          )}`}
          expandableContent={<CollegeCosts year={tuitionAndFeesYear} />}
        />
        {chosenSchool?.livingChoice === LivingChoice.ON_CAMPUS && (
          <Panel
            titleClassName="flex flex-1 text-sm font-semibold"
            className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
            classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
            title={`$${housingAndMeals.toLocaleString("en-US")} ${housingTitle(
              chosenSchool.livingChoice
            )}`}
            expandableContent={<CollegeCosts year={housingAndMealsYear} />}
          />
        )}
      </Panel>
      <Panel
        id="life-budget"
        tooltip={tooltip}
        tooltipColor={color}
        isDesktop={isDesktop}
        gaOnClickFunc={gaOnClickFunc}
        tooltipClassName={tooltipBudgetClassName}
        title={`$${schoolCosts.lifeBudget.toLocaleString("en-US")} Life budget`}
        className="
          py-2
          px-4
          my-4
          bg-white
          mobile:shadow-lg
          mobile-sm:shadow-lg
          tablet-sm:shadow-lg
        "
        titleClassName="
          text-xs
          capitalize
          font-semibold
          tablet:text-base
          laptop:text-base
          desktop:text-base
        "
        tooltipLabel="Think of this as any other costs, such as commuting or eating out. Remember this is an estimate. This will be higher if you live off campus."
      >
        {chosenSchool?.livingChoice !== LivingChoice.ON_CAMPUS && (
          <Panel
            titleClassName="flex flex-1 text-sm font-semibold"
            className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
            classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
            title={`$${housingAndMeals.toLocaleString("en-US")} ${housingTitle(
              chosenSchool.livingChoice
            )}`}
            expandableContent={<CollegeCosts year={housingAndMealsYear} />}
          />
        )}
        <Panel
          title={`$${booksAndSupplies.toLocaleString(
            "en-US"
          )} Textbooks & Supplies`}
          titleClassName="flex flex-1 text-sm font-semibold"
          className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
          classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
          expandableContent={<CollegeCosts year={booksAndSuppliesYear} />}
        />
        <Panel
          titleClassName="flex flex-1 text-sm font-semibold"
          title={`$${transportation.toLocaleString("en-US")} Transportation`}
          className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
          classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
          expandableContent={<CollegeCosts year={transportationYear} />}
        />
        <Panel
          titleClassName="flex flex-1 text-sm font-semibold"
          className={`p-3 my-3 border border-black ${colors.secondary.bg}`}
          classNameClose="
            p-3
            my-3
            border
            bg-white
            border-black
          "
          title={`$${personalExpenses.toLocaleString(
            "en-US"
          )} Personal Expenses`}
          expandableContent={<CollegeCosts year={personalExpensesYear} />}
        />
      </Panel>

      {(successfulAward || errorPending) && (
        <Panel
          tooltip={tooltip}
          tooltipTitle={true}
          tooltipColor={color}
          isDesktop={isDesktop}
          id="grants-scholarships"
          gaOnClickFunc={gaOnClickFunc}
          tooltipClassName={tooltipGrantsClassName}
          tooltipLabel="This is free money to help you pay for college!"
          className="
            py-2
            px-4
            my-4
            bg-white
            mobile:shadow-lg
            mobile-sm:shadow-lg
            tablet-sm:shadow-lg
          "
          titleClassName="
            text-xs
            capitalize
            font-semibold
            tablet:text-base
            laptop:text-base
            desktop:text-base
          "
          title={`$${award.postAwardSchoolCosts.totalAward.toLocaleString(
            "en-US"
          )} Grants & Scholarships`}
        >
          {errorPending && (
            <p
              className="
                px-4
                py-2
                text-xs
                laptop:text-base
                desktop:text-base
                bg-alternative-yellow
              "
            >
              Error report submitted. Final results under review.
            </p>
          )}
          {GrantList({ colors, aidAmounts: award?.aidAmounts })}
          <div ref={loader} />
        </Panel>
      )}
    </>
  );
}

FirstYearBreakdown.propTypes = {
  tooltip: PropTypes.bool,
  colors: PropTypes.shape(),
  isDesktop: PropTypes.bool,
  studentState: PropTypes.string,
  chosenSchool: PropTypes.shape(),
  handleUpdateAward: PropTypes.func,
  tooltipCostClassName: PropTypes.string,
  tooltipBudgetClassName: PropTypes.string,
  tooltipGrantsClassName: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default FirstYearBreakdown;
