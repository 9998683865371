import Types from "./types";

const INITIAL_STATE = {
  error: null,
  profile: null,
  isLoading: false,
  isLoggedIn: false,
};

export const advisorReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Types.SET_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    default:
      return state;
  }
};
