import PropTypes from "prop-types";

import { Button } from "../../../../../core/buttons";

function Buttons({ onNo, onYes }) {
  return (
    <>
      <Button
        onClick={onYes}
        className="
          w-full
          text-white
          bg-primary-blue
          border-primary-blue
          hover:bg-white
          active:bg-white
          hover:text-primary-blue
          active:text-primary-blue
        "
      >
        yes, let's go!
      </Button>
      <Button
        onClick={onNo}
        className="
          w-full
          bg-white
          text-primary-blue
          border-primary-blue
          hover:text-white
          active:text-white
          hover:bg-primary-blue
          active:bg-primary-blue
        "
      >
        no, i need some help
      </Button>
    </>
  );
}

Buttons.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
};

export default Buttons;
