import PropTypes from "prop-types";

function TypeCell({ listType }) {
  return (
    <div className="text-center">
      <p className="text-sm font-bold capitalize">{listType}</p>
    </div>
  );
}

TypeCell.propTypes = {
  listType: PropTypes.string,
};

export default TypeCell;
