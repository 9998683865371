import PropTypes from "prop-types";

function StudentsCell({ students }) {
  return (
    <div className="text-center">
      <p className="text-sm font-bold capitalize">{students}</p>
    </div>
  );
}

StudentsCell.propTypes = {
  students: PropTypes.number,
};

export default StudentsCell;
