import PropTypes from "prop-types";

function Content({ firstText, secondText, disableTopMargin = false }) {
  return (
    <div className="pr-20 mobile:pr-0">
      <div
        className={`
          pt-4
          text-3xl
          font-black
          leading-10
          text-primary-blue
          ${!disableTopMargin && "mt-12 mobile:mt-6 mobile-sm:mt-5"}
        `}
      >
        {firstText}
      </div>
      {secondText && (
        <div
          className="
            mb-4
            mt-10
            text-3xl
            leading-10
            text-black
            font-semibold
            mobile:mt-5
            mobile-sm:mt-4
          "
        >
          {secondText}
        </div>
      )}
    </div>
  );
}

Content.propTypes = {
  firstText: PropTypes.any,
  secondText: PropTypes.any,
};

export default Content;
