import PropTypes from "prop-types";

import { Button } from "../../../../core/buttons";

import { ModalTypes } from "../Utils";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function NotesButton({
  userId,
  openModal,
  studentId,
  studentName,
  organizationId,
}) {
  return (
    <Button
      className="
        flex
        flex-1
        flex-row
        bg-white
        items-center
        overflow-hidden
        text-primary-blue
        border-primary-blue
        hover:text-white
        active:text-white
        hover:bg-primary-blue
        active:bg-primary-blue
      "
      onClick={() => {
        interactionEventDataLayer({
          eventName: "student_notes_button",
          params: { user_id: userId },
        });
        openModal(
          {
            userId,
            studentId,
            studentName,
            organizationId,
          },
          ModalTypes.NOTES_STUDENT
        );
      }}
    >
      <span className="w-full text-sm capitalize font-semibold">
        {`my notes for ${studentName}`}
      </span>
    </Button>
  );
}

NotesButton.propTypes = {
  userId: PropTypes.string,
  openModal: PropTypes.func,
  studentId: PropTypes.string,
  studentName: PropTypes.string,
  organizationId: PropTypes.string,
};

export default NotesButton;
