import PropTypes from "prop-types";

import Header, { SimpleHeader } from "./Header";
import Footer from "./Footer";

function LayoutManager({
  children,
  logoWhite,
  showHeader = true,
  showFooter = true,
  isHeaderSimple
}) {
  return (
    <main>
      {showHeader && (
        <>
          {isHeaderSimple ? <SimpleHeader logoWhite={logoWhite} /> : <Header />}
        </>
      )}
      {children}
      {showFooter && <Footer />}
    </main>
  );
}

LayoutManager.propTypes = {
  children: PropTypes.any,
  logoWhite: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  isHeaderSimple: PropTypes.bool
};

export default LayoutManager;
