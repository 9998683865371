import { combineReducers } from "redux";

import { tableReducer } from "./Table/reducer";
import { advisorReducer } from "./Advisor/reducer";
import { studentsReducer } from "./Students/reducer";
import { advisorsReducer } from "./Advisors/reducer";
import { permissionsReducer } from "./Permissions/reducer";
import { organizationsReducer } from "./Organizations/reducer";
import { notificationsReducer } from "./Notifications/reducer";
import { studentInvitesReducer } from "./StudentInvites/reducer";
import { advisorInvitesReducer } from "./AdvisorInvites/reducer";
import { archivedStudentListsReducer } from "./ArchivedStudentLists/reducer";
import { archivedAdvisorListsReducer } from "./ArchivedAdvisorLists/reducer";

const rootReducer = combineReducers({
  table: tableReducer,
  advisor: advisorReducer,
  students: studentsReducer,
  advisors: advisorsReducer,
  permissions: permissionsReducer,
  organizations: organizationsReducer,
  notifications: notificationsReducer,
  studentInvites: studentInvitesReducer,
  advisorInvites: advisorInvitesReducer,
  archivedStudentLists: archivedStudentListsReducer,
  archivedAdvisorLists: archivedAdvisorListsReducer,
});

export default rootReducer;
