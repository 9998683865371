// import { useMemo } from "react";

// import AdvisorTable from "../../Advisor/Table";

// import { Advisors } from "../MockData";
// import { getColumns as getColumnsMobile } from "../../Advisor/Table/Mobile/columns";
// import { getColumns as getColumnsDesktop } from "../../Advisor/Table/Desktop/columns";

function AdvisorsScreenOnboarding() {
  // const advisors = Advisors.map((item) => ({
  //   item: item,
  //   advisor: {
  //     advisorId: item.advisorId,
  //     firstName: item.firstName,
  //     lastName: item.lastName,
  //     email: item.email,
  //     role: item.organizations.items[0].role,
  //   },
  //   students: item.organizations.items[0].students.itemsCount,
  //   groups: item.organizations.items[0].groups.items.map((group) => group.name),
  // }));

  // const columnsDesktop = useMemo(
  //   () =>
  //     getColumnsDesktop({
  //       groups: [],
  //       openModal: () => {},
  //       toggleSortingOrder: () => {},
  //       onFilterRolesSelect: () => {},
  //       onFilterGroupsSelect: () => {},
  //     }),
  //   []
  // );

  // const columnsMobile = useMemo(
  //   () =>
  //     getColumnsMobile({
  //       openModal: () => {},
  //       onAdvisorSelect: () => {},
  //       toggleSortingOrder: () => {},
  //     }),
  //   []
  // );

  return (
    <div
      className="
        py-6
        px-16
        mobile:px-4
        mobile-sm:px-3
        tablet-sm:px-4
        tablet:px-6
        laptop:px-10
      "
    >
      {/* <AdvisorTable
        data={advisors}
        advisorSearch=""
        organizationId="0"
        onRemove={() => {}}
        onChange={() => {}}
        openModal={() => {}}
        filterTagsRoles={[]}
        filterTagsGroups={[]}
        handlePagination={() => {}}
        columnsMobile={columnsMobile}
        columnsDesktop={columnsDesktop}
        advisorsState={{ itemsCount: advisors.length, isLoading: false }}
      /> */}
    </div>
  );
}

export default AdvisorsScreenOnboarding;
