import PropTypes from "prop-types";

function TotalEstimatedCost({ totalCost, className = "" }) {
  return (
    <div
      className={`
        flex
        flex-1
        flex-row
        font-bold
        items-center
        justify-center
        ${className}
      `}
    >
      <p className="w-full capitalize text-center">
        total estimated cost before financial aid:
        <span>{` $${totalCost.toLocaleString("en-US")}`}</span>
      </p>
    </div>
  );
}

TotalEstimatedCost.propTypes = {
  totalCost: PropTypes.number,
  className: PropTypes.string
};

export default TotalEstimatedCost;
