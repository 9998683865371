const RESET = "PERMISSIONS/RESET";
const SET_ERROR = "PERMISSIONS/SET_ERROR";
const SET_IS_READY = "PERMISSIONS/SET_IS_READY";
const SET_IS_LOADING = "PERMISSIONS/SET_IS_LOADING";
const SET_PERMISSIONS = "PERMISSIONS/SET_PERMISSIONS";

const Types = {
  RESET,
  SET_ERROR,
  SET_IS_READY,
  SET_IS_LOADING,
  SET_PERMISSIONS,
};

export default Types;
