import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import JSZip from "jszip";
import PropTypes from "prop-types";

import FileList from "./FileList";

import { Button } from "../../../../../../core/buttons";
import { Link, Dropdown } from "../../../../../../core";
import {
  Computer,
  ShortArrow,
  Upload as UploadIcon
} from "../../../../../../core/icons";
import { Colors } from "../../../../../../core/utils";

const osList = [
  {
    id: 1,
    enabled: true,
    label: "Google Chromebook",
    screenshotKeys: "Ctrl + Show Windows"
  },
  {
    id: 2,
    enabled: true,
    label: "Windows",
    screenshotKeys: "Windows key + Shift + S"
  },
  {
    id: 3,
    enabled: true,
    label: "Macintosh",
    screenshotKeys: "Shift + Command + 3."
  },
  {
    id: 4,
    enabled: true,
    label: "Linux",
    screenshotKeys: "Shift + PrtSc"
  }
];

function Upload({ onBack, onDone, studentName = "your student" }) {
  const navigate = useNavigate();
  const { studentId, collegeId } = useParams();

  const [files, setFiles] = useState([]);
  const [isPDF, setIsPDF] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [osSelected, SetOSSelected] = useState(osList[0]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    disabled,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/heic": [".heic"],
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpeg", ".jpg"]
    }
  });

  const screenshotsGuide = `/dashboard/students/${studentId}/${collegeId}/upload-award/dont-have-award`;

  useEffect(() => {
    setFiles((filesState) => {
      const pdfFile = acceptedFiles.find(
        (newFile) => newFile.type === "application/pdf"
      );
      if (pdfFile) {
        setIsPDF(true);
        setDisabled(true);
        return [pdfFile];
      }

      let newFiles = [];
      acceptedFiles.forEach((newFile) => {
        const exists = filesState.some(
          (statefile) => statefile.name === newFile.name
        );
        if (!exists) newFiles.push(newFile);
      });
      return filesState.concat(newFiles);
    });
  }, [acceptedFiles]);

  const handlerOsSelect = (data) => SetOSSelected(data);

  const handlerOnRemoveFile = (file) =>
    setFiles((filesState) => {
      const newFileState = filesState.filter(
        (fileState) => fileState.name !== file.name
      );

      if (newFileState.length === 0) {
        setIsPDF(false);
        setDisabled(false);
      }

      return newFileState;
    });

  const handlerOnDone = async () => {
    if (isPDF || files.length === 1) {
      onDone(files[0]);
    } else {
      const zip = new JSZip();

      files.forEach((file, index) => {
        const extension = file.name.split(".").slice(-1)[0];
        zip.file(`${index + 1}.${extension}`, file);
      });

      const zipArchive = await zip.generateAsync({ type: "blob" });
      zipArchive.name = "zipArchive.zip";
      onDone(zipArchive);
    }
  };

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div role="button" onClick={onBack}>
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>Upload Images</span>
      </div>
      <div className="p-4 flex flex-1 flex-col bg-white">
        <div className="flex flex-1 flex-col">
          <p className="my-8 text-xl font-bold text-primary-blue">
            Upload a .jpeg, .png, .heic or .pdf of your award for {studentName}{" "}
            in order, starting with page 1.
          </p>
          <div className="flex py-6 my-6 flex-row space-x-10">
            <div className="flex w-1/2 flex-col">
              <div
                className={`
                  p-4
                  flex
                  flex-1
                  border
                  items-center
                  border-dashed
                  justify-center
                  border-black
                  hover:border-primary-blue
                  ${files.length > 0 ? "h-1/2" : "h-full"}
                  ${disabled && "opacity-40 pointer-events-none"}
                `}
              >
                <div
                  {...getRootProps({
                    className:
                      "flex flex-1 h-full flex-col items-center justify-center"
                  })}
                >
                  <input id="files-input" {...getInputProps()} />
                  <div className="flex flex-col items-center justify-center">
                    <UploadIcon
                      width={files.length > 0 ? 80 : 140}
                      height={files.length > 0 ? 75 : 120}
                    />
                    <p
                      className="
                        my-4
                        w-80
                        text-xl
                        text-center
                        font-bold
                        text-primary-blue
                      "
                    >
                      Drag and drop your file here or browse to upload
                    </p>
                  </div>
                  <Button
                    onClick={open}
                    className="
                      mt-4
                      text-white
                      bg-primary-blue
                      border-primary-blue
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-blue
                      active:text-primary-blue
                    "
                  >
                    Browse
                  </Button>
                </div>
              </div>
              {files.length > 0 && (
                <div className="w-full">
                  <p className="mt-4 mb-1 font-bold">Uploaded:</p>
                  <FileList files={files} onRemoveFile={handlerOnRemoveFile} />
                </div>
              )}
            </div>

            <div
              className="
                w-1/2
                space-y-10
              "
            >
              <div className="flex flex-row">
                <div className="mr-2 mt-2">
                  <Computer />
                </div>
                <div className="flex flex-1 flex-col">
                  <p className="font-bold normal-case">
                    To take a screenshot on a {osSelected.label}
                  </p>
                  <p className="normal-case">
                    Press{" "}
                    <span className="font-bold text-primary-blue">
                      {osSelected.screenshotKeys}
                    </span>
                  </p>
                </div>
              </div>
              <Dropdown
                options={osList}
                color={Colors.BLUE}
                currentOption={osSelected}
                handleOnSelect={handlerOsSelect}
              />
              <Button
                onClick={handlerOnDone}
                disabled={files.length === 0}
                id="upload-done-upload-button"
                className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                Done
              </Button>
              <div className="w-full">
                <p className="font-bold">Having trouble?</p>
                <Link
                  icon={true}
                  color={Colors.BLUE}
                  textClassName="text-left font-bold"
                  onClick={() => navigate(screenshotsGuide)}
                  text="Next steps if you can't find your award letter"
                  customIcon={
                    <div className="transform place-self-end rotate-180">
                      <ShortArrow size={16} color="stroke-current" />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Upload.propTypes = {
  onBack: PropTypes.func,
  onDone: PropTypes.func,
  studentName: PropTypes.string
};

export default Upload;
