import Types from "./types";

function setWholeList(wholeList) {
  return {
    type: Types.SET_WHOLE_LIST,
    wholeList,
  };
}

function setTableCheckbox(tableCheckbox) {
  return {
    type: Types.SET_TABLE_CHECKBOX,
    tableCheckbox,
  };
}

export function updateWholeListOption(value) {
  return (dispatch) => {
    dispatch(setWholeList(value));
  };
}

export function updateTableCheckboxOption(value) {
  return (dispatch) => {
    dispatch(setTableCheckbox(value));
  };
}
