import PropTypes from "prop-types";

import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { MagnifyGlass } from "../icons";
import { Colors, getColors } from "../utils";

function SearchTextDropdown({
  disabled,
  gaOnClick,
  openOnClick,
  closeOnSelect,
  placeholder = "",
  onChange = () => {},
  loadOptions = () => {},
  color = Colors.DEFAULT
}) {
  const colors = getColors(color);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <MagnifyGlass size={20} color="fill-current" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="py-2" onClick={gaOnClick}>
      <AsyncSelect
        value={null}
        defaultOptions
        isDisabled={disabled}
        placeholder={placeholder}
        loadOptions={loadOptions}
        menuShouldScrollIntoView={true}
        controlShouldRenderValue={false}
        closeMenuOnSelect={closeOnSelect}
        blurInputOnSelect={closeOnSelect}
        components={{ DropdownIndicator }}
        onChange={(data) => {
          onChange(data);
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            borderRadius: 0,
            fontSize: "14px",
            padding: "3px 2px",
            cursor: state.isDisabled ? "not-allowed" : "text",
            boxShadow: state.isFocused ? "0 1px 6px 0 #000000" : "none",
            border: state.isDisabled
              ? "1px solid rgba(0, 0, 0, .5)"
              : "1px solid #000000",
            "&:hover": { border: "1px solid #000000" }
          }),
          valueContainer: (base) => ({
            ...base,
            maxHeight: 90,
            fontWeight: 500,
            overflow: "auto"
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color: "#000000",
            cursor: "pointer",
            opacity: state.isDisabled ? 0.5 : 1,
            "&:hover": { color: colors.primary.hexa }
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: "none"
          }),
          menu: (base, state) => {
            const { selectProps } = state;
            const { inputValue } = selectProps;
            return {
              ...base,
              borderTop: 0,
              marginTop: 0,
              maxHeight: 200,
              borderRadius: 0,
              padding: "0 5px",
              fontSize: "14px",
              overflow: "hidden",
              minHeight: "fit-content",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
              boxShadow: `0 1px 6px 0 #000000`,
              borderBottom: "1px solid #000000",
              display: !openOnClick && inputValue === "" ? "none" : "block"
            };
          },
          menuList: (base) => ({
            ...base,
            maxHeight: 200
          }),
          option: (base, state) => ({
            ...base,
            color: state.isSelected ? "#FFFFFF" : "#000000",
            backgroundColor: state.isSelected
              ? colors.primary.hexa
              : state.isFocused
              ? colors.secondary.hexa
              : "transparent",
            "&:hover": {
              color: "#000000",
              backgroundColor: colors.secondary.hexa
            },
            "&:active": {
              color: "#000000",
              backgroundColor: colors.secondary.hexa
            }
          })
        }}
      />
    </div>
  );
}

SearchTextDropdown.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  loadOptions: PropTypes.func,
  openOnClick: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default SearchTextDropdown;
