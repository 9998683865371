import PropTypes from "prop-types";

function CapAndBook({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 105.02 107.91"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M103.47,55.69l.03-33.3,1.51-.61L51.91,0,0,21.38l19.76,8.52-.9,32.07,31.59-1.9,33.11,4.19,.13-33.89,13.64-5.5-.02,30.81h6.17Zm-26.06,1.58l-26.77-3.38-25.44,1.53,.64-22.88,25.32,10.92,26.33-10.61-.09,24.41Zm-26.16-20.49L15.88,21.51,51.91,6.67l36.72,15.05-37.37,15.05Z"
      />
      <path
        className={color}
        d="M96.99,78.71v-6.17H19.68c-9.75,0-17.69,7.93-17.69,17.69s7.93,17.68,17.69,17.68H96.99v-6.17c-2.25,0-6.57-4.86-6.57-11.52s4.93-11.52,6.57-11.52Zm-12.49,8.43H21.59v6.17h62.91c.47,3.1,1.61,6.03,3.17,8.43H19.68c-6.35,0-11.52-5.17-11.52-11.52s5.17-11.52,11.52-11.52H87.83c-1.61,2.37-2.82,5.28-3.32,8.43Z"
      />
    </svg>
  );
}

CapAndBook.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default CapAndBook;
