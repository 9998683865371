import { EmailCell, StatusCell, AdvisorCell, SenderCell } from "./Cell";
import {
  EmailHeader,
  StatusHeader,
  AdvisorHeader,
  SenderHeader,
} from "./Header";
import { SortingType, InvitationStatus, GetInvitationStatus } from "../Utils";

import { clearSort } from "../../../Common/utils";

const Status = [
  {
    key: InvitationStatus.ACCEPTED,
    name: GetInvitationStatus(InvitationStatus.ACCEPTED),
  },
  {
    key: InvitationStatus.PENDING,
    name: GetInvitationStatus(InvitationStatus.PENDING),
  },
  {
    key: InvitationStatus.EXPIRED,
    name: GetInvitationStatus(InvitationStatus.EXPIRED),
  },
];

export function getColumns({
  orderBy,
  assingAdvisors,
  toggleSortingOrder = () => {},
  onFilterStatusSelect = () => {},
  onFilterSenderSelect = () => {},
  onFilterAdvisorSelect = () => {},
}) {
  return [
    {
      accessor: "email",
      Header: (
        <EmailHeader
          clearSort={clearSort({ orderBy, field: SortingType.EMAIL })}
          onSort={(order) => toggleSortingOrder(SortingType.EMAIL, order)}
        />
      ),
      Cell: ({ row }) => <EmailCell email={row.original.email} />,
      width: "40%",
    },
    {
      accessor: "status",
      Header: <StatusHeader status={Status} onFilter={onFilterStatusSelect} />,
      Cell: ({ row }) => <StatusCell invitation={row.original} />,
      width: "30%",
    },
    {
      accessor: "advisor",
      Header: (
        <AdvisorHeader
          onFilter={onFilterAdvisorSelect}
          advisors={assingAdvisors.map((advisor) => ({
            ...advisor,
            key: `advisor-${advisor.id}`,
          }))}
        />
      ),
      Cell: ({ row }) => <AdvisorCell advisor={row.original.assignedAdvisor} />,
      width: "30%",
    },
    {
      accessor: "sender",
      Header: (
        <SenderHeader
          onFilter={onFilterSenderSelect}
          advisors={assingAdvisors.map((advisor) => ({
            ...advisor,
            key: `sender-${advisor.id}`,
          }))}
        />
      ),
      Cell: ({ row }) => <SenderCell advisor={row.original.sender.advisor} />,
      width: "20%",
    },
  ];
}
