import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { dollarFormat } from "./Utils";

function FinancialAidList({
  confirm = false,
  financialAids,
  onRemoveFinancialAid
}) {
  const divContainerRef = useRef();

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const container = divContainerRef.current;

    const handleScroll = (element) => {
      if (element.scrollTop > 0) setHasScroll(true);
      else setHasScroll(false);
    };

    if (container)
      container.addEventListener("scroll", () => handleScroll(container));
    return () => {
      if (container)
        container.removeEventListener("scroll", () => handleScroll(container));
    };
  }, []);

  if (financialAids?.length === 0) return null;

  return (
    <div
      className={`
        py-2
        ${confirm ? "-mt-10 text-base text-black" : "text-sm text-gray-500"}
      `}
    >
      <p className="px-1 pb-2 border-b border-gray-300">
        {confirm ? "Aid Added:" : "Aid Already Added:"}
      </p>
      <ol
        id="file-list"
        ref={divContainerRef}
        className={`
          max-h-full
          divide-y
          overflow-y-auto
          overflow-x-hidden
          divide-gray-300
          ${hasScroll && "border-t border-gray-300"}
        `}
      >
        {financialAids.map((financialAid) => (
          <li className="py-2 px-2" key={financialAid.id}>
            <div className="flex flex-1 flex-row space-x-2">
              <div className="flex flex-1 truncate">{financialAid.name}</div>
              {confirm && (
                <div className="pr-10 flex flex-1 justify-end">
                  {dollarFormat.format(financialAid.amount)}
                </div>
              )}
              <div
                className="cursor-pointer"
                onClick={() => onRemoveFinancialAid(financialAid)}
              >
                Delete
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

FinancialAidList.propTypes = {
  confirm: PropTypes.bool,
  onRemoveFinancialAid: PropTypes.func,
  financialAids: PropTypes.arrayOf(PropTypes.any)
};

export default FinancialAidList;
