import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../../Common/Confirm";

import Api from "../../../../services/Api";

function DeleteAdvisorInvitesModal({
  onClose,
  filters,
  invites,
  organizationId,
  setConfirmModal,
}) {
  const [firstText, setFirstText] = useState("");
  const [secondText, setSecondText] = useState(null);

  const getSecondText = (otherInvites) => {
    return (
      <div className="text-2xl font-medium text-primary-blue">
        <p>
          You cannot delete the following
          {otherInvites.length > 1 ? " advisor invites" : " advisor invite"},
          since you did not send the initial
          {otherInvites.length > 1 ? " invites" : " invite"}.
        </p>
        <ul className="ml-4 py-4">
          {otherInvites.map((item, i) => (
            <li
              key={`${i}-${item}`}
              className="list-disc list-inside text-black"
            >
              <span>{item}</span>
            </li>
          ))}
        </ul>
        <p>You can contact the initial sender to delete these invites.</p>
      </div>
    );
  };

  useEffect(() => {
    const handlerDeleteInvites = async () => {
      const ownInvitesIds = invites?.map((invite) => invite.id) || [];
      const dataMutation = {
        organizationId,
        invitations: {
          ids: ownInvitesIds.length > 0 ? ownInvitesIds : null,
          filter: ownInvitesIds.length > 0 ? null : filters,
        },
      };

      const { deletedCount, nonDeletedInvitations } =
        await Api.deleteAdvisorInvites(dataMutation);

      setFirstText(
        `You have deleted ${deletedCount} advisor ${
          deletedCount === 1 ? "invite" : "invites"
        }`
      );
      if (nonDeletedInvitations.length > 0)
        setSecondText(getSecondText(nonDeletedInvitations));
      setConfirmModal(true);
    };

    handlerDeleteInvites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invites, filters]);

  return (
    <div className="flex flex-col h-full">
      <Confirm
        onClick={onClose}
        firstText={firstText}
        secondText={secondText}
        buttonText="Go Back to Manage Invites"
      />
    </div>
  );
}

DeleteAdvisorInvitesModal.propTypes = {
  filters: PropTypes.shape(),
  organizationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  invites: PropTypes.arrayOf(PropTypes.shape()),
};

export default DeleteAdvisorInvitesModal;
