import PropTypes from "prop-types";

import { Letter, ShortArrow, Wallet } from "../../icons";

import { useIsDesktopMQL } from "../../hooks";
import { getColors, decidedRoute } from "../../utils";
import Link from "../../Link";

function Budget({
  color,
  lifeBudget,
  collegeCosts,
  classNameCost,
  classNameLink,
  customClassName
}) {
  const isDesktop = useIsDesktopMQL();

  const colors = getColors(color);

  return (
    <div className={customClassName ? customClassName : "flex flex-1 flex-row"}>
      <div className="flex flex-1 flex-row items-center">
        <Letter
          size={isDesktop ? 40 : 30}
          iconColor={`fill-current ${colors.primary.text}`}
          fillColor={`fill-current ${colors.secondary.text}`}
        />
        <div className="flex flex-1 flex-col ml-2">
          {typeof collegeCosts === "number" && (
            <span className={classNameCost}>{`$${collegeCosts.toLocaleString(
              "en-US"
            )}`}</span>
          )}
          <Link
            icon={true}
            color={color}
            target="_blank"
            internal={false}
            text="for College Bill"
            textClassName={classNameLink}
            href={decidedRoute("/guides/understanding-your-college-bill")}
            customIcon={
              <div className="transform place-self-end rotate-180">
                <ShortArrow size={16} color="stroke-current" />
              </div>
            }
          />
        </div>
      </div>
      <div
        className={`
          flex
          flex-1
          flex-row
          items-center
          ${!customClassName ? "justify-end" : ""}
        `}
      >
        <Wallet
          size={isDesktop ? 40 : 30}
          iconColor={`fill-current ${colors.primary.text}`}
          fillColor={`fill-current ${colors.secondary.text}`}
        />
        <div className="flex flex-col ml-2">
          {typeof lifeBudget === "number" && (
            <span className={classNameCost}>{`$${
              lifeBudget >= 0 ? lifeBudget.toLocaleString("en-US") : 0
            }`}</span>
          )}
          <Link
            icon={true}
            color={color}
            target="_blank"
            internal={false}
            text="for Life Budget"
            textClassName={classNameLink}
            href={decidedRoute("/guides/understanding-your-life-budget")}
            customIcon={
              <div className="transform place-self-end rotate-180">
                <ShortArrow size={16} color="stroke-current" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

Budget.propTypes = {
  color: PropTypes.string,
  classNameCost: PropTypes.string,
  classNameLink: PropTypes.string,
  customClassName: PropTypes.string,
  collegeCosts: PropTypes.number.isRequired,
  lifeBudget: PropTypes.number.isRequired
};

export default Budget;
