import PropTypes from "prop-types";

import { GetRole } from "../../Utils";

function RoleCell({ advisor }) {
  const roleArray = GetRole(advisor.role).split(" ");

  const role = roleArray.shift();
  const description = roleArray.join(" ");

  const roleTipString = () => {
    switch (role) {
      case "Admin":
        return "Admin can invite and view any new advisors and set access for other advisors in their organization.";
      case "Advisor":
        return "Advisor can view and invite any student in their organization.";
      default:
        return "";
    }
  };

  return (
    <>
      <div data-tooltip-id="role-cell" className="text-center" data-tooltip-content={roleTipString()}>
        <p className="text-sm font-bold capitalize">{role}</p>
        {description && <p className="text-sm font-bold capitalize">{description}</p>}
      </div>
    </>
  );
}

RoleCell.propTypes = {
  advisor: PropTypes.shape().isRequired,
};

export default RoleCell;
