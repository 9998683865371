import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import { Colors, getColors } from "./utils";

const Directions = {
  TOP_LEFT: "TOP-LEFT",
  TOP_RIGHT: "TOP-RIGHT",
  BOTTOM_LEFT: "BOTTOM-LEFT",
  BOTTOM_RIGHT: "BOTTOM-RIGHT"
};

function Tooltip({
  open,
  text,
  tooltipClassName,
  tooltipCloseButton,
  tooltipContainerRef,
  tooltipHandleOnClose,
  tooltipCloseOnClickOutside,
  tooltipColor = Colors.DEFAULT,
  direction = Directions.BOTTOM_LEFT
}) {
  const divSvgRef = useRef();

  const size = 300;

  useEffect(() => {
    const colors = getColors(tooltipColor);
    const divSvg = d3.select(divSvgRef.current);

    function wrap(text, width) {
      text.each(function () {
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1,
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    if (open) {
      const svg = divSvg.append("svg");

      const defs = svg.append("defs");
      const filter = defs.append("filter").attr("id", "dropshadow2");

      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 2)
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dx", 1)
        .attr("dy", 1)
        .attr("result", "offsetBlur");

      const feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      const group = svg.append("g");

      const rect = group
        .append("rect")
        .attr("width", size)
        .attr("height", size / 2)
        .style("fill", "transparent");

      const txtRef = group
        .append("text")
        .text(text)
        .attr("x", 20)
        .attr("dy", ".71em")
        .attr("y", tooltipCloseButton ? 40 : 20)
        .style("fill", "none")
        .call(
          wrap,
          [Directions.TOP_LEFT, Directions.TOP_RIGHT].includes(direction)
            ? size + 40
            : size - 40
        );

      const height =
        txtRef.node().getBBox().height + (tooltipCloseButton ? 60 : 40);
      const finalHeight = height > size / 3 ? height : size / 3;
      svg
        .style("overflow", "visible")
        .attr("viewBox", `0 0 ${size + 24} ${finalHeight}`);
      rect.attr("height", finalHeight);

      group
        .append("polyline")
        .attr("stroke-width", 2)
        .attr("fill", colors.primary.hexa)
        .attr("stroke", colors.primary.hexa)
        .attr(
          "points",
          `
          -2,0
          -2,${finalHeight - 1.3}
          -15,${finalHeight - 15}
          -15,-13
          -2,0
          `
        );

      switch (direction) {
        case Directions.TOP_LEFT:
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", colors.primary.hexa)
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,-2
              ${size - 1.3},-2
              ${size - 15},-15
              95,-15
              35,-50
              45,-15
              -13,-15
              0,-2
              `
            );
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", "#FFFFFF")
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,0
              0,${finalHeight}
              ${size},${finalHeight}
              ${size},0
              0,0
              `
            );
          break;
        case Directions.TOP_RIGHT:
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", colors.primary.hexa)
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,-2
              ${size - 1.3},-2
              ${size - 15},-15
              255,-15
              265,-50
              205,-15
              -13,-15
              0,-2
              `
            );
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", "#FFFFFF")
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,0
              0,${finalHeight}
              ${size},${finalHeight}
              ${size},0
              0,0
              `
            );
          break;
        case Directions.BOTTOM_RIGHT:
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", colors.primary.hexa)
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,-2
              ${size - 1.3},-2
              ${size - 15},-15
              -13,-15
              0,-2
              `
            );
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", "#FFFFFF")
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,0
              0,${finalHeight}
              205,${finalHeight}
              265,${finalHeight + 35}
              255,${finalHeight}
              ${size},${finalHeight}
              ${size},0
              0,0
              `
            );
          break;
        case Directions.BOTTOM_LEFT:
        default:
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", colors.primary.hexa)
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,-2
              ${size - 1.3},-2
              ${size - 15},-15
              -13,-15
              0,-2
              `
            );
          group
            .append("polyline")
            .attr("stroke-width", 2)
            .attr("fill", "#FFFFFF")
            .attr("stroke", colors.primary.hexa)
            .attr(
              "points",
              `
              0,0
              0,${finalHeight}
              45,${finalHeight}
              35,${finalHeight + 35}
              95,${finalHeight}
              ${size},${finalHeight}
              ${size},0
              0,0
              `
            );
          break;
      }

      group
        .append("polyline")
        .attr("fill", "none")
        .attr("stroke-width", 2)
        .attr("stroke", "#FFFFFF")
        .attr("points", "1,1 -15.5,-15.5 ");

      group
        .append("text")
        .text(text)
        .attr("x", 20)
        .attr("dy", ".71em")
        .attr("y", tooltipCloseButton ? 40 : 20)
        .attr("transform", `translate(20, 0)`)
        .style("fill", "#000000")
        .call(wrap, size - 40);

      group
        .attr("transform", `translate(18, 18)`)
        .attr("filter", "url(#dropshadow2)");

      if (tooltipCloseButton) {
        const closeGroup = group
          .append("g")
          .style("cursor", "pointer")
          .on("click", () => tooltipHandleOnClose());

        closeGroup
          .append("circle")
          .attr("r", 10)
          .attr("cy", 20)
          .attr("cx", size - 20)
          .attr("fill", "#FFFFFF")
          .attr("stroke", "#000000");

        closeGroup
          .append("text")
          .text("X")
          .attr("y", 26)
          .attr("x", size - 25);
      }
    } else divSvg.selectAll("*").remove();

    return () => divSvg.selectAll("*").remove();
  }, [
    size,
    open,
    text,
    direction,
    tooltipColor,
    tooltipCloseButton,
    tooltipHandleOnClose
  ]);

  useEffect(() => {
    if (tooltipCloseOnClickOutside)
      window.addEventListener("mousedown", outsideClick);
    return () => {
      if (tooltipCloseOnClickOutside)
        window.removeEventListener("mousedown", outsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipCloseOnClickOutside]);

  const outsideClick = (event) => {
    if (
      tooltipContainerRef &&
      tooltipContainerRef.current &&
      !tooltipContainerRef.current.contains(event.target)
    )
      tooltipHandleOnClose();
  };

  return (
    <div className={`z-20 absolute ${tooltipClassName}`}>
      <div style={{ width: 300 }}>
        <div ref={divSvgRef} />
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipCloseButton: PropTypes.bool,
  tooltipContainerRef: PropTypes.any,
  tooltipHandleOnClose: PropTypes.func,
  tooltipCloseOnClickOutside: PropTypes.bool,
  direction: PropTypes.oneOf([
    Directions.TOP_LEFT,
    Directions.TOP_RIGHT,
    Directions.BOTTOM_LEFT,
    Directions.BOTTOM_RIGHT
  ]),
  tooltipColor: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export { Directions };
export default Tooltip;
