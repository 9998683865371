import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Link } from "../";
import { ButtonTooltip } from ".";
import { ShortArrow } from "../icons";
import { Colors } from "../utils";

function getClassName(open, className, classNameClose, defaultClassName) {
  if (open) {
    return className ? className : defaultClassName;
  } else {
    return classNameClose
      ? classNameClose
      : className
      ? className
      : defaultClassName;
  }
}

function Panel({
  id,
  title,
  linkUrl,
  subTitle,
  children,
  className,
  isDesktop,
  linkLabel,
  tooltipColor,
  tooltipLabel,
  gaToggleCall,
  gaOnClickFunc,
  externalToggle,
  classNameClose,
  titleClassName,
  initiallyOpened,
  tooltipClassName,
  subTitleClassName,
  expandableContent,
  tooltip = false,
  openByDefault = false,
  tooltipCustomToggle = null,
  callbackToggle = () => {},
  linkColor = Colors.DEFAULT
}) {
  const [open, setOpen] = useState(initiallyOpened || openByDefault);

  useEffect(() => setOpen(externalToggle), [externalToggle]);

  const toggle = () => {
    if (gaToggleCall && !open) gaToggleCall();
    setOpen(!open);
    callbackToggle(!open);
  };

  const arrowRotation = open ? "-rotate-180" : "rotate-0";
  const contentPadding = children || (open && expandableContent) ? "pb-2" : "";

  return (
    <div
      id={`${id}-panel`}
      className={getClassName(
        open,
        className,
        classNameClose,
        "bg-white shadow-lg p-4 mx-4 my-2"
      )}
    >
      {(title || linkUrl) && (
        <>
          <div
            id={`${id}-panel-title`}
            className={`flex flex-1 flex-row items-center ${contentPadding}`}
          >
            {title && (
              <div className="flex flex-row items-center">
                <h2
                  className={
                    titleClassName
                      ? titleClassName
                      : `flex font-medium capitalize`
                  }
                >
                  {title}
                </h2>
                {tooltip && (
                  <ButtonTooltip
                    text={tooltipLabel}
                    tooltipCloseButton={true}
                    tooltipColor={tooltipColor}
                    gaOnClickFunc={gaOnClickFunc}
                    tooltipCloseOnClickOutside={true}
                    tooltipClassName={tooltipClassName}
                    tooltipCustomToggle={tooltipCustomToggle}
                  />
                )}
              </div>
            )}
            {!linkUrl && expandableContent && (
              <div
                onClick={toggle}
                id={`${id}-panel-toggle`}
                className="
                  flex
                  flex-1
                  flex-row
                  justify-end
                  cursor-pointer
                "
              >
                <div
                  className={`
                    ml-2
                    transform
                    justify-end
                    duration-300
                    ${arrowRotation}
                  `}
                >
                  <div className="transform -rotate-90">
                    <ShortArrow size={16} />
                  </div>
                </div>
              </div>
            )}
            {linkUrl && (
              <div
                className={`
                  flex
                  flex-row
                  justify-end
                  ${(!tooltip || isDesktop) && "flex-1"}
                `}
              >
                <Link
                  icon={true}
                  href={linkUrl}
                  target="_blank"
                  internal={false}
                  text={linkLabel}
                  color={linkColor}
                  id={`${id}-panel-link`}
                  customIcon={
                    <div className="transform place-self-end rotate-180">
                      <ShortArrow size={16} color="stroke-current" />
                    </div>
                  }
                />
              </div>
            )}
          </div>
          <div
            id={`${id}-panel-subtitle`}
            className={
              subTitleClassName ? subTitleClassName : "text-sm capitalize"
            }
          >
            {subTitle && subTitle}
          </div>
        </>
      )}
      {children}
      {open && expandableContent}
    </div>
  );
}

Panel.propTypes = {
  id: PropTypes.string,
  tooltip: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  isDesktop: PropTypes.bool,
  linkUrl: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  linkLabel: PropTypes.string,
  openByDefault: PropTypes.bool,
  tooltipLabel: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  initiallyOpened: PropTypes.bool,
  classNameClose: PropTypes.string,
  expandableContent: PropTypes.any,
  titleClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  subTitleClassName: PropTypes.string,
  tooltipCustomToggle: PropTypes.func,
  linkColor: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ]),
  tooltipColor: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Panel;
