import PropTypes from "prop-types";

function FiltersItem({ filters, onSelect, noItemsLabel = "No items yet!!" }) {
  return (
    <>
      <div className="min-h-6rem max-h-8rem overflow-y-auto">
        {filters.map((item, index) => (
          <p
            key={index}
            onClick={() => onSelect(item)}
            className="my-2 truncate capitalize text-sm"
          >
            {item.name}
          </p>
        ))}
        {filters.length === 0 && (
          <div className="text-center">{noItemsLabel}</div>
        )}
      </div>
    </>
  );
}

FiltersItem.propTypes = {
  noItemsLabel: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default FiltersItem;
