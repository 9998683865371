import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import RemoveStudentList from "./RemoveStudentList";
import Api from "../../../services/Api";

function DeleteStudentModal({
  list,
  filters,
  onClose,
  students,
  organizationId,
  removeStudents,
  setConfirmModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const deleteStudentOnClick = async () => {
    const dataMutation = {
      organizationId,
      studentListIds: [list.id],
      removeStudents: {
        filter: students?.length > 0 ? null : filters,
        studentIds: students?.length > 0 ? students : null,
      },
    };

    await Api.addOrRemoveStudentsToList(dataMutation);

    setConfirm(true);
    setConfirmModal(true);
  };

  const firstTextConfirmation = (
    <span>
      You have removed
      {removeStudents === 1 ? " one student " : ` ${removeStudents} students `}
      from the list <span className="text-black"> "{list.name}"</span>.
    </span>
  );

  const secondTextConfirmation = (
    <span className="text-2xl font-medium">
      These changes will be visible to all advisors who have shared access to
      this list.
    </span>
  );

  const isPrivateList =
    list.sharedWith.length === 0 && list.sharedWithOrganization === false;

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <RemoveStudentList
          list={list}
          onClick={deleteStudentOnClick}
          removeStudents={removeStudents}
          secondTextContent={!isPrivateList && secondTextConfirmation}
        />
      ) : (
        <Confirm
          onClick={onClose}
          buttonText="Back to List"
          firstText={firstTextConfirmation}
          secondText={!isPrivateList && secondTextConfirmation}
        />
      )}
    </div>
  );
}

DeleteStudentModal.propTypes = {
  list: PropTypes.shape(),
  filters: PropTypes.shape(),
  removeStudents: PropTypes.number,
  organizationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.string),
};

export default DeleteStudentModal;
