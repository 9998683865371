import TagManager from "react-gtm-module";

export const interactionEventDataLayer = ({ eventName, params }) => {
  const tagManagerArgs = {
    dataLayer: {
      event: "interactionEvent",
      eventName: eventName,
      ...params,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};
