import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import { LoadingButton } from "../../../core/buttons";
import { Checkbox } from "../../../core/inputs";
import { Colors } from "../../../core/utils";

import { useAdvisor } from "../../../redux/Advisor/hooks";
import { useOrganizations } from "../../../redux/Organizations/hooks";

function AddStudentList({
  onSubmit,
  defaultValues,
  requestedClose,
  organizationId,
  saveChangesError,
  requestCloseHandle,
}) {
  const { profile } = useAdvisor();
  const { studentLists } = useOrganizations();

  const [error, setError] = useState(null);
  const [myLists, setMyLists] = useState([]);
  const [orgLists, setOrgLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLists, setSeletedList] = useState([]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({ selectedLists });
  }, [selectedLists, requestCloseHandle]);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  useEffect(
    () => setSeletedList(defaultValues.selectedLists),
    [defaultValues.selectedLists]
  );

  useEffect(() => {
    if (saveChangesError === "selectedLists")
      setError("At least one list should be selected!");
  }, [saveChangesError]);

  useEffect(() => {
    const getListsCheckboxData = (lists) =>
      lists.map((list) => ({
        id: list.id,
        label: list.name,
        shared: list.isShared,
        value: selectedLists.map((list) => list.id).includes(list.id),
      }));

    const myLists = studentLists.filter(
      (list) => !list.archived && !list.isShared && list.owner.id === profile.id
    );
    const orgLists = studentLists.filter(
      (list) => !list.archived && list.owner.id === profile.id && list.isShared
    );

    setMyLists(getListsCheckboxData(myLists));
    setOrgLists(getListsCheckboxData(orgLists));
  }, [profile, studentLists, selectedLists, organizationId]);

  const handleOnClick = (listId, setLists) =>
    setLists((lists) =>
      lists.map((list) => {
        setError(null);
        if (list.id === listId) {
          if (!list.value)
            setSeletedList((selectedLists) =>
              !selectedLists.map((list) => list.id).includes(listId)
                ? selectedLists.concat([list])
                : selectedLists
            );
          else
            setSeletedList((selectedLists) =>
              selectedLists.filter((list) => list.id !== listId)
            );
          return { ...list, value: !list.value };
        }
        return list;
      })
    );

  return (
    <>
      <div className="pt-4 flex flex-1 flex-col overflow-y-auto">
        <p className="text-2xl font-medium">
          You are adding the selected student(s) to a list.
        </p>
        <p className="py-2 text-sm font-semibold text-primary-blue">
          Select the lists you would like these students added to:
        </p>
        <div
          className="
            py-4
            px-6
            flex
            flex-1
            border
            flex-col
            border-black
            overflow-y-auto
          "
        >
          <p className="my-1 font-semibold">My Lists</p>
          {myLists.map((list) => (
            <div className="px-2" key={list.id}>
              <Checkbox
                value={list.value}
                label={list.label}
                color={Colors.BLUE}
                checked={list.value}
                onChange={() => handleOnClick(list.id, setMyLists)}
              />
            </div>
          ))}
          <p className="my-1 font-semibold">Org Lists</p>
          {orgLists.map((list) => (
            <div className="px-2" key={list.id}>
              <Checkbox
                value={list.value}
                label={list.label}
                color={Colors.BLUE}
                checked={list.value}
                onChange={() => handleOnClick(list.id, setOrgLists)}
              />
            </div>
          ))}
        </div>
        {error && (
          <p className="text-sm font-semibold text-primary-red">{error}</p>
        )}
      </div>
      <div className="py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          id="add-student-to-list-button"
          disabled={selectedLists.length === 0}
          onClick={() => {
            setIsLoading(true);
            onSubmit({ selectedLists });
          }}
          className="
            mb-5
            w-1/2
            ml-auto
            normal-case
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          Add to List
        </LoadingButton>
      </div>
    </>
  );
}

AddStudentList.propTypes = {
  onSubmit: PropTypes.func,
  requestedClose: PropTypes.bool,
  organizationId: PropTypes.string,
  defaultValues: PropTypes.shape(),
  requestCloseHandle: PropTypes.func,
  saveChangesError: PropTypes.string,
};

export default AddStudentList;
