import PropTypes from "prop-types";
import { getGrantsAndScholarships } from "../../utils";

function GrantList({ colors, aidAmounts }) {
  const grants = getGrantsAndScholarships(aidAmounts);
  return grants.map((grant, index) => (
    <div
      key={index}
      className="
        p-3
        my-3
        flex
        flex-1
        border
        text-sm
        bg-white
        border-black
        font-semibold
      "
    >
      <p>
        ${grant.value.toLocaleString("en-US")} {grant.description}
        {grant.isNew && (
          <span className={`font-bold ${colors.primary.text}`}> New!</span>
        )}
      </p>
    </div>
  ));
}

GrantList.propTypes = {
  colosr: PropTypes.shape(),
  aidAmounts: PropTypes.array,
};

export default GrantList;
