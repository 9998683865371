import PropTypes from "prop-types";

import { FirstYearBreakdownCard as CommonFirstYearBreakdownCard } from "../../../../../core/compose/cards";

import { getTheme, getColors } from "../../../../../core/utils";

function FirstYearBreakdownCard({
  open,
  chosenSchool,
  studentState,
  externalToggle,
  callbackToggle,
}) {
  const { award } = chosenSchool;

  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability,
  });
  const colors = getColors(theme.color);

  return (
    <CommonFirstYearBreakdownCard
      open={open}
      colors={colors}
      chosenSchool={chosenSchool}
      studentState={studentState}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
    />
  );
}

FirstYearBreakdownCard.propTypes = {
  open: PropTypes.bool,
  studentState: PropTypes.string,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default FirstYearBreakdownCard;
