import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";

import Table from "../../../Common/Table";

function AdvisorTableBody({
  data,
  columns,
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  globalIsOwner,
  handlerOnSelectAdvisors,
}) {
  return (
    <>
      <Table
        data={data}
        columns={columns}
        pageSize={pageSize}
        itemsCount={itemsCount}
        currentPage={currentPage}
        addSelectionColumn={true}
        onPageChange={onPageChange}
        tableSelector="viewport-advisor-table"
        handlerOnSelectRows={handlerOnSelectAdvisors}
        hideColumn={{ id: "selection", value: !globalIsOwner }}
        propsBody={{ className: "min-h-15rem h-screen-footer-xl" }}
      />
      <Tooltip
        id="role-cell"
        place="bottom"
        effect="solid"
        delayShow={500}
        getContent={(description) => {
          return (
            <div className="w-48">
              <p>{description}</p>
            </div>
          );
        }}
      />
    </>
  );
}

AdvisorTableBody.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  globalIsOwner: PropTypes.bool,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handlerOnSelectAdvisors: PropTypes.func.isRequired,
};

export default AdvisorTableBody;
