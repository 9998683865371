import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import InfoRow from "../Common/InfoRow";

import { Form } from "../../../core";
import { Copy } from "../../../core/icons";
import { PillList } from "../../../core/compose";
import { LoadingButton } from "../../../core/buttons";
import { PillText } from "../../../core/inputs";
import { Colors } from "../../../core/utils";

import { extractEmails } from "../Common/Utils";

function StudentForm({
  onSubmit,
  defaultValues = {},
  requestedClose,
  assingAdvisors,
  saveChangesError,
  requestCloseHandle
}) {
  const FORM_ID = "invite-new-student-from-id";

  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setEmails(defaultValues.students);
  }, [defaultValues.students]);

  useEffect(() => {
    if (saveChangesError === "students")
      setError(saveChangesError, {
        message: "At least one student's email is required."
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChangesError]);

  const {
    watch,
    setError,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues
  });

  const [advisor] = watch(["advisor"]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      students: emails,
      advisor: advisor
    });
  }, [emails, advisor, requestCloseHandle]);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  const fetchAssingAdvisorsList = (search, callback) => {
    const sortedAdvisors = assingAdvisors.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const options = sortedAdvisors.map((advisor) => ({
      id: advisor.id,
      value: advisor.id,
      label: advisor.name
    }));

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const handlerOnEmailChange = (value) => {
    clearErrors("students");
    setCurrentEmail(value);
  };

  const handlerOnEmailsPaste = (emailsPasted) => {
    clearErrors("students");
    let localEmails = [];
    let badEmails = emailsPasted;
    let newEmailsPasted = extractEmails(emailsPasted);

    newEmailsPasted.forEach((email) => {
      badEmails = badEmails.replace(email, "\n");
      if (!localEmails.some((emailItem) => emailItem === email))
        localEmails.push(email);
    });
    localEmails = localEmails
      .filter(
        (emailItem) => !emails.map((email) => email.label).includes(emailItem)
      )
      .map((emailItem) => ({ label: emailItem }));
    setEmails((emails) => [...emails, ...localEmails]);
    setCurrentEmail(
      badEmails
        .split("\n")
        .filter((email) => email.length > 2)
        .join(" ")
    );
  };

  const handlerOnEmailAdd = (email) => {
    email = email.trim();
    clearErrors("students");
    const exists = emails.some((emailItem) => emailItem.label === email);
    if (exists) {
      setError("students", {
        message: "This email already exists."
      });
    } else if (email.match(/^[\w+-.%]+@[\w-.]+\.[A-Za-z]{0,4}$/) && !exists) {
      setEmails((emails) => [...emails, { label: email }]);
      setCurrentEmail("");
    } else {
      if (email && !exists)
        setError("students", {
          message: "There is an invalid email."
        });
    }
  };

  const handlerOnRemovePill = (oldEmail) => {
    setEmails((emails) =>
      emails.filter((email) => email.label !== oldEmail.label)
    );
  };

  return (
    <>
      <div className="p-1 flex flex-1 flex-col overflow-auto">
        <Form
          id={FORM_ID}
          onSubmit={handleSubmit(async (form) => {
            if (emails.length === 0) {
              setError("students", {
                message: "At least one student's email is required."
              });
              return;
            }

            setIsLoading(true);
            try {
              const formStudentData = {
                students: emails,
                advisor: form.advisor
              };
              await onSubmit(formStudentData);
            } finally {
              setIsLoading(false);
            }
          })}
        >
          <div className="my-4 flex flex-col">
            <p
              className="
                mb-2
                text-sm
                capitalize
                font-semibold
                text-primary-blue
              "
            >
              Invite student
            </p>
            <div className="p-2 border border-black focus-within:shadow-black">
              <div className="overflow-auto" style={{ maxHeight: "80px" }}>
                <PillList
                  data={emails}
                  color={Colors.BLUE}
                  pillWidthFull={true}
                  onRemovePill={handlerOnRemovePill}
                />
              </div>
              <div className="overflow-hidden">
                <PillText
                  value={currentEmail}
                  handlerOnAdd={handlerOnEmailAdd}
                  handlerOnPaste={handlerOnEmailsPaste}
                  handlerOnChange={handlerOnEmailChange}
                  placeholder="jandoe@email.edu, johndoe@email.edu"
                />
              </div>
            </div>
          </div>
          <Form.ErrorMessage errors={errors} name="students" />
          <Form.Select
            name="advisor"
            control={control}
            color={Colors.BLUE}
            label="Assign Advisor"
            id="assign-advisor-input"
            placeholder="Choose One Advisor"
            loadOptions={fetchAssingAdvisorsList}
          />
          <Form.ErrorMessage errors={errors} name="owner" />

          <div className="mobile:mt-2 mobile-sm:mt-2 tablet-sm:mt-2">
            <div className="mt-2">
              <InfoRow
                label="Organization"
                text={`${defaultValues.currentOrg.organization.name}`}
                className="mt-6 mobile:mt-3 mobile-sm:mt-3 tablet-sm:mt-3"
              />
            </div>

            <p className="text-sm font-bold text-primary-blue mt-8">
              <span className="text-primary-red">NEW!</span> Copy and share a
              unique URL, prompting students to join your organization:
            </p>
            <div className="flex flex-row items-center mt-2">
              <p className="text-sm font-bold">
                <span className="text-primary-blue">URL:</span>{" "}
                {`${window.SERVER_DATA.REACT_APP_STUDENT_URL}/join-org/${defaultValues.currentOrg?.organization?.code}`}
              </p>
              <div
                className="
                    ml-8
                    flex
                    flex-row
                    space-x-2
                    items-center
                    font-bold
                    hover:underline
                    hover:text-primary-blue
                    cursor-pointer"
                onClick={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 2000);
                  navigator.clipboard.writeText(
                    `${window.SERVER_DATA.REACT_APP_STUDENT_URL}/join-org/${defaultValues.currentOrg?.organization?.code}`
                  );
                }}
              >
                <p className="flex flex-row text-sm">
                  {copied ? "Copied to clipboard!" : "Copy URL"}
                </p>
                <Copy size={15} />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="bottom-0 py-5 flex flex-row">
        <LoadingButton
          type="submit"
          value="submit"
          form={FORM_ID}
          isLoading={isLoading}
          id="invite-students-button"
          disabled={emails.length > 0 ? false : true}
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
            place-self-center
          "
        >
          send invites
        </LoadingButton>
      </div>
    </>
  );
}

StudentForm.propTypes = {
  onSubmit: PropTypes.func,
  requestedClose: PropTypes.bool,
  defaultValues: PropTypes.shape(),
  saveChangesError: PropTypes.string,
  requestCloseHandle: PropTypes.func,
  assingAdvisors: PropTypes.arrayOf(PropTypes.shape())
};

export default StudentForm;
