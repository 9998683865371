import PropTypes from "prop-types";

function AllNotes({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 43 39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M41.3035 13.0025V3.18398C41.3035 2.87168 41.018 2.6127 40.6736 2.6127H36.8943V0.571289C36.8943 0.258984 36.6088 0 36.2645 0H9.38105H7.30664H2.32637C1.98203 0 1.69648 0.258984 1.69648 0.571289V8.38652C0.722266 8.63789 0 9.45293 0 10.4203V36.89C0 38.0555 1.04141 39 2.32637 39H13.8994H18.3002H40.6736C41.9586 39 43 38.0555 43 36.89V15.0363C43 14.0689 42.2777 13.2539 41.3035 13.0025ZM36.8943 3.75527H40.0438V12.9264H36.8943V3.75527ZM2.95625 1.14258H7.30664H9.38105H35.6346V12.9264H29.5877H28.3363H19.0057C18.2666 12.9264 17.5695 12.6674 17.0488 12.1951L13.4459 8.92734C13.0092 8.53125 12.4213 8.31035 11.7998 8.31035H2.95625V1.14258ZM41.7402 36.89C41.7402 37.4232 41.2615 37.8574 40.6736 37.8574H18.3002H13.8994H2.32637C1.73848 37.8574 1.25977 37.4232 1.25977 36.89V10.4203C1.25977 9.88711 1.73848 9.45293 2.32637 9.45293H11.8082C12.0938 9.45293 12.3625 9.55195 12.5641 9.73477L16.167 13.0025C16.9229 13.6881 17.9391 14.0689 19.0141 14.0689H28.3447H29.5961H40.682C41.2699 14.0689 41.7486 14.5031 41.7486 15.0363V36.89H41.7402Z"
      />
      <path
        className={color}
        d="M10.6416 3.60291H27.9508C28.2951 3.60291 28.5807 3.34392 28.5807 3.03162C28.5807 2.71931 28.2951 2.46033 27.9508 2.46033H10.6416C10.2973 2.46033 10.0117 2.71931 10.0117 3.03162C10.0117 3.34392 10.2973 3.60291 10.6416 3.60291Z"
      />
      <path
        className={color}
        d="M32.3926 5.69763C32.3926 5.38533 32.107 5.12634 31.7627 5.12634H6.81934C6.475 5.12634 6.18945 5.38533 6.18945 5.69763C6.18945 6.00994 6.475 6.26892 6.81934 6.26892H31.7627C32.1154 6.26892 32.3926 6.01755 32.3926 5.69763Z"
      />
      <path
        className={color}
        d="M31.7635 7.79999H16.3271C15.9828 7.79999 15.6973 8.05897 15.6973 8.37128C15.6973 8.68358 15.9828 8.94257 16.3271 8.94257H31.7719C32.1162 8.94257 32.4018 8.68358 32.4018 8.37128C32.4018 8.05897 32.1162 7.79999 31.7635 7.79999Z"
      />
      <path
        className={color}
        d="M31.7625 10.466H20.6514C20.307 10.466 20.0215 10.725 20.0215 11.0373C20.0215 11.3496 20.307 11.6086 20.6514 11.6086H31.7625C32.1068 11.6086 32.3924 11.3496 32.3924 11.0373C32.3924 10.725 32.1152 10.466 31.7625 10.466Z"
      />
      <path
        className={color}
        d="M9.45684 32.3197H3.34277C2.99844 32.3197 2.71289 32.5787 2.71289 32.891V35.9683C2.71289 36.2806 2.99844 36.5396 3.34277 36.5396H9.44844C9.79277 36.5396 10.0783 36.2806 10.0783 35.9683V32.891C10.0867 32.5711 9.80117 32.3197 9.45684 32.3197ZM8.82695 35.3971H3.97266V33.4623H8.81855V35.3971H8.82695Z"
      />
      <path
        className={color}
        d="M9.45684 27.3914H3.34277C2.99844 27.3914 2.71289 27.6504 2.71289 27.9627C2.71289 28.275 2.99844 28.534 3.34277 28.534H9.44844C9.79277 28.534 10.0783 28.275 10.0783 27.9627C10.0783 27.6504 9.80117 27.3914 9.45684 27.3914Z"
      />
      <path
        className={color}
        d="M9.45684 29.8517H3.34277C2.99844 29.8517 2.71289 30.1107 2.71289 30.423C2.71289 30.7353 2.99844 30.9943 3.34277 30.9943H9.44844C9.79277 30.9943 10.0783 30.7353 10.0783 30.423C10.0783 30.1107 9.80117 29.8517 9.45684 29.8517Z"
      />
    </svg>
  );
}

AllNotes.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default AllNotes;
