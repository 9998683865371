import PropTypes from "prop-types";

function House({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="11.5" width="27" height="12" className={color} />
      <rect x="0.5" y="0.5" width="31" height="11" className={color} />
      <path d="M13.5 16.5H18.5V23.5H13.5V16.5Z" className={color} />
      <rect x="5.5" y="15.5" width="4" height="4" className={color} />
      <path
        className={color}
        d="M8.5 11.4996V7.35367L10.4656 6.12519L12.5 7.35818V11.4996H8.5Z"
      />
      <path
        className={color}
        d="M19.5 11.4996V7.35367L21.4656 6.12519L23.5 7.35818V11.4996H19.5Z"
      />
    </svg>
  );
}

House.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default House;
