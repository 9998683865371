import PropTypes from "prop-types";

function NameCell({ student, ...restProps }) {
  const name = `${student.firstName} ${student.lastName}`;
  return (
    <div role="button" {...restProps}>
      <p className="font-bold">{name}</p>
    </div>
  );
}

NameCell.propTypes = {
  student: PropTypes.shape().isRequired,
};

export { NameCell };
