import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { AffordabilitySquare } from "../../../../../core/compose/affordability";

function AffordabilityCell({ chosenSchools, ...restProps }) {
  const [showPlus, setShowPlus] = useState(false);
  const [localSchools, setLocalSchools] = useState([]);

  useEffect(() => {
    setLocalSchools(chosenSchools.slice(0, 5));

    if (chosenSchools.length > 5) setShowPlus(true);
    else setShowPlus(false);
  }, [chosenSchools]);

  return (
    <div
      role="button"
      className="flex flex-1 flex-row justify-center"
      {...restProps}
    >
      {chosenSchools.length === 0 && (
        <div
          className="
            py-2
            px-4
            mx-auto
            text-xs
            normal-case
            text-center
            font-semibold
            bg-light-gray
            text-gray-500
          "
        >
          No College Added
        </div>
      )}
      <div>
        <div className="flex flex-1 flex-row items-center justify-center">
          {localSchools.map((chosenSchool, index) => (
            <AffordabilitySquare
              key={index}
              data-tooltip-id="affordability-square"
              coverage={chosenSchool.coverage}
              data-tooltip-content={chosenSchool.schoolName}
              affordability={chosenSchool.affordability}
            />
          ))}
          {showPlus && (
            <div
              className="
                ml-1
                text-xs
                text-gray-500
              "
            >{`+${chosenSchools.length - 5}`}</div>
          )}
        </div>
      </div>
    </div>
  );
}

AffordabilityCell.propTypes = {
  chosenSchools: PropTypes.arrayOf(
    PropTypes.shape({
      schoolName: PropTypes.string,
      affordability: PropTypes.string,
    }),
  ).isRequired,
};

export { AffordabilityCell };
