import PropTypes from "prop-types";

function Item({ text, icon, iconLabel }) {
  return (
    <div className="mb-4 flex flex-1 flex-row justify-center">
      <div className="mt-2 flex flex-1 flex-col items-center">
        {icon}
        {iconLabel && (
          <p className="text-center font-semibold text-xs uppercase">
            {iconLabel}
          </p>
        )}
      </div>
      <div className="px-4 flex flex-2">{text}</div>
    </div>
  );
}

Item.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.any,
  iconLabel: PropTypes.string,
};

export default Item;
