import { AdvisorRoles } from "../components/Dashboard/Advisors/Utils";

import { Students, Advisors } from "../core/icons";

import StudentsScreenOnboarding from "../components/Onboarding/Layout/Students";
import AdvisorsScreenOnboarding from "../components/Onboarding/Layout/Advisors";

export function getPermissions(role, accessLevel = true) {
  const getBasePermissions = (accessLevel) => {
    return {
      students: {
        canAccess: accessLevel,
        permissions: {
          actionOptions: {
            canAccess: accessLevel,
            canRemoveStudent: accessLevel,
            canMessageStudent: accessLevel,
          },
          canDownloadFile: accessLevel,
          canInviteStudent: accessLevel,
        },
      },
      advisors: {
        canAccess: accessLevel,
        permissions: {
          actionOptions: {
            canAccess: accessLevel,
            canEditAdvisor: accessLevel,
            canDeleteAdvisor: accessLevel,
          },
          canInviteAdvisor: accessLevel,
        },
      },
    };
  };

  const permissions = getBasePermissions(accessLevel);
  switch (role) {
    case AdvisorRoles.ADMIN:
      return {
        ...permissions,
      };
    case AdvisorRoles.ADVISOR:
      return {
        ...permissions,
        advisors: {
          canAccess: false,
        },
      };

    default:
      return getBasePermissions(false);
  }
}

export function getAppSections(permissions, organization) {
  return [
    {
      id: "students",
      label: "All Students",
      list: organization.studentLists,
      icon: (
        <Students
          size={30}
          fillColor="fill-current"
          strokeColor="stroke-current"
        />
      ),
    },
    {
      id: "advisors",
      label: "All Advisors",
      list: organization.advisorLists,
      icon: <Advisors fillColor="fill-current" strokeColor="stroke-current" />,
    },
  ].filter((section) => permissions[section.id].canAccess);
}

export function getAppTabsOnboarding(permissions) {
  const tabs = [
    {
      tabName: "Advisors",
      component: (
        <AdvisorsScreenOnboarding
          permissions={permissions.advisors.permissions}
        />
      ),
      tabIcon: <Advisors />,
    },
    {
      tabName: "Students",
      component: (
        <StudentsScreenOnboarding
          permissions={permissions.students.permissions}
        />
      ),
      tabIcon: <Students />,
    },
  ].filter((tab) => permissions[tab.tabName.toLowerCase()].canAccess);

  return tabs;
}
