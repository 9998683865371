import PropTypes from "prop-types";

import { EarningsCard as CommonEarningsCard } from "../../../../../core/compose/cards";

function EarningsCard({ open, chosenSchool, externalToggle, callbackToggle }) {
  return (
    <CommonEarningsCard
      open={open}
      chosenSchool={chosenSchool}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
    />
  );
}

EarningsCard.propTypes = {
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default EarningsCard;
