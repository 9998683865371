import PropTypes from "prop-types";

import Table from "../../../Common/Table";

function StudentTableBody({
  data,
  columns,
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  handlerOnSelectStudents,
}) {
  return (
    <>
      <Table
        data={data}
        columns={columns}
        pageSize={pageSize}
        itemsCount={itemsCount}
        currentPage={currentPage}
        addSelectionColumn={true}
        onPageChange={onPageChange}
        handlerOnSelectRows={handlerOnSelectStudents}
        tableSelector="viewport-student-invites-table"
        propsBody={{ className: "min-h-15rem h-screen-footer-xl" }}
      />
    </>
  );
}

StudentTableBody.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handlerOnSelectStudents: PropTypes.func.isRequired,
};

export default StudentTableBody;
