export const ModalTypes = {
  EXPORT_CSV: "EXPORT_CSV",
  EDIT_LIST: "EDIT_STUDENT_LIST",
  NOTES_STUDENT: "NOTES_STUDENT",
  INVITE_STUDENT: "INVITE_STUDENT",
  DELETE_STUDENT: "DELETE_STUDENT",
  ASSIGN_ADVISOR: "ASSIGN_ADVISOR",
  MESSAGE_STUDENT: "MESSAGE_STUDENT",
  ARCHIVE_STUDENT: "ARCHIVE_STUDENT",
  UNARCHIVE_STUDENT: "UNARCHIVE_STUDENT",
  CREATE_LIST: "CREATE_STUDENT_LIST",
  ADD_TO_LIST: "ADD_STUDENT_TO_LIST",
  DELETE_LIST: "DELETE_STUDENT_LIST",
  ARCHIVE_LIST: "ARCHIVE_STUDENT_LIST",
  DELETE_INVITE: "DELETE_STUDENT_INVITE",
  UNARCHIVE_LIST: "UNARCHIVE_STUDENT_LIST",
  RE_SEND_INVITE: "RE_SEND_STUDENT_INVITE",
  REMOVE_FROM_LIST: "REMOVE_STUDENT_FROM_LIST",
  ARCHIVE_STUDENT_BULK: "ARCHIVE_STUDENT_BULK",
};

export const SortingType = {
  NAME: "name",
  OWNER: "owner",
  LAST_LOGIN: "lastLogin",
  ITEMS_COUNT: "itemsCount",
  DATE_ARCHIVED: "dateArchived",
};

export const EnrollmentDictionary = {
  ENROLLMENT_1_INTERESTED: "Interested",
  ENROLLMENT_2_APPLIED: "Applied",
  ENROLLMENT_3_ACCEPTED: "Accepted",
  ENROLLMENT_4_UPLOADED_LETTER: "Uploaded Letter",
  ENROLLMENT_5_ENROLLED: "Enrolled",
};
