import PropTypes from "prop-types";

function LinkCustom({
  id,
  href,
  target = "_self",
  className,
  children,
  onClick = () => null
}) {
  return (
    <a
      id={id}
      href={href}
      target={target}
      onClick={onClick}
      rel="noreferrer"
      className={className}
    >
      {children}
    </a>
  );
}

LinkCustom.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.oneOf(["_self", "_blank"])
};

export default LinkCustom;
