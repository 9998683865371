import PropTypes from "prop-types";

function OwnerCell({ owner }) {
  const name = `${owner?.firstName} ${owner?.lastName}`;

  return (
    <div className="flex flex-1 flex-row justify-center">
      <p className="font-bold">{name}</p>
    </div>
  );
}

OwnerCell.propTypes = {
  owner: PropTypes.shape(),
};

export default OwnerCell;
