import PropTypes from "prop-types";

function Letter({
  size = 25,
  fillColor = "fill-white",
  iconColor = "fill-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      className={fillColor}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="black"
        strokeWidth="0.8"
        className={fillColor}
        d="M2.48579 11.5918L4.63817 0.4H31.5121L29.3633 11.1439L2.48579 11.5918Z"
      />
      <path
        stroke="black"
        strokeWidth="0.8"
        className={fillColor}
        d="M2.48792 30.7537L4.63561 20.0152H31.5999V20.7252L29.3714 30.7537H2.48792Z"
      />
      <path
        stroke="black"
        strokeWidth="0.8"
        className={fillColor}
        d="M31.5999 19.6648L29.3802 10.7861L2.78862 10.8989L2.41706 12.0136L4.61604 20.3694H31.5999V19.6648Z"
      />
      <path
        fill="black"
        d="M6.60394 4.33825H17.0902L17.2726 3.46191H6.78631L6.60394 4.33825Z"
      />
      <path
        fill="black"
        d="M6.28546 6.68591H10.8447L11.0271 5.80957H6.46783L6.28546 6.68591Z"
      />
      <path
        fill="black"
        d="M26.2558 13.8457H5.73914L5.87591 14.722H26.3926L26.2558 13.8457Z"
      />
      <path
        fill="black"
        d="M6.37662 16.0391L6.51339 16.9154H25.6623L25.5255 16.0391H6.37662Z"
      />
      <path
        fill="black"
        d="M17.866 24.2305H6.46783L6.28546 25.1068H17.6836L17.866 24.2305Z"
      />
      <path
        fill="black"
        d="M10.663 26.8223H6.10379L5.96701 27.6986H10.5263L10.663 26.8223Z"
      />
      <path
        className={iconColor}
        d="M23.5381 28.7593L23.5133 28.8795H23.636H24.3583H24.442L24.4568 28.7971L24.5756 28.134C25.4253 27.9823 25.9936 27.4263 26.1308 26.7609C26.2005 26.4223 26.1591 26.1358 25.9771 25.8958C25.7994 25.6615 25.4983 25.4861 25.082 25.3406C24.7878 25.2295 24.6044 25.1386 24.4989 25.0539C24.4473 25.0125 24.4191 24.9762 24.4044 24.9445C24.3905 24.9145 24.386 24.883 24.3914 24.845C24.4132 24.7219 24.549 24.547 24.9822 24.547C25.4559 24.547 25.7304 24.6813 25.9236 24.7758C25.9348 24.7813 25.9457 24.7866 25.9563 24.7918L26.0487 24.8366L26.0911 24.7431L26.4522 23.9467L26.4903 23.8627L26.4096 23.818C26.1828 23.6922 25.8999 23.5984 25.4606 23.5843L25.5405 23.0932L25.5594 22.9771H25.4418H24.7195H24.6372L24.6214 23.0579L24.5027 23.6622C23.7239 23.8395 23.1503 24.3291 23.0451 24.9709C22.9741 25.3344 23.064 25.6297 23.2796 25.8611C23.4896 26.0865 23.8119 26.2441 24.1946 26.3575C24.4386 26.4524 24.6033 26.5345 24.6993 26.6173C24.7462 26.6577 24.7718 26.6943 24.7843 26.7278C24.7963 26.7599 24.7988 26.7966 24.7868 26.8431L24.7862 26.8458C24.7365 27.0628 24.5064 27.2141 24.0957 27.2141C23.6572 27.2141 23.2481 27.0614 22.9983 26.91L22.9005 26.8507L22.8548 26.9556L22.4937 27.7838L22.4587 27.864L22.5335 27.9093C22.7923 28.0662 23.1905 28.1894 23.6465 28.233L23.5381 28.7593Z"
      />
    </svg>
  );
}

Letter.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  iconColor: PropTypes.string
};

export default Letter;
