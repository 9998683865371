import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Api from "../../services/Api";
import {
  fetchOrganizations,
  setSelectedOrganizationId
} from "../../redux/Organizations/actions";
import { decidedRoute } from "../../core/utils";

function CreateOrganization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [orgName, setOrgName] = useState("");
  const [error, setError] = useState(false);

  const createOrganizationAndUpdate = async () => {
    try {
      const response = await Api.createOrganization({ name: orgName });
      if (response) {
        await dispatch(fetchOrganizations());
        await dispatch(setSelectedOrganizationId(response.id));
        navigate("/");
      }
    } catch (error) {
      const errorMessage = error.message;
      setError(errorMessage);
    }
  };

  const handleCreateOrg = async () => {
    await createOrganizationAndUpdate();
  };

  const handleChange = (e) => {
    setOrgName(e.target.value);
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start py-20 min-h-screen bg-white">
      <div className="flex flex-col w-1/2 items-center text-center">
        <h1 className="text-primary-blue font-bold text-3xl">
          Now, let’s confirm your info, create an organization and add you to
          it.
        </h1>
        <p className="my-4 font-bold text-lg w-2/3">
          You can invite additional users to your organization anytime from your
          organization settings page.
        </p>
        <div className="flex flex-row items-center justify-center w-2/3 my-4">
          <span className="text-primary-blue font-bold">Organization name</span>
          <input
            id="org-name-input"
            className="border ml-4 h-8 w-1/3 px-1"
            type="text"
            value={orgName}
            onChange={handleChange}
          />
        </div>
        {error && <span className="text-primary-red font-bold">{error}</span>}
        <button
          id="create-org-button"
          disabled={!orgName}
          className="
            w-1/3 
            h-14 
            mt-4 
            mb-4
            bg-primary-blue 
            text-white 
            font-bold 
            px-4 
            py-2 
            hover:drop-shadow-md"
          onClick={handleCreateOrg}
        >
          Create Organization
        </button>
        <p className="text-sm text-center w-1/4 text-wrap ">
          <span>
            By signing up you agree to our
            <br />
          </span>
          <a
            className="underline cursor-pointer"
            href={decidedRoute("/legal/terms-of-service/")}
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            className="underline cursor-pointer"
            href={decidedRoute("/legal/privacy-policy/")}
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default CreateOrganization;
