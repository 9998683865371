import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Viewer from "./Viewer";

import { getGrantsAndScholarships } from "../../../../../core/utils";
import { useOrganizations } from "../../../../../redux/Organizations/hooks";

import Api from "../../../../../services/Api";

function ViewAwardLetterScreen() {
  const navigate = useNavigate();
  const { selectedOrganizationId } = useOrganizations();
  const { collegeId, studentId } = useParams();

  const [awardSelected, setAwardSelected] = useState(null);
  const [collegeSelected, setCollegeSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getAwardLetter = useCallback(async () => {
    try {
      const { student } = await Api.fetchAdvisorStudentDetail({
        organizationId: selectedOrganizationId,
        filter: {
          studentId
        }
      });

      const { chosenSchools } = student;
      const chosenSchool = chosenSchools.find(
        ({ school }) => school.ipedsId === collegeId
      );

      if (chosenSchool) {
        const { award } = chosenSchool;
        setCollegeSelected(chosenSchool);
        setAwardSelected(award);
      } else {
        throw new Error("The school doesn't have an award.");
      }
    } catch (error) {
      console.error(error);
      navigate(
        `/dashboard/students/${studentId}/${collegeId}/upload-award/report-error`
      );
    } finally {
      setIsLoading(false);
    }
  }, [collegeId, navigate, selectedOrganizationId, studentId]);

  useEffect(() => {
    getAwardLetter();
  }, [getAwardLetter]);

  const grants = getGrantsAndScholarships(
    collegeSelected?.award?.aidAmounts || []
  );

  const data = {
    schoolName: collegeSelected?.school?.name,
    file: collegeSelected?.award?.downloadUrl || "",
    grants: grants.map((grant) => ({
      id: grant.id,
      value: grant.value,
      isNew: grant.isNew,
      name: grant.description,
      type: { value: grant.category },
      categorySource: grant.categorySource
    }))
  };

  return (
    <Viewer
      data={data}
      chosenSchool={collegeSelected}
      award={awardSelected}
      onBack={() => navigate(-1)}
      filePurged={collegeSelected?.award?.filePurged || false}
      onClose={() => navigate(`/dashboard/students/${studentId}`)}
      onError={() =>
        navigate(
          `/dashboard/students/${studentId}/${collegeId}/upload-award/report-error`
        )
      }
      isLoading={isLoading}
    />
  );
}

export default ViewAwardLetterScreen;
