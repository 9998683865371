import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";
import { loadState, saveState, Items } from "./storePersist";

const middlewares = [thunk];

const store = createStore(
  rootReducer,
  { organizations: loadState(Items.ORGS) },
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

store.subscribe(() => saveState(store.getState().organizations, Items.ORGS));

export default store;
