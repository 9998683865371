const SET_IS_LOADING = "STUDENTS/SET_IS_LOADING";
const SET_ERROR = "STUDENTS/SET_ERROR";
const SET_PAGE = "STUDENTS/SET_PAGE";
const RESET = "STUDENTS/RESET";

const Types = {
  SET_IS_LOADING,
  SET_ERROR,
  SET_PAGE,
  RESET,
};

export default Types;
