import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import { Select } from "../../../core/inputs";
import { LoadingButton } from "../../../core/buttons";
import { Colors } from "../../../core/utils";

import { useOrganizations } from "../../../redux/Organizations/hooks";
import { interactionEventDataLayer } from "../../../utils/DataLayers";

function EditAccess({
  onClick,
  advisors,
  defaultValues = {},
  requestedClose,
  saveChangesError,
  requestCloseHandle
}) {
  const [access, setAccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [noAccessSelected, setNoAccessSelected] = useState(false);

  const { selectedOrganizationId } = useOrganizations();

  useEffect(() => {
    setAccess(defaultValues.access);
  }, [defaultValues.access]);

  useEffect(() => {
    if (saveChangesError === "access") setNoAccessSelected(true);
  }, [saveChangesError]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      access: access,
      role: access?.value,
      organizationId: selectedOrganizationId,
      advisorIds: advisors.map((advisor) => advisor.advisorId)
    });
  }, [requestCloseHandle, advisors, selectedOrganizationId, access]);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  const handleOnClick = (event) => {
    event.preventDefault();
    if (!access?.value) {
      setNoAccessSelected(true);
      return;
    }

    setIsLoading(true);
    onClick({
      role: access?.value,
      organizationId: selectedOrganizationId,
      advisorIds: advisors.map((advisor) => advisor.advisorId)
    });
    interactionEventDataLayer({
      eventName: "edit_access_save_edits",
      params: {
        user_id: advisors.map((advisor) => advisor.userId)
      }
    });
  };

  const fetchAccessRoles = (search, callback) => {
    const accessRoles = defaultValues.roles.map((access) => ({
      value: access.role,
      label: access.name
    }));

    callback(() =>
      accessRoles.filter((access) =>
        access.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const handlerOnSelectAccess = (data) => {
    setAccess(data);
    setNoAccessSelected(false);
  };

  return (
    <>
      <div className="p-1 h-full overflow-auto">
        <div className="pt-4">
          <span className="text-2xl font-medium">
            You are editing the role for
            {advisors.length === 1 ? (
              <span className="text-primary-blue">
                {` ${advisors[0].firstName} ${advisors[0].lastName} `}
              </span>
            ) : (
              ` ${advisors.length} selected advisors/admins `
            )}
            , which will change their access.
          </span>
        </div>
        <div className="mt-8">
          <Select
            value={access}
            color={Colors.BLUE}
            defaultValue={access}
            placeholder="Select Role"
            loadOptions={fetchAccessRoles}
            onChange={handlerOnSelectAccess}
          />
        </div>
        {noAccessSelected && (
          <p className="mt-2 font-bold text-primary-red">
            You must select an access level.
          </p>
        )}
      </div>
      <div className="py-5 flex flex-row mt-auto">
        <LoadingButton
          isLoading={isLoading}
          id="save-edits-button"
          onClick={handleOnClick}
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          save edits
        </LoadingButton>
      </div>
    </>
  );
}

EditAccess.propTypes = {
  defaultValues: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
  requestedClose: PropTypes.bool.isRequired,
  requestCloseHandle: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default EditAccess;
