import PropTypes from "prop-types";

import { Button } from "../../../../../core/buttons";

function ReportErrorSuccess({ onBack }) {
  return (
    <div
      className="
        p-4
        flex
        flex-1
        h-full
        w-full
        flex-col
        items-center
        justify-center
        bg-secondary-blue
      "
    >
      <div
        className="
          text-6xl
          capitalize
          text-center
          font-extrabold
          text-primary-blue
        "
      >
        error report submitted
      </div>
      <div
        className="
          mt-2
          flex
          px-16
          text-xl
          flex-col
          space-y-4
          text-center
        "
      >
        <p className="font-base">
          Thank you for the heads up. One of our team members needs to take a
          closer look at your question and investigate what might have gone
          wrong.
        </p>
        <p className="font-base">
          We'll email you with any questions or updates. You can expect to hear
          from us in 1 to 3 business days.
        </p>
      </div>
      <div className="mt-20 px-16 w-full">
        <Button
          onClick={onBack}
          className="
            w-full
            normal-case
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
          "
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
}

ReportErrorSuccess.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default ReportErrorSuccess;
