import {
  NameHeader,
  TypeHeader,
  OwnerHeader,
  AdvisorsHeader,
  DateCreatedHeader,
} from "./Header";

import {
  NameCell,
  TypeCell,
  OwnerCell,
  AdvisorsCell,
  DateCreatedCell,
} from "./Cell";

import { SortingType } from "../Utils";
import { clearSort } from "../../../Common/utils";

const ListTypes = [
  {
    value: false,
    key: "PRIVATE",
    name: "My List",
  },
  {
    value: true,
    key: "PUBLIC",
    name: "Org. List",
  },
];

export function getColumns({
  orderBy,
  toggleSortingOrder,
  onFilterListTypeSelect = () => {},
}) {
  return [
    {
      accessor: "name",
      Header: (
        <NameHeader
          clearSort={clearSort({ orderBy, field: SortingType.NAME })}
          onSort={(order) => toggleSortingOrder(SortingType.NAME, order)}
        />
      ),
      Cell: ({ row }) => <NameCell name={row.original.name} />,
      width: "30%",
    },
    {
      accessor: "owner",
      Header: <OwnerHeader />,
      Cell: ({ row }) => <OwnerCell owner={row.original.owner} />,
      width: "25%",
    },
    {
      accessor: "listType",
      Header: (
        <TypeHeader types={ListTypes} onFilter={onFilterListTypeSelect} />
      ),
      Cell: ({ row }) => <TypeCell listType={row.original.listType} />,
      width: "15%",
    },
    {
      accessor: "advisors",
      Header: (
        <AdvisorsHeader
          clearSort={clearSort({ orderBy, field: SortingType.ITEMS_COUNT })}
          onSort={(order) => toggleSortingOrder(SortingType.ITEMS_COUNT, order)}
        />
      ),
      Cell: ({ row }) => <AdvisorsCell advisors={row.original.advisorsCount} />,
      width: "15%",
    },
    {
      accessor: "dateArchived",
      Header: (
        <DateCreatedHeader
          clearSort={clearSort({ orderBy, field: SortingType.DATE_ARCHIVED })}
          onSort={(order) =>
            toggleSortingOrder(SortingType.DATE_ARCHIVED, order)
          }
        />
      ),
      Cell: ({ row }) => (
        <DateCreatedCell dateCreated={row.original.insertTime} />
      ),
      width: "15%",
    },
  ];
}
