import PropTypes from "prop-types";

function TwoStudents({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 97.41 98.48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M47.88,48.2l.37-13.68,5.9-2.2-27.11-11.96L0,30.68l10.58,4.9-.84,13.83,1.38,.09c-.11,.75-.18,1.5-.18,2.26,0,6.05,3.61,11.27,8.78,13.65-10.22,2.76-17.78,12.09-17.78,23.17v9.91H49.99v-9.91c0-11.08-7.56-20.41-17.78-23.17,5.17-2.38,8.78-7.59,8.78-13.65,0-.14-.03-.27-.03-.41l1.03,.06,.49-14.74,.99-.37-.32,11.77,4.74,.13ZM26.92,25.48l14.66,6.47-15.28,5.69-14.12-6.54,14.74-5.62Zm18.33,63.09v5.17H6.68v-5.17c0-10.63,8.65-19.29,19.29-19.29s19.28,8.65,19.28,19.29Zm-9-36.82c0,5.67-4.61,10.29-10.28,10.29s-10.29-4.61-10.29-10.29c0-.67,.08-1.32,.21-1.96l20.31,1.26c.02,.24,.05,.47,.05,.71Zm1.17-5.36l-22.66-1.41,.44-7.27,10.91,5.05,11.58-4.31-.27,7.94Z"
      />
      <path
        className={color}
        d="M91.14,27.84l.37-13.68,5.9-2.2L70.31,0l-27.05,10.31,10.58,4.9-.84,13.83,1.38,.09c-.11,.75-.18,1.5-.18,2.26,0,6.05,3.61,11.27,8.78,13.65-10.22,2.76-17.78,12.09-17.78,23.17v9.91h48.04v-9.91c0-11.08-7.56-20.41-17.78-23.17,5.17-2.38,8.78-7.59,8.78-13.65,0-.14-.03-.27-.03-.41l1.03,.06,.49-14.74,.99-.37-.32,11.77,4.74,.13ZM70.17,5.12l14.66,6.47-15.28,5.69-14.12-6.54,14.74-5.62Zm18.33,63.09v5.17H49.94v-5.17c0-10.63,8.65-19.29,19.29-19.29s19.28,8.65,19.28,19.29Zm-9-36.82c0,5.67-4.61,10.29-10.28,10.29s-10.29-4.61-10.29-10.29c0-.67,.08-1.32,.21-1.96l20.31,1.26c.02,.24,.05,.47,.05,.71Zm1.17-5.36l-22.66-1.41,.44-7.27,10.91,5.05,11.58-4.31-.27,7.94Z"
      />
    </svg>
  );
}

TwoStudents.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default TwoStudents;
