import PropTypes from "prop-types";

function Students({
  size = 25,
  fillColor = "fill-black",
  strokeColor = "stroke-black"
}) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 34 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillColor}
        d="M7.855 23.3816H5.63029C3.47218 23.3816 1.60914 24.8919 1.03106 26.9993H0.000914574C0.603687 24.3518 2.89532 22.3816 5.63029 22.3816H7.855C10.59 22.3816 12.8816 24.3518 13.4844 26.9993H12.4542C11.8762 24.8919 10.0131 23.3816 7.855 23.3816Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M6.74309 23.8062C4.53057 23.8062 2.79478 21.8951 2.79478 19.6116C2.79478 17.328 4.53057 15.417 6.74309 15.417C8.95561 15.417 10.6914 17.328 10.6914 19.6116C10.6914 21.8951 8.95561 23.8062 6.74309 23.8062Z"
      />
      <path
        className={fillColor}
        d="M18.1128 8.46509H15.8881C13.73 8.46509 11.8669 9.9754 11.2889 12.0828H10.2587C10.8615 9.43526 13.1531 7.46509 15.8881 7.46509H18.1128C20.8478 7.46509 23.1394 9.43526 23.7422 12.0828H22.712C22.134 9.9754 20.2709 8.46509 18.1128 8.46509Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M17.0009 8.88918C14.7884 8.88918 13.0526 6.97815 13.0526 4.69459C13.0526 2.41103 14.7884 0.5 17.0009 0.5C19.2134 0.5 20.9492 2.41103 20.9492 4.69459C20.9492 6.97815 19.2134 8.88918 17.0009 8.88918Z"
      />
      <path
        className={fillColor}
        d="M18.1128 20.2417H15.8881C13.73 20.2417 11.8669 21.752 11.2889 23.8594H10.2587C10.8615 21.2119 13.1531 19.2417 15.8881 19.2417H18.1128C20.8478 19.2417 23.1394 21.2119 23.7422 23.8594H22.712C22.134 21.752 20.2709 20.2417 18.1128 20.2417Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M17.0009 20.6653C14.7884 20.6653 13.0526 18.7543 13.0526 16.4707C13.0526 14.1872 14.7884 12.2761 17.0009 12.2761C19.2134 12.2761 20.9492 14.1872 20.9492 16.4707C20.9492 18.7543 19.2134 20.6653 17.0009 20.6653Z"
      />
      <path
        className={fillColor}
        d="M28.3706 11.6062H26.1459C23.9878 11.6062 22.1248 13.1165 21.5467 15.2239H20.5165C21.1193 12.5764 23.4109 10.6062 26.1459 10.6062H28.3706C31.1056 10.6062 33.3972 12.5764 34 15.2239H32.9699C32.3918 13.1165 30.5287 11.6062 28.3706 11.6062Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M27.2587 12.0293C25.0462 12.0293 23.3104 10.1183 23.3104 7.83473C23.3104 5.55117 25.0462 3.64014 27.2587 3.64014C29.4712 3.64014 31.207 5.55117 31.207 7.83473C31.207 10.1183 29.4712 12.0293 27.2587 12.0293Z"
      />
      <path
        className={fillColor}
        d="M28.3706 23.3816H26.1459C23.9878 23.3816 22.1248 24.8919 21.5467 26.9993H20.5165C21.1193 24.3518 23.4109 22.3816 26.1459 22.3816H28.3706C31.1056 22.3816 33.3972 24.3518 34 26.9993H32.9699C32.3918 24.8919 30.5287 23.3816 28.3706 23.3816Z"
      />
      <path
        fill="#DFE0EB"
        className={strokeColor}
        d="M27.2587 23.8062C25.0462 23.8062 23.3104 21.8951 23.3104 19.6116C23.3104 17.328 25.0462 15.417 27.2587 15.417C29.4712 15.417 31.207 17.328 31.207 19.6116C31.207 21.8951 29.4712 23.8062 27.2587 23.8062Z"
      />
    </svg>
  );
}

Students.propTypes = {
  size: PropTypes.number,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string
};

export default Students;
