import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import DeleteList from "../DeleteList";
import Confirm from "../../Common/Confirm";

import Api from "../../../../services/Api";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";

function DeleteStudentListModal({
  lists,
  filters,
  onClose,
  isArchived = false,
  deleteLists,
  organizationId,
  setConfirmModal
}) {
  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const deleteListOnClick = async () => {
    await Api.deleteStudentLists({
      organizationId,
      lists: {
        filter: lists?.length > 0 ? null : filters,
        ids: lists?.length > 0 ? lists.map((list) => list.id) : null
      }
    });
    setConfirm(true);
    setConfirmModal(true);
    dispatch(fetchOrganizations());
  };

  const sharedList = lists?.some((list) => list.isShared) || false;

  const firstTextConfirmation =
    lists?.length > 0 ? (
      <div>
        <p>You have deleted:</p>
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {lists.map((list) => (
              <li key={list.id} className="list-disc list-inside">
                <span>{list.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ) : (
      <p>You have delete {deleteLists} lists</p>
    );

  const singularSecondConfirmationText = (
    <span className="text-2xl font-medium">
      This list will no longer be visible for all organization advisors that it
      is shared with and it will no longer appear in your student lists.
    </span>
  );

  const pluralSecondConfirmationText = (
    <span className="text-2xl font-medium">
      At least one list is shared. These lists will no longer be visible for all
      organization advisors that they are shared with and they will no longer
      appear in your student lists.
    </span>
  );

  const secondTextConfirmation = sharedList ? (
    <span className="text-2xl font-medium">
      {deleteLists === 1
        ? singularSecondConfirmationText
        : pluralSecondConfirmationText}
    </span>
  ) : (
    <span className="text-2xl font-medium">
      {deleteLists === 1 ? "This list " : "These lists "}will no longer appear
      in your student lists.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <DeleteList
          sharedList={sharedList}
          deleteLists={deleteLists}
          onSubmit={deleteListOnClick}
          lists={lists}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText={`Back to ${
            isArchived ? "Archived Lists" : "All Students"
          }`}
        />
      )}
    </div>
  );
}

DeleteStudentListModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  deleteLists: PropTypes.number,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape())
};

export default DeleteStudentListModal;
