import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import AddStudentList from "./AddStudentList";

import Api from "../../../services/Api";

function AddStudentListModal({
  onClose,
  filters,
  students,
  organizationId,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState("");
  const [saveChangesError, setSaveChangesError] = useState(null);

  const addStudentListOnSubmit = async (data) => {
    const dataMutation = {
      organizationId,
      studentListIds: data.selectedLists.map((list) => list.id),
      addStudents: {
        filter: students?.length > 0 ? null : filters,
        studentIds: students?.length > 0 ? students : null,
      },
    };

    await Api.addOrRemoveStudentsToList(dataMutation);

    const isSharedList = data.selectedLists.some((list) => list.shared);

    setTextConfirmation(
      <span className="text-3xl text-black font-semibold">
        Great! You have added one or more student(s) to the
        {data.selectedLists.length === 1 ? " list" : " following lists:"}
        {data.selectedLists.map((list, index) => (
          <span key={`${index}-list-${list.id}`}>
            <span className="text-primary-blue"> "{list.label}"</span>
            {index !== data.selectedLists.length - 1 && ","}
          </span>
        ))}
        .
        {isSharedList && (
          <span>
            {data.selectedLists.length > 1 && <span> One list is shared.</span>}
            <span> All advisors in your organization will see this change</span>
          </span>
        )}
      </span>
    );
    setConfirm(true);
    setConfirmModal(true);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (saveData.selectedLists.length === 0)
      setSaveChangesError("selectedLists");
    else await addStudentListOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const requestCloseHandle = (data) => {
    setSaveChangesError(null);
    setSaveData(data);
    setSave(true);
  };

  const defaultValues = {
    selectedLists: saveData.selectedLists || [],
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <AddStudentList
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          organizationId={organizationId}
          onSubmit={addStudentListOnSubmit}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          onClick={onClose}
          firstText={textConfirmation}
          buttonText="Back to All Students"
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

AddStudentListModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  setOnRequestClose: PropTypes.func,
  students: PropTypes.arrayOf(PropTypes.string),
};

export default AddStudentListModal;
