import { useEffect, useState } from "react";

export function useRefreshFlag({ refreshTimeout }) {
  const [refreshFlag, setRefreshFlag] = useState(0);

  useEffect(() => {
    if (refreshTimeout) {
      const id = setInterval(() => {
        setRefreshFlag(Date.now());
      }, refreshTimeout);

      return () => clearInterval(id);
    }
  }, [refreshTimeout]);

  return { refreshFlag };
}
