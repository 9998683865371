import PropTypes from "prop-types";
import { Link } from "../";
import { ShortArrow } from "../icons";
import { DonutChart } from "../charts";
import { Ethnicity, decidedRoute, ethnicityDisplay, getTheme } from "../utils";

function Diversity({
  ethnicity,
  className,
  affordability,
  diversityPercentage = 0,
  financialAidPercentage = 0,
  studentDiversityPercentage = 0
}) {
  const theme = getTheme({ affordability });
  const isDiversityReported = diversityPercentage > 0;
  const isFinancialAidReported = financialAidPercentage > 0;

  const diversityText = !isDiversityReported
    ? "This school has not reported the diversity of the campus."
    : !ethnicity || ethnicity === Ethnicity.WHITE
    ? `${diversityPercentage}% of students identify as students of color.`
    : `${diversityPercentage}% of students identify as students of color—of which ${studentDiversityPercentage}% of students identify as ${ethnicityDisplay(
        ethnicity
      )}.`;

  const financialAidText = isFinancialAidReported
    ? `${financialAidPercentage}% of students receive financial aid for low-income students.`
    : "This school has not reported on the % of students who receive need-based financial aid.";

  return (
    <div className={className}>
      <div className="flex w-full flex-row items-center">
        <div className="w-1/3">
          <DonutChart
            affordability={affordability}
            percent={isDiversityReported ? diversityPercentage : 0}
          />
        </div>
        <div className="flex w-2/3 flex-row">
          <p className="pl-4 pb-4 text-lg font-medium mobile-sm:text-sm">
            {diversityText}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-row items-center">
        <div className="w-1/3">
          <DonutChart
            affordability={affordability}
            percent={isFinancialAidReported ? financialAidPercentage : 0}
          />
        </div>
        <div className="flex w-2/3 flex-row">
          <p className="pl-4 pb-4 text-lg font-medium mobile-sm:text-sm">
            {financialAidText}
          </p>
        </div>
      </div>
      <Link
        icon={true}
        target="_blank"
        internal={false}
        color={theme.color}
        text="Why Diversity Matters"
        textClassName="font-bold text-left"
        href={decidedRoute("/guide/diversity/")}
        customIcon={
          <div className="transform place-self-end rotate-180">
            <ShortArrow size={16} color="stroke-current" />
          </div>
        }
      />
    </div>
  );
}

Diversity.propTypes = {
  className: PropTypes.string,
  ethnicity: PropTypes.string,
  affordability: PropTypes.string,
  diversityPercentage: PropTypes.number,
  financialAidPercentage: PropTypes.number,
  studentDiversityPercentage: PropTypes.number
};

export default Diversity;
