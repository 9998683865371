import PropTypes from "prop-types";

function EditPencil({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M9.98353 2.12083L7.87917 0.0164648C7.86824 0.00590354 7.85364 0 7.83844 0C7.82325 0 7.80865 0.00590354 7.79772 0.0164648L0.82366 6.99052C0.815168 6.999 0.809563 7.00993 0.807644 7.02178L0.800808 7.01416L0 10L2.98584 9.19919L2.97822 9.19157C2.99006 9.18964 3.00099 9.18404 3.00947 9.17556L9.98353 2.20228C9.9941 2.19135 10 2.17675 10 2.16156C10 2.14636 9.9941 2.13176 9.98353 2.12083Z"
      />
    </svg>
  );
}

EditPencil.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default EditPencil;
