import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FiltersList from "../../../Common/FiltersList";
import { AdvisorRoles } from "../../Advisors/Utils";

import { Button } from "../../../../core/buttons";
import { SearchText, Checkbox } from "../../../../core/inputs";
import { ItemsCounter, Dropdown } from "../../../../core";
import { Colors } from "../../../../core/utils";
import { ButtonTooltip } from "../../../../core/compose";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { fetchAdvisor, updateAdvisor } from "../../../../redux/Advisor/actions";
import { updateWholeListOption } from "../../../../redux/Table/actions";
import { useOrganizations } from "../../../../redux/Organizations/hooks";

import { ModalTypes } from "../Utils";
import Api from "../../../../services/Api";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function StudentTableHeader({
  filters,
  onClear,
  onChange,
  onRemove,
  openModal,
  studentSearch,
  studentsState,
  studentListId,
  organizationId,
  assingAdvisors,
  isArchiveList,
  selectedStudents,
  filterTagsAdvisor,
  filterTagsGradeYear,
  filterTagsAffordability,
  filterTagsEnrollmentStatus
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { studentLists, selectedOrganizationId } = useOrganizations();

  const [list, setList] = useState(null);
  const [isOwner, setIsOwner] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [tooltipOptions, setTooltipOptions] = useState({});

  useEffect(() => {
    const organization = profile?.organizationAdvisors?.items.find(
      (org) => org.organization.id === selectedOrganizationId
    );

    if (organization?.role === AdvisorRoles.ADMIN) {
      setIsAdmin(true);
    }

    if (!isArchiveList && !studentListId && studentsState?.itemsCount > 0) {
      if (organization?.mustSeeArchiveStudentsPopup) {
        openModal(
          {
            numberOfStudents: studentsState?.itemsCount,
            onClick: handlerArchiveStudentsModal
          },
          ModalTypes.ARCHIVE_STUDENT_BULK
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile?.organizationAdvisors?.items,
    selectedOrganizationId,
    studentsState?.itemsCount
  ]);

  useEffect(() => {
    let text;
    let open = false;
    let onClose = null;

    if (studentListId || concatFilters.length > 0) {
      text =
        "This is the total number of active students in your filtered list. You can archive or delete students who are no longer active.";
      open = !profile.onboardingStudentsFiltersTooltup;
      onClose = tooltipOptions.open
        ? () =>
            dispatch(
              updateAdvisor({
                ...profile,
                onboardingStudentsFiltersTooltup: true
              })
            )
        : null;
    } else if (!isArchiveList && studentsState.itemsCount === 0) {
      text =
        "You have not added any students. Click the Invite button in the top right corner to invite students to your organzation.";
      open = !profile.onboardingStudentsTooltip;
      onClose = tooltipOptions.open
        ? () =>
            dispatch(
              updateAdvisor({
                ...profile,
                onboardingStudentsTooltip: true
              })
            )
        : null;
    } else if (!isArchiveList && studentsState.itemsCount > 0) {
      text =
        "This is the total number of active students in your organization. Click the check-box next to one or more student names to complete any action such as making a list.";
      open = !profile.onboardingStudentsTooltip;
      onClose = tooltipOptions.open
        ? () =>
            dispatch(
              updateAdvisor({
                ...profile,
                onboardingStudentsTooltip: true
              })
            )
        : null;
    } else if (isArchiveList) {
      text =
        "These students are not active in your organization. You can unarchive them to see them in All Students, or delete them.";
      open = false;
    }

    setTooltipOptions({ text, open, onClose });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile,
    isArchiveList,
    studentListId,
    studentsState.itemsCount,
    tooltipOptions.open
  ]);

  useEffect(() => {
    const getList = async () => {
      if (studentListId) {
        const list = studentLists.find((list) => list.id === studentListId);

        if (list) {
          if (list.owner.id === profile.id) setIsOwner(true);
          else setIsOwner(false);
          const {
            items: [fetchList]
          } = await Api.fetchOrganizationStudentLists({
            organizationId,
            filter: { ids: [list.id] }
          });
          setList({ ...list, ...fetchList });
        } else navigate("/dashboard/students");
      } else {
        setList(null);
        setIsOwner(true);
      }
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, studentLists, studentListId, organizationId]);

  useEffect(() => {
    if (list) {
      if (isOwner && !list.isShared) {
        interactionEventDataLayer({
          eventName: "student_my_list",
          params: {
            list_id: studentListId
          }
        });
      } else {
        interactionEventDataLayer({
          eventName: "student_org_list",
          params: {
            list_id: studentListId
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const concatFilters = []
    .concat(filterTagsEnrollmentStatus)
    .concat(filterTagsAffordability)
    .concat(filterTagsAdvisor)
    .concat(filterTagsGradeYear);

  const getActionLabel = (count) => {
    return count === 1 ? `${count} Student` : `${count} Students`;
  };

  const handleOnSelectOption = (data) => data.handlerOnClick();

  const handlerOnEditList = () => {
    openModal({ list, organizationId }, ModalTypes.EDIT_LIST);
    interactionEventDataLayer({
      eventName: "student_edit_list_settings_button"
    });
  };

  const handlerOnDeleteList = () => {
    openModal(
      {
        lists: [list],
        deleteLists: 1,
        organizationId
      },
      ModalTypes.DELETE_LIST
    );
    interactionEventDataLayer({
      eventName: "delete_list_button",
      params: {
        list_id: studentListId
      }
    });
  };

  const handlerOnArchiveList = () => {
    openModal(
      {
        lists: [list],
        organizationId,
        archiveLists: 1
      },
      ModalTypes.ARCHIVE_LIST
    );
    interactionEventDataLayer({
      eventName: "student_archive_list_button"
    });
  };

  function injectCSS(cssString) {
    const styleEl = document.createElement("style");
    if (styleEl.styleSheet) {
      styleEl.styleSheet.cssText = cssString;
    } else {
      styleEl.appendChild(document.createTextNode(cssString));
    }
    document.head.appendChild(styleEl);
  }

  const handlerArchiveStudentsModal = async () => {
    const dropdown = document.getElementById("students-dropdown");
    dropdown.dispatchEvent(new Event("click", { bubbles: true }));
    dispatch(updateWholeListOption(true));
    injectCSS(`
    @keyframes slidein {
        from {
            margin-left: 0;
        }
        to {
            margin-left: 10px;
        }
    }
    `);

    setTimeout(() => {
      const archiveOption = document.getElementById(
        "students-dropdown-option-4"
      );
      if (archiveOption) {
        archiveOption.classList.add("bg-secondary-blue");
        archiveOption.style.animation = ".1s linear 0s 10 alternate slidein";
      }
      const dropdownContainer = document.getElementById(
        "students-dropdown-container"
      );
      dropdownContainer.addEventListener("mouseenter", function () {
        archiveOption.classList.remove("bg-secondary-blue");
      });
    }, 500);

    await Api.dismissArchiveStudentsPopup({ organizationId });
    dispatch(fetchAdvisor({ organizationId }));
  };

  const handlerOnCreateList = () => {
    openModal(
      {
        organizationId,
        filters: wholeList ? filters : null,
        students:
          !wholeList && selectedStudents.length > 0
            ? selectedStudents.map((item) => item.student.studentId)
            : null,
        elements: wholeList ? studentsState.itemsCount : selectedStudents.length
      },
      ModalTypes.CREATE_LIST
    );
    interactionEventDataLayer({
      eventName: "create_student_list"
    });
  };

  const messageAction = {
    id: "message",
    enabled: selectedStudents.length > 0 || wholeList,
    handlerOnClick: () => {
      const data = {
        filters,
        organizationId,
        students: !wholeList
          ? selectedStudents.map((item) => item.student)
          : null,
        messageStudents: !wholeList
          ? selectedStudents.length
          : studentsState.itemsCount
      };
      openModal(data, ModalTypes.MESSAGE_STUDENT);
    },
    label: (
      <div className="text-center text-primary-blue">
        Message{" "}
        {getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}
      </div>
    )
  };

  const exportAction = {
    id: "export",
    enabled: selectedStudents.length > 0 || wholeList,
    handlerOnClick: () => {
      interactionEventDataLayer({
        eventName: "export_report_button",
        params: {
          list_id: studentListId
        }
      });
      const data = {
        filters,
        organizationId,
        students: !wholeList
          ? selectedStudents.map((item) => item.student)
          : null
      };
      openModal(data, ModalTypes.EXPORT_CSV);
    },
    label: (
      <div className="text-center text-primary-blue">
        Export Report of{" "}
        {getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}
      </div>
    )
  };

  const removeAction = {
    id: "remove",
    enabled: selectedStudents.length > 0 || wholeList,
    handlerOnClick: () => {
      const data = {
        list,
        organizationId,
        filter: filters,
        students: !wholeList
          ? selectedStudents.map((item) => item.student.studentId)
          : null,
        removeStudents: !wholeList
          ? selectedStudents.length
          : studentsState.itemsCount
      };
      interactionEventDataLayer({
        eventName: "student_list_remove_from_list",
        params: {
          user_id: selectedStudents.map((item) => item.student.userId)
        }
      });
      openModal(data, ModalTypes.REMOVE_FROM_LIST);
    },
    label: (
      <div className="text-center text-primary-blue">
        {`Remove ${getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}`}
      </div>
    )
  };

  const archiveAction = {
    id: "archive",
    enabled: selectedStudents.length > 0 || wholeList,
    handlerOnClick: () => {
      const data = {
        organizationId,
        filter: filters,
        students: !wholeList
          ? selectedStudents.map((item) => item.student)
          : null,
        archiveStudents: !wholeList
          ? selectedStudents.length
          : studentsState.itemsCount
      };
      openModal(data, ModalTypes.ARCHIVE_STUDENT);
    },
    label: (
      <div className="text-center text-primary-blue">
        {`Archive ${getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}`}
      </div>
    )
  };

  const addAction = {
    id: "add",
    enabled:
      studentLists.length > 0 &&
      studentLists.some(
        (list) => list.owner.id === profile.id && !list.archived
      ) &&
      (selectedStudents.length > 0 || wholeList),
    handlerOnClick: () => {
      const data = {
        filters,
        organizationId,
        students: !wholeList
          ? selectedStudents.map((item) => item.student.studentId)
          : null
      };
      interactionEventDataLayer({
        eventName: "all_st_add_to_list",
        params: {
          user_id: selectedStudents.map((item) => item.student.userId)
        }
      });
      openModal(data, ModalTypes.ADD_TO_LIST);
    },
    label: (
      <div className="text-center text-primary-blue">
        Add to List{" "}
        {getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}
      </div>
    )
  };

  const assignAction = {
    id: "assign",
    enabled: selectedStudents.length > 0 || wholeList,
    handlerOnClick: () => {
      const data = {
        organizationId,
        assingAdvisors,
        filter: filters,
        students: !wholeList
          ? selectedStudents.map((item) => item.student)
          : null,
        assignStudents: !wholeList
          ? selectedStudents.length
          : studentsState.itemsCount
      };
      openModal(data, ModalTypes.ASSIGN_ADVISOR);
    },
    label: (
      <div className="text-center text-primary-blue">
        Assign Advisor to{" "}
        {getActionLabel(
          wholeList ? studentsState?.itemsCount : selectedStudents.length
        )}
      </div>
    )
  };

  const dropdownOptions = [
    messageAction,
    addAction,
    assignAction,
    exportAction,
    archiveAction
  ];

  const dropdownOptionsList = [
    messageAction,
    exportAction,
    removeAction,
    archiveAction
  ];

  const dropdownArchiveOptions = [
    {
      id: "unarchive",
      enabled: selectedStudents.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          organizationId,
          filter: filters,
          students: !wholeList
            ? selectedStudents.map((item) => item.student)
            : null,
          unarchiveStudents: !wholeList
            ? selectedStudents.length
            : studentsState.itemsCount
        };
        openModal(data, ModalTypes.UNARCHIVE_STUDENT);
      },
      label: (
        <div className="text-center text-primary-blue">
          {`Unarchive ${getActionLabel(
            wholeList ? studentsState?.itemsCount : selectedStudents.length
          )}`}
        </div>
      )
    },
    {
      id: "delete",
      enabled: selectedStudents.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          organizationId,
          filter: filters,
          students: !wholeList
            ? selectedStudents.map((item) => item.student)
            : null,
          deleteStudents: !wholeList
            ? selectedStudents.length
            : studentsState.itemsCount
        };
        openModal(data, ModalTypes.DELETE_STUDENT);
      },
      label: (
        <div className="text-center text-primary-blue">
          {`Delete ${getActionLabel(
            wholeList ? studentsState?.itemsCount : selectedStudents.length
          )}`}
        </div>
      )
    }
  ];

  const getListActions = () => {
    return isOwner
      ? dropdownOptionsList
      : dropdownOptionsList.filter((action) => action.id !== "remove");
  };

  const createdDate = new Date(list?.insertTime).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric"
  });

  const onClickFunctionDropdown = () => {
    !studentListId &&
      interactionEventDataLayer({
        eventName: "action_button_all_students"
      });
  };

  return (
    <>
      {list && (
        <div className="flex flex-row justify-between">
          <p className="mb-2 text-xl font-semibold text-primary-blue">
            {list.name}
          </p>
          <p className="text-sm font-semibold text-gray-500">
            List owned by {list.owner.advisor?.firstName}{" "}
            {list.owner.advisor?.lastName} created on {createdDate}
          </p>
        </div>
      )}
      {isArchiveList && (
        <div className="flex flex-1 text-sm flex-row items-center">
          <div className="flex flex-1 flex-row">
            <div className="flex flex-col">
              <div className="text-4xl font-bold">Archived Students</div>
            </div>
          </div>
          <div className="flex flex-1 flex-row space-x-4 justify-end">
            <div className="min-w-15rem font-bold">
              <Dropdown
                id="students-dropdown"
                color={Colors.BLUE}
                defaultLabel="Action"
                options={dropdownArchiveOptions}
                handleOnSelect={handleOnSelectOption}
                onClickFunction={onClickFunctionDropdown}
              />
            </div>
          </div>
        </div>
      )}
      <div className="mb-2 flex flex-1 text-sm flow-row space-x-4">
        <div className="flex flex-1 flex-row space-between">
          {concatFilters.length > 1 && (
            <p
              className="mr-2 self-center font-semibold text-primary-blue cursor-pointer"
              onClick={onClear}
            >
              Clear Filters
            </p>
          )}
          <FiltersList
            onRemove={onRemove}
            filters={concatFilters}
            isLoading={studentsState.isLoading}
          />
        </div>
        <div>
          <Button
            id="delete-list-button"
            onClick={() => handlerOnDeleteList()}
            className={`
              my-1
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${studentListId && isOwner ? "block" : "hidden"}
            `}
          >
            Delete List
          </Button>
        </div>
        <div>
          <Button
            id="archive-list-button"
            onClick={() => handlerOnArchiveList()}
            className={`
              my-1
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${studentListId && (isOwner || isAdmin) ? "block" : "hidden"}
            `}
          >
            Archive List
          </Button>
        </div>
      </div>
      <div className="flex flex-1 text-sm flex-row justify-start">
        <div className="flex flex-1 flex-row justify-start">
          <SearchText
            id="students-search-bar"
            handlerOnSubmit={onChange}
            placeholder="Student Search"
            defaultValue={studentSearch}
          />
        </div>
        <div className="flex flex-1 flex-row space-x-4 justify-end">
          {!isArchiveList && (
            <Button
              id="create-list-button"
              onClick={() =>
                studentListId ? handlerOnEditList() : handlerOnCreateList()
              }
              className={`
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
              ${!isOwner && "hidden"}
            `}
            >
              {studentListId ? "Edit List Settings" : "Create List"}
            </Button>
          )}
          {!isArchiveList && (
            <div className="min-w-15rem font-bold">
              <Dropdown
                id="students-dropdown"
                color={Colors.BLUE}
                defaultLabel="Action"
                handleOnSelect={handleOnSelectOption}
                onClickFunction={onClickFunctionDropdown}
                options={studentListId ? getListActions() : dropdownOptions}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 flex text-sm flex-row items-center">
        <ItemsCounter
          pluralDescriptionItems={
            selectedStudents.length > 0 || wholeList
              ? "Students Selected"
              : "Students"
          }
          singularDescriptionItems={
            selectedStudents.length > 0 || wholeList
              ? "Student Selected"
              : "Student"
          }
          numberItems={
            !wholeList && selectedStudents.length > 0
              ? selectedStudents.length
              : studentsState?.itemsCount || 0
          }
        />
        <ButtonTooltip
          questionSize={20}
          tooltipColor={Colors.BLUE}
          tooltipCloseButton={true}
          tooltipDirection={"TOP-LEFT"}
          tooltipClassName="-left-2 top-16"
          tooltipCloseOnClickOutside={true}
          tooltipOptions={tooltipOptions}
        />
        {selectedStudents.length > 0 &&
          studentsState.itemsCount !== selectedStudents.length && (
            <div className="w-fit">
              <Checkbox
                color={Colors.BLUE}
                value={wholeList}
                checked={wholeList}
                label={`Select All (${studentsState?.itemsCount} Students)`}
                onChange={() => {
                  dispatch(updateWholeListOption(!wholeList));
                }}
              />
            </div>
          )}
      </div>
    </>
  );
}

StudentTableHeader.propTypes = {
  openModal: PropTypes.func,
  filters: PropTypes.shape(),
  studentListId: PropTypes.string,
  organizationId: PropTypes.string,
  studentsState: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  studentSearch: PropTypes.string.isRequired,
  filterTagsAdvisor: PropTypes.array.isRequired,
  filterTagsAffordability: PropTypes.array.isRequired,
  filterTagsEnrollmentStatus: PropTypes.array.isRequired,
  assingAdvisors: PropTypes.arrayOf(PropTypes.PropTypes.shape()).isRequired,
  selectedStudents: PropTypes.arrayOf(PropTypes.PropTypes.shape()).isRequired
};

export default StudentTableHeader;
