import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import LayoutManager from "../Common/LayoutManager";
import { LayoutCenterContent, LayoutDesktop } from "../Common/Layout";

import { Button, LoadingButton } from "../../core/buttons";

import Api from "../../services/Api";
import { useAdvisor } from "../../redux/Advisor/hooks";
import { updateAdvisorLocal } from "../../redux/Advisor/actions";
import { useOrganizations } from "../../redux/Organizations/hooks";
import { fetchOrganizations } from "../../redux/Organizations/actions";
import { interactionEventDataLayer } from "../../utils/DataLayers";

export default function EmailUpdate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateId } = useParams();

  const advisorState = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMutation, setIsLoadingMutation] = useState(false);
  const [emailChange, setEmailChange] = useState();

  const skipOnClick = () => navigate("/");

  const acceptOnClick = async () => {
    setIsLoadingMutation(true);
    try {
      const advisor = advisorState.profile;
      const { newEmail } = await Api.confirmEmailChange({ updateId });
      await dispatch(fetchOrganizations());
      await dispatch(updateAdvisorLocal({ ...advisor, email: newEmail }));
      navigate("/");
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingMutation(false);
    }
  };

  useEffect(() => {
    const { profile } = advisorState;
    const organization = profile?.organizations?.items?.find(
      (org) => org.organization.organizationId === selectedOrganizationId
    );

    if (organization)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          screen_name: "EmailUpdate",
          userId: profile.advisorId,
          organizationName: organization.organization.name,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  useEffect(() => {
    const fetchEmailUpdate = async () => {
      if (updateId) {
        try {
          setEmailChange(await Api.emailChangeRequest({ updateId }));
        } catch (error) {
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    setIsLoading(true);
    fetchEmailUpdate();
  }, [updateId]);

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }

  const title = "Are you sure you want to change your email?";
  const content = (
    <>
      <p>
        From: <span className="font-semibold">{emailChange?.oldEmail} </span>
      </p>
      <p>
        to: <span className="font-semibold">{emailChange?.newEmail}</span>
      </p>
    </>
  );

  return (
    <LayoutManager showHeader={true} isHeaderSimple={true}>
      <LayoutDesktop height="min-h-screen-footer">
        <LayoutCenterContent className="shadow-lg relative overflow-hidden">
          <div className="flex flex-1 flex-row">
            <p
              className="
                mb-8
                text-3xl
                font-bold
                leading-none
                tablet:px-6
                tablet:py-10
                tablet-sm:px-6
                tablet-sm:py-10
                laptop:text-4xl
                desktop:text-5xl
              "
            >
              Confirm your profile update
            </p>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="my-12">
              <div className="text-4xl text-bold-blue font-extrabold">
                {error ? error?.errors[0]?.message : title}
              </div>
              <div
                className={`text-xl text-black my-12 mb-4 ${error && "hidden"}`}
              >
                {content}
              </div>
            </div>
            <div
              className={`flex flex-row flex-1 space-x-4 ${error && "hidden"}`}
            >
              <div className="w-1/2">
                <Button
                  className="
                    w-full
                    bg-white
                    text-primary-blue
                    border-primary-blue
                    hover:text-white
                    active:text-white
                    hover:bg-primary-blue
                    active:bg-primary-blue
                  "
                  onClick={() => skipOnClick()}
                >
                  Skip
                </Button>
              </div>
              <div className="w-1/2">
                <LoadingButton
                  isLoading={isLoadingMutation}
                  onClick={() => acceptOnClick()}
                  id="delete-group-save-button"
                  className="
                    w-full
                    text-white
                    bg-primary-blue
                    border-primary-blue
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-blue
                    active:text-primary-blue
                  "
                >
                  Accept
                </LoadingButton>
              </div>
            </div>
            <div className={`flex flex-row flex-1 ${!error && "hidden"}`}>
              <div className="w-full">
                <Button
                  onClick={() => skipOnClick()}
                  className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
                >
                  Return
                </Button>
              </div>
            </div>
          </div>
        </LayoutCenterContent>
      </LayoutDesktop>
    </LayoutManager>
  );
}
