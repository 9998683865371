import { useMemo, useCallback, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { debounce, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import Insights from "../../Insights";
import NoResults from "../../../Common/NoResults";
import StudentTableBody from "./StudentTableBody";
import StudentTableHeader from "./StudentTableHeader";
import FloatLoader from "../../../Common/FloatLoader";

import { getColumns } from "./columns";
import { Order } from "../../../../utils/order";
import { ModalTypes, SortingType } from "../Utils";
import { FilterTag } from "../../../Common/FiltersList";
import ModalContext from "../../../../contexts/ModalContext";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { useStudents } from "../../../../redux/Students/hooks";
import { useFetchOrganizationAdvisors } from "../../../Common/Hooks";
import { useOrganizations } from "../../../../redux/Organizations/hooks";

import { updateWholeListOption } from "../../../../redux/Table/actions";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";
import { Items, loadState, saveState } from "../../../../redux/storePersist";
import {
  resetStudentState,
  fetchOrganizationStudents
} from "../../../../redux/Students/actions";

import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function getData(awsData) {
  const students = awsData || [];
  return students.map((item) => {
    let enrollmentStatus = "";
    return {
      item,
      affordability: item.student.chosenSchools.map((chosenSchool) => {
        if (chosenSchool.enrollmentStatus > enrollmentStatus) {
          enrollmentStatus = chosenSchool.enrollmentStatus;
        }
        return {
          schoolName: chosenSchool.school.name,
          affordability:
            chosenSchool.award?.postAwardSchoolCosts?.affordability,
          coverage: chosenSchool.award?.postAwardSchoolCosts?.coverage
        };
      }),
      student: {
        enrollmentStatus,
        studentId: item.id,
        email: item.student.email,
        isPell: item.student.isPell,
        userId: item.student.userId,
        lastName: item.student.lastName,
        firstName: item.student.firstName,
        gradeYear: item.student.gradeYear,
        lastLogin: item.student.lastLogin,
        assignedAdvisor: item.assignedAdvisor,
        lastLoginHumanize: item.student.lastLoginHumanize
      }
    };
  });
}

function StudentTable({ isArchiveList = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { listId } = useParams();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const studentsState = useStudents();
  const modalContext = useContext(ModalContext);

  const { advisors } = useFetchOrganizationAdvisors(selectedOrganizationId);

  const pageSize = 20;

  const [data, setData] = useState([]);
  const [doFetch, setDoFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [getDataFlag, setGetDataFlag] = useState(false);
  const [studentSearch, setStudentSearch] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [temStudentSearch, setTemStudentSearch] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [filterTagsAdvisor, setFilterTagsAdvisor] = useState([]);
  const [studentListId, setStudentListId] = useState(listId || null);
  const [orderBy, setOrderBy] = useState({ [SortingType.NAME]: Order.asc });
  const [filterTagsAffordability, setFilterTagsAffordability] = useState([]);
  const [filterTagsGradeYear, setFilterTagsGradeYear] = useState([]);
  const [organizationId, setOrganizationId] = useState(selectedOrganizationId);
  const [filterTagsEnrollmentStatus, setFilterTagsEnrollmentStatus] = useState(
    []
  );

  const defaultFilters = {
    search: null,
    gradeYear: [],
    affordability: [],
    noAwardLetter: null,
    assignedAdvisor: [],
    noCollegeAdded: null,
    enrollmentStatus: [],
    archived: isArchiveList,
    onlyStudentsFromList: isArchiveList ? null : studentListId
  };
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    setOrganizationId(selectedOrganizationId);
  }, [selectedOrganizationId]);

  useEffect(() => {
    const organization = profile?.organizationAdvisors?.items?.find(
      (org) => org.organization.id === organizationId
    );

    if (!studentListId && organization && getDataFlag)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          user_id: profile.userId,
          screen_name: "StudentsTable",
          search_input: filters.search,
          organization_id: organization?.organization?.id,
          count_of_search_results: studentsState?.itemsCount
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, filters.search, getDataFlag]);

  const assingAdvisors = useMemo(() => {
    const noAdvisor = {
      id: null,
      userId: null,
      name: "Unassigned"
    };
    const advisor = {
      id: profile.id,
      userId: profile.userId,
      name: `${profile.firstName} ${profile.lastName}`
    };

    const sortedAdvisors = [advisor, ...advisors].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return [...sortedAdvisors, noAdvisor];
  }, [profile, advisors]);

  const toggleSortingOrderDebounce = useMemo(
    () =>
      debounce((sortingType, fieldOrder) => {
        setDoFetch(() => {
          setData([]);
          setCurrentPage(1);
          setOrderBy({ [sortingType]: fieldOrder });
          return true;
        });
      }, 500),
    []
  );

  const onChangeSearchStudentDebounce = useMemo(
    () => debounce(setTemStudentSearch, 500),
    []
  );

  const toggleSortingOrder = useCallback(toggleSortingOrderDebounce, [
    toggleSortingOrderDebounce
  ]);

  const onChangeSearchStudent = useCallback(onChangeSearchStudentDebounce, [
    onChangeSearchStudentDebounce
  ]);

  const onFilterEnrollmentStatusSelect = useCallback(
    (filter) => {
      if (filter.key !== FilterTag.NO_COLLEGE) {
        if (
          !filterTagsEnrollmentStatus.some(
            (filterTagEnrollmentStatus) =>
              filterTagEnrollmentStatus.key === filter.key
          )
        ) {
          setFilterTagsEnrollmentStatus([
            ...filterTagsEnrollmentStatus,
            filter
          ]);
        }
      } else {
        if (
          !filterTagsAffordability.some(
            (filterTagAffordability) =>
              filterTagAffordability.key === filter.key
          )
        ) {
          setFilterTagsAffordability([...filterTagsAffordability, filter]);
        }
      }
    },
    [filterTagsAffordability, filterTagsEnrollmentStatus]
  );

  const onFilterAffordabilitySelect = useCallback(
    (filter) => {
      if (
        !filterTagsAffordability.some(
          (filterTagAffordability) => filterTagAffordability.key === filter.key
        )
      ) {
        setFilterTagsAffordability([...filterTagsAffordability, filter]);
      }
    },
    [filterTagsAffordability]
  );

  const onFilterGradeYearSelect = useCallback(
    (filter) => {
      if (
        !filterTagsGradeYear.some(
          (filterGradeYear) => filterGradeYear.key === filter.key
        )
      ) {
        setFilterTagsGradeYear([...filterTagsGradeYear, filter]);
      }
    },
    [filterTagsGradeYear]
  );

  const onFilterAdvisorSelect = useCallback(
    (filter) => {
      if (
        !filterTagsAdvisor.some(
          (filterTagAdvisor) => filterTagAdvisor.key === filter.key
        )
      ) {
        interactionEventDataLayer({
          eventName: "all_st_assigned_advisor",
          params: {
            user_id: filter.userId
          }
        });
        setFilterTagsAdvisor([...filterTagsAdvisor, filter]);
      }
    },
    [filterTagsAdvisor]
  );

  const closeModal = useCallback(
    (modalType) => {
      const fetchStudents = () => {
        setData([]);
        setCurrentPage(1);
        setDoFetch(true);
      };

      const updateWholeList = () => dispatch(updateWholeListOption(false));

      if (modalType) {
        switch (modalType) {
          case ModalTypes.ASSIGN_ADVISOR:
          case ModalTypes.DELETE_STUDENT:
          case ModalTypes.ARCHIVE_STUDENT:
          case ModalTypes.REMOVE_FROM_LIST:
            if (wholeList) updateWholeList();
            fetchStudents();
            break;
          case ModalTypes.EXPORT_CSV:
          case ModalTypes.ADD_TO_LIST:
          case ModalTypes.MESSAGE_STUDENT:
            if (wholeList) updateWholeList();
            break;
          case ModalTypes.ARCHIVE_LIST:
            dispatch(fetchOrganizations());
            navigate("/dashboard/students");
            break;
          case ModalTypes.UNARCHIVE_STUDENT:
            dispatch(fetchOrganizations());
            if (wholeList) updateWholeList();
            fetchStudents();
            navigate("/dashboard/students/items/archive");
            break;
          default:
            break;
        }
      }
    },
    [dispatch, navigate, wholeList]
  );

  const onClickStudent = useCallback(
    (row) => {
      if (!isArchiveList) {
        const studentSelected = row.original.student;
        interactionEventDataLayer({
          eventName: "student_detail_button",
          params: {
            userId: studentSelected.studentId
          }
        });
        let studentDetailUrl = `/dashboard/students/${studentSelected.studentId}`;
        if (studentListId) studentDetailUrl += `?listId=${studentListId}`;
        navigate(studentDetailUrl);
      }
    },
    [isArchiveList, studentListId, navigate]
  );

  const handlerOnSelectStudents = useCallback(
    (selectedStudents) => setSelectedStudents(selectedStudents),
    []
  );

  const columns = useMemo(
    () =>
      getColumns({
        orderBy,
        assingAdvisors,
        toggleSortingOrder,
        onClickStudent,
        onFilterAdvisorSelect,
        onFilterGradeYearSelect,
        onFilterAffordabilitySelect,
        onFilterEnrollmentStatusSelect
      }),
    [
      orderBy,
      assingAdvisors,
      toggleSortingOrder,
      onClickStudent,
      onFilterAdvisorSelect,
      onFilterGradeYearSelect,
      onFilterAffordabilitySelect,
      onFilterEnrollmentStatusSelect
    ]
  );

  useEffect(() => {
    onClearAllFilters();

    setStudentListId(listId || null);
  }, [listId]);

  useEffect(() => {
    closeModal(modalContext.modalResponse);
  }, [closeModal, modalContext.modalResponse]);

  useEffect(() => {
    if (!studentsState.isLoading) setStudentSearch(temStudentSearch);
  }, [studentsState.isLoading, temStudentSearch]);

  useEffect(() => {
    const { internalState } = loadState(Items.APP_STATE);

    if (internalState?.filters) {
      handlerOnViewInsight(internalState?.filters);
      saveState({ internalState: {} }, Items.APP_STATE);
      return;
    }

    dispatch(resetStudentState());
    setData([]);
    const filters = {
      gradeYear: [],
      affordability: [],
      noAwardLetter: null,
      noCollegeAdded: null,
      enrollmentStatus: [],
      archived: isArchiveList,
      search: studentSearch || null,
      onlyStudentsFromList: isArchiveList ? null : studentListId,
      assignedAdvisor: filterTagsAdvisor.map((advisor) => advisor.id)
    };

    for (const tagType of filterTagsAffordability) {
      switch (tagType.key) {
        case FilterTag.AFFORDABLE:
          filters.affordability.push(FilterTag.AFFORDABLE);
          break;
        case FilterTag.NOT_AFFORDABLE:
          filters.affordability.push(FilterTag.NOT_AFFORDABLE);
          break;
        case FilterTag.SOMEWHAT_AFFORDABLE:
          filters.affordability.push(FilterTag.SOMEWHAT_AFFORDABLE);
          break;
        case FilterTag.NO_AWARD:
          filters.noAwardLetter = true;
          break;
        case FilterTag.NO_COLLEGE:
          filters.noCollegeAdded = true;
          break;
        default:
          console.log(`Ignoring filter ${tagType.key} for fetchStudentList()`);
          break;
      }
    }
    for (const tagType of filterTagsEnrollmentStatus) {
      switch (tagType.key) {
        case FilterTag.ENROLLMENT_1_INTERESTED:
          filters.enrollmentStatus.push(FilterTag.ENROLLMENT_1_INTERESTED);
          break;
        case FilterTag.ENROLLMENT_2_APPLIED:
          filters.enrollmentStatus.push(FilterTag.ENROLLMENT_2_APPLIED);
          break;
        case FilterTag.ENROLLMENT_3_ACCEPTED:
          filters.enrollmentStatus.push(FilterTag.ENROLLMENT_3_ACCEPTED);
          break;
        case FilterTag.ENROLLMENT_4_UPLOADED_LETTER:
          filters.enrollmentStatus.push(FilterTag.ENROLLMENT_4_UPLOADED_LETTER);
          break;
        case FilterTag.ENROLLMENT_5_ENROLLED:
          filters.enrollmentStatus.push(FilterTag.ENROLLMENT_5_ENROLLED);
          break;
        default:
          console.log(`Ignoring filter ${tagType.key} for fetchStudentList()`);
          break;
      }
    }
    for (const tagType of filterTagsGradeYear) {
      switch (tagType.key) {
        case FilterTag.HIGH_SCHOOL_SENIOR:
          filters.gradeYear.push(FilterTag.HIGH_SCHOOL_SENIOR);
          break;
        case FilterTag.HIGH_SCHOOL_JUNIOR:
          filters.gradeYear.push(FilterTag.HIGH_SCHOOL_JUNIOR);
          break;
        case FilterTag.HIGH_SCHOOL_SOPHOMORE:
          filters.gradeYear.push(FilterTag.HIGH_SCHOOL_SOPHOMORE);
          break;
        case FilterTag.OTHER:
          filters.gradeYear.push(FilterTag.OTHER);
          break;
        default:
          console.log(`Ignoring filter ${tagType.key} for fetchStudentList()`);
          break;
      }
    }

    if (isEqual(defaultFilters, filters)) setFiltersApplied(false);
    else setFiltersApplied(true);
    setFilters(filters);
    setCurrentPage(1);
    setDoFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    studentSearch,
    studentListId,
    isArchiveList,
    filterTagsAdvisor,
    filterTagsGradeYear,
    filterTagsAffordability,
    filterTagsEnrollmentStatus
  ]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchStudents = async () => {
      await dispatch(
        fetchOrganizationStudents({
          orderBy,
          organizationId,
          filter: {
            ...filters,
            onlyStudentsFromList: studentListId
          },
          page: { pageSize, pageNumber: currentPage }
        })
      );
      if (isSubscribed) {
        setDoFetch(false);
        setGetDataFlag(true);
      }
    };

    if (doFetch) fetchStudents();
    return () => {
      isSubscribed = false;
      if (wholeList) dispatch(updateWholeListOption(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doFetch, studentListId]);

  useEffect(() => {
    if (getDataFlag) {
      if (!studentsState.isLoading) {
        setData(getData(studentsState?.pages[currentPage - 1]));
        setGetDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsState.isLoading, studentsState.pages, currentPage, getDataFlag]);

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    setDoFetch(true);
  };

  const onRemoveFilterTag = (tagType) => {
    const { key } = tagType;
    setFilterTagsAdvisor(filterTagsAdvisor.filter((x) => x.key !== key));
    setFilterTagsGradeYear(filterTagsGradeYear.filter((x) => x.key !== key));
    setFilterTagsAffordability(
      filterTagsAffordability.filter((x) => x.key !== key)
    );
    setFilterTagsEnrollmentStatus(
      filterTagsEnrollmentStatus.filter((x) => x.key !== key)
    );
  };

  const onClearAllFilters = () => {
    setStudentSearch("");
    setTemStudentSearch("");
    setFilterTagsAdvisor([]);
    setFilterTagsGradeYear([]);
    setFilterTagsAffordability([]);
    setFilterTagsEnrollmentStatus([]);
  };

  const handlerOnViewInsight = (filterTagsEnrollmentStatus) => {
    onClearAllFilters();

    setFilterTagsEnrollmentStatus(filterTagsEnrollmentStatus);
  };

  const onStudentError = (error) => {
    toast.error(error, {
      progress: 1,
      theme: "colored",
      closeOnClick: true,
      position: "top-right",
      hideProgressBar: false
    });
  };

  if (studentsState.error) onStudentError(studentsState.error);

  return (
    <div className="px-4 pt-8 relative min-h-screen-footer-md">
      <Insights
        profile={profile}
        organizationId={organizationId}
        show={!studentListId && !isArchiveList}
        handlerOnViewInsight={handlerOnViewInsight}
      />
      <StudentTableHeader
        filters={filters}
        onRemove={onRemoveFilterTag}
        onClear={onClearAllFilters}
        isArchiveList={isArchiveList}
        studentListId={studentListId}
        studentsState={studentsState}
        studentSearch={studentSearch}
        organizationId={organizationId}
        assingAdvisors={assingAdvisors}
        onChange={onChangeSearchStudent}
        selectedStudents={selectedStudents}
        filterTagsAdvisor={filterTagsAdvisor}
        openModal={modalContext.openModalHandler}
        filterTagsGradeYear={filterTagsGradeYear}
        filterTagsAffordability={filterTagsAffordability}
        filterTagsEnrollmentStatus={filterTagsEnrollmentStatus}
      />
      <StudentTableBody
        data={data}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        studentListId={studentListId}
        onPageChange={handleOnPageChange}
        itemsCount={studentsState.itemsCount}
        handlerOnSelectStudents={handlerOnSelectStudents}
      />
      {studentsState.isLoading && (
        <FloatLoader show={studentsState.isLoading} />
      )}
      {data.length === 0 && filtersApplied && !studentsState.isLoading && (
        <NoResults trigger={filters} tableSelector="viewport-student-table" />
      )}
    </div>
  );
}

StudentTable.propTypes = {
  isArchiveList: PropTypes.bool
};

export default StudentTable;
