import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import InvitesInsight from "./InvitesInsight";
import AddedCollegeInsight from "./AddedCollegeInsight";
import UploadedLetterInsight from "./UploadedLetterInsight";

import { Link, Tooltip } from "../../../core";
import { GaugeChart } from "../../../core/icons";

import { Colors } from "../../../core/utils";
import { Directions } from "../../../core/Tooltip";
import { useRefreshFlag } from "../../../core/hooks";

import { ModalTypes } from "../Students/Utils";
import { updateAdvisor } from "../../../redux/Advisor/actions";

function Insights({
  show,
  profile,
  modalResponse,
  organizationId,
  invitesInsights = false,
  handlerOnViewInsight
}) {
  const dispatch = useDispatch();
  const { refreshFlag } = useRefreshFlag({ refreshTimeout: 120 * 1000 });

  const [refetchData, setRefetchData] = useState(true);

  useEffect(() => setRefetchData(true), [refreshFlag]);
  useEffect(() => {
    switch (modalResponse) {
      case ModalTypes.DELETE_INVITE:
      case ModalTypes.RE_SEND_INVITE:
      case ModalTypes.INVITE_STUDENT:
      default:
        setRefetchData(true);
    }
  }, [modalResponse]);

  if (!show) return null;

  if (!profile.insightsVisible)
    return (
      <div
        className="
          px-4
          flex
          text-sm
          flex-row
          relative
          space-x-2
          font-bold
          justify-end
        "
      >
        <Link
          text="View my insights"
          onClick={() => {
            const newProfile = {
              ...profile,
              insightsVisible: !profile.insightsVisible
            };
            if (!profile.onboardingInsightsTooltip)
              newProfile.onboardingInsightsTooltip = true;
            dispatch(updateAdvisor(newProfile));
          }}
        />
        <GaugeChart width={40} height={20} />
        <Tooltip
          tooltipCloseButton={true}
          tooltipColor={Colors.BLUE}
          direction={Directions.TOP_RIGHT}
          tooltipClassName="mt-12 right-20"
          open={!profile.onboardingInsightsTooltip}
          tooltipHandleOnClose={() =>
            dispatch(
              updateAdvisor({ ...profile, onboardingInsightsTooltip: true })
            )
          }
          text="If you dismiss insights you can always reactivate them by clicking “View my insights”"
        />
      </div>
    );

  const name = `${profile.firstName} ${profile.lastName}`;

  return (
    <div className="p-4 mb-5 flex flex-col shadow-black">
      <div
        className="
          mb-2
          flex
          flex-1
          text-sm
          flow-row
          font-bold
          items-center
          justify-between
        "
      >
        <div>Hi {name}! Here are some insights</div>
        <Link
          text="Dismiss Insights"
          textClassName="text-gray-400 hover:underline"
          onClick={() =>
            dispatch(
              updateAdvisor({
                ...profile,
                insightsVisible: !profile.insightsVisible
              })
            )
          }
        />
      </div>
      <div
        className="
          mt-1
          flex
          flex-1
          flex-row
          divide-x
          divide-black
        "
      >
        <div className="px-4 w-1/3 flex">
          <InvitesInsight
            refetchData={refetchData}
            setRefetchData={setRefetchData}
            organizationId={organizationId}
            invitesInsights={invitesInsights}
            handlerOnViewInsight={handlerOnViewInsight}
          />
        </div>
        <div className="px-4 w-1/3 flex">
          <AddedCollegeInsight
            refetchData={refetchData}
            setRefetchData={setRefetchData}
            organizationId={organizationId}
            invitesInsights={invitesInsights}
            handlerOnViewInsight={handlerOnViewInsight}
          />
        </div>
        <div className="px-4 w-1/3 flex">
          <UploadedLetterInsight
            refetchData={refetchData}
            setRefetchData={setRefetchData}
            organizationId={organizationId}
            invitesInsights={invitesInsights}
            handlerOnViewInsight={handlerOnViewInsight}
          />
        </div>
      </div>
    </div>
  );
}

Insights.propTypes = {
  show: PropTypes.bool,
  profile: PropTypes.shape(),
  modalResponse: PropTypes.string,
  invitesInsights: PropTypes.bool,
  organizationId: PropTypes.string,
  handlerOnViewInsight: PropTypes.func
};

export default Insights;
