import React, { useState, useEffect } from "react";

import { Button } from "../../core/buttons";
import { RocksPattern } from "../../core/icons/patterns";

function ArchiveStudentsBulkModal({ onClick, onClose, numberOfStudents }) {
  const onClickHandler = () => {
    onClick();
    onClose();
  };

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="archive-student-bulk-modal"
      className="
        pt-2
        flex
        pb-16
        flex-1
        flex-col
        relative
      "
    >
      <div
        className="  
        mx-10
          flex
          flex-1
          flex-col
          justify-center
          mobile:mx-5
          mobile-sm:mx-5
        "
      >
        <div className="pt-8 z-10 flex flex-col items-center">
          <p
            className="
              mx-6
              text-3xl
              text-center
              font-extrabold
              mobile:mx-0
              mobile-sm:mx-0
              tablet-sm:mx-0
            "
          >
            <span>You worked with {numberOfStudents} students last year!</span>
          </p>
          <div className="mt-4 w-2/4">
            <p className="font-bold text-center">
              Ready to start fresh for the upcoming academic year? Archive last
              year's cohort of students from the Action menu below!
            </p>
          </div>
          <div className="flex w-full items-center justify-center pt-8">
            <Button
              id="close-student-bulk-modal-button"
              onClick={() => onClickHandler()}
              className="
                py-2
                w-1/2
                text-2xl
                text-white
                bg-primary-blue
                hover:bg-white
                active:bg-white
                hover:text-primary-blue
                active:text-primary-blue
                hover:border-primary-blue
                active:border-primary-blue
                mobile:w-full
                mobile:text-xl
                mobile-sm:w-full
                mobile-sm:text-xl
                tablet-sm:text-xl
              "
            >
              Show me how
            </Button>
          </div>
          <div className="-top-16 -left-6 absolute">
            <RocksPattern 
              color="fill-primary-green" 
              height={dimensions.height * 0.15} 
              width={dimensions.width * 0.15} 
            />
          </div>
          <div className="bottom-6 -right-6 absolute">
            <RocksPattern
              color="fill-primary-green"
              height={dimensions.height * 0.15} 
              width={dimensions.width * 0.15} 
              className="transform rotate-180"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchiveStudentsBulkModal;
