import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import ArchiveStudent from "./ArchiveStudent";

import Api from "../../../services/Api";

function ArchiveStudentModal({
  filter,
  onClose,
  students,
  organizationId,
  archiveStudents,
  setConfirmModal,
  finishCloseModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const archiveStudentOnClick = async () => {
    await Api.archiveOrUnarchiveStudents({
      archived: true,
      organizationId,
      students: {
        filter: students?.length > 0 ? null : filter,
        studentIds:
          students?.length > 0
            ? students.map((student) => student.studentId)
            : null,
      },
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const firstTextConfirmation =
    students?.length > 0 ? (
      <div>
        <p>Great! You have archived:</p>
        <div className="flex flex-column mt-6">
          <ul className="ml-4 text-2xl text-black font-semibold">
            {students.map((student) => (
              <li key={student.studentId} className="list-disc list-inside">
                <span>
                  {student.firstName} {student.lastName}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ) : (
      <p>You have archived {archiveStudents} students</p>
    );

  const secondTextConfirmation = (
    <span className="text-2xl font-medium">
      This change will now be visible to all advisors in your organization.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <ArchiveStudent
          students={students}
          onClick={archiveStudentOnClick}
          archiveStudents={archiveStudents}
          finishCloseModal={finishCloseModal}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to All Students"
        />
      )}
    </div>
  );
}

ArchiveStudentModal.propTypes = {
  filter: PropTypes.shape(),
  organizationId: PropTypes.string,
  archiveStudents: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  finishCloseModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape()),
};

export default ArchiveStudentModal;
