import { Advisors } from "../../../core/icons";

export const getAssigAdvisorList = (list) => {
  return (
    list?.map((advisor) => ({
      enabled: true,
      id: advisor.id,
      name: advisor.name,
      label: (
        <div className="flex flex-1 flex-row font-medium space-x-4 items-center">
          <div>
            <Advisors fillColor="fill-current" strokeColor="stroke-current" />
          </div>
          <span>{advisor.name}</span>
        </div>
      ),
    })) || []
  );
};
