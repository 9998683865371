import PropTypes from "prop-types";
import { Button } from "../../core/buttons";

function LayoutDesktop({
  children,
  className = "bg-primary-blue min-h-screen-footer"
}) {
  return (
    <div
      className={`
        p-4
        flex
        flex-1
        flex-col
        laptop:flex-row
        desktop:flex-row
        laptop:justify-center
        desktop:justify-center
        ${className}
      `}
    >
      <div
        className="
          flex
          flex-1
          flex-col
          laptop:flex-row
          laptop:max-w-75rem
          desktop:flex-row
          desktop:max-w-75rem
        "
      >
        {children}
      </div>
    </div>
  );
}

LayoutDesktop.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

function LayoutCenterContent({ children, className }) {
  return (
    <div
      className={`
        p-10
        w-full
        my-auto
        mx-auto
        bg-white
        mobile-sm:p-5
        mobile:p-6
        tablet-sm:p-6
        tablet:p-6
        laptop:w-2/3
        desktop:w-2/3
        ${className}
      `}
    >
      {children}
    </div>
  );
}

LayoutCenterContent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

function Layout({ text, children, hasButton, backBtnOnClick }) {
  return (
    <main
      className="
        tablet:flex
        tablet:flex-row
        tablet:flex-1
        tablet:justify-center
        laptop:flex
        laptop:flex-row
        laptop:flex-1
        laptop:justify-center
        desktop:flex
        desktop:flex-row
        desktop:flex-1
        desktop:justify-center
      "
    >
      <div
        className="
          py-8
          tablet:flex
          tablet:flex-row
          tablet:flex-1
          tablet:max-w-75rem
          laptop:flex
          laptop:flex-row
          laptop:flex-1
          laptop:max-w-75rem
          desktop:flex
          desktop:flex-row
          desktop:flex-1
          desktop:max-w-75rem
        "
      >
        <div
          className="
            flex
            flex-1
            flex-col
            w-1/3
            p-8
            mt-4"
        >
          <h1
            className="
              mb-8
              text-3xl
              font-bold
              leading-none
              text-white
              tablet:px-6
              tablet:py-10
              tablet-sm:px-6
              tablet-sm:py-10
              laptop:text-4xl
              desktop:text-5xl
            "
          >
            {text}
          </h1>
          {hasButton && (
            <Button
              onClick={backBtnOnClick}
              className="
                mb-5
                w-2/12
                bg-white
                text-primary-blue
                border-primary-blue
                mobile:text-sm
                mobile-sm:text-sm
                tablet-sm:text-sm
                hover:text-white
                active:text-white
                hover:bg-primary-blue
                hover:border-white
                active:bg-primary-blue
              "
            >
              Back
            </Button>
          )}
        </div>
        {children}
      </div>
    </main>
  );
}

Layout.propTypes = {
  text: PropTypes.string,
  children: PropTypes.any
};

export { LayoutDesktop, LayoutCenterContent };
export default Layout;
