import PropTypes from "prop-types";

import { CloseMini } from "../../core/icons";

import {
  Enrollment,
  Affordability,
  GradeYear,
  getGradeYearLabel,
  getEnrollmentIcon
} from "../../core/utils";

export const FilterTag = {
  ...Enrollment,
  ...Affordability,
  ...GradeYear,
  NO_AWARD: "NO_AWARD",
  NO_COLLEGE: "NO_COLLEGE"
};

function tagValues(tagType) {
  const size = 18;
  switch (tagType?.key) {
    case FilterTag.ENROLLMENT_1_INTERESTED:
      return {
        icon: getEnrollmentIcon({
          size,
          enrollment: FilterTag.ENROLLMENT_1_INTERESTED
        }),
        text: "interested"
      };

    case FilterTag.ENROLLMENT_2_APPLIED:
      return {
        icon: getEnrollmentIcon({
          size: size + 5,
          enrollment: FilterTag.ENROLLMENT_2_APPLIED
        }),
        text: "applied"
      };

    case FilterTag.ENROLLMENT_3_ACCEPTED:
      return {
        icon: getEnrollmentIcon({
          size,
          enrollment: FilterTag.ENROLLMENT_3_ACCEPTED
        }),
        text: "accepted"
      };

    case FilterTag.ENROLLMENT_4_UPLOADED_LETTER:
      return {
        icon: getEnrollmentIcon({
          size,
          enrollment: FilterTag.ENROLLMENT_4_UPLOADED_LETTER
        }),
        text: "uploaded letter"
      };

    case FilterTag.ENROLLMENT_5_ENROLLED:
      return {
        icon: getEnrollmentIcon({
          size,
          enrollment: FilterTag.ENROLLMENT_5_ENROLLED
        }),
        text: "enrolled"
      };

    case FilterTag.AFFORDABLE:
      return {
        color: {
          bg: "bg-primary-green",
          border: "border-primary-green"
        },
        text: "affordable"
      };

    case FilterTag.NOT_AFFORDABLE:
      return {
        color: {
          bg: "bg-primary-red",
          border: "border-primary-red"
        },
        text: "not affordable"
      };

    case FilterTag.SOMEWHAT_AFFORDABLE:
      return {
        color: {
          bg: "bg-primary-orange",
          border: "border-primary-orange"
        },
        text: "somewhat affordable"
      };

    case FilterTag.NO_AWARD:
      return {
        color: {
          bg: "bg-primary-blue",
          border: "border-black"
        },
        text: "no award letter"
      };

    case FilterTag.NO_COLLEGE:
      return {
        color: {
          bg: "bg-light-gray",
          border: "border-light-gray"
        },
        text: "no college added"
      };

    case FilterTag.HIGH_SCHOOL_SENIOR:
      return {
        text: getGradeYearLabel(GradeYear.HIGH_SCHOOL_SENIOR)
      };

    case FilterTag.HIGH_SCHOOL_JUNIOR:
      return {
        text: getGradeYearLabel(GradeYear.HIGH_SCHOOL_JUNIOR)
      };

    case FilterTag.HIGH_SCHOOL_SOPHOMORE:
      return {
        text: getGradeYearLabel(GradeYear.HIGH_SCHOOL_SOPHOMORE)
      };

    case FilterTag.OTHER:
      return {
        text: getGradeYearLabel(GradeYear.OTHER)
      };

    default:
      return {
        text: tagType?.name
      };
  }
}

function Tag({ tagType, onRemove, isLoading }) {
  const tag = tagValues(tagType);
  return (
    <div
      className="
        m-1
        p-2
        flex
        border
        flex-row
        bg-white
        items-center
        border-light-grey
      "
    >
      {tag.icon && tag.icon}
      {tag.color && (
        <div
          className={`
            h-4
            w-5
            border
            ${tag.color.bg}
            ${tag.color.border}
          `}
        ></div>
      )}
      <p
        className="
          mx-2
          font-bold
          capitalize
          mobile:text-xs
          mobile-sm:text-xs
          tablet-sm:text-xs
        "
      >
        {tag.text}
      </p>
      <div
        role="button"
        onClick={() => onRemove(tagType)}
        className={`
          ${isLoading ? "pointer-events-none" : "pointer-events-auto"}
        `}
      >
        <CloseMini size={18} />
      </div>
    </div>
  );
}

Tag.propTypes = {
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  tagType: PropTypes.shape().isRequired
};

function FiltersList({ filters, onRemove, isLoading }) {
  return (
    <div
      className="
        flex
        -mx-1
        flex-1
        text-sm
        flex-row
        flex-wrap
      "
    >
      {filters.map((tagType, index) => (
        <Tag
          key={index}
          tagType={tagType}
          onRemove={onRemove}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
}

FiltersList.propTypes = {
  onRemove: PropTypes.func,
  isLoading: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.shape())
};

export default FiltersList;
