import PropTypes from "prop-types";

import CategoryItem from "./CategoryItem";
import ShowMoreButton from "./ShowMoreButton";

function CategoryGroup({ list, toggle, pathname, toggleOnClick }) {
  return (
    <>
      <div
        className={`
          flex
          flex-col
          space-y-1
          overflow-hidden
          ${
            list.length > 5
              ? toggle
                ? "min-h-fit max-h-26rem overflow-y-auto"
                : "h-8.75rem"
              : "h-fit"
          }
        `}
      >
        {list.map((list) => (
          <CategoryItem list={list} key={list.id} pathname={pathname} />
        ))}
      </div>
      {list.length > 5 && (
        <ShowMoreButton toggle={toggle} onClick={toggleOnClick} />
      )}
    </>
  );
}

CategoryGroup.propTypes = {
  toggle: PropTypes.bool,
  pathname: PropTypes.string,
  toggleOnClick: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape()),
};

export default CategoryGroup;
