import { useCallback, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import { Form } from "../../../core";
import { LoadingButton } from "../../../core/buttons";
import { Colors } from "../../../core/utils";

import { AdvisorRoles } from "../../Dashboard/Advisors/Utils";

import { useAdvisor } from "../../../redux/Advisor/hooks";
import { useFetchOrganizationAdvisors } from "../../Common/Hooks";

function CreateListForm({
  id,
  edit,
  onSubmit,
  isAdvisorList = false,
  defaultValues,
  requestedClose,
  organizationId,
  saveChangesError,
  requestCloseHandle
}) {
  const { profile } = useAdvisor();

  const parentRef = useRef(null);

  const [formValid, setFormValid] = useState(false);
  const [maxHeight, setMaxHeight] = useState(null);

  const dontShare = {
    id: "nobody",
    name: "Don't share with anyone in your organization"
  };
  const wholeOrg = {
    id: "all",
    name: "Share with your whole organization"
  };
  const advisor = {
    id: profile.id,
    name: `${profile.firstName} ${profile.lastName}`
  };
  const { isLoading: isLoadingAdvisors, advisors } =
    useFetchOrganizationAdvisors(organizationId);

  const { reset, watch, setError, setValue, handleSubmit, control, formState } =
    useForm({
      mode: "onChange",
      defaultValues
    });

  const [owner, listName, sharedWith] = watch([
    "owner",
    "listName",
    "sharedWith"
  ]);

  const setSaveData = useCallback(() => {
    requestCloseHandle({
      listName: listName,
      sharedWith: sharedWith,
      owner: owner ? owner : { id: profile.id }
    });
  }, [owner, listName, sharedWith, profile, requestCloseHandle]);

  useEffect(() => {
    const minHeight = 50;

    const updateMaxHeight = () => {
      if (parentRef.current) {
        const parentBottom = parentRef.current.getBoundingClientRect().bottom;
        let availableHeight = parentBottom - 400;
        availableHeight = Math.max(availableHeight, minHeight);

        setMaxHeight(availableHeight);
      }
    };

    updateMaxHeight();

    window.addEventListener("resize", updateMaxHeight);
    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, []);

  useEffect(() => {
    if (requestedClose) setSaveData();
  }, [setSaveData, requestedClose]);

  useEffect(() => {
    if (saveChangesError) {
      if (saveChangesError.includes("listName"))
        setError("listName", {
          message: "List Name is required."
        });
      if (saveChangesError.includes("sharedWith"))
        setError("sharedWith", {
          message: "Share with option is required."
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChangesError]);

  useEffect(() => {
    if (sharedWith?.length > 1) {
      const lastItemSelected = sharedWith[sharedWith.length - 1];
      const hasSpecialItem = sharedWith.some(
        (item) => item.id === "nobody" || item.id === "all"
      );
      const isSpecialItem =
        lastItemSelected.id === "nobody" || lastItemSelected.id === "all";

      if (isSpecialItem) {
        reset({ owner, listName, sharedWith: [lastItemSelected] });
        return;
      } else if (hasSpecialItem) {
        setValue("sharedWith", [lastItemSelected]);
        return;
      }
    }

    if (sharedWith?.length === 0) {
      setValue("sharedWith", null);
      setError("sharedWith", {
        message: "Share with option is required."
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedWith]);

  useEffect(() => {
    if (
      owner &&
      sharedWith &&
      !sharedWith?.some((advisor) => advisor.id === "all")
    ) {
      const advisorId = profile.id;
      if (owner.id !== advisorId) {
        if (!sharedWith.map((advisor) => advisor.id).includes(advisorId))
          setValue("sharedWith", [
            { id: advisor.id, value: advisor.id, label: advisor.name },
            ...sharedWith
          ]);
      } else {
        setValue("sharedWith", [
          ...sharedWith?.filter((advisor) => advisor.id !== advisorId)
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  useEffect(() => {
    if (isEqual({ owner, listName, sharedWith }, defaultValues.list)) {
      setAnyChanges(false);
    } else setAnyChanges(true);
  }, [owner, listName, sharedWith, defaultValues]);

  useEffect(() => setFormValid(formState.isValid), [formState.isValid]);

  const fetchSharedWithList = (search, callback) => {
    const advisorsArr = !isAdvisorList
      ? advisors
      : advisors.filter((advisor) => advisor.role === AdvisorRoles.ADMIN);
    const sortedAdvisors = [advisor, ...advisorsArr].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const options = [dontShare, wholeOrg, ...sortedAdvisors].map((advisor) => ({
      id: advisor.id,
      value: advisor.id,
      label: advisor.name
    }));

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const fetchOwnerList = (search, callback) => {
    const advisorsArr = !isAdvisorList
      ? advisors
      : advisors.filter((advisor) => advisor.role === AdvisorRoles.ADMIN);
    const sortedAdvisors = [advisor, ...advisorsArr].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const options = sortedAdvisors.map((advisor) => ({
      id: advisor.id,
      value: advisor.id,
      label: advisor.name
    }));

    callback(() =>
      options.filter((option) =>
        option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [anyChanges, setAnyChanges] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  const dissableButton = edit ? !anyChanges || !formValid : !formValid;

  return (
    <Form
      id={id}
      onSubmit={handleSubmit(async (form) => {
        setIsLoading(true);
        setSomethingWentWrong(false);
        try {
          const formCreateListData = {
            listName: form.listName,
            sharedWith: form.sharedWith,
            owner: form.owner ? form.owner : advisor
          };
          await onSubmit(formCreateListData);
        } catch (error) {
          setSomethingWentWrong(true);
        }
      })}
    >
      <div className="px-1 h-full pt-2" ref={parentRef}>
        <Form.Input
          required={true}
          name="listName"
          control={control}
          label="list name"
          color={Colors.BLUE}
          id="list-name-input"
          errors={formState.errors}
          rules={{
            required: { value: true, message: "List Name is required." }
          }}
        />
        <Form.ErrorMessage errors={formState.errors} name="listName" />

        {!isLoadingAdvisors && (
          <>
            <Form.Select
              multi={true}
              checkbox={true}
              required={true}
              name="sharedWith"
              control={control}
              color={Colors.BLUE}
              id="shared-with-input"
              loadOptions={fetchSharedWithList}
              placeholder="Who can see this List"
              label={`Share with your whole organization or select ${
                isAdvisorList ? "admins" : "advisors"
              } from the list below`}
              rules={{
                required: {
                  value: true,
                  message: "Share with option is required."
                }
              }}
            />
            <Form.ErrorMessage errors={formState.errors} name="sharedWith" />

            <Form.Select
              name="owner"
              id="owner-input"
              maxHeight={maxHeight}
              control={control}
              label="List Owner"
              color={Colors.BLUE}
              loadOptions={fetchOwnerList}
              placeholder="Change list owner"
            />
            <Form.ErrorMessage errors={formState.errors} name="owner" />
          </>
        )}

        <div className="mt-1">
          {somethingWentWrong && (
            <p className="text-sm font-semibold text-primary-red">
              Something went wrong, please try again later!
            </p>
          )}
        </div>
      </div>
      <div className="mt-auto py-5 flex flex-row">
        <LoadingButton
          form={id}
          type="submit"
          value="submit"
          isLoading={isLoading}
          disabled={dissableButton}
          id="create-edit-list-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-blue
            mobile:w-full
            mobile:text-sm
            mobile-sm:w-full
            mobile-sm:text-sm
            tablet-sm:text-sm
          "
        >
          {edit ? "Save Changes" : "Create List"}
        </LoadingButton>
      </div>
    </Form>
  );
}

CreateListForm.propTypes = {
  id: PropTypes.string,
  edit: PropTypes.bool,
  onSubmit: PropTypes.func,
  isAdvisorList: PropTypes.bool,
  requestedClose: PropTypes.bool,
  organizationId: PropTypes.string,
  defaultValues: PropTypes.shape(),
  requestCloseHandle: PropTypes.func,
  saveChangesError: PropTypes.arrayOf(PropTypes.string)
};

export default CreateListForm;
