import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import DeleteStudent from "./DeleteStudent";

import Api from "../../../services/Api";

function DeleteStudentModal({
  filter,
  onClose,
  students,
  organizationId,
  deleteStudents,
  setConfirmModal,
  finishCloseModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const deleteStudentOnClick = async () => {
    await Api.removeStudentFromOrg({
      organizationId,
      students: {
        filter: {
          ...filter,
          archived: true,
        },
        studentIds:
          students?.length > 0
            ? students.map((student) => student.studentId)
            : null,
      },
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const student = deleteStudents === 1 ? students[0] : null;

  const firstTextConfirmation = (
    <span>
      Great!
      {student ? (
        <span className="text-black">
          {` ${student.firstName} ${student.lastName} `}
        </span>
      ) : (
        ` ${deleteStudents} students `
      )}
      {student ? "has " : "have "}
      been deleted from your organization.
    </span>
  );

  const secondTextConfirmation = (
    <span className="text-2xl font-medium">
      This change will now be visible to all advisors in your organization.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <DeleteStudent
          student={student}
          onClick={deleteStudentOnClick}
          deleteStudents={deleteStudents}
          finishCloseModal={finishCloseModal}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to Archived Students"
        />
      )}
    </div>
  );
}

DeleteStudentModal.propTypes = {
  filter: PropTypes.shape(),
  organizationId: PropTypes.string,
  deleteStudents: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  finishCloseModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape()),
};

export default DeleteStudentModal;
