import { useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { Form } from "../../core";
import { Button } from "../../core/buttons";
import { Colors } from "../../core/utils";

function AccountInfoForm({ id, isOpen, onSubmit, defaultValues }) {
  const phoneNumber = (defaultValues.phoneNumber || "").replace(/^\+1/, "");
  const {
    reset,
    setError,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      phoneNumber: phoneNumber,
    },
  });

  useEffect(() => {
    if (!defaultValues.openEditAccountInfo)
      reset({ ...defaultValues, phoneNumber: phoneNumber });
  }, [reset, defaultValues, phoneNumber]);

  const onSubmitWithSpinner = async (data) => {
    const result = await onSubmit(data);
    if (result?.type === "DuplicateEmailAddress") {
      setError("email", { message: result.message });
    } else if (result?.type === "EmailAddressSetByOAuth") {
      setError("email", { message: (
        <p>
          Users who log in to DecidED using Google Single Sign-on cannot change their email address. Please contact{" "}
          <a className="underline" href="mailto:support@decided.org">support@decided.org</a>{" "}
          for more help.
        </p>
      )});
    }
  };

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmitWithSpinner)}>
      <Form.Input
        errors={errors}
        required={true}
        name="firstName"
        control={control}
        label="first name"
        color={Colors.BLUE}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          maxLength: { value: 255, message: "First Name is too long." },
          required: { value: true, message: "First Name is required." },
        }}
      />
      <Form.ErrorMessage errors={errors} name="firstName" />

      <Form.Input
        errors={errors}
        required={true}
        name="lastName"
        label="last name"
        control={control}
        color={Colors.BLUE}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          maxLength: { value: 255, message: "Last Name is too long." },
          required: { value: true, message: "Last Name is required." },
        }}
      />
      <Form.ErrorMessage errors={errors} name="lastName" />

      <Form.Input
        name="title"
        label="title"
        required={true}
        errors={errors}
        control={control}
        color={Colors.BLUE}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          required: { value: true, message: "Job title is required." },
        }}
      />
      <Form.ErrorMessage errors={errors} name="title" />

      <Form.Input
        errors={errors}
        required={true}
        control={control}
        name="phoneNumber"
        label="phone number"
        color={Colors.BLUE}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          required: { value: true, message: "Phone number is required." },
          maxLength: {
            value: 10,
            message:
              "Must be a valid 10-digit Phone Number. Please enter your number with no symbols or dashes.",
          },
          pattern: {
            value: /[0-9]{10}/,
            message:
              "Must be a valid 10-digit Phone Number. Please enter your number with no symbols or dashes.",
          },
        }}
      />
      <Form.ErrorMessage errors={errors} name="phoneNumber" />

      <Form.Input
        name="email"
        label="email"
        required={true}
        errors={errors}
        control={control}
        color={Colors.BLUE}
        tabIndex={!isOpen ? -1 : null}
        rules={{
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Enter a valid email.",
          },
          required: { value: true, message: "Email is required." },
        }}
      />
      <Form.ErrorMessage errors={errors} name="email" />

      <Button
        form={id}
        type="submit"
        tabIndex={!isOpen ? -1 : null}
        id="button-save-edit-account-info"
        className="
          mt-4
          w-full
          text-white
          bg-primary-blue
          border-primary-blue
          hover:bg-white
          active:bg-white
          hover:text-primary-blue
          active:text-primary-blue
        "
      >
        save
      </Button>
    </Form>
  );
}

AccountInfoForm.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.shape(),
};

export default AccountInfoForm;
