import PropTypes from "prop-types";

function StudentsCell({ itemsCount }) {
  return <p className="text-sm font-bold text-center">{itemsCount}</p>;
}

StudentsCell.propTypes = {
  itemsCount: PropTypes.number.isRequired,
};

export default StudentsCell;
