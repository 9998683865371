const RESET = "STUDENT_INVITES/RESET";
const SET_PAGE = "STUDENT_INVITES/SET_PAGE";
const SET_ERROR = "STUDENT_INVITES/SET_ERROR";
const SET_IS_LOADING = "STUDENT_INVITES/SET_IS_LOADING";

const Types = {
  RESET,
  SET_PAGE,
  SET_ERROR,
  SET_IS_LOADING,
};

export default Types;
