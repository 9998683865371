import { useCallback, useEffect, useState, useMemo, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { debounce, isEqual } from "lodash";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";

import NoResults from "../../../Common/NoResults";
import AdvisorTableBody from "./AdvisorTableBody";
import AdvisorTableHeader from "./AdvisorTableHeader";
import FloatLoader from "../../../Common/FloatLoader";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { useAdvisors } from "../../../../redux/Advisors/hooks";
import { useOrganizations } from "../../../../redux/Organizations/hooks";

import { updateWholeListOption } from "../../../../redux/Table/actions";
import {
  resetAdvisorsState,
  fetchOrganizationAdvisors
} from "../../../../redux/Advisors/actions";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";

import ModalContext from "../../../../contexts/ModalContext";

import { getColumns } from "./columns";
import { Order } from "../../../../utils/order";
import { ModalTypes, SortingType } from "../Utils";

import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function getData(fetchData) {
  const advisors = fetchData || [];
  return advisors.map((item) => ({
    item: item,
    advisor: {
      role: item.role,
      advisorId: item.id,
      email: item.advisor.email,
      userId: item.advisor.userId,
      lastName: item.advisor.lastName,
      firstName: item.advisor.firstName,
      lastLogin: item.advisor.lastLogin,
      lastLoginHumanize: item.advisor.lastLoginHumanize
    },
    students: item.assignedStudentsCount
  }));
}

function AdvisorTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { listId } = useParams();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const advisorsState = useAdvisors();
  const modalContext = useContext(ModalContext);
  const openModalHandler = modalContext.openModalHandler;

  const pageSize = 20;

  const [data, setData] = useState([]);
  const [doFetch, setDoFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [getDataFlag, setGetDataFlag] = useState(false);
  const [advisorSearch, setAdvisorSearch] = useState("");
  const [globalIsOwner, setGlobalIsOwner] = useState(true);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [temAdvisorSearch, setTemAdvisorSearch] = useState("");
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [advisorListId, setAdvisorListId] = useState(listId || null);
  const [orderBy, setOrderBy] = useState({ [SortingType.NAME]: Order.asc });
  const [organizationId, setOrganizationId] = useState(selectedOrganizationId);

  const defaultFilters = {
    search: null,
    onlyAdvisorsFromList: advisorListId
  };
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    setOrganizationId(selectedOrganizationId);
  }, [selectedOrganizationId]);

  useEffect(() => {
    const organization = profile?.organizationAdvisors?.items?.find(
      (org) => org.organization.id === organizationId
    );

    if (!advisorListId && organization && getDataFlag)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          user_id: profile.userId,
          screen_name: "AdvisorsTable",
          search_input: filters.search,
          organization_id: organization?.id,
          count_of_search_results: advisorsState?.itemsCount
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, filters.search, getDataFlag]);

  const toggleSortingOrderDebounce = useMemo(
    () =>
      debounce((sortingType) => {
        const fieldOrder =
          orderBy[sortingType] === Order.asc ? Order.desc : Order.asc;
        setDoFetch(() => {
          setData([]);
          setCurrentPage(1);
          setOrderBy({ [sortingType]: fieldOrder });
          return true;
        });
      }, 500),
    [orderBy]
  );

  const onChangeSearchAdvisorDebounce = useMemo(
    () => debounce(setTemAdvisorSearch, 500),
    []
  );

  const toggleSortingOrder = useCallback(toggleSortingOrderDebounce, [
    toggleSortingOrderDebounce
  ]);

  const onChangeSearchAdvisor = useCallback(onChangeSearchAdvisorDebounce, [
    onChangeSearchAdvisorDebounce
  ]);

  const closeModal = useCallback(
    (modalType) => {
      const fetchAdvisors = () => {
        setData([]);
        setCurrentPage(1);
        setDoFetch(true);
      };

      const updateWholeList = () => dispatch(updateWholeListOption(false));

      if (modalType) {
        switch (modalType) {
          case ModalTypes.DELETE_ADVISOR:
          case ModalTypes.EDIT_ACCESS_ADVISOR:
          case ModalTypes.REMOVE_ADVISOR_FROM_LIST:
            if (wholeList) updateWholeList();
            fetchAdvisors();
            break;
          case ModalTypes.ARCHIVE_LIST:
            dispatch(fetchOrganizations());
            navigate("/dashboard/advisors");
            break;
          case ModalTypes.ADD_TO_LIST:
            if (wholeList) updateWholeList();
            break;
          default:
            break;
        }
      }
    },
    [dispatch, navigate, wholeList]
  );

  const handlerOnSelectAdvisors = useCallback(
    (selectedAdvisors) => setSelectedAdvisors(selectedAdvisors),
    []
  );

  const columns = useMemo(
    () =>
      getColumns({
        orderBy,
        openModalHandler,
        toggleSortingOrder
      }),
    [orderBy, openModalHandler, toggleSortingOrder]
  );

  useEffect(() => {
    setAdvisorSearch("");
    setTemAdvisorSearch("");

    setAdvisorListId(listId || null);
  }, [listId]);

  useEffect(() => {
    closeModal(modalContext.modalResponse);
  }, [closeModal, modalContext.modalResponse]);

  useEffect(() => {
    if (!advisorsState.isLoading) setAdvisorSearch(temAdvisorSearch);
  }, [advisorsState.isLoading, temAdvisorSearch]);

  useEffect(() => {
    dispatch(resetAdvisorsState());
    setData([]);
    const filters = {
      search: advisorSearch || null,
      onlyAdvisorsFromList: advisorListId
    };

    if (isEqual(defaultFilters, filters)) setFiltersApplied(false);
    else setFiltersApplied(true);
    setFilters(filters);
    setCurrentPage(1);
    setDoFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, advisorSearch, advisorListId]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchAdvisors = async () => {
      await dispatch(
        fetchOrganizationAdvisors({
          orderBy,
          organizationId,
          filter: {
            ...filters,
            onlyAdvisorsFromList: advisorListId
          },
          page: { pageSize, pageNumber: currentPage }
        })
      );
      if (isSubscribed) {
        setDoFetch(false);
        setGetDataFlag(true);
      }
    };

    if (doFetch) fetchAdvisors();
    return () => {
      isSubscribed = false;
      if (wholeList) dispatch(updateWholeListOption(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doFetch, advisorListId]);

  useEffect(() => {
    if (getDataFlag) {
      if (!advisorsState.isLoading) {
        setData(getData(advisorsState?.pages[currentPage - 1]));
        setGetDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisorsState.isLoading, advisorsState.pages, currentPage, getDataFlag]);

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    setDoFetch(true);
  };

  const onAdvisorError = (error) => {
    toast.error(error, {
      progress: 1,
      theme: "colored",
      closeOnClick: true,
      position: "top-right",
      hideProgressBar: false
    });
  };

  if (advisorsState.error) onAdvisorError(advisorsState.error);

  return (
    <div className="px-4 pt-8 relative min-h-screen-footer-md">
      <AdvisorTableHeader
        filters={filters}
        openModal={openModalHandler}
        advisorsState={advisorsState}
        advisorSearch={advisorSearch}
        advisorListId={advisorListId}
        organizationId={organizationId}
        onChange={onChangeSearchAdvisor}
        selectedAdvisors={selectedAdvisors}
        setGlobalIsOwner={setGlobalIsOwner}
      />
      <AdvisorTableBody
        data={data}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        globalIsOwner={globalIsOwner}
        onPageChange={handleOnPageChange}
        itemsCount={advisorsState.itemsCount}
        handlerOnSelectAdvisors={handlerOnSelectAdvisors}
      />
      {advisorsState.isLoading && (
        <FloatLoader show={advisorsState.isLoading} />
      )}
      {data.length === 0 && filtersApplied && !advisorsState.isLoading && (
        <NoResults trigger={filters} tableSelector="viewport-advisor-table" />
      )}
    </div>
  );
}

export default AdvisorTable;
