import { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import AccountInfoForm from "./AccountInfoForm";
import ModalContext from "../../contexts/ModalContext";

import { Colors } from "../../core/utils";
import { Close } from "../../core/icons";
import { TitleRow, InfoRow } from "../../core/labels";
import { Button, BackButton } from "../../core/buttons";
import { SideMenu, SmallModal, Dropdown } from "../../core/";

import { GetRole, AdvisorRoles } from "../Dashboard/Advisors/Utils";
import Api, { ErrorTypes } from "../../services/Api";
import {
  fetchOrganizations,
  setSelectedOrganizationId
} from "../../redux/Organizations/actions";
import {
  fetchAdvisor,
  updateAdvisor,
  updateAdvisorLocal
} from "../../redux/Advisor/actions";
import { interactionEventDataLayer } from "../../utils/DataLayers";

import { ModalTypes } from "./utils";

function AdvisorProfile({
  isOpen,
  onClose,
  orgList,
  currentOrg,
  advisorState,
  organizationId,
  handleOnSelectOrg
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalContext = useContext(ModalContext);
  const openModalHandler = modalContext.openModalHandler;

  const { profile } = advisorState;

  const divContainerRef = useRef();
  const [hasScroll, setHasScroll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [isModalLeaveOpen, setIsModalLeaveOpen] = useState(false);
  const [confirmAccDeleted, setConfirmAccDeleted] = useState(false);
  const [openEditAccountInfo, setOpenEditAccountInfo] = useState(false);

  useEffect(() => {
    const container = divContainerRef.current;

    const handleScroll = (element) => {
      if (element.scrollTop > 0) setHasScroll(true);
      else setHasScroll(false);
    };

    container.addEventListener("scroll", () => handleScroll(container));
    return () => {
      container.removeEventListener("scroll", () => handleScroll(container));
    };
  });

  useEffect(() => {
    if (updateProfile && organizationId) {
      setUpdateProfile(false);
      dispatch(fetchAdvisor({ organizationId }));
    }
  }, [dispatch, updateProfile, organizationId]);

  const closeEditAccount = () => setOpenEditAccountInfo(false);
  const openEditAccount = () => {
    interactionEventDataLayer({ eventName: "edit_account_info_button" });
    setOpenEditAccountInfo(true);
  };

  const openEditOrg = () => {
    const data = {
      currentOrg
    };
    openModalHandler(data, ModalTypes.EDIT_ORG_NAME);
  };

  const deleteAccountConfirmHandler = () => setIsModalOpen(!isModalOpen);

  const onSubmitAccountInfo = async (submitData) => {
    const account = {
      email: submitData.email,
      title: submitData.title,
      lastName: submitData.lastName,
      firstName: submitData.firstName,
      phoneNumber: submitData.phoneNumber ? `+1${submitData.phoneNumber}` : null
    };

    try {
      await dispatch(updateAdvisor({ ...account, organizationId }));
    } catch (error) {
      if (error.type === ErrorTypes.graphQLError) {
        if (error.errors[0].extensions.code === "DuplicateEmailAddress") {
          return {
            type: "DuplicateEmailAddress",
            message: error.errors[0].message
          };
        } else if (error.errors[0].extensions.code === "EmailAddressSetByOAuth") {
          return {
            type: "EmailAddressSetByOAuth",
            message: error.errors[0].message
          };
        }
      } else throw error;
    }
    closeEditAccount();
    interactionEventDataLayer({ eventName: "edit_account_info_save_button" });
  };

  const logOutHandler = () => navigate("/logout");

  const deleteAccount = async () => {
    setConfirmAccDeleted(true);
    await Api.deleteAdvisor();
    setTimeout(() => {
      navigate("/logout");
    }, 2000);
  };

  const leaveOrganizationHandle = async () => {
    await Api.removeAdvisorFromOrg({
      organizationId: currentOrg.id,
      advisorIds: [advisorState.profile.id]
    });
    dispatch(setSelectedOrganizationId(null));
    dispatch(fetchOrganizations());
    setIsModalLeaveOpen(false);
    setUpdateProfile(true);
  };

  const activeTabIndex = !isOpen || isModalOpen || openEditAccountInfo;

  return (
    <div
      id="profile-drawer"
      ref={divContainerRef}
      className="h-full w-full bg-white shadow-md overflow-auto"
    >
      <div
        className={`
          p-4
          flex
          top-0
          flex-1
          sticky
          flex-row
          bg-white
          items-center
          ${hasScroll && "shadow-md"}
        `}
      >
        <BackButton
          tabIndex={activeTabIndex ? -1 : null}
          onClick={() => {
            if (advisorState.profile.emailChangeRequestSent) {
              dispatch(
                updateAdvisorLocal({
                  ...advisorState.profile,
                  emailChangeRequestSent: false
                })
              );
            }
            onClose();
          }}
        />
      </div>
      <div className="px-4 laptop:px-6 desktop:px-6">
        <TitleRow large={true} text="Your Account" />
        <div className="p-4 mb-6 border border-black">
          <TitleRow
            text="Account Info"
            onClick={openEditAccount}
            id="button-profile-edit-account"
          />
          <InfoRow
            id="name"
            label="name"
            labelColor="text-primary-blue"
            text={`${profile?.firstName} ${profile?.lastName}`}
          />
          {profile?.title && (
            <InfoRow
              id="title"
              label="title"
              labelColor="text-primary-blue"
              text={profile?.title}
            />
          )}
          <InfoRow
            id="phone-number"
            label="phone number"
            labelColor="text-primary-blue"
            text={`${profile.phoneNumber || ""}`}
          />
          <InfoRow
            id="email"
            label="email"
            labelColor="text-primary-blue"
            text={profile?.email}
          />
          {advisorState.profile?.emailChangeRequestSent && (
            <p className="text-sm text-center font-semibold text-primary-blue">
              We have sent an email to your new address to confirm this change.
            </p>
          )}
          <Button
            tabIndex={activeTabIndex ? -1 : null}
            onClick={() => navigate("/subscription-settings")}
            className="
              w-full
              text-xs
              text-white
              bg-primary-blue
              border-primary-blue
              hover:bg-white
              active:bg-white
              hover:text-primary-blue
              active:text-primary-blue
            "
          >
            subscription settings
          </Button>
        </div>
        <div className="p-4 mb-6 border border-black">
          <TitleRow
            text="Organization Info"
            onClick={
              currentOrg?.role === AdvisorRoles.ADMIN ? openEditOrg : null
            }
          />
          <InfoRow
            id="role"
            label="role"
            labelColor="text-primary-blue"
            text={GetRole(currentOrg?.role)}
          />
          {currentOrg?.id && (
            <>
              <div className="flex flex-row justify-between">
                <InfoRow
                  label="organization"
                  text={currentOrg?.label}
                  labelColor="text-primary-blue"
                />
                <button
                  id="organization-leave-button"
                  tabIndex={activeTabIndex ? -1 : null}
                  onClick={() => setIsModalLeaveOpen(true)}
                  className="
                  pb-2
                  mr-2
                  text-xs
                  self-end
                  font-semibold
                  cursor-pointer
                  hover:underline
                  focus:outline-none
                "
                >
                  Leave
                </button>
              </div>
              <div className="flex flex-row">
                <InfoRow
                  label="organization code"
                  text={currentOrg?.code}
                  labelColor="text-primary-blue"
                />
                <button
                  id="organization-leave-button"
                  tabIndex={activeTabIndex ? -1 : null}
                  onClick={() => {
                    navigator.clipboard.writeText(currentOrg?.code);
                  }}
                  className="
                  pb-2
                  w-1/3
                  text-xs
                  self-end
                  font-semibold
                  cursor-pointer
                  hover:underline
                  focus:outline-none
                "
                >
                  Copy Code
                </button>
              </div>
              <div className="flex flex-row items-start">
                <InfoRow
                  label="invite student URL"
                  text={`${window.SERVER_DATA.REACT_APP_STUDENT_URL}/join-org/${currentOrg?.code}`}
                  labelColor="text-primary-blue"
                />
                <button
                  id="copy-url-button"
                  tabIndex={activeTabIndex ? -1 : null}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.SERVER_DATA.REACT_APP_STUDENT_URL}/join-org/${currentOrg?.code}`
                    );
                  }}
                  className="
                  pb-2
                  w-1/3
                  text-xs
                  self-end
                  font-semibold
                  cursor-pointer
                  hover:underline
                  focus:outline-none
                "
                >
                  Copy URL
                </button>
              </div>
            </>
          )}
          <InfoRow
            id="switch-org"
            label="Switch Your Org. Below"
            labelColor="text-primary-blue"
          />
          <div className="font-medium">
            <Dropdown
              options={orgList}
              color={Colors.BLUE}
              id="organization-dropdown"
              currentOption={currentOrg}
              handleOnSelect={handleOnSelectOrg}
            />
          </div>
        </div>

        <div
          className="
            py-4
            mb-6
            flex
            flex-1
            flex-row
            space-x-4
            items-center
            justify-center
          "
        >
          <Button
            id="delete-account-button"
            tabIndex={activeTabIndex ? -1 : null}
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="
              w-full
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
            "
          >
            delete account
          </Button>
          <Button
            id="sign-out-button"
            onClick={logOutHandler}
            tabIndex={activeTabIndex ? -1 : null}
            className="
              w-full
              text-white
              bg-primary-blue
              border-primary-blue
              hover:bg-white
              active:bg-white
              hover:text-primary-blue
              active:text-primary-blue
            "
          >
            sign out
          </Button>
        </div>
      </div>

      <SideMenu open={openEditAccountInfo} direction="rtl">
        <div className="bg-white h-full w-full p-6">
          <div className="mb-6 flex flex-1 flex-row items-center">
            <TitleRow text="Edit Account Info" />
            <div
              role="button"
              onClick={closeEditAccount}
              id="button-profile-close-edit-account"
            >
              <Close />
            </div>
          </div>
          <AccountInfoForm
            id="form-edit-account"
            isOpen={openEditAccountInfo}
            onSubmit={onSubmitAccountInfo}
            defaultValues={{
              ...profile,
              openEditAccountInfo
            }}
          />
        </div>
      </SideMenu>

      {isModalOpen && (
        <SmallModal open={isModalOpen}>
          {!confirmAccDeleted ? (
            <div className="flex flex-1 flex-col">
              <p className="text-xl font-semibold text-primary-blue">
                Are you sure you want to delete your DecidED account?
              </p>
              <p className="mt-1 text-black font-semibold">
                This will be a permanent change and cannot be undone.
                Organizations that you created will be deleted along with their
                content. If you want to transfer the ownership of an
                organization{" "}
                <a
                  href="mailto:help@decided.org"
                  className="hover:underline text-primary-blue"
                >
                  get in touch with us
                </a>
                .
              </p>
              <div className="mt-12 flex flex-1 flex-row space-x-4">
                <Button
                  id="button-delete-account-cancel"
                  tabIndex={!isModalOpen ? -1 : null}
                  onClick={deleteAccountConfirmHandler}
                  className="
                    w-full
                    bg-white
                    text-primary-blue
                    border-primary-blue
                    hover:text-white
                    active:text-white
                    hover:bg-primary-blue
                    active:bg-primary-blue
                  "
                >
                  Cancel
                </Button>
                <Button
                  onClick={deleteAccount}
                  id="button-delete-account-delete"
                  tabIndex={!isModalOpen ? -1 : null}
                  className="
                    w-full
                    text-white
                    bg-primary-blue
                    border-primary-blue
                    hover:bg-white
                    active:bg-white
                    hover:text-primary-blue
                    active:text-primary-blue
                  "
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <div className="mt-10 flex flex-1 flex-col">
              <p className="text-xl font-semibold text-primary-blue">
                Your account has been permanently deleted!
              </p>
              <p className="mt-1 text-black font-semibold">DecidED Team</p>
            </div>
          )}
        </SmallModal>
      )}

      {isModalLeaveOpen && (
        <SmallModal open={isModalLeaveOpen}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold">
              Are you sure you want to leave the organization?
            </p>
            <div className="mt-20 flex flex-1 flex-row space-x-4">
              <Button
                id="button-leave-org-cancel"
                tabIndex={!isModalOpen ? -1 : null}
                onClick={() => setIsModalLeaveOpen(false)}
                className="
                  w-full
                  bg-white
                  text-primary-blue
                  border-primary-blue
                  hover:text-white
                  active:text-white
                  hover:bg-primary-blue
                  active:bg-primary-blue
                "
              >
                Cancel
              </Button>
              <Button
                id="button-leave-org-leave"
                onClick={leaveOrganizationHandle}
                tabIndex={!isModalOpen ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                Leave
              </Button>
            </div>
          </div>
        </SmallModal>
      )}
    </div>
  );
}

AdvisorProfile.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  currentOrg: PropTypes.shape(),
  advisorState: PropTypes.shape(),
  organizationId: PropTypes.string
};

export default AdvisorProfile;
