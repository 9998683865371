import PropTypes from "prop-types";

function AdvisorsCell({ advisors }) {
  return (
    <div className="text-center">
      <p className="text-sm font-bold capitalize">{advisors}</p>
    </div>
  );
}

AdvisorsCell.propTypes = {
  advisors: PropTypes.number,
};

export default AdvisorsCell;
