import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { keyBy, merge, values } from "lodash";
import PropTypes from "prop-types";

import { Colors, getColors } from "../utils";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

function MapChart({ color, className, isDesktop, mapStates, onSelectState }) {
  const colors = getColors(color);

  return (
    <ComposableMap
      width={770}
      height={500}
      projection="geoAlbersUsa"
      className={className}
      style={{
        zIndex: 1,
        width: "35.5rem",
        height: isDesktop ? "21rem" : "14rem",
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) => {
          const geos = values(
            merge(keyBy(geographies, "id"), keyBy(mapStates, "id"))
          );
          return (
            <>
              {geos.map((geo) => (
                <Geography
                  stroke="#FFF"
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => onSelectState(geo)}
                  fill={geo.isActive ? colors.primary.hexa : "#000"}
                />
              ))}
            </>
          );
        }}
      </Geographies>
    </ComposableMap>
  );
}

MapChart.propTypes = {
  isDesktop: PropTypes.bool,
  className: PropTypes.string,
  onSelectState: PropTypes.func,
  mapStates: PropTypes.arrayOf(PropTypes.shape()),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default MapChart;
