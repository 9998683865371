import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FiltersList from "../../../Common/FiltersList";

import { SearchText, Checkbox } from "../../../../core/inputs";
import { ItemsCounter, Dropdown } from "../../../../core";
import { Colors } from "../../../../core/utils";

import { ModalTypes } from "../Utils";

import { useTable } from "../../../../redux/Table/hooks";
import { updateWholeListOption } from "../../../../redux/Table/actions";

function ArchivedAdvisorListTableHeader({
  filters,
  onChange,
  onRemove,
  openModal,
  organizationId,
  archivedListsState,
  filterTagsListType,
  archiveAdvisorListSearch,
  selectedArchiveAdvisorLists,
}) {
  const dispatch = useDispatch();

  const { wholeList } = useTable();

  const getActionLabel = (count) => {
    return count === 1 ? ` ${count} List` : ` ${count} Lists`;
  };

  const handleOnSelectOption = (data) => data.handlerOnClick();

  const dropdownOptions = [
    {
      id: "delete",
      enabled: selectedArchiveAdvisorLists.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          filters,
          organizationId,
          isArchived: true,
          deleteLists: !wholeList
            ? selectedArchiveAdvisorLists.length
            : archivedListsState.itemsCount,
          lists: !wholeList ? selectedArchiveAdvisorLists : null,
        };
        openModal(data, ModalTypes.DELETE_LIST);
      },
      label: (
        <div className="text-center text-primary-blue">
          Delete
          {getActionLabel(
            wholeList
              ? archivedListsState?.itemsCount
              : selectedArchiveAdvisorLists.length
          )}
        </div>
      ),
    },
    {
      id: "unarchive",
      enabled: selectedArchiveAdvisorLists.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          filters,
          organizationId,
          unarchiveLists: !wholeList
            ? selectedArchiveAdvisorLists.length
            : archivedListsState.itemsCount,
          lists: !wholeList ? selectedArchiveAdvisorLists : null,
        };
        openModal(data, ModalTypes.UNARCHIVE_LIST);
      },
      label: (
        <div className="text-center text-primary-blue">
          Unarchive
          {getActionLabel(
            wholeList
              ? archivedListsState?.itemsCount
              : selectedArchiveAdvisorLists.length
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="pb-2 flex flex-1 text-sm flex-row items-center">
        <div className="flex flex-1 flex-row">
          <div className="flex flex-col">
            <div className="text-4xl font-bold">Archived Advisor Lists</div>
          </div>
        </div>
        <div className="flex flex-1 flex-row space-x-4 justify-end">
          <div className="min-w-15rem font-bold">
            <Dropdown
              color={Colors.BLUE}
              defaultLabel="Action"
              options={dropdownOptions}
              handleOnSelect={handleOnSelectOption}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-1 text-sm flex-row justify-start">
        <div className="flex w-1/2 flex-row justify-start">
          <SearchText
            handlerOnSubmit={onChange}
            id="archived-advisor-lists-search-bar"
            defaultValue={archiveAdvisorListSearch}
            placeholder="Archived Advisor Lists Search"
          />
        </div>
      </div>
      <div className="mt-2 flex flex-1 flex-row space-between">
        <FiltersList
          onRemove={onRemove}
          filters={filterTagsListType}
          isLoading={archivedListsState.isLoading}
        />
      </div>
      <div className="mt-4 flex text-sm flex-row items-center space-x-4">
        <ItemsCounter
          pluralDescriptionItems={
            selectedArchiveAdvisorLists.length > 0 || wholeList
              ? "Archived Lists Selected"
              : "Archived Lists"
          }
          singularDescriptionItems={
            selectedArchiveAdvisorLists.length > 0 || wholeList
              ? "Archived List Selected"
              : "Archived List"
          }
          numberItems={
            !wholeList && selectedArchiveAdvisorLists.length > 0
              ? selectedArchiveAdvisorLists.length
              : archivedListsState?.itemsCount || 0
          }
        />
        {selectedArchiveAdvisorLists.length > 0 &&
          archivedListsState.itemsCount !==
            selectedArchiveAdvisorLists.length && (
            <div className="w-fit">
              <Checkbox
                value={wholeList}
                checked={wholeList}
                color={Colors.BLUE}
                label={`Select All (${archivedListsState?.itemsCount} Lists)`}
                onChange={() => {
                  dispatch(updateWholeListOption(!wholeList));
                }}
              />
            </div>
          )}
      </div>
    </>
  );
}

ArchivedAdvisorListTableHeader.propTypes = {
  openModal: PropTypes.func,
  filters: PropTypes.shape(),
  organizationId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  archiveListsState: PropTypes.shape(),
  filterTagsListType: PropTypes.array.isRequired,
  archiveAdvisorListSearch: PropTypes.string.isRequired,
  selectedArchiveAdvisorLists: PropTypes.arrayOf(PropTypes.PropTypes.shape())
    .isRequired,
};

export default ArchivedAdvisorListTableHeader;
