import PropTypes from "prop-types";

function ConfirmContent({
  plural,
  plural2,
  plural3,
  invitationsSent,
  invitationsAlreadyPending,
  alreadyBelongToOrganization,
}) {
  function List({ data, itemColor }) {
    return (
      <ul className="ml-4">
        {data.map((item) => (
          <li key={item} className={`list-disc list-inside ${itemColor}`}>
            <span>{item}</span>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="w-full text-2xl font-medium">
      {invitationsSent.length > 0 && (
        <>
          <List data={invitationsSent} itemColor="text-primary-blue" />
          <div className="mt-4">
            Make sure to let your {plural ? "students" : "student"} know. They
            will also get an email with next steps.
          </div>
        </>
      )}
      {alreadyBelongToOrganization.length > 0 && (
        <div className="mt-4">
          {invitationsSent.length > 0 && (
            <p className="mb-4">
              The following
              {plural2 ? " students have " : " student has "}
              already joined your organization and won't receive another
              invitation:
            </p>
          )}
          <List
            itemColor="text-primary-blue"
            data={alreadyBelongToOrganization}
          />
        </div>
      )}
      {invitationsAlreadyPending.length > 0 && (
        <>
          {(invitationsSent.length > 0 ||
            alreadyBelongToOrganization.length > 0) && (
            <p className="my-4">
              The following
              {plural3 ? " students have " : " student has "}
              already been invited to your organization:
            </p>
          )}
          <List
            itemColor="text-primary-blue"
            data={invitationsAlreadyPending}
          />
          <div className="mt-4">Would you like to re-send them an invite?</div>
        </>
      )}
    </div>
  );
}

ConfirmContent.propTypes = {
  plural: PropTypes.bool.isRequired,
  plural2: PropTypes.bool.isRequired,
  plural3: PropTypes.bool.isRequired,
  invitationsSent: PropTypes.arrayOf(PropTypes.string).isRequired,
  invitationsAlreadyPending: PropTypes.arrayOf(PropTypes.string).isRequired,
  alreadyBelongToOrganization: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ConfirmContent;
