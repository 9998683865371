import PropTypes from "prop-types";

import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

import { useIsDesktopMQL } from "./hooks";
import { Colors, getColors } from "./utils";

function Banner({ color = Colors.DEFAULT, content }) {
  const isDesktop = useIsDesktopMQL();

  const colors = getColors(color);

  if (content)
    return (
      <div
        className={`
          p-2
          flex
          w-full
          flex-row
          shadow-lg
          justify-center
          ${colors.primary.bg}
        `}
      >
        <div
          className={`
            uppercase
            font-bold
            ${color === Colors.DEFAULT ? "text-black" : "text-white"}
          `}
        >
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={
              isDesktop ? content.desktopContent : content.mobileContent
            }
          />
        </div>
      </div>
    );

  return null;
}

Banner.propTypes = {
  content: PropTypes.shape({
    desktopContent: PropTypes.any,
    mobileContent: PropTypes.any
  }),
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default Banner;
