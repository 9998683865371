import { useMemo, useCallback, useEffect, useState, useContext } from "react";
import { debounce, isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import NoResults from "../../../Common/NoResults";
import ArchivedStudentListTableBody from "./ArchivedStudentListTableBody";
import ArchivedStudentListTableHeader from "./ArchivedStudentListTableHeader";
import FloatLoader from "../../../Common/FloatLoader";

import { getColumns } from "./columns";
import { Order } from "../../../../utils/order";
import { ModalTypes, SortingType } from "../Utils";
import ModalContext from "../../../../contexts/ModalContext";
import { AdvisorRoles } from "../../Advisors/Utils";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { useOrganizations } from "../../../../redux/Organizations/hooks";
import { useArchivedStudentLists } from "../../../../redux/ArchivedStudentLists/hooks";

import { updateWholeListOption } from "../../../../redux/Table/actions";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";
import {
  resetArchivedStudentListsState,
  fetchOrganizationArchivedStudentLists,
} from "../../../../redux/ArchivedStudentLists/actions";

import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function getData(awsData) {
  const students = awsData || [];
  return students.map((item) => ({
    id: item.id,
    name: item.name,
    archived: item.archived,
    isShared: item.isShared,
    insertTime: item.insertTime,
    studentsCount: item.studentsCount,
    listType: !item.isShared ? "My List" : "Org. List",
    sharedWithOrganization: item.sharedWithOrganization,
    owner: {
      id: item.owner.id,
      lastName: item.owner.advisor.lastName,
      firstName: item.owner.advisor.firstName,
    },
  }));
}

function ArchivedStudentListTable() {
  const dispatch = useDispatch();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();
  const { selectedOrganizationId } = useOrganizations();

  const archivedListsState = useArchivedStudentLists();
  const modalContext = useContext(ModalContext);

  const pageSize = 20;

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [doFetch, setDoFetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [getDataFlag, setGetDataFlag] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [filterTagsListType, setFilterTagsListType] = useState([]);
  const [orderBy, setOrderBy] = useState({ [SortingType.NAME]: Order.asc });
  const [selectedArchiveStudentLists, setSelectedArchiveStudentLists] =
    useState([]);
  const [organizationId, setOrganizationId] = useState(selectedOrganizationId);

  const defaultFilters = {
    search: null,
    archived: true,
    isShared: null,
    owners: [profile.id],
  };
  const [filters, setFilters] = useState(defaultFilters);

  const organization = useMemo(() => {
    return profile?.organizationAdvisors?.items?.find(
        (org) => org.organization.id === organizationId
    );
}, [profile, organizationId]);

  useEffect(() => {
    setData([]);
    setCurrentPage(1);
    setDoFetch(true);
    setOrganizationId(selectedOrganizationId);
  }, [selectedOrganizationId]);

  useEffect(() => {
    if (organization && getDataFlag)
      interactionEventDataLayer({
        eventName: "page_view",
        params: {
          user_id: profile.userId,
          search_input: filters.search,
          screen_name: "ArchivedStudentListTable",
          organization_id: organization?.organization?.id,
          count_of_search_results: archivedListsState?.itemsCount,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, filters.search, getDataFlag]);

  const toggleSortingOrderDebounce = useMemo(
    () =>
      debounce((sortingType, fieldOrder) => {
        setDoFetch(() => {
          setData([]);
          setCurrentPage(1);
          setOrderBy({ [sortingType]: fieldOrder });
          return true;
        });
      }, 500),
    []
  );

  const handlerOnChangeSearchDebounce = useMemo(
    () => debounce((value) => setSearch(value), 500),
    []
  );

  const toggleSortingOrder = useCallback(toggleSortingOrderDebounce, [
    toggleSortingOrderDebounce,
  ]);

  const handlerOnChangeSearch = useCallback(handlerOnChangeSearchDebounce, [
    handlerOnChangeSearchDebounce,
  ]);

  const onFilterListTypeSelect = useCallback(
    (filter) => setFilterTagsListType([filter]),
    []
  );

  const closeModal = useCallback(
    (modalType) => {
      if (modalType) {
        switch (modalType) {
          case ModalTypes.DELETE_LIST:
          case ModalTypes.UNARCHIVE_LIST:
            if (modalType === ModalTypes.UNARCHIVE_LIST)
              dispatch(fetchOrganizations());
            if (wholeList) dispatch(updateWholeListOption(false));
            setData([]);
            setCurrentPage(1);
            setDoFetch(true);
            break;
          default:
            break;
        }
      }
    },
    [dispatch, wholeList]
  );

  const handlerOnSelectArchiveStudentLists = useCallback(
    (selectedArchiveStudentLists) =>
      setSelectedArchiveStudentLists(selectedArchiveStudentLists),
    []
  );

  const columns = useMemo(
    () =>
      getColumns({
        orderBy,
        toggleSortingOrder,
        onFilterListTypeSelect,
      }),
    [orderBy, toggleSortingOrder, onFilterListTypeSelect]
  );

  useEffect(() => {
    closeModal(modalContext.modalResponse);
  }, [closeModal, modalContext.modalResponse]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchStudents = async () => {
      await dispatch(
        fetchOrganizationArchivedStudentLists({
          orderBy,
          organizationId,
          filter: filters,
          page: { pageSize, pageNumber: currentPage },
        })
      );
      if (isSubscribed) {
        setDoFetch(false);
        setGetDataFlag(true);
      }
    };

    if (doFetch) fetchStudents();
    return () => {
      isSubscribed = false;
      if (wholeList) dispatch(updateWholeListOption(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doFetch]);

  useEffect(() => {
    if (getDataFlag) {
      if (!archivedListsState.isLoading) {
        setData(getData(archivedListsState?.pages[currentPage - 1]));
        setGetDataFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    archivedListsState.isLoading,
    archivedListsState.pages,
    currentPage,
    getDataFlag,
  ]);

  useEffect(() => {
    dispatch(resetArchivedStudentListsState());
    
    const defaultOwner = organization?.role === AdvisorRoles.ADMIN ? null : [profile.id];
    
    const filters = {
        archived: true,
        owners: defaultOwner,
        search: search || null,
        isShared: filterTagsListType.length > 0 ? filterTagsListType[0].value : null
    };

    if (isEqual(defaultFilters, filters)) setFiltersApplied(false);
    else setFiltersApplied(true);

    setDoFetch(() => {
      setData([]);
      setCurrentPage(1);

      setFilters(filters);
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filterTagsListType, organization]);

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    setDoFetch(true);
  };

  const onRemoveFilterTag = (tagType) => {
    setFilterTagsListType(
      filterTagsListType.filter((x) => x.key !== tagType.key)
    );
  };

  const onStudentError = (error) => {
    toast.error(error, {
      progress: 1,
      theme: "colored",
      closeOnClick: true,
      position: "top-right",
      hideProgressBar: false,
    });
  };

  if (archivedListsState.error) onStudentError(archivedListsState.error);

  return (
    <div className="px-4 pt-8 relative min-h-screen-footer-md">
      <ArchivedStudentListTableHeader
        filters={filters}
        onRemove={onRemoveFilterTag}
        organizationId={organizationId}
        onChange={handlerOnChangeSearch}
        archiveStudentListSearch={search}
        archivedListsState={archivedListsState}
        filterTagsListType={filterTagsListType}
        openModal={modalContext.openModalHandler}
        selectedArchiveStudentLists={selectedArchiveStudentLists}
      />
      <ArchivedStudentListTableBody
        data={data}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handleOnPageChange}
        itemsCount={archivedListsState.itemsCount}
        handlerOnSelectArchiveStudentLists={handlerOnSelectArchiveStudentLists}
      />
      {archivedListsState.isLoading && (
        <FloatLoader show={archivedListsState.isLoading} />
      )}
      {data.length === 0 && filtersApplied && !archivedListsState.isLoading && (
        <NoResults
          trigger={filters}
          tableSelector="viewport-archived-student-table"
        />
      )}
    </div>
  );
}

export default ArchivedStudentListTable;
