import PropTypes from "prop-types";

import { EditPencil } from "../icons";

function TitleRow({
  id = "",
  text,
  large = false,
  onClick,
  actionText = "Edit"
}) {
  return (
    <div
      id={`title-row-${id}`}
      className="p-2 flex flex-1 flex-row items-center text-black"
    >
      {large ? (
        <h1 className="flex flex-1 font-black text-3xl">{text}</h1>
      ) : (
        <h2 className="flex flex-1 font-black text-xl">{text}</h2>
      )}
      {onClick && (
        <div
          tabIndex={1}
          role="button"
          onClick={onClick}
          id={`title-row-onclick-${id}`}
          className="flex flex-row items-center"
        >
          <EditPencil size={10} color="fill-current" />
          <p className="ml-1 text-xs font-semibold">{actionText}</p>
        </div>
      )}
    </div>
  );
}

TitleRow.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  actionText: PropTypes.string
};

export default TitleRow;
