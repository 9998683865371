import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function InfiniteScroll({
  id,
  style,
  children,
  className = "min-h-6rem",
  querySelector = "screenViewport",
  handlePagination = (isIntersecting) => {}
}) {
  const loader = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => handlePagination(entry.isIntersecting),
      {
        root: querySelector
          ? document.querySelector(`#${querySelector}`)
          : null,
        rootMargin: "0px",
        threshold: 1
      }
    );

    observer.observe(loader.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div
      style={style}
      id={id || querySelector}
      className={`overflow-y-auto px-1 ${className}`}
    >
      {children}
      <div ref={loader} className="h-1"></div>
    </div>
  );
}

InfiniteScroll.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.shape(),
  className: PropTypes.string,
  querySelector: PropTypes.string,
  handlePagination: PropTypes.func
};

export default InfiniteScroll;
