import PropTypes from "prop-types";
import { GaugeChart } from "../../charts";
import {
  CollegeType,
  computeWarningText,
  computeEthnicityGroupText
} from "../../utils";

function GraduationRate({
  affordability,
  graduationRate,
  ethnicityGroup,
  ethnicityStudent,
  schoolProgramLength
}) {
  const warningText = computeWarningText(graduationRate);
  const ethnicityGroupText = computeEthnicityGroupText(
    ethnicityGroup,
    ethnicityStudent
  );
  const isGraduationRateAvailable = graduationRate > 0;

  return (
    <>
      {warningText && (
        <h2
          className="
            text-2xl
            font-extrabold
            mobile:text-xl
            mobile-sm:text-lg
            tablet-sm:text-xl
          "
        >
          {warningText}
        </h2>
      )}
      <div className="flex flex-1 flex-row justify-between">
        <div
          className={`w-2/3 ${
            !isGraduationRateAvailable ? "flex flex-row items-center" : ""
          }`}
        >
          {" "}
          <h1
            className="
              w-1/3
              text-6xl
              font-extrabold
              mobile:text-5xl
              tablet-sm:text-5xl
              mobile-sm:text-4xl
            "
          >
            {isGraduationRateAvailable ? (
              <>
                {graduationRate}
                <span
                  className="
                    text-3xl
                    mobile:text-2xl
                    mobile-sm:text-xl
                    tablet-sm:text-2xl
                  "
                >
                  %
                </span>
              </>
            ) : (
              "N/A"
            )}
          </h1>
          {!isGraduationRateAvailable ? (
            <div className="flex flex-col w-2/3 justify-center">
              This school has not reported their most recent graduation rates
            </div>
          ) : (
            <p className="mobile:text-sm mobile-sm:text-xs tablet-sm:text-sm">
              of {ethnicityGroupText} graduate from here in{" "}
              {schoolProgramLength === CollegeType.FOUR_YEARS
                ? "4 years"
                : "2 years"}
              .
            </p>
          )}
        </div>
        <div className="w-1/3">
          <GaugeChart percent={graduationRate} affordability={affordability} />
        </div>
      </div>
    </>
  );
}

GraduationRate.propTypes = {
  affordability: PropTypes.string,
  graduationRate: PropTypes.number,
  ethnicityGroup: PropTypes.string,
  ethnicityStudent: PropTypes.string,
  schoolProgramLength: PropTypes.string
};

export default GraduationRate;
