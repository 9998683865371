import { useState, useRef } from "react";
import PropTypes from "prop-types";

import { PurgedLetter } from "../../../../../../core/compose";

import ImagesCount from "./ImagesCount";
import ZoomControlButtons from "./ZoomControlButtons";
import ImagesButtonsControl from "./ImagesButtonsControl";

function DesktopViewer({
  awardPdf,
  filePurged,
  awardImages,
  currentImage,
  setCurrentImage,
  somethingWentWrong
}) {
  const imgRef = useRef();

  const [zoomStepsCounter, setZoomStepsCounter] = useState(1);
  const [defaultImgDimensions, setDefaultImgDimensions] = useState({});
  const [currentZoomPercentage, setCurrentZoomPercentage] = useState(100);
  const [imgDimensions, setImgDimensions] = useState({
    width: null,
    height: null
  });

  const ZOOM_MAX_LIMIT = 2;
  const ZOOM_STEP_SIZE = 0.1;
  const ZOOM_MIN_LIMIT = ZOOM_STEP_SIZE;

  const handleOnLoad = () => {
    setImgDimensions({
      width: imgRef.current?.clientWidth,
      height: imgRef.current?.clientHeight
    });
    setDefaultImgDimensions({
      width: imgRef.current?.clientWidth,
      height: imgRef.current?.clientHeight
    });
  };

  const handleZoomIn = () => {
    if (zoomStepsCounter < ZOOM_MAX_LIMIT) {
      setZoomStepsCounter(zoomStepsCounter + ZOOM_STEP_SIZE);
      setImgDimensions({
        height: imgDimensions.height + imgDimensions.height * ZOOM_STEP_SIZE,
        width: imgDimensions.width + imgDimensions.width * ZOOM_STEP_SIZE
      });
      setCurrentZoomPercentage(currentZoomPercentage + ZOOM_STEP_SIZE * 100);
    }
  };

  const handleZoomOut = () => {
    if (zoomStepsCounter > ZOOM_MIN_LIMIT) {
      setZoomStepsCounter(zoomStepsCounter - ZOOM_STEP_SIZE);
      setImgDimensions({
        height: imgDimensions.height - imgDimensions.height * ZOOM_STEP_SIZE,
        width: imgDimensions.width - imgDimensions.width * ZOOM_STEP_SIZE
      });
      setCurrentZoomPercentage(currentZoomPercentage - ZOOM_STEP_SIZE * 100);
    }
  };

  const handleResetZoom = () => {
    setZoomStepsCounter(1);
    setImgDimensions({
      height: defaultImgDimensions.height,
      width: defaultImgDimensions.width
    });
    setCurrentZoomPercentage(100);
  };

  const handleSetCurrentImage = (setParams) => {
    handleResetZoom();
    setCurrentImage(setParams);
  };

  return (
    <>
      <div className="w-full h-35rem border border-black bg-secondary-blue">
        <div className="w-full h-full relative bg-white">
          {filePurged ? (
            <PurgedLetter color="blue" />
          ) : (
            <>
              {awardPdf && (
                <embed
                  src={awardPdf}
                  className="w-full h-full absolute bg-black"
                />
              )}
              {awardImages.length > 0 && (
                <div
                  className="
                  flex
                  h-full
                  w-full
                  flex-col
                  relative
                  bg-white
                  items-center
                  justify-center
                "
                >
                  <div className="h-full w-full absolute overflow-scroll">
                    <img
                      src={awardImages[currentImage].url}
                      ref={imgRef}
                      alt="Award Letter Preview"
                      onLoad={handleOnLoad}
                      style={{
                        ...imgDimensions,
                        maxWidth: "none",
                        overflow: "scroll",
                        position: "absolute"
                      }}
                    />
                  </div>

                  <ZoomControlButtons
                    zoomIn={handleZoomIn}
                    zoomOut={handleZoomOut}
                    resetTransform={handleResetZoom}
                    currentZoomPercentage={currentZoomPercentage}
                  />
                </div>
              )}
              {awardImages.length > 1 && (
                <ImagesCount
                  currentImage={currentImage + 1}
                  imagesCount={awardImages.length}
                />
              )}
            </>
          )}
          {somethingWentWrong && (
            <div className="flex h-full flex-col items-center justify-center">
              <p className="font-bold text-gray-500">Something went wrong!</p>
            </div>
          )}
        </div>
      </div>
      {awardImages.length > 1 && (
        <ImagesButtonsControl
          disablePrev={currentImage === 0}
          disableNext={currentImage === awardImages.length - 1}
          imagesCount={awardImages.length}
          setCurrentImage={handleSetCurrentImage}
        />
      )}
    </>
  );
}

DesktopViewer.propTypes = {
  awardPdf: PropTypes.string,
  awardImages: PropTypes.array,
  filePurged: PropTypes.bool,
  currentImage: PropTypes.number,
  setCurrentImage: PropTypes.func,
  somethingWentWrong: PropTypes.bool
};

export default DesktopViewer;
