import { EmailCell, StatusCell, RoleCell, SenderCell } from "./Cell";
import { EmailHeader, StatusHeader, RoleHeader, SenderHeader } from "./Header";
import {
  SortingType,
  InvitationStatus,
  GetInvitationStatus,
  InvitationRole,
  GetInvitationRole,
} from "../Utils";

import { clearSort } from "../../../Common/utils";
import { AdvisorRoles } from "../../Advisors/Utils";

const Status = [
  {
    key: InvitationStatus.ACCEPTED,
    name: GetInvitationStatus(InvitationStatus.ACCEPTED),
  },
  {
    key: InvitationStatus.PENDING,
    name: GetInvitationStatus(InvitationStatus.PENDING),
  },
  {
    key: InvitationStatus.EXPIRED,
    name: GetInvitationStatus(InvitationStatus.EXPIRED),
  },
];

const Roles = [
  {
    key: InvitationRole.ADMIN,
    name: GetInvitationRole(InvitationRole.ADMIN),
  },
  {
    key: InvitationRole.ADVISOR,
    name: GetInvitationRole(InvitationRole.ADVISOR),
  },
];

export function getColumns({
  orderBy,
  assingAdvisors,
  toggleSortingOrder = () => {},
  onFilterStatusSelect = () => {},
  onFilterRoleSelect = () => {},
  onFilterSenderSelect = () => {},
}) {
  return [
    {
      accessor: "email",
      Header: (
        <EmailHeader
          clearSort={clearSort({ orderBy, field: SortingType.EMAIL })}
          onSort={(order) => toggleSortingOrder(SortingType.EMAIL, order)}
        />
      ),
      Cell: ({ row }) => <EmailCell email={row.original.email} />,
      width: "30%",
    },
    {
      accessor: "status",
      Header: <StatusHeader status={Status} onFilter={onFilterStatusSelect} />,
      Cell: ({ row }) => <StatusCell invitation={row.original} />,
      width: "20%",
    },
    {
      accessor: "role",
      Header: <RoleHeader onFilter={onFilterRoleSelect} roles={Roles} />,
      Cell: ({ row }) => <RoleCell role={row.original.role} />,
      width: "20%",
    },
    {
      accessor: "sender",
      Header: (
        <SenderHeader
          onFilter={onFilterSenderSelect}
          advisors={assingAdvisors
            .filter((advisor) => advisor.role === AdvisorRoles.ADMIN)
            .map((advisor) => ({
              ...advisor,
              key: `sender-${advisor.id}`,
            }))}
        />
      ),
      Cell: ({ row }) => <SenderCell advisor={row.original.sender.advisor} />,
      width: "20%",
    },
  ];
}
