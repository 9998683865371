import PropTypes from "prop-types";

import { SearchTextDropdown } from "../../inputs";
import { Colors } from "../../utils";

function SearchMajors({ color, selectMajor, searchMajorsResults, gaOnClick }) {
  return (
    <SearchTextDropdown
      color={color}
      closeOnSelect={true}
      gaOnClick={gaOnClick}
      onChange={selectMajor}
      placeholder="Search a major"
      loadOptions={searchMajorsResults}
    />
  );
}

SearchMajors.propTypes = {
  selectMajor: PropTypes.func,
  searchMajorsResults: PropTypes.func,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default SearchMajors;
