import PropTypes from "prop-types";

function AddedCollege({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 65 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M29.8222 13.3701H22.9922V20.2001H29.8222V13.3701Z"
      />
      <path
        className={color}
        d="M42.6503 13.3701H35.8203V20.2001H42.6503V13.3701Z"
      />
      <path
        className={color}
        d="M29.8222 24.1001H22.9922V30.9301H29.8222V24.1001Z"
      />
      <path
        className={color}
        d="M42.6503 24.1001H35.8203V30.9301H42.6503V24.1001Z"
      />
      <path
        className={color}
        d="M16.3417 34.9102H9.51172V41.7402H16.3417V34.9102Z"
      />
      <path className={color} d="M54.83 35H48V41.83H54.83V35Z" />
      <path
        className={color}
        d="M53.2114 26.41V11.07L32.7814 0L12.3514 11.07V26.36H0.941406V63.76H64.6314V26.36L53.2114 26.41ZM28.9914 59.47V47.34C29.9914 46.69 31.4414 45.79 32.8314 44.99C34.5514 45.99 35.9214 46.85 36.6514 47.32V59.47H28.9914ZM39.9914 44.41C39.9914 44.41 37.4714 42.66 33.8214 40.64L32.8214 40.07L31.8214 40.64C29.3114 42 26.2614 44 25.6314 44.38L24.6314 45.02V59.47H5.23141V30.66H16.6314V13.66L32.7814 4.88L48.9214 13.63V30.72H60.3314V59.52H40.9514V45.05L39.9914 44.41Z"
      />
    </svg>
  );
}

AddedCollege.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default AddedCollege;
