import Types from "./types";

function setPermissions(permissions) {
  return {
    type: Types.SET_PERMISSIONS,
    permissions,
  };
}

function setIsLoading(isLoading) {
  return {
    type: Types.SET_IS_LOADING,
    isLoading,
  };
}

function setIsReady(isReady) {
  return {
    type: Types.SET_IS_READY,
    isReady,
  };
}

function setError(error) {
  return {
    type: Types.SET_ERROR,
    error,
  };
}

function setReset() {
  return {
    type: Types.RESET,
  };
}

export function resetPermissionsState() {
  return (dispatch) => {
    dispatch(setReset());
  };
}

export function fetchPermissions({ permissions } = {}) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      if (Object.keys(permissions).length > 0) {
        dispatch(setIsReady(true));
        dispatch(setPermissions(permissions));
      }
    } catch (error) {
      dispatch(setError(error));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}
