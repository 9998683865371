import PropTypes from "prop-types";
import { useRef, useState } from "react";

import { FloatBox } from "../../../../../core";
import { Filter } from "../../../../../core/icons";

import GradeYearFilter from "../../Common/GradeYearFilter";

import { useStudents } from "../../../../../redux/Students/hooks";

function GradeYearHeader({ onFilter }) {
  const studentsState = useStudents();

  const containerRef = useRef(null);
  const [show, setShow] = useState(false);

  const outSideClick = (state) => setShow(state);

  return (
    <div
      role="button"
      ref={containerRef}
      onClick={() => setShow(!show)}
      id="students-affordability-options-filter-button"
      className={`
        flex
        flex-row
        items-center
        justify-center
        ${
          studentsState.isLoading
            ? "pointer-events-none"
            : "pointer-events-auto"
        }
      `}
    >
      <div className="relative place-self-center">
        &nbsp;
        <FloatBox
          open={show}
          containerRef={containerRef}
          handleOnClickOutside={outSideClick}
          className="
            p-4
            mt-2
            -ml-12
            bg-white
            border-black
          "
        >
          <GradeYearFilter
            onSelect={onFilter}
            id="students-gradeYear-options-filter-item-button"
          />
        </FloatBox>
      </div>
      <div className="mr-2 place-self-center">
        <Filter size={15} />
      </div>
      <span className="text-sm">Grade Year</span>
    </div>
  );
}

GradeYearHeader.protoTypes = {
  onFilter: PropTypes.func
};

GradeYearHeader.defaultProp = {
  onFilter: () => {}
};

export { GradeYearHeader };
