import PropTypes from "prop-types";

function LastActivityCell({ advisor, ...restProps }) {
  const lastLoginDate = new Date(advisor.lastLogin).toLocaleString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div role="button" className="text-center" {...restProps}>
      <p
        className="
          text-sm
          font-bold
          capitalize
          tablet-sm:text-xs
        "
      >
        {advisor.lastLoginHumanize}
      </p>
      {advisor.lastLogin && (
        <p className="text-xs font-bold text-gray-500">{lastLoginDate}</p>
      )}
    </div>
  );
}

LastActivityCell.propTypes = {
  student: PropTypes.shape(),
};

export default LastActivityCell;
