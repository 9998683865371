import PropTypes from "prop-types";

function AdvisorCell({ advisor, ...restProps }) {
  const name = advisor ? advisor : null;

  return (
    <div
      role="button"
      className="flex flex-1 flex-row justify-center"
      {...restProps}
    >
      {name ? (
        <p className="font-bold">{name}</p>
      ) : (
        <div
          className="
            py-2
            px-4
            mx-auto
            text-xs
            normal-case
            text-center
            font-semibold
            bg-light-gray
            text-gray-500
          "
        >
          Unassigned
        </div>
      )}
    </div>
  );
}

AdvisorCell.propTypes = {
  advisor: PropTypes.string,
};

export default AdvisorCell;
