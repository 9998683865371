import PropTypes from "prop-types";

import { FilterTag } from "../../../Common/FiltersList";
import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function AffordabilityFilter({ onSelect }) {
  const onSelectHandler = (key) => {
    onSelect(key);
    switch (key.key) {
      case FilterTag.AFFORDABLE:
        interactionEventDataLayer({ eventName: "all_st_affordable_filter" });
        break;
      case FilterTag.SOMEWHAT_AFFORDABLE:
        interactionEventDataLayer({
          eventName: "all_st_somewhat_affordable_filter",
        });
        break;
      case FilterTag.NOT_AFFORDABLE:
        interactionEventDataLayer({
          eventName: "all_st_not_affordable_filter",
        });
        break;
      case FilterTag.NO_AWARD:
        interactionEventDataLayer({
          eventName: "all_st_no_award_letter_filter",
        });
        break;
      case FilterTag.NO_COLLEGE:
        interactionEventDataLayer({
          eventName: "all_st_no_college_added_filter",
        });
        break;
      default:
        return;
    }
  };

  return (
    <div
      className="
        flex
        text-sm
        flex-col
        divide-y
        space-y-2
        capitalize
        divide-solid
        divide-black
      "
    >
      <div className="flex flex-col">
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.AFFORDABLE })}
        >
          <div className="w-20">
            <div className="h-full w-3/4 bg-primary-green"></div>
          </div>
          <span>affordable</span>
        </div>
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() =>
            onSelectHandler({ key: FilterTag.SOMEWHAT_AFFORDABLE })
          }
        >
          <div className="w-20">
            <div className="h-full w-2/4 bg-primary-orange"></div>
          </div>
          <span>Somewhat affordable</span>
        </div>
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.NOT_AFFORDABLE })}
        >
          <div className="w-20">
            <div className="h-full w-1/4 bg-primary-red"></div>
          </div>
          <span>Not affordable</span>
        </div>
      </div>
      <div className="pt-2 flex flex-col">
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.NO_AWARD })}
        >
          <div className="w-20">
            <div className="h-full w-2/4 border bg-primary-blue border-black"></div>
          </div>
          <span>No award letter</span>
        </div>
      </div>
      <div className="pt-2 flex flex-col">
        <div
          className="py-2 flex flex-row cursor-pointer"
          onClick={() => onSelectHandler({ key: FilterTag.NO_COLLEGE })}
        >
          <div className="w-20">
            <div className="h-full w-2/4 bg-light-gray"></div>
          </div>
          <span>No college added</span>
        </div>
      </div>
    </div>
  );
}

AffordabilityFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default AffordabilityFilter;
