import PropTypes from "prop-types";

import { ShortArrow } from "../../core/icons";

import { usePagination, DOTS } from "./Hooks";

function Pagination({
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  siblingCount = 1
}) {
  const paginationRange = usePagination({
    pageSize,
    itemsCount,
    currentPage,
    siblingCount
  });

  if (currentPage === 0) return null;

  const onNext = () => onPageChange(currentPage + 1);

  const onPrevious = () => onPageChange(currentPage - 1);

  let lastPage = paginationRange[paginationRange.length - 1] || 1;

  return (
    <ul className="py-2 flex flex-1 flex-row list-none items-center justify-center">
      <li
        role="button"
        onClick={onPrevious}
        className={`
          mx-1
          px-2
          ${currentPage === 1 && "opacity-40 pointer-events-none"}
        `}
      >
        <ShortArrow size={15} />
      </li>
      {paginationRange.length > 0 ? (
        paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="font-semibold">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={index}
              role="button"
              onClick={() =>
                currentPage !== pageNumber ? onPageChange(pageNumber) : null
              }
              className={`
              mx-1
              px-2
              font-semibold
              ${
                pageNumber === currentPage &&
                "text-primary-blue pointer-events-none"
              }
            `}
            >
              {pageNumber}
            </li>
          );
        })
      ) : (
        <li className="mx-1 px-2 font-semibold opacity-40">0</li>
      )}
      <li
        role="button"
        onClick={onNext}
        className={`
          mx-1
          px-2
          ${currentPage === lastPage && "opacity-40 pointer-events-none"}
        `}
      >
        <div className="transform rotate-180">
          <ShortArrow size={15} />
        </div>
      </li>
    </ul>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  onPageChange: PropTypes.func,
  currentPage: PropTypes.number,
  siblingCount: PropTypes.number
};

export default Pagination;
