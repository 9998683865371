import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import JSZip from "jszip";

import Buttons from "./Buttons";
import BoxAmounts from "./BoxAmounts";
import DesktopViewer from "./DesktopViewer";

import { SmallModal } from "../../../../../../core";
import { Button } from "../../../../../../core/buttons";
import { ShortArrow, Close } from "../../../../../../core/icons";
import { getGrantsAndScholarships } from "../../../../../../core/utils";

import Api from "../../../../../../services/Api";
import { ModalTypes } from "../../../../../Common/utils";
import ModalContext from "../../../../../../contexts/ModalContext";
import { useOrganizations } from "../../../../../../redux/Organizations/hooks";

import { useSpinner } from "../../../../../Common/Hooks";

function Viewer({ data, onClose, onBack, onError, chosenSchool, filePurged }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { studentId } = useParams();
  const { selectedOrganizationId } = useOrganizations();

  const modalContext = useContext(ModalContext);
  const openModalHandler = modalContext.openModalHandler;

  const [isPdf, setIsPdf] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const [grants, setGrants] = useState([]);
  const [awardPdf, setAwardPdf] = useState(null);
  const [removeAid, setRemoveAid] = useState(null);
  const [awardImages, setAwardImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [financialAids, setFinancialAids] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [removeFinancialAids, setRemoveFinancialAids] = useState([]);
  const [onBackConfirmation, setOnBackConfirmation] = useState(false);
  const [showRemoveAidModal, setShowRemoveAidModal] = useState(false);
  const [showLeaveEditModal, setShowLeaveEditModal] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);

  useEffect(() => {
    if (data.grants) {
      setGrants(data.grants);
    }
  }, [data]);

  const onAdd = () => {
    openModalHandler(data, ModalTypes.ADD_FINANCIAL_AID);
  };

  const removeAidOnClick = () => handlerOnRemoveFinancialAid(removeAid.id);

  const cancelRemoveAidOnClick = () => {
    setRemoveAid(null);
    setShowRemoveAidModal(false);
  };

  const leaveEditOnClick = () => {
    if (onBackConfirmation) onBack();
    else onClose();
  };

  const cancelLeaveEditOnClick = () =>
    setShowLeaveEditModal(!showLeaveEditModal);

  const handlerRemoveFinancialAids = (id) => {
    if (isNaN(Number(id))) return;

    setRemoveFinancialAids((removeFinancialAids) =>
      removeFinancialAids.includes(id)
        ? removeFinancialAids
        : removeFinancialAids.concat([id])
    );
  };

  const handlerOnRemoveFinancialAid = (removeId) => {
    const localRemoveAid = financialAids.find(
      (financialAid) => financialAid.id === removeId
    );
    if (!removeAid) {
      setRemoveAid(localRemoveAid);
      setShowRemoveAidModal(true);
      return;
    }
    if (removeAid) {
      handlerRemoveFinancialAids(removeId);
      setShowRemoveAidModal(false);
      setRemoveAid(null);
    }

    setFinancialAids((financialAids) =>
      financialAids.filter((financialAid) => financialAid.id !== removeId)
    );
  };

  const handlerOnEditFinancialAid = ({ value, editId }) => {
    setFinancialAids((financialAids) => {
      handlerRemoveFinancialAids(editId);
      return financialAids.map((financialAid) => {
        if (financialAid.id === editId)
          return { ...financialAid, added: true, amount: value };
        return financialAid;
      });
    });
  };

  const handlerOnSaveEdit = useSpinner(async () => {
    const addAidAmounts = financialAids
      .filter((financialAid) => financialAid?.added)
      .map((financialAid) => ({
        value: financialAid.amount,
        description: financialAid.name,
        category: financialAid.type.value
      }));

    const mutationData = {
      letterId: chosenSchool.award.id,
      addAidAmounts: addAidAmounts.length > 0 ? addAidAmounts : null,
      removeAidAmounts:
        removeFinancialAids.length > 0 ? removeFinancialAids : null,
      organizationId: selectedOrganizationId
    };
    const newAward = await Api.editAwardLetterResults(mutationData);
    setFinancialAids([]);
    setRemoveFinancialAids([]);
    const newData = getGrantsAndScholarships(newAward?.aidAmounts).map(
      (grant) => ({
        name: grant.description,
        type: { value: grant.category },
        ...grant
      })
    );
    setGrants(newData);
    onBack();
  });

  const closeModal = useCallback(
    (modalType) => {
      if (modalType) {
        switch (modalType) {
          case ModalTypes.ADD_FINANCIAL_AID:
            if (modalContext.modalResponseData?.financialAids)
              setFinancialAids((financialAids) => {
                if (financialAids.length > 0)
                  return financialAids.concat(
                    modalContext.modalResponseData.financialAids.filter(
                      (data) =>
                        !financialAids
                          .map((financialAid) => financialAid.id)
                          .includes(data.id)
                    )
                  );
                return modalContext.modalResponseData.financialAids;
              });
            break;
          default:
            break;
        }
      }
    },
    [modalContext.modalResponseData]
  );

  useEffect(() => {
    closeModal(modalContext.modalResponse);
  }, [closeModal, modalContext.modalResponse]);

  useEffect(() => {
    const getStudentGrants = (excludedAids) =>
      grants
        .filter(
          (aid) =>
            (aid.categorySource === "ADVISOR" ||
              aid.categorySource === "STUDENT") &&
            !excludedAids.includes(aid.id)
        )
        .map((aid) => ({
          edit: true,
          id: aid.id,
          name: aid.name,
          type: aid.type,
          amount: aid.value,
          categorySource: aid.categorySource
        }));

    setFinancialAids((financialAids) => {
      const excludedAids = removeFinancialAids.concat(
        financialAids.map((financialAid) => financialAid.id)
      );
      if (financialAids.length > 0)
        return financialAids.concat(getStudentGrants(excludedAids));
      return getStudentGrants(excludedAids);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grants]);

  useEffect(() => {
    if (selectedFileUrl !== data.file) {
      if (data.file.includes(".zip")) setIsZip(true);
      else if (data.file.includes(".pdf")) setIsPdf(true);

      setSelectedFileUrl(data.file);
    }
  }, [selectedFileUrl, data.file]);

  useEffect(() => {
    const fetchZip = async () => {
      if (selectedFileUrl) {
        try {
          const response = await fetch(selectedFileUrl);
          const blob = await response.blob();
          if (isZip) unzipFile(blob);
          else if (isPdf) getPdf(blob);
          else getImage(blob);
        } catch (error) {
          setSomethingWentWrong(true);
        }
      }
    };

    const getPdf = (blob) => {
      const url = URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );
      setAwardPdf(url);
    };

    const getImage = (blob) => {
      const url = URL.createObjectURL(blob);
      const newFile = { id: "AwardLetter", url };
      setAwardImages([newFile]);
    };

    const unzipFile = async (blob) => {
      var zip = new JSZip();

      const zipFile = await zip.loadAsync(blob);
      Object.keys(zipFile.files).forEach(async (filename) => {
        const fileType = getFileType(filename.split(".").slice(-1)[0]);
        const fileContent = await zip.file(filename).async("blob");
        const url = URL.createObjectURL(
          new Blob([fileContent], { type: fileType })
        );
        const newFile = { id: filename, url };
        setAwardImages((state) =>
          !containsFile(newFile, state) ? state.concat([newFile]) : state
        );
      });
    };

    const getFileType = (extension) => {
      switch (extension) {
        case "png":
          return "image/png";
        case "jpeg":
          return "image/jpeg";
        case "jpg":
          return "image/jpg";
        default:
          return "";
      }
    };

    const containsFile = (file, awardImages) => {
      for (var x in awardImages) {
        if (awardImages[x].id === file.id) return true;
      }
      return false;
    };

    fetchZip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileUrl]);

  const disableButton = useMemo(
    () =>
      removeFinancialAids.length !== 0
        ? false
        : !financialAids.some((financialAid) => financialAid.added),
    [financialAids, removeFinancialAids]
  );

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div
          role="button"
          onClick={() => {
            if (!disableButton) {
              setShowLeaveEditModal(true);
              setOnBackConfirmation(true);
            } else onBack();
          }}
        >
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>
          {location.state?.isEdit ? "Edit Award Letter" : "View Award Letter"}
        </span>
        <div
          role="button"
          onClick={() => navigate(`/dashboard/students/${studentId}`)}
        >
          <Close />
        </div>
      </div>
      <div
        className="
          p-8
          flex
          flex-1
          flex-col
          bg-white
        "
      >
        <div
          className="
            mb-4
            flex
            flex-1
            flex-row
            justify-end
          "
        >
          <div
            className="
              w-1/2
              flex
              flex-row
              space-x-6
              laptop:w-2/3
            "
          >
            <Buttons onAdd={onAdd} onError={onError} />
          </div>
        </div>
        <div className="flex flex-1 flex-row">
          <div className="w-2/3 pr-10">
            <DesktopViewer
              awardPdf={awardPdf}
              filePurged={filePurged}
              awardImages={awardImages}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              somethingWentWrong={somethingWentWrong}
            />
          </div>
          <div className="my-4 w-1/3">
            {financialAids.length > 0 || removeFinancialAids.length > 0 ? (
              <div
                role="button"
                onClick={() => setShowLeaveEditModal(true)}
                className="
                  pl-2
                  mb-4
                  flex
                  w-fit
                  flex-row
                  font-bold
                  space-x-2
                  normal-case
                  items-center
                "
              >
                <ShortArrow size={15} />
                <span>Leave Edit</span>
              </div>
            ) : (
              <p className="pl-2 mb-4 font-bold normal-case">
                What we're seeing:
              </p>
            )}
            <BoxAmounts
              grants={grants}
              financialAids={financialAids}
              removeFinancialAids={removeFinancialAids}
              handlerOnEditFinancialAid={handlerOnEditFinancialAid}
              handlerOnRemoveFinancialAid={handlerOnRemoveFinancialAid}
            />
            {(financialAids.length > 0 || removeFinancialAids.length > 0) && (
              <Button
                disabled={disableButton}
                onClick={handlerOnSaveEdit}
                className="
                  mt-6
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                Save
              </Button>
            )}
            <p className="pl-2 mt-4 font-bold">
              Keep in mind we are only showing free money from the award letter.
              That means we left out any potential loans or work study. We also
              leave out any health insurance grants, as those fees are not
              included in the estimated tuition and fees on DecidED.
            </p>
          </div>
        </div>
      </div>

      {showRemoveAidModal && (
        <SmallModal open={showRemoveAidModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-blue">
              Are you sure you want to delete {removeAid?.name}?
            </p>
            <p className="mt-1 text-black font-semibold">
              This action will change the affordability of this school.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelRemoveAidOnClick}
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-blue
                  border-primary-blue
                  hover:text-white
                  active:text-white
                  hover:bg-primary-blue
                  active:bg-primary-blue
                "
              >
                Cancel
              </Button>
              <Button
                onClick={removeAidOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showRemoveAidModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                Confirm
              </Button>
            </div>
          </div>
        </SmallModal>
      )}

      {showLeaveEditModal && (
        <SmallModal open={showLeaveEditModal}>
          <div className="flex flex-1 flex-col">
            <p className="text-xl font-semibold text-primary-blue">
              Are you sure you want to quit without saving your edits?
            </p>
            <p className="mt-1 text-black font-semibold">
              You will lose all the unsaved changes.
            </p>
            <div className="mt-16 flex flex-1 flex-row space-x-4">
              <Button
                id="cancel-leave-edit-button"
                onClick={cancelLeaveEditOnClick}
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  bg-white
                  text-primary-blue
                  border-primary-blue
                  hover:text-white
                  active:text-white
                  hover:bg-primary-blue
                  active:bg-primary-blue
                "
              >
                Cancel
              </Button>
              <Button
                onClick={leaveEditOnClick}
                id="confirm-leave-edit-button"
                tabIndex={!showLeaveEditModal ? -1 : null}
                className="
                  w-full
                  text-white
                  bg-primary-blue
                  border-primary-blue
                  hover:bg-white
                  active:bg-white
                  hover:text-primary-blue
                  active:text-primary-blue
                "
              >
                Leave
              </Button>
            </div>
          </div>
        </SmallModal>
      )}
    </div>
  );
}

Viewer.propTypes = {
  openModal: PropTypes.func,
  filePurged: PropTypes.bool,
  modalResponse: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  modalResponseData: PropTypes.shape(),
  chosenSchool: PropTypes.shape().isRequired
};

export default Viewer;
