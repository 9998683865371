import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "../../../../../core/buttons";
import { ShortArrow } from "../../../../../core/icons";

import Buttons from "./Buttons";
import BoxAmounts from "./BoxAmounts";

import { getGrantsAndScholarships } from "../../../../../core/utils";
import { useOrganizations } from "../../../../../redux/Organizations/hooks";
import Api from "../../../../../services/Api";

function HowDoesThisLook({ onNo, onYes, onView }) {
  const { selectedOrganizationId } = useOrganizations();
  const { studentId, collegeId } = useParams();

  const [collegeSelected, setCollegeSelected] = useState(null);
  const [data, setData] = useState({ grants: [], totalAward: 0 });

  useEffect(() => {
    const getChosenSchool = async () => {
      const { student } = await Api.fetchAdvisorStudentDetail({
        organizationId: selectedOrganizationId,
        filter: {
          studentId: studentId,
        },
      });
      const chosenSchool = student.chosenSchools.find((school) => {
        return school.school.ipedsId === collegeId;
      });
      if (chosenSchool) {
        setCollegeSelected(chosenSchool);
      } else {
        setTimeout(getChosenSchool, 1000);
      }
    };

    getChosenSchool();
  }, [selectedOrganizationId, studentId, collegeId]);

  useEffect(() => {
    if (collegeSelected && collegeSelected.award) {
      const grants = getGrantList(collegeSelected.award.aidAmounts);
      const totalAward =
        collegeSelected.award.postAwardSchoolCosts?.totalAward || 0;
      setData({ grants, totalAward });
    }
  }, [collegeSelected]);

  const getGrantList = (aidAmounts) => {
    const grants = getGrantsAndScholarships(aidAmounts);
    return grants.map((aid, index) => {
      return { id: index, name: aid.description, value: aid.value };
    });
  };

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div role="button" onClick={onYes}>
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>
          How does this look?
        </span>
      </div>
      <div className="p-8 flex flex-1 flex-col bg-white">
        <div className="border-b-0 border-grey">
          <div className="py-6 pr-10 w-2/3">
            <p className="text-lg font-semibold">
              We're listing out all the free money that is guaranteed that the
              student won't have to pay back. That means we leave out loans and
              work study.
            </p>
          </div>
          <p className="pb-4 text-2xl font-bold normal-case">
            What we're seeing:
          </p>
          <div className="flex flex-1 flex-row space-x-10">
            <div className="w-2/3">
              <BoxAmounts grants={data.grants} totalAward={data.totalAward} />
            </div>
            <div className="my-4 w-1/3 pl-10 space-y-6">
              <Buttons onYes={onYes} onNo={onNo} />
            </div>
          </div>
          <div
            className="
              my-6
              flex
              flex-1
              flex-row
              space-x-10
              items-center
            "
          >
            <div className="w-2/3"></div>
            <div className="w-1/3 pl-10">
              <Button
                onClick={onView}
                className="
                  w-full
                  bg-white
                  text-primary-blue
                  border-primary-blue
                  hover:text-white
                  active:text-white
                  hover:bg-primary-blue
                  active:bg-primary-blue
                "
              >
                view award letter
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HowDoesThisLook.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default HowDoesThisLook;
