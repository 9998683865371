import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Tip, { TipDirection } from "./Tip";

import { useIsDesktopMQL } from "../../../core/hooks";

function AdvisorsTips({ onSkip, onFinish }) {
  const isDesktop = useIsDesktopMQL();

  const [tip, setTip] = useState({
    content: null,
    position: null,
    direction: null,
    handlerOnClick: () => {},
  });
  const [tipsState, setTipsState] = useState({
    table: true,
    advancedMenu: false,
  });

  const getLocation = useCallback(() => {
    if (tipsState.table) {
      const refPosition = document
        .getElementById("advisor-sort-by-name-button")
        .getBoundingClientRect();

      setTip({
        direction: TipDirection.BOTTOM,
        position: { top: refPosition.y, left: refPosition.x },
        content: (
          <p>
            This is an example of an Advisor list. Once you add advisors, you
            can sort or filter as needed.
          </p>
        ),
        handlerOnClick: () => {
          if (isDesktop) {
            const menu = document.getElementById("advisor-item-menu-button-2");
            menu.dispatchEvent(
              new MouseEvent("click", {
                buttons: 1,
                view: window,
                bubbles: true,
                cancelable: true,
              })
            );
          }

          setTipsState({
            table: false,
            advancedMenu: true,
          });
        },
      });
    } else if (tipsState.advancedMenu) {
      let refPosition;
      if (isDesktop)
        refPosition = document
          .getElementById("box-advisor-item-menu-button-2")
          .getBoundingClientRect();
      else
        refPosition = document
          .getElementById("advisor-menu-button-1")
          .getBoundingClientRect();

      setTip({
        direction: TipDirection.RIGHT,
        position: { top: refPosition.y, left: refPosition.x - 10 },
        content: (
          <p>
            Access the advanced menu at these three dots to edit an advisor's
            level of access or remove them from the organization.
          </p>
        ),
        handlerOnClick: () => {
          onFinish();
          setTipsState({
            table: false,
            advancedMenu: false,
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipsState]);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return (
    <Tip
      handlerOnSkip={onSkip}
      position={tip.position}
      direction={tip.direction}
      handlerOnNext={tip.handlerOnClick}
    >
      {tip.content}
    </Tip>
  );
}

AdvisorsTips.propTypes = {
  onSkip: PropTypes.func,
  onFinish: PropTypes.func,
};

export default AdvisorsTips;
