import { useState, useRef } from "react";
import PropTypes from "prop-types";

import { FloatBox } from "../";
import { Dots } from "../icons";
import { Colors, scrollIntoViewIfNeeded } from "../utils";

function ButtonDots({
  size,
  width,
  color,
  customId,
  children,
  className = "p-4 border bg-white shadow-md border-black"
}) {
  const divRef = useRef(null);
  const divRefScroll = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    scrollIntoViewIfNeeded(divRefScroll);
    setOpen(!open);
  };

  const handleOutsideClick = (state) => {
    if (window.location.pathname !== "/onboarding") setOpen(state);
  };

  return (
    <div ref={divRef} className="place-self-center">
      <div
        role="button"
        id={customId}
        onClick={handleClick}
        className="text-gray-500"
      >
        <Dots size={size} color="fill-current" />
      </div>
      <div className="relative">
        <FloatBox
          open={open}
          width={width}
          color={color}
          containerRef={divRef}
          id={`box-${customId}`}
          componentRef={divRefScroll}
          handleOnClickOutside={handleOutsideClick}
          className={`mt-2 right-2 shadow-lg ${className}`}
        >
          {children}
        </FloatBox>
      </div>
    </div>
  );
}

ButtonDots.propTypes = {
  children: PropTypes.any,
  customId: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default ButtonDots;
