import { useState } from "react";
import PropTypes from "prop-types";

import SelectorBar from "./Common/SelectorBar";

import { Dropdown } from "../../core";
import { LoadingButton } from "../../core/buttons";
import { Colors } from "../../core/utils";

import Api from "../../services/Api";
import { useOrganizations } from "../../redux/Organizations/hooks";
import { interactionEventDataLayer } from "../../utils/DataLayers";

const ExportOptions = {
  STUDENTS: "STUDENTS",
  COLLEGES: "COLLEGES",
};

function getEndpoint(id, type) {
  switch (type) {
    case ExportOptions.STUDENTS:
      return `advisors/api/bulk-export/student-summary/${id}`;
    case ExportOptions.COLLEGES:
      return `advisors/api/bulk-export/student-college/${id}`;
    default:
      return "";
  }
}

function ExportCSVModal({
  onClose,
  filters,
  students,
  organizationId,
  setConfirmModal,
}) {
  const { pages } = useOrganizations();

  const optionsList = [
    {
      id: 0,
      isSelected: true,
      label: "student summary list",
    },
    {
      id: 1,
      label: "college detail list",
    },
  ];

  const EnrollmentDictionary = {
    ENROLLMENT_1_INTERESTED: "1",
    ENROLLMENT_2_APPLIED: "2",
    ENROLLMENT_3_ACCEPTED: "3",
    ENROLLMENT_4_UPLOADED_LETTER: "4",
    ENROLLMENT_5_ENROLLED: "5",
  };

  const orgList = pages[0].map((org) => {
    return {
      enabled: true,
      id: org.organization.id,
      label: org.organization.name,
    };
  });

  const defaultOrg = pages[0].find(
    (org) => org.organization.id === organizationId
  );

  const [isLoading, setIsLoading] = useState(false);
  const [listSelected, setListSelected] = useState(0);
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [orgSelected, setOrgSelected] = useState({
    id: defaultOrg.organization.id,
    label: defaultOrg.organization.name,
  });

  const selectOrgHandler = (data) => setOrgSelected(data);

  const selectListHandler = (data) => {
    interactionEventDataLayer({
      eventName:
        data === 0
          ? "student_summary_list_button"
          : "college_detail_list_button",
    });
    setListSelected(data);
  };

  const onExportHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSomethingWentWrong(false);
    interactionEventDataLayer({ eventName: "export_csv_confirmation_button" });
    try {
      await onExportCSV({ ...orgSelected, type: listSelected });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setSomethingWentWrong(true);
    }
  };

  const onExportCSV = async ({ id, label, type }) => {
    try {
      const csvType =
        type === 0 ? ExportOptions.STUDENTS : ExportOptions.COLLEGES;
      const body = {};
      if (students?.length > 0) {
        body.student_ids = students.map((student) => student.studentId);
      } else {
        body.name = filters?.search || null;
        body.affordability = filters?.affordability || null;
        body.no_award_letter = filters?.noAwardLetter || null;
        body.no_college_added = filters?.noCollegeAdded || null;
        body.enrollment_status =
          filters?.enrollmentStatus.map(
            (enrollment) => EnrollmentDictionary[enrollment]
          ) || null;
        body.only_students_from_list = filters?.onlyStudentsFromList || null;
        if (typeof filters?.assignedAdvisor !== "undefined")
          body.assigned_advisor = filters?.assignedAdvisor;
      }
      const endpoint = getEndpoint(id, csvType);
      await Api.downloadFile(
        body,
        endpoint,
        `${label.toUpperCase()}-${csvType}.CSV`
      );
      setConfirmModal(true);
      onClose();
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-4 flex flex-1 flex-col overflow-y-auto">
        <p className="my-4 text-sm font-semibold text-primary-blue">
          Choose the organization you need the report from
        </p>
        <Dropdown
          options={orgList}
          color={Colors.BLUE}
          currentOption={orgSelected}
          handleOnSelect={selectOrgHandler}
          id="export-csv-organization-dropdown"
        />
        <div className="py-4 px-10 my-auto text-center">
          <p className="my-4 text-sm normal-case font-semibold text-primary-blue">
            Which List Do You Want to Export?
          </p>
          <SelectorBar
            id="export-csv-selector"
            optionList={optionsList}
            onSelect={selectListHandler}
          />
          <div className="flex flex-1 flex-row">
            <p
              className={`
                px-4
                pt-4
                w-1/2
                text-sm
                ${listSelected !== 0 && "text-gray-500"}
              `}
            >
              List of each student with count of colleges researched, award
              letters reviewed and affordability results.
            </p>
            <p
              className={`
                px-4
                pt-4
                w-1/2
                text-sm
                ${listSelected !== 1 && "text-gray-500"}
              `}
            >
              List of each student with a detail of every college researched and
              award letter results.
            </p>
          </div>
          {somethingWentWrong && (
            <p className="py-2 text-primary-red">Something went wrong!</p>
          )}
        </div>
      </div>
      <div className="py-5 flex flex-row">
        <LoadingButton
          isLoading={isLoading}
          onClick={onExportHandler}
          id="export-csv-export-button"
          className="
            mb-5
            w-1/2
            ml-auto
            text-white
            bg-primary-blue
            border-primary-blue
            hover:bg-white
            active:bg-white
            hover:text-primary-blue
            active:text-primary-green
          "
        >
          export report
        </LoadingButton>
      </div>
    </div>
  );
}

ExportCSVModal.propTypes = {
  filters: PropTypes.shape(),
  organizationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape()),
};

export default ExportCSVModal;
