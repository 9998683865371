import { useEffect, useRef, useState } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import PropTypes from "prop-types";

import Item from "./Item";
import { InfiniteScroll } from "../../";
import { TitleRow } from "../../labels";
import { Button, BackButton } from "../../buttons";
import { Colors, getColors } from "../../utils";

function Notifications({
  open,
  color,
  onClose,
  onMarkAll,
  isLoading,
  notifications,
  handlePagination,
  newNotifications,
  totalNotifications,
  viewResultsDataLayer,
}) {
  const divContainerRef = useRef();
  const [hasScroll, setHasScroll] = useState(false);

  const getComposeBg = (bg) => {
    switch (bg) {
      case "bg-primary-red":
        return "text-white bg-primary-red hover:bg-white active:bg-white hover:text-primary-red active:text-primary-red";
      case "bg-primary-blue":
        return "text-white bg-primary-blue hover:bg-white active:bg-white hover:text-primary-blue active:text-primary-blue";
      case "bg-primary-green":
        return "text-white bg-primary-green hover:bg-white active:bg-white hover:text-primary-green active:text-primary-green";
      case "bg-primary-orange":
        return "text-white bg-primary-orange hover:bg-white active:bg-white hover:text-primary-orange active:text-primary-orange";

      default:
        return "text-black bg-white hover:bg-light-gray active:bg-light-gray";
    }
  };

  const colors = getColors(color);
  const composeBg = getComposeBg(colors.primary.bg);

  const noNotifications = totalNotifications === 0;
  const showNoNotificationsYet =
    !isLoading && noNotifications && notifications.length === 0;
  const showScrollForMore =
    !isLoading &&
    !noNotifications &&
    notifications.length !== 0 &&
    notifications.length < totalNotifications;

  useEffect(() => {
    const container = divContainerRef.current;

    const handleScroll = (container) => {
      if (container.scrollTop > 0) setHasScroll(true);
      else setHasScroll(false);
    };

    container.addEventListener("scroll", () => handleScroll(container));
    return () =>
      container.removeEventListener("scroll", () => handleScroll(container));
  });

  return (
    <div
      ref={divContainerRef}
      id="notifications-drawer"
      className="h-full w-full bg-white shadow-lg overflow-y-auto"
    >
      <div className={`z-10 top-0 sticky bg-white ${hasScroll && "shadow-lg"}`}>
        <div className="p-4 flex flex-1 flex-row items-center">
          <BackButton tabIndex={!open ? -1 : null} onClick={onClose} />
        </div>
        <div className="px-4 laptop:px-6 desktop:px-6">
          <TitleRow large={true} text="Notifications"></TitleRow>
          {newNotifications > 0 && (
            <div
              className="
                flex
                flex-1
                flex-row
                border-t
                items-center
                border-black
              "
            >
              <div
                className="
                  py-4
                  flex
                  w-1/2
                  text-2xl
                  font-bold
                  items-center
                  justify-start
                "
              >
                <div
                  className={`
                    h-10
                    w-10
                    mr-2
                    flex
                    items-center
                    rounded-full
                    justify-center
                    bg-primary-orange
                    ${newNotifications > 15 && "text-xl"}
                  `}
                >
                  {newNotifications > 15 ? "15+" : newNotifications}
                </div>
                New!
              </div>
              <div
                className="
                  flex
                  w-1/2
                  text-xs
                  flex-col
                  items-center
                  justify-center
                "
              >
                <Button
                  onClick={onMarkAll}
                  id="mark-all-as-read-button"
                  className={`
                    py-1
                    w-full
                    border
                    ${composeBg}
                    ${colors.primary.border}
                  `}
                >
                  mark all as read
                </Button>
                <a
                  href="mailto:help@decided.org"
                  className="mt-1 underline cursor-pointer"
                >
                  Got a question? Email us.
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="px-4 h-full laptop:px-6 desktop:px-6">
        <InfiniteScroll
          querySelector={null}
          handlePagination={handlePagination}
          className="pb-5 w-full divide-y-2 divide-light-gray"
        >
          {notifications.map((notification) => {
            const notificationDate = new Date(notification.date);
            return (
              <Item
                key={notification.id}
                date={notificationDate}
                sentBy={notification.sentBy}
                action={notification.action}
                status={notification.status}
                content={notification.content}
                colors={{ ...colors, composeBg }}
                onClick={() =>
                  notification.markAsRead(notification.id, notification.status)
                }
                viewResultsDataLayer={viewResultsDataLayer}
                id="notification-item"
              />
            );
          })}
          {showScrollForMore && (
            <div className="pt-5 pb-16 font-bold">SCROLL FOR MORE...</div>
          )}
        </InfiniteScroll>

        {showNoNotificationsYet && (
          <div className="flex font-semibold items-center justify-center">
            No Notifications yet!!
          </div>
        )}
        {isLoading && (
          <div
            className="
              z-20
              flex
              fixed
              top-0
              right-0
              bottom-0
              bg-black
              opacity-25
              items-center
              font-semibold
              justify-center
            "
            style={{
              width: divContainerRef.current.getBoundingClientRect().width,
            }}
          >
            <SyncLoader size={30} color="#FFF" loading={true} />
          </div>
        )}
      </div>
    </div>
  );
}

Notifications.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  notifications: PropTypes.array,
  newNotifications: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onMarkAll: PropTypes.func.isRequired,
  totalNotifications: PropTypes.number,
  handlePagination: PropTypes.func.isRequired,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT,
  ]),
};

export default Notifications;
