import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import FiltersList from "../../../Common/FiltersList";

import { Button } from "../../../../core/buttons";
import { SearchText, Checkbox } from "../../../../core/inputs";
import { ItemsCounter, Dropdown, Link } from "../../../../core";
import { Colors } from "../../../../core/utils";
import { ButtonTooltip } from "../../../../core/compose";

import { ModalTypes } from "../../Advisors/Utils";

import { useTable } from "../../../../redux/Table/hooks";
import { useAdvisor } from "../../../../redux/Advisor/hooks";
import { updateAdvisor } from "../../../../redux/Advisor/actions";
import { updateWholeListOption } from "../../../../redux/Table/actions";

function AdvisorInvitesTableHeader({
  filters,
  onClear,
  onChange,
  onRemove,
  openModal,
  invitesState,
  inviteSearch,
  inviteFilters,
  organizationId,
  selectedInvitations,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { wholeList } = useTable();
  const { profile } = useAdvisor();

  const [tooltipOptions, setTooltipOptions] = useState({});

  useEffect(() => {
    if (invitesState.itemsCount <= 0) {
      setTooltipOptions({
        text: `Click the "Invite Advisors +" button and add one or more emails to invite advisors to your organization. `,
        open: !profile.onboardingAdvisorInvitationsTooltip,
        onClose: tooltipOptions.open
          ? () =>
              dispatch(
                updateAdvisor({
                  ...profile,
                  onboardingAdvisorInvitationsTooltip: true,
                })
              )
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitesState.itemsCount, profile, tooltipOptions.open]);

  const getActionLabel = (count) => {
    return count === 1 ? ` ${count} Invite` : ` ${count} Invites`;
  };

  const handleOnSelectOption = (data) => data.handlerOnClick();

  const handlerOnInviteAdvisor = () => {
    openModal({ organizationId }, ModalTypes.INVITE_ADVISOR);
  };

  const dropdownOptions = [
    {
      id: "re-sent",
      enabled: selectedInvitations.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          filters,
          organizationId,
          reSentInvites: !wholeList
            ? selectedInvitations.length
            : invitesState.itemsCount,
          invites: !wholeList
            ? selectedInvitations.map((item) => item.id)
            : null,
        };
        openModal(data, ModalTypes.RE_SEND_INVITE);
      },
      label: (
        <div className="text-center text-primary-blue">
          Re-send
          {getActionLabel(
            wholeList ? invitesState?.itemsCount : selectedInvitations.length
          )}
        </div>
      ),
    },
    {
      id: "delete",
      enabled: selectedInvitations.length > 0 || wholeList,
      handlerOnClick: () => {
        const data = {
          filters,
          organizationId,
          deleteInvites: !wholeList
            ? selectedInvitations.length
            : invitesState.itemsCount,
          invites: !wholeList ? selectedInvitations : null,
        };
        openModal(data, ModalTypes.DELETE_INVITE);
      },
      label: (
        <div className="text-center text-primary-blue">
          Delete
          {getActionLabel(
            wholeList ? invitesState?.itemsCount : selectedInvitations.length
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="pb-2 flex flex-1 text-sm flex-row items-center">
        <div className="flex flex-1 flex-row">
          <div className="flex flex-col">
            <div className="text-4xl font-bold">Manage Advisor Invites</div>
            <Link
              color={Colors.BLUE}
              text="Switch to Manage Students Invites"
              onClick={() => navigate("/dashboard/invites/students")}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-row space-x-4 justify-end">
          <Button
            id="invite-advisor-button"
            onClick={() => handlerOnInviteAdvisor()}
            className="
              bg-white
              text-primary-blue
              border-primary-blue
              hover:text-white
              active:text-white
              hover:bg-primary-blue
              active:bg-primary-blue
            "
          >
            Invite Advisors +
          </Button>
          <div className="min-w-15rem font-bold">
            <Dropdown
              color={Colors.BLUE}
              defaultLabel="Action"
              options={dropdownOptions}
              handleOnSelect={handleOnSelectOption}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-1 text-sm flex-row justify-start">
        <div className="flex w-1/2 flex-row justify-start">
          <SearchText
            id="invites-search-bar"
            handlerOnSubmit={onChange}
            defaultValue={inviteSearch}
            placeholder="Invite Search"
          />
        </div>
      </div>
      <div className="mt-2 flex flex-1 flex-row space-between">
        {inviteFilters.length > 1 && (
          <p
            className="mr-2 self-center font-semibold text-primary-blue cursor-pointer"
            onClick={onClear}
          >
            Clear Filters
          </p>
        )}
        <FiltersList
          onRemove={onRemove}
          filters={inviteFilters}
          isLoading={invitesState.isLoading}
        />
      </div>
      <div className="mt-4 flex text-sm flex-row items-center">
        <ItemsCounter
          pluralDescriptionItems={
            selectedInvitations.length > 0 || wholeList
              ? "Invites Selected"
              : "Invites"
          }
          singularDescriptionItems={
            selectedInvitations.length > 0 || wholeList
              ? "Invite Selected"
              : "Invite"
          }
          numberItems={
            !wholeList && selectedInvitations.length > 0
              ? selectedInvitations.length
              : invitesState?.itemsCount || 0
          }
        />
        <ButtonTooltip
          questionSize={20}
          tooltipColor={Colors.BLUE}
          tooltipCloseButton={true}
          tooltipDirection={"TOP-LEFT"}
          tooltipClassName="-left-2 top-16"
          tooltipCloseOnClickOutside={true}
          tooltipOptions={tooltipOptions}
        />
        {selectedInvitations.length > 0 &&
          invitesState.itemsCount !== selectedInvitations.length && (
            <div className="w-fit">
              <Checkbox
                value={wholeList}
                checked={wholeList}
                color={Colors.BLUE}
                label={`Select All (${invitesState.itemsCount} Invites)`}
                onChange={() => {
                  dispatch(updateWholeListOption(!wholeList));
                }}
              />
            </div>
          )}
      </div>
    </>
  );
}

AdvisorInvitesTableHeader.propTypes = {
  openModal: PropTypes.func,
  filters: PropTypes.shape(),
  invitesState: PropTypes.shape(),
  organizationId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  inviteSearch: PropTypes.string.isRequired,
  inviteFilters: PropTypes.array.isRequired,
  selectedInvitations: PropTypes.arrayOf(PropTypes.PropTypes.shape())
    .isRequired,
};

export default AdvisorInvitesTableHeader;
