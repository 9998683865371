import { toast } from "react-toastify";
import { ErrorTypes } from "../services/Api";

function GenericErrorMessage() {
  return (
    <>
      <p>Something went wrong!</p>
      <p>Try again or contact us at info@decided.org</p>
      <p>(press here to reload the page)</p>
    </>
  );
}

function ApiVersionErrorMessage() {
  return (
    <>
      <p>We have released a new version of DecidED.</p>
      <p>Please refresh your browser to see the latest version.</p>
      <p>(or press here to reload page)</p>
    </>
  );
}

function unexpectedErrorToast({ message, options, type }) {
  try {
    const msg = message || <GenericErrorMessage />;
    toast(msg, {
      theme: "colored",
      toastId: "unexpected-error",
      type: type || "error",
      autoClose: false,
      onClick: () => {
        window.location.reload();
      },
      ...options
    });
  } catch (e) {
    console.error(e);
  }
}

function apiVersionErrorToast() {
  unexpectedErrorToast({
    message: <ApiVersionErrorMessage />,
    options: null,
    type: "info"
  });
}

function installUnexpectedErrorToast({ message, options } = {}) {
  const errorList = Object.values(ErrorTypes).reduce(
    (array, value) => [...array, value],
    []
  );

  window.addEventListener("error", (event) => {
    const error = event.type;

    if (errorList.includes(error))
      if (error === ErrorTypes.apiVersionError) {
        apiVersionErrorToast();
      } else {
        unexpectedErrorToast({ message, options });
      }
  });

  window.addEventListener("unhandledrejection", (event) => {
    const error = event.reason?.type;

    if (errorList.includes(error))
      if (error === ErrorTypes.apiVersionError) {
        apiVersionErrorToast();
      } else {
        unexpectedErrorToast({ message, options });
      }
  });

  window.addEventListener("offline", () => {
    const message = (
      <>
        <p>Internet issues!</p>
        <p>Try again later</p>
        <p>(press here to reload the page)</p>
      </>
    );
    unexpectedErrorToast({ message });
  });
}

export {
  installUnexpectedErrorToast,
  unexpectedErrorToast,
  apiVersionErrorToast
};
