import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import RemoveAdvisor from "./RemoveAdvisorList";

import Api from "../../../services/Api";

function RemoveAdvisorListModal({
  list,
  filters,
  onClose,
  advisors,
  organizationId,
  removeAdvisors,
  setConfirmModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const removeAdvisorOnClick = async () => {
    await Api.addOrRemoveAdvisorsToList({
      organizationId,
      advisorListIds: [list.id],
      removeAdvisors: {
        filter: advisors?.length > 0 ? null : filters,
        advisorIds: advisors?.length > 0 ? advisors : null,
      },
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const firstTextConfirmation = (
    <span>
      You have removed
      {removeAdvisors === 1 ? " one advisor " : ` ${removeAdvisors} advisors `}
      from the list <span className="text-black"> "{list.name}"</span>.
    </span>
  );

  const secondTextConfirmation = (
    <span className="text-2xl font-medium">
      These changes will be visible to all admins who have shared access to this
      list.
    </span>
  );

  const isPrivateList =
    list.sharedWith.length === 0 && list.sharedWithOrganization === false;

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <RemoveAdvisor
          list={list}
          onClick={removeAdvisorOnClick}
          removeAdvisors={removeAdvisors}
          secondTextContent={!isPrivateList && secondTextConfirmation}
        />
      ) : (
        <Confirm
          onClick={onClose}
          buttonText="Back to List"
          firstText={firstTextConfirmation}
          secondText={!isPrivateList && secondTextConfirmation}
        />
      )}
    </div>
  );
}

RemoveAdvisorListModal.propTypes = {
  filters: PropTypes.shape(),
  organizationId: PropTypes.string,
  removeAdvisors: PropTypes.number,
  list: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.string),
};

export default RemoveAdvisorListModal;
