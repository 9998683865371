import {
  NameHeader,
  RoleHeader,
  StudentsHeader,
  LastActivityHeader,
} from "./Header";
import { NameCell, RoleCell, StudentsCell, LastActivityCell } from "./Cell";

import { clearSort } from "../../../Common/utils";
import { SortingType } from "../Utils";

export function getColumns({ orderBy, toggleSortingOrder }) {
  return [
    {
      accessor: "name",
      Header: (
        <NameHeader
          onSort={() => toggleSortingOrder(SortingType.NAME)}
          clearSort={clearSort({ orderBy, field: SortingType.NAME })}
        />
      ),
      Cell: ({ row }) => <NameCell advisor={row.original.advisor} />,
      width: "35%",
    },
    {
      accessor: "role",
      Header: (
        <RoleHeader
          onSort={() => toggleSortingOrder(SortingType.ROLE)}
          clearSort={clearSort({ orderBy, field: SortingType.ROLE })}
        />
      ),
      Cell: ({ row }) => <RoleCell advisor={row.original.advisor} />,
      width: "20%",
    },
    {
      accessor: "students",
      Header: <StudentsHeader />,
      Cell: ({ row }) => <StudentsCell itemsCount={row.original.students} />,
      width: "20%",
    },
    {
      accessor: "lastActivity",
      Header: (
        <LastActivityHeader
          onSort={() => toggleSortingOrder(SortingType.LAST_ACTIVITY)}
          clearSort={clearSort({ orderBy, field: SortingType.LAST_ACTIVITY })}
        />
      ),
      Cell: ({ row }) => <LastActivityCell advisor={row.original.advisor} />,
      width: "25%",
    },
  ];
}
