import Types from "./types";

const INITIAL_STATE = {
  wholeList: false,
  tableCheckbox: false,
};

export const tableReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.SET_WHOLE_LIST:
      return {
        ...state,
        wholeList: action.wholeList,
      };
    case Types.SET_TABLE_CHECKBOX:
      return {
        ...state,
        tableCheckbox: action.tableCheckbox,
      };
    default:
      return state;
  }
};
