import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import PropTypes from "prop-types";

import ChangeOwner from "../ChangeOwner";
import Confirm from "../../Common/Confirm";
import CreateListForm from "../CreateListForm";

import Api from "../../../../services/Api";
import { getConfirmationText } from "./Utils";
import { fetchOrganizations } from "../../../../redux/Organizations/actions";

function EditListAdvisorModal({
  list,
  onClose,
  organizationId,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const formID = "edit-advisor-list-form";

  const dispatch = useDispatch();

  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeOwner, setChangeOwner] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [textConfirmation, setTextConfirmation] = useState("");
  const [saveChangesError, setSaveChangesError] = useState(null);
  const [confirmChangeOwner, setConfirmChangeOwner] = useState(false);

  const editListOnSubmit = async (form) => {
    if (!confirmChangeOwner && form.owner.id !== list.owner.id) {
      setConfirmChangeOwner(true);
      setChangeOwner(true);
      setSaveData(form);
      if (save) {
        setSave(false);
        setOnRequestClose(false);
      }
      return;
    }

    const sharedWith =
      form?.sharedWith
        .filter((advisor) => advisor.id !== "nobody")
        .filter((advisor) => advisor.id !== "all")
        .map((advisor) => advisor.id) || null;
    const wholeOrg =
      form?.sharedWith.find((advisor) => advisor.id === "all") || null;

    const oldSharedWith = list.sharedWith.map((share) => share.id);

    const addSharedWith = sharedWith.filter(
      (id) => !oldSharedWith.includes(id)
    );
    const removeSharedWith = oldSharedWith.filter(
      (id) => !sharedWith.includes(id)
    );

    const dataMutation = {
      addSharedWith,
      organizationId,
      removeSharedWith,
      name: form.listName,
      ownerId: form.owner.id,
      advisorListId: list.id,
      sharedWithOrganization: wholeOrg ? true : false,
    };

    await Api.updateAdvisorList(dataMutation);
    await dispatch(fetchOrganizations());

    const getTextConfirmation = () => {
      const processedText = getConfirmationText({
        listName: dataMutation.name,
        nameUpdate: !isEqual(list.name, dataMutation.name),
        ownerUpdate: !isEqual(list.owner.id, dataMutation.ownerId),
        sharedUptade:
          !isEqual(list.sharedWith, sharedWith) ||
          !isEqual(
            list.sharedWithOrganization,
            dataMutation.sharedWithOrganization
          ),
      });

      return (
        <span className="text-3xl text-black font-semibold">
          {processedText.main}
          {processedText.complement}
          {processedText.options.length > 0 ? (
            <ul>
              {processedText.options.map((option, index) => (
                <li key={index} className="ml-2 flex flex-row">
                  - {option}
                </li>
              ))}
            </ul>
          ) : (
            "."
          )}
        </span>
      );
    };

    setTextConfirmation(getTextConfirmation());
    setConfirm(true);
    setConfirmModal(true);
    if (confirmChangeOwner) setConfirmChangeOwner(false);
  };

  const saveChangeOwner = async () => {
    setIsLoading(true);
    await editListOnSubmit(saveData);

    setIsLoading(false);
    setChangeOwner(false);
  };

  const saveChanges = async () => {
    const errors = [];
    setIsLoading(true);
    if (saveData.listName === "") errors.push("listName");
    if (!saveData.sharedWith) errors.push("sharedWith");

    if (errors.length > 0) setSaveChangesError(errors);
    else await editListOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const backChangeOwnerHandle = () => {
    setChangeOwner(false);
    setConfirmChangeOwner(false);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
    if (confirmChangeOwner) setChangeOwner(true);
  };

  const requestCloseHandle = (form) => {
    setSaveChangesError(null);
    setSaveData(form);
    setSave(true);
  };

  const requestCloseHandle2 = () => {
    setSave(true);
    setChangeOwner(false);
  };

  useEffect(() => {
    let sharedWith = [];
    if (list.sharedWithOrganization)
      sharedWith = sharedWith.concat([
        {
          id: "all",
          value: "all",
          label: "Share with your whole organization",
        },
      ]);

    sharedWith = sharedWith.concat(
      list.sharedWith.map((share) => ({
        id: share.id,
        value: share.id,
        label: `${share.advisor.firstName} ${share.advisor.lastName}`,
      }))
    );

    if (sharedWith.length === 0)
      sharedWith = sharedWith.concat([
        {
          id: "nobody",
          value: "nobody",
          label: "Don't share with anyone in your organization",
        },
      ]);

    const owner = {
      id: list.owner.id,
      value: list.owner.id,
      label: `${list.owner.advisor.firstName} ${list.owner.advisor.lastName}`,
    };

    setDefaultValues({
      owner: saveData.owner || owner,
      listName: saveData.listName || list.name,
      list: { owner, sharedWith, listName: list.name },
      sharedWith: Object.keys(saveData).includes("sharedWith")
        ? saveData.sharedWith
        : sharedWith,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, saveData]);

  return (
    <div className="flex flex-col h-full">
      {defaultValues && !confirm && !save && !changeOwner && (
        <CreateListForm
          id={formID}
          edit={true}
          isAdvisorList={true}
          onSubmit={editListOnSubmit}
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          organizationId={organizationId}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {!confirm && !save && changeOwner && (
        <ChangeOwner
          isLoading={isLoading}
          requestedClose={requestedClose}
          saveChangeOwner={saveChangeOwner}
          requestCloseHandle={requestCloseHandle2}
          backChangeOwnerHandle={backChangeOwnerHandle}
        />
      )}
      {confirm && !save && !changeOwner && (
        <Confirm
          onClick={onClose}
          buttonText="View List"
          firstText={textConfirmation}
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

EditListAdvisorModal.propTypes = {
  list: PropTypes.shape(),
  onClose: PropTypes.func,
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  setOnRequestClose: PropTypes.func,
};

export default EditListAdvisorModal;
