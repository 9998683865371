import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SimpleSelect as Select } from "../../core/inputs";

import Api from "../../services/Api";
import {
  fetchOrganizations,
  setSelectedOrganizationId
} from "../../redux/Organizations/actions";
import { unexpectedErrorToast } from "../../utils/unexpectedErrorToast";
import { decidedRoute } from "../../core/utils";

function AcceptOrgInvitation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [selectedInvitationId, setSelectedInvitationId] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const orgs = await Api.myPendingInvitations();
        setInvitations(orgs);
        const organizationsArray = orgs.map((org) => org.sender.organization);
        setOrganizations(organizationsArray);

        if (organizationsArray.length > 0) {
          setCurrentOrganization(organizationsArray[0]);
          setSelectedInvitationId(orgs[0].id);
        }
      } catch (error) {
        unexpectedErrorToast({
          message: (
            <>
              <p>Error fetching organizations.</p>
              <p>Please try again later.</p>
            </>
          )
        });
      }
    };
    fetchOrganizations();
  }, []);

  const handleJoinOrg = async () => {
    if (selectedInvitationId) {
      try {
        const response = await Api.acceptInvitation({
          invitationId: selectedInvitationId
        });

        if (response) {
          await dispatch(fetchOrganizations());
          await dispatch(setSelectedOrganizationId(response.organizationId));
          navigate("/");
        } else {
          unexpectedErrorToast({
            message: (
              <>
                <p>Failed to join organization.</p>
                <p>Please try again later.</p>
              </>
            )
          });
        }
      } catch (error) {
        unexpectedErrorToast({
          message: (
            <>
              <p>Error joining organization.</p>
              <p>Please try again later.</p>
            </>
          )
        });
      }
    }
  };

  const handleOnSelectOrganization = (selectedOrg) => {
    setCurrentOrganization(selectedOrg);

    const invitation = invitations.find((invitation) => {
      return invitation.sender.organization.id === selectedOrg.id;
    });
    setSelectedInvitationId(invitation.id);
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start py-20 min-h-screen bg-white">
      <div className="flex flex-col w-1/2 items-center text-center">
        <h1 className="text-primary-blue font-bold text-3xl">
          It looks like someone has already invited you to join an organization.
        </h1>
        <p className="my-4 font-bold text-lg w-2/3">
          Select the organization you want to join below.
        </p>
        <div className="flex flex-row items-center justify-center w-2/3 my-4">
          <span className="text-primary-blue font-bold">
            Below is a list of organization(s) that have sent you invitations
          </span>
        </div>
        <div className="flex flex-row items-center justify-center w-2/4 my-4">
          <Select
            placeholder="Select organization"
            list={organizations}
            current={currentOrganization?.name}
            onSelect={handleOnSelectOrganization}
          />
        </div>
        <button
          className="
            w-1/3 
            h-14 
            mt-4 
            mb-4
            bg-primary-blue 
            text-white 
            font-bold 
            px-4 
            py-2 
            hover:drop-shadow-md"
          onClick={handleJoinOrg}
        >
          Sign Up
        </button>
        <p className="text-sm text-center w-1/4 text-wrap ">
          <span>
            By signing up you agree to our
            <br />
          </span>
          <a
            className="underline cursor-pointer"
            href={decidedRoute("/legal/terms-of-service/")}
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            className="underline cursor-pointer"
            href={decidedRoute("/legal/privacy-policy/")}
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default AcceptOrgInvitation;
