import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import { BackButton } from "../../../../core/buttons";

function NoteDetail({ note, handlerOnCloseNote }) {
  const date = new Date(note.insertTime);
  const [styleScroll, setStyleScroll] = useState({});

  useEffect(() => {
    calculateMaxHeight();
    window.addEventListener("resize", calculateMaxHeight);
    return () => window.removeEventListener("resize", calculateMaxHeight);
  }, []);

  const calculateMaxHeight = () => {
    const containerHeight = document
      .getElementById("share-notes-container")
      ?.getBoundingClientRect().height;
    const headerHeight = document
      .getElementById("share-note-detail-header")
      ?.getBoundingClientRect().height;

    setStyleScroll({ maxHeight: `${containerHeight - headerHeight}px` });
  };

  return (
    <>
      <div
        id="share-note-detail-header"
        className="my-2 flex flex-1 flex-row items-center"
      >
        <BackButton onClick={() => handlerOnCloseNote(null)} />
      </div>
      <div style={styleScroll} className="px-4 mb-5 overflow-y-auto">
        <div
          className="
            py-2
            flex
            top-0
            flex-1
            sticky
            text-sm
            flex-row
            border-b
            bg-white
            text-gray-500
            justify-between
            border-light-gray
          "
        >
          <span className="pl-2">Advisor: {note.advisorName}</span>
          <span className="pr-2">Created {date.toLocaleString("en-US")}</span>
        </div>
        <div className="p-2 markdown-body">
          <ReactMarkdown children={note.text} />
        </div>
      </div>
    </>
  );
}

NoteDetail.propTypes = {
  note: PropTypes.shape(),
  handlerOnCloseNote: PropTypes.func,
};

export default NoteDetail;
