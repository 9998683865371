import PropTypes from "prop-types";

import CreateAccountForm from "./CreateAccountForm";

import { Logo } from "../../../core/icons";
import { BackButton } from "../../../core/buttons";

import { useIsDesktopMQL } from "../../../core/hooks";

function CreateAccountScreen({ profile, goToSignIn, redirectUri }) {
  const isDesktop = useIsDesktopMQL();

  const defaultValues = {
    zip: "",
    title: "",
    phoneNumber: "",
    emailReceive: true,
    firstName: profile?.given_name || "",
    lastName: profile?.family_name || ""
  };

  return (
    <div className="flex flex-col">
      <div
        className="
          pb-4
          flex
          flex-1
          flex-row
          relative
          justify-center
          mobile:justify-end
          tablet:justify-end
          tablet-sm:justify-end
        "
      >
        <div className="-mb-10 flex flex-1 flex-row justify-between">
          <BackButton onClick={goToSignIn} />
          <Logo size={isDesktop ? 50 : 30} />
        </div>
      </div>
      <div className="my-5 w-1/2 text-center mobile:my-2 self-center">
        <p className="font-bold text-2xl">
          Welcome! Fill us in a bit more and create your account
        </p>
      </div>
      <div
        className="
          w-2/3
          mobile:w-full
          mobile-sm:w-full
          tablet-sm:w-full
          place-self-center
        "
      >
        <div className="pb-4 flex flex-row flex-wrap text-xsm font-bold">
          <div className="mr-1 text-primary-blue">Email:</div>
          <div className="mx-1 font-medium break-all">{profile.email}</div>
          <div
            onClick={goToSignIn}
            className="ml-1 cursor-pointer text-primary-blue"
          >
            (not you?)
          </div>
        </div>
        <CreateAccountForm
          redirectUri={redirectUri}
          defaultValues={defaultValues}
        />
      </div>
    </div>
  );
}

CreateAccountScreen.propTypes = {
  profile: PropTypes.shape(),
  goToSignIn: PropTypes.func,
  redirectUri: PropTypes.string
};

export default CreateAccountScreen;
