import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import PropTypes from "prop-types";

import clearTextIcon from "../../assets/clearTextIcon.svg";

function RichTextEditor({ studentName, editorState, onEditorStateChange }) {
  return (
    <div className="App h-full " >
      <div className="px-2">
        Jot down your notes, edit and share with other advisors to help{" "}
        {studentName} succeed
      </div>
      <div className="mt-2 h-full markdown-body">
        <Editor
          editorState={editorState}
          wrapperStyle={{
            height: "100%",
            overflow: "hidden",
          }}
          editorStyle={{
            height: "85%",
            paddingLeft: "1rem",
          }}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "list",
              "link",
              "remove",
              "history",
            ],
            remove: {
              icon: clearTextIcon,
            },
            blockType: {
              inDropdown: false,
              options: ["Normal", "Blockquote"],
            },
            inline: {
              options: ["bold", "italic"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            link: {
              options: ["link", "unlink"],
              popupClassName: "popup",
            },
          }}
        />
      </div>
    </div>
  );
}

RichTextEditor.propTypes = {
  editorState: PropTypes.any,
  studentName: PropTypes.string,
  onEditorStateChange: PropTypes.func,
};

export default RichTextEditor;
