import { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { FloatBox } from "../../../../../core";
import { Filter } from "../../../../../core/icons";

import FiltersItem from "../../../../Common/FiltersItem";

import { useStudentInvites } from "../../../../../redux/StudentInvites/hooks";

function StatusHeader({ status = [], onFilter = () => {} }) {
  const invitesState = useStudentInvites();

  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const outSideClick = (state) => setShow(state);

  const widthFloatBox = useMemo(() => {
    if (width >= 427 && width <= 949) return 200;
    else if (width >= 950 && width <= 1050) return 230;
    return 270;
  }, [width]);

  return (
    <div
      role="button"
      ref={containerRef}
      onClick={() => setShow(!show)}
      id="invites-status-filter-button"
      className={`
        flex
        flex-row
        items-center
        justify-center
        ${
          invitesState.isLoading ? "pointer-events-none" : "pointer-events-auto"
        }
      `}
    >
      <div className="relative">
        &nbsp;
        <FloatBox
          open={show}
          width={widthFloatBox}
          containerRef={containerRef}
          handleOnClickOutside={outSideClick}
          className="
            p-4
            mt-2
            bg-white
            border-black
            tablet-sm:-ml-8
            tablet:-ml-2
            laptop:-ml-2
          "
        >
          <FiltersItem
            filters={status}
            onSelect={onFilter}
            noItemsLabel="No Groups yet!!"
            id="students-groups-filter-item-button"
          />
        </FloatBox>
      </div>
      <div className="mr-2">
        <Filter size={15} />
      </div>
      <span className="text-sm">Invite Status</span>
    </div>
  );
}

StatusHeader.protoTypes = {
  onFilter: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.string),
};

export default StatusHeader;
