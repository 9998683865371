import PropTypes from "prop-types";

function Dorms({ size = 25, color = "stroke-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 32 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="7.5" width="31" height="6" className={color} />
      <rect x="0.5" y="19.5" width="31" height="6" className={color} />
      <path
        className={color}
        d="M0.5 0V29M31.5 0V29M20.5 7.5V10.5M20.5 19.5V10.5M27.5 7.5V10.5M27.5 19.5V10.5M20.5 10.5H27.5M21 16.5H27.5"
      />
      <path d="M1 3.5H7.5V7.5" className={color} />
      <path d="M1 15.5H7.5V20" className={color} />
      <rect x="22" y="2" width="4" height="4" className={color} />
    </svg>
  );
}

Dorms.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Dorms;
