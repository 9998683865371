import { Order } from "../../utils/order";

import { SortUp, SortDown } from "../../core/icons";

export const getAdvisorRole = ({ profile, organizationId }) => {
  return (
    profile.organizationAdvisors.items.find(
      (org) => org.organization.id === organizationId
    )?.role || null
  );
};

export const clearSort = ({ orderBy, field }) => {
  return orderBy ? (!orderBy[field] ? true : false) : true;
};

export const getOrderIcon = (order) => {
  switch (order) {
    case Order.asc:
      return (
        <div className="w-4 ml-4">
          <SortUp size={12} />
        </div>
      );
    case Order.desc:
      return (
        <div className="w-4 ml-4">
          <SortDown size={12} />
        </div>
      );
    default:
      return (
        <div className="w-2 ml-4 mr-2 flex flex-col">
          <SortUp size={8} color="fill-light-gray" />
          <SortDown size={8} color="fill-light-gray" />
        </div>
      );
  }
};

export const ModalTypes = {
  EDIT_ORG_NAME: "EDIT_ORG_NAME",
  ADD_FINANCIAL_AID: "ADD_FINANCIAL_AID",
};
