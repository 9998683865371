export const ErrorReport = {
  VALUE_ERROR: "VALUE_ERROR",
  COST_ERROR: "COST_ERROR",
  MISSING_AWARD: "MISSING_AWARD",
  SOMETHING_ELSE: "SOMETHING_ELSE",
};

export function errorReportDisplay(error) {
  switch (error) {
    case ErrorReport.VALUE_ERROR:
      return "The total $ seems incorrect";
    case ErrorReport.COST_ERROR:
      return "My costs seem incorrect";
    case ErrorReport.MISSING_AWARD:
      return "One or more of my awards are missing";
    case ErrorReport.SOMETHING_ELSE:
      return "I need help with something else";
    default:
      return "";
  }
}
