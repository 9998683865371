import PropTypes from "prop-types";

function Enrolled({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 79 76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82 72.442L0 65.362L34.85 3.00195L71.63 38.902L6.82 72.442ZM5.11 64.702L7.63 67.352L64.63 37.902L35.83 9.75195L5.11 64.702Z"
        className={color}
      />
      <path
        d="M50.782 4.11186L46.6445 4.25635L46.9485 12.961L51.086 12.8165L50.782 4.11186Z"
        className={color}
      />
      <path
        d="M57.833 1.98413L51.8086 15.7539L55.6015 17.4133L61.6259 3.64357L57.833 1.98413Z"
        className={color}
      />
      <path
        d="M70.2542 14.5765L56.3828 17.751L57.3064 21.7867L71.1778 18.6122L70.2542 14.5765Z"
        className={color}
      />
      <path
        d="M61.134 22.2067L60.2344 26.2478L67.5649 27.8797L68.4645 23.8386L61.134 22.2067Z"
        className={color}
      />
      <path
        d="M21.4054 67.6717L18.3594 70.4756L23.4456 76.001L26.4916 73.1972L21.4054 67.6717Z"
        className={color}
      />
      <path
        d="M45.272 55.4109L43.1504 62.615L47.1218 63.7846L49.2434 56.5805L45.272 55.4109Z"
        className={color}
      />
      <path
        d="M26.8863 4.63398e-05L22.9707 1.34448L25.4095 8.44747L29.3251 7.10303L26.8863 4.63398e-05Z"
        className={color}
      />
      <path
        d="M72.795 62.347L70.0469 65.4434L75.6637 70.4285L78.4118 67.3321L72.795 62.347Z"
        className={color}
      />
      <path
        d="M58.247 57.3274L54.2617 58.4109L58.058 72.374L62.0434 71.2904L58.247 57.3274Z"
        className={color}
      />
      <path
        d="M64.2928 61.0602L51.373 64.5776L52.4606 68.5722L65.3803 65.0547L64.2928 61.0602Z"
        className={color}
      />
      <path
        d="M11.5412 2.70689L7.3125 16.5557L11.272 17.7647L15.5007 3.91593L11.5412 2.70689Z"
        className={color}
      />
      <path
        d="M5.81715 6.39445L4.60742 10.3538L17.413 14.2664L18.6227 10.3071L5.81715 6.39445Z"
        className={color}
      />
      <path
        d="M65.9602 51.0819C66.6781 51.0819 67.2602 50.4999 67.2602 49.7819C67.2602 49.064 66.6781 48.4819 65.9602 48.4819C65.2422 48.4819 64.6602 49.064 64.6602 49.7819C64.6602 50.4999 65.2422 51.0819 65.9602 51.0819Z"
        className={color}
      />
      <path
        d="M65.9599 52.3119C65.4587 52.3139 64.9681 52.167 64.5506 51.8897C64.133 51.6125 63.8071 51.2175 63.6144 50.7548C63.4217 50.2921 63.3707 49.7826 63.4681 49.2909C63.5654 48.7992 63.8065 48.3474 64.1609 47.993C64.5154 47.6386 64.9671 47.3974 65.4588 47.3001C65.9505 47.2028 66.46 47.2537 66.9227 47.4464C67.3854 47.6392 67.7805 47.965 68.0577 48.3826C68.3349 48.8002 68.4819 49.2907 68.4799 49.792C68.4773 50.4595 68.2109 51.0989 67.7389 51.571C67.2669 52.043 66.6274 52.3093 65.9599 52.3119ZM65.9599 49.7019L66.0399 49.792C66.04 49.7697 66.0319 49.7482 66.0172 49.7316C66.0024 49.715 65.982 49.7044 65.9599 49.7019Z"
        className={color}
      />
      <path
        d="M39.2707 73.1317C39.9887 73.1317 40.5707 72.5497 40.5707 71.8317C40.5707 71.1138 39.9887 70.5317 39.2707 70.5317C38.5527 70.5317 37.9707 71.1138 37.9707 71.8317C37.9707 72.5497 38.5527 73.1317 39.2707 73.1317Z"
        className={color}
      />
      <path
        d="M39.2705 74.3618C38.7697 74.3638 38.2795 74.2171 37.8622 73.9403C37.4448 73.6635 37.119 73.269 36.9259 72.8069C36.7329 72.3448 36.6814 71.8358 36.7779 71.3443C36.8744 70.8529 37.1145 70.4012 37.468 70.0464C37.8214 69.6915 38.2722 69.4495 38.7632 69.3511C39.2542 69.2527 39.7635 69.3022 40.2263 69.4934C40.6892 69.6846 41.0849 70.0089 41.3634 70.4251C41.6418 70.8414 41.7905 71.3309 41.7905 71.8318C41.7879 72.5002 41.5218 73.1407 41.0501 73.6144C40.5784 74.088 39.9389 74.3565 39.2705 74.3618ZM39.2705 71.7517L39.3505 71.8318C39.3505 71.8105 39.342 71.7902 39.327 71.7752C39.312 71.7602 39.2917 71.7517 39.2705 71.7517Z"
        className={color}
      />
      <path
        d="M10.091 31.9318C10.809 31.9318 11.391 31.3497 11.391 30.6318C11.391 29.9138 10.809 29.3318 10.091 29.3318C9.37305 29.3318 8.79102 29.9138 8.79102 30.6318C8.79102 31.3497 9.37305 31.9318 10.091 31.9318Z"
        className={color}
      />
      <path
        d="M10.0918 33.1517C9.59426 33.1478 9.10902 32.9966 8.69725 32.7173C8.28548 32.438 7.9656 32.0431 7.77793 31.5823C7.59026 31.1215 7.5432 30.6155 7.64268 30.128C7.74216 29.6405 7.98373 29.1933 8.33695 28.8429C8.69016 28.4925 9.1392 28.2544 9.62748 28.1588C10.1158 28.0632 10.6214 28.1142 11.0807 28.3055C11.54 28.4968 11.9324 28.8198 12.2085 29.2338C12.4845 29.6478 12.6318 30.1341 12.6318 30.6317C12.6318 30.9643 12.566 31.2936 12.4381 31.6007C12.3102 31.9077 12.1228 32.1865 11.8867 32.4208C11.6505 32.655 11.3704 32.8402 11.0623 32.9657C10.7543 33.0911 10.4244 33.1544 10.0918 33.1517ZM10.0918 30.5517L10.1718 30.6317C10.1718 30.6105 10.1634 30.5902 10.1484 30.5752C10.1334 30.5602 10.113 30.5517 10.0918 30.5517Z"
        className={color}
      />
      <path
        d="M20.0605 36.9881L15.9902 37.6882L20.252 62.4644L24.3222 61.7643L20.0605 36.9881Z"
        className={color}
      />
      <path
        d="M33.8659 12.2742L29.7734 12.8997L36.0829 54.1802L40.1754 53.5547L33.8659 12.2742Z"
        className={color}
      />
    </svg>
  );
}

Enrolled.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Enrolled;
