import { useRef, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { Link } from "../../../core";
import { Button } from "../../../core/buttons";
import { PieChart } from "../../../core/charts";
import { Browser, Student, ShortArrow } from "../../../core/icons";

import { Affordability, decidedRouteSupport } from "../../../core/utils";

import { FilterTag } from "../../Common/FiltersList";

import Api from "../../../services/Api";
import { Items, saveState } from "../../../redux/storePersist";

function AddedCollegeInsight({
  refetchData,
  setRefetchData,
  organizationId,
  invitesInsights,
  handlerOnViewInsight,
}) {
  const navigate = useNavigate();

  const isMounted = useRef(false);
  const [content, setContent] = useState(null);

  const filters = useMemo(
    () => [
      { key: FilterTag.ENROLLMENT_1_INTERESTED },
      { key: FilterTag.ENROLLMENT_2_APPLIED },
      { key: FilterTag.ENROLLMENT_3_ACCEPTED },
      { key: FilterTag.ENROLLMENT_4_UPLOADED_LETTER },
      { key: FilterTag.ENROLLMENT_5_ENROLLED },
    ],
    []
  );

  useEffect(() => {
    isMounted.current = true;

    const fetchData = async () => {
      setRefetchData(false);
      const {
        percentStudents,
        totalInvitesCount,
        totalStudentsCount,
        filterInvitesCount,
        filterStudentsCount,
      } = await Api.fetchStudentInsights({
        organizationId,
        filter: filters.map((filter) => filter.key),
      });

      if (isMounted.current) {
        const handlerOnClick = () => {
          if (invitesInsights) {
            saveState({ internalState: { filters } }, Items.APP_STATE);

            navigate("/dashboard/students");
          } else handlerOnViewInsight(filters);
        };

        if (
          (totalStudentsCount === 0 && totalInvitesCount === 0) ||
          (totalStudentsCount === 0 && totalInvitesCount > 0) ||
          (filterInvitesCount > 0 &&
            totalStudentsCount === 0 &&
            filterStudentsCount === 0)
        )
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="flex w-1/3 justify-center">
                  <Browser size={60} color="fill-gray-500" />
                </div>
                <div className="w-2/3">
                  <p>
                    {totalStudentsCount === 0 && totalInvitesCount === 0
                      ? "Once you add students"
                      : "Once your students join"}
                    , you'll see who's added colleges or letters here.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-xs font-bold">
                  <Button
                    onClick={() =>
                      window.open(
                        decidedRouteSupport(
                          "/articles/8095712362900-How-to-add-students-to-your-organization"
                        ),
                        "_blank"
                      )
                    }
                    className="
                      w-fit
                      normal-case
                      text-white
                      bg-primary-green
                      border-primary-green
                      hover:bg-white
                      active:bg-white
                      hover:text-primary-green
                      active:text-primary-green
                    "
                  >
                    How to Add Students
                  </Button>
                </div>
              </div>
            </>
          );
        else if (totalStudentsCount > 0 && filterStudentsCount === 0)
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="flex w-1/3 justify-center">
                  <Student size={60} color="fill-gray-500" />
                </div>
                <div className="w-2/3">
                  <p>
                    None of your students have added colleges to their list.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-xs font-bold">
                  <Link
                    icon={true}
                    target="_blank"
                    internal={false}
                    text="Message students"
                    href={decidedRouteSupport(
                      "/articles/8097522663700-Messaging-students-with-notifications"
                    )}
                    customIcon={
                      <div className="-ml-1 transform place-self-end rotate-180">
                        <ShortArrow size={12} color="stroke-current" />
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          );
        else if (filterStudentsCount > 0)
          setContent(
            <>
              <div className="flex flex-1 flow-row space-x-2 items-center">
                <div className="w-1/3">
                  <PieChart
                    percent={percentStudents}
                    affordability={Affordability.AFFORDABLE}
                  />
                </div>
                <div className="w-2/3">
                  <p>
                    <span className="font-extrabold">{percentStudents}% </span>
                    of your students have added colleges to their list.
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end">
                <div className="mt-1 pl-2 w-2/3 text-sm font-bold">
                  <Link
                    icon={true}
                    onClick={handlerOnClick}
                    text="View these students"
                    customIcon={
                      <div className="-ml-1 transform place-self-end rotate-180">
                        <ShortArrow size={12} color="stroke-current" />
                      </div>
                    }
                  />
                </div>
              </div>
            </>
          );
      }
    };

    fetchData();
    return () => (isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refetchData, organizationId]);

  return <div className="flex flex-1 flex-col">{content}</div>;
}

AddedCollegeInsight.propTypes = {
  refetchData: PropTypes.bool,
  setRefetchData: PropTypes.func,
  invitesInsights: PropTypes.bool,
  organizationId: PropTypes.string,
  handlerOnViewInsight: PropTypes.func,
};

export default AddedCollegeInsight;
