import { useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import { ModalTypes as StudentModalTypes } from "../Dashboard/Students/Utils";
import { ModalTypes as AdvisorModalTypes } from "../Dashboard/Advisors/Utils";
import { ModalTypes as GeneralModalTypes } from "./utils";
import {
  NotesModal,
  ExportCSVModal,
  EditAccessModal,
  EditOrgNameModal,
  InviteStudentModal,
  InviteAdvisorModal,
  DeleteStudentModal,
  DeleteAdvisorModal,
  AssignAdvisorModal,
  MessageStudentModal,
  AddStudentListModal,
  AddAdvisorListModal,
  ArchiveStudentModal,
  EditListStudentModal,
  EditListAdvisorModal,
  UnarchiveStudentModal,
  CreateListStudentModal,
  CreateListAdvisorModal,
  DeleteListStudentModal,
  DeleteListAdvisorModal,
  RemoveStudentListModal,
  RemoveAdvisorListModal,
  ArchiveListAdvisorModal,
  ArchiveListStudentModal,
  ArchiveStudentsBulkModal,
  UnarchiveListStudentModal,
  DeleteStudentInvitesModal,
  ReSentStudentInvitesModal,
  DeleteAdvisorInvitesModal,
  ReSentAdvisorInvitesModal,
  UnarchiveListAdvisorModal,
  AddAdditionalFinancialAidModal,
} from "../Modals/index";

import { Close } from "../../core/icons";
import { interactionEventDataLayer } from "../../utils/DataLayers";

ReactModal.setAppElement("#root");

function Modal({ show, closeModal, modalData, clearReponse }) {
  const [confirmModal, setConfirmModal] = useState(false);
  const [requestedClose, setRequestedClose] = useState(false);

  const finishCloseModal = () => {
    modal.closeModalAction && modal.closeModalAction();
    if (confirmModal) {
      closeModal(modalData.type);
    } else {
      closeModal();
    }
  };

  const getModal = (modalData) => {
    switch (modalData.type) {
      case GeneralModalTypes.EDIT_ORG_NAME:
        return {
          title: `Edit "${modalData.data.currentOrg?.label || "Org Name"}"`,
          component: (
            <EditOrgNameModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              currentOrg={modalData.data.currentOrg}
              onClose={() => closeModal(modalData.type)}
            />
          ),
        };
      case GeneralModalTypes.ADD_FINANCIAL_AID:
        return {
          isFixed: false,
          title: "Add Additional Financial Aid",
          component: (
            <AddAdditionalFinancialAidModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              onClose={(financialAids) =>
                closeModal(modalData.type, { financialAids })
              }
            />
          ),
        };
      case StudentModalTypes.CREATE_LIST:
        return {
          title: (
            <div className="flex flex-row items-center">
              New Student List
              <p className="ml-4 font-bold text-xl text-primary-blue">
                ({modalData.data.elements ? modalData.data.elements : 0}{" "}
                {modalData.data.elements === 1 ? "Student" : "Students"})
              </p>
            </div>
          ),
          component: (
            <CreateListStudentModal
              requestedClose={requestedClose}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              elements={modalData.data.elements}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
          closeModalAction: () =>
            interactionEventDataLayer({
              eventName: "new_student_list_modal_x_button",
            }),
        };
      case StudentModalTypes.DELETE_LIST:
        return {
          title:
            modalData.data.deleteLists > 1
              ? "Delete Student Lists"
              : "Delete Student List",
          component: (
            <DeleteListStudentModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              isArchived={modalData.data.isArchived}
              deleteLists={modalData.data.deleteLists}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.EDIT_LIST:
        return {
          title: "Edit List Settings",
          component: (
            <EditListStudentModal
              list={modalData.data.list}
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.ARCHIVE_LIST:
        return {
          title: "Archive List",
          component: (
            <ArchiveListStudentModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              archiveLists={modalData.data.archiveLists}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.UNARCHIVE_LIST:
        return {
          title: "Archived Student Lists",
          component: (
            <UnarchiveListStudentModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
              unarchiveLists={modalData.data.unarchiveLists}
            />
          ),
        };
      case StudentModalTypes.ADD_TO_LIST:
        return {
          title: "Add to List",
          component: (
            <AddStudentListModal
              requestedClose={requestedClose}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.REMOVE_FROM_LIST:
        return {
          title: "Remove from List",
          component: (
            <RemoveStudentListModal
              list={modalData.data.list}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              onClose={() => closeModal(modalData.type)}
              removeStudents={modalData.data.removeStudents}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.MESSAGE_STUDENT:
        return {
          title: "Send Notification",
          description:
            "Share a one-way message with selected students in DecidED. They will also be notified by email.",
          component: (
            <MessageStudentModal
              requestedClose={requestedClose}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
              messageStudents={modalData.data.messageStudents}
            />
          ),
          closeModalAction: () =>
            interactionEventDataLayer({
              eventName: "send_notification_x_button",
            }),
        };
      case StudentModalTypes.ASSIGN_ADVISOR:
        return {
          title: "Assign an Advisor",
          component: (
            <AssignAdvisorModal
              filter={modalData.data.filter}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              onClose={() => closeModal(modalData.type)}
              confirmAssign={modalData.data.confirmAssign}
              assingAdvisors={modalData.data.assingAdvisors}
              assignStudents={modalData.data.assignStudents}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.EXPORT_CSV:
        return {
          title: "Export Report",
          component: (
            <ExportCSVModal
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.DELETE_STUDENT:
        return {
          title: "Delete Student",
          component: (
            <DeleteStudentModal
              filter={modalData.data.filter}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              finishCloseModal={finishCloseModal}
              onClose={() => closeModal(modalData.type)}
              deleteStudents={modalData.data.deleteStudents}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.ARCHIVE_STUDENT:
        return {
          title: "Archive Student",
          component: (
            <ArchiveStudentModal
              filter={modalData.data.filter}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              finishCloseModal={finishCloseModal}
              onClose={() => closeModal(modalData.type)}
              archiveStudents={modalData.data.archiveStudents}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.UNARCHIVE_STUDENT:
        return {
          title: "Unarchive Student",
          component: (
            <UnarchiveStudentModal
              filter={modalData.data.filter}
              setConfirmModal={setConfirmModal}
              students={modalData.data.students}
              finishCloseModal={finishCloseModal}
              onClose={() => closeModal(modalData.type)}
              unarchiveStudents={modalData.data.unarchiveStudents}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.NOTES_STUDENT:
        return {
          title: "",
          component: (
            <NotesModal
              userId={modalData.data.userId}
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              studentId={modalData.data.studentId}
              setOnRequestClose={setRequestedClose}
              studentName={modalData.data.studentName}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.INVITE_STUDENT:
        return {
          title: "Invite New Students",
          component: (
            <InviteStudentModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              assingAdvisors={modalData.data.assingAdvisors}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.DELETE_INVITE:
        return {
          title: "Manage Student Invites",
          component: (
            <DeleteStudentInvitesModal
              filters={modalData.data.filters}
              invites={modalData.data.invites}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.RE_SEND_INVITE:
        return {
          title: "Re-invite New Student(s)",
          component: (
            <ReSentStudentInvitesModal
              filters={modalData.data.filters}
              invites={modalData.data.invites}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              reSentInvites={modalData.data.reSentInvites}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case StudentModalTypes.ARCHIVE_STUDENT_BULK:
        return {
          isFixed: true,
          component: (
            <ArchiveStudentsBulkModal
              onClose={() => closeModal(modalData.type)}
              onClick={modalData.data.onClick}
              numberOfStudents={modalData.data.numberOfStudents}
            />
          ),
        };
      case AdvisorModalTypes.CREATE_LIST:
        return {
          title: "New Advisor List",
          component: (
            <CreateListAdvisorModal
              requestedClose={requestedClose}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              advisors={modalData.data.advisors}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
          closeModalAction: () =>
            interactionEventDataLayer({
              eventName: "new_advisor_list_modal_x_button",
            }),
        };
      case AdvisorModalTypes.EDIT_LIST:
        return {
          title: "Edit List Settings",
          component: (
            <EditListAdvisorModal
              list={modalData.data.list}
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.DELETE_LIST:
        return {
          title: "Delete Advisor Lists",
          component: (
            <DeleteListAdvisorModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              isArchived={modalData.data.isArchived}
              deleteLists={modalData.data.deleteLists}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.ADD_TO_LIST:
        return {
          title: "Add to List",
          component: (
            <AddAdvisorListModal
              requestedClose={requestedClose}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              advisors={modalData.data.advisors}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.EDIT_ACCESS_ADVISOR:
        return {
          title: "Edit Access",
          component: (
            <EditAccessModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              advisors={modalData.data.advisors}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
            />
          ),
        };
      case AdvisorModalTypes.DELETE_ADVISOR:
        return {
          title: "Delete Advisor",
          component: (
            <DeleteAdvisorModal
              setConfirmModal={setConfirmModal}
              advisors={modalData.data.advisors}
              finishCloseModal={finishCloseModal}
              onClose={() => closeModal(modalData.type)}
              deleteAdvisors={modalData.data.deleteAdvisors}
              organizationId={modalData.data.organizationId}
            />
          ),
        };

      case AdvisorModalTypes.REMOVE_ADVISOR_FROM_LIST:
        return {
          title: "Remove from List",
          component: (
            <RemoveAdvisorListModal
              list={modalData.data.list}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              advisors={modalData.data.advisors}
              onClose={() => closeModal(modalData.type)}
              removeAdvisors={modalData.data.removeAdvisors}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.INVITE_ADVISOR:
        return {
          title: "Invite New Advisors",
          component: (
            <InviteAdvisorModal
              requestedClose={requestedClose}
              setConfirmModal={setConfirmModal}
              setOnRequestClose={setRequestedClose}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.DELETE_INVITE:
        return {
          title: "Manage Advisor Invites",
          component: (
            <DeleteAdvisorInvitesModal
              filters={modalData.data.filters}
              invites={modalData.data.invites}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.RE_SEND_INVITE:
        return {
          title: "Re-invite New Advisor(s)",
          component: (
            <ReSentAdvisorInvitesModal
              filters={modalData.data.filters}
              invites={modalData.data.invites}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              reSentInvites={modalData.data.reSentInvites}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.ARCHIVE_LIST:
        return {
          title: "Archive Advisor List",
          component: (
            <ArchiveListAdvisorModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              archiveLists={modalData.data.archiveLists}
              organizationId={modalData.data.organizationId}
            />
          ),
        };
      case AdvisorModalTypes.UNARCHIVE_LIST:
        return {
          title: "Archived Advisor Lists",
          component: (
            <UnarchiveListAdvisorModal
              lists={modalData.data.lists}
              filters={modalData.data.filters}
              setConfirmModal={setConfirmModal}
              onClose={() => closeModal(modalData.type)}
              organizationId={modalData.data.organizationId}
              unarchiveLists={modalData.data.unarchiveLists}
            />
          ),
        };
      default:
        return {
          title: "Modal Tittle",
          component: <div>Modal component</div>,
        };
    }
  };

  const onRequestCloseHandle = () => {
    setRequestedClose(true);
    if (!confirmModal) {
      if (
        modalData.type !== GeneralModalTypes.EDIT_ORG_NAME &&
        modalData.type !== GeneralModalTypes.ADD_FINANCIAL_AID &&
        modalData.type !== StudentModalTypes.EDIT_LIST &&
        modalData.type !== StudentModalTypes.ADD_TO_LIST &&
        modalData.type !== StudentModalTypes.CREATE_LIST &&
        modalData.type !== StudentModalTypes.NOTES_STUDENT &&
        modalData.type !== StudentModalTypes.INVITE_STUDENT &&
        modalData.type !== StudentModalTypes.MESSAGE_STUDENT &&
        modalData.type !== StudentModalTypes.ARCHIVE_STUDENT &&
        modalData.type !== StudentModalTypes.UNARCHIVE_STUDENT &&
        modalData.type !== AdvisorModalTypes.EDIT_LIST &&
        modalData.type !== AdvisorModalTypes.ADD_TO_LIST &&
        modalData.type !== AdvisorModalTypes.CREATE_LIST &&
        modalData.type !== AdvisorModalTypes.INVITE_ADVISOR &&
        modalData.type !== AdvisorModalTypes.EDIT_ACCESS_ADVISOR
      )
        finishCloseModal();
    } else finishCloseModal();
  };

  const modal = getModal(modalData);

  const onAfterClose = () => {
    clearReponse("");
    setConfirmModal(false);
    setRequestedClose(false);
  };

  return !modal.isFixed ? (
    <ReactModal
      isOpen={show}
      shouldCloseOnEsc={true}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestCloseHandle}
      className={`
        p-6
        mx-auto
        w-40rem
        absolute
        flex-col
        top-1/11
        left-1/10
        right-1/10
        bg-white
        bottom-1/11
        outline-none
        mobile:top-0
        mobile:w-full
        mobile:h-full
        mobile:left-0
        mobile:right-0
        mobile:bottom-0
        mobile-sm:top-0
        mobile-sm:w-full
        mobile-sm:h-full
        mobile-sm:left-0
        mobile-sm:right-0
        mobile-sm:bottom-0
        tablet-sm:top-0
        tablet-sm:w-full
        tablet-sm:h-full
        tablet-sm:left-0
        tablet-sm:right-0
        tablet-sm:bottom-0
        ${modalData?.data?.className}
      `}
      overlayClassName="
        z-30
        fixed
        inset-0
        bg-transparent-primary-blue
      "
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row items-center">
          <h1 className="flex flex-row  font-black text-4xl">{modal.title}</h1>
          <div
            role="button"
            onClick={finishCloseModal}
            className="ml-auto place-self-center"
          >
            <Close size={25} />
          </div>
        </div>
        {modal.description && (
          <p className="mr-6 text-sm font-bold text-gray-500">
            {modal.description}
          </p>
        )}
        {modal.component}
      </div>
    </ReactModal>
  ) : (
    <ReactModal
      isOpen={show}
      shouldCloseOnEsc={true}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestCloseHandle}
      className={`
        m-auto
        w-2/4
        h-2/4
        absolute
        flex-col
        top-1/11
        left-1/10
        right-1/10
        bg-white
        bottom-1/11
        outline-none
        mobile:top-0
        mobile:w-full
        mobile:h-full
        mobile:left-0
        mobile:right-0
        mobile:bottom-0
        mobile-sm:top-0
        mobile-sm:w-full
        mobile-sm:h-full
        mobile-sm:left-0
        mobile-sm:right-0
        mobile-sm:bottom-0
        tablet-sm:top-0
        tablet-sm:w-full
        tablet-sm:h-full
        tablet-sm:left-0
        tablet-sm:right-0
        tablet-sm:bottom-0
        ${modalData?.data?.className}
      `}
      overlayClassName="
        z-30
        fixed
        inset-0
        bg-transparent-primary-blue
      "
    >
      <div className="flex flex-col h-full overflow-hidden">
        <div
          role="button"
          onClick={finishCloseModal}
          className="ml-auto place-self-center p-6"
        >
          <Close size={25} />
        </div>
        {modal.component}
      </div>
    </ReactModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  clearReponse: PropTypes.func.isRequired,
  modalData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.any,
  }).isRequired,
};

export default Modal;
