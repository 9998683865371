import PropTypes from "prop-types";

import { EnrollmentDictionary } from "../../Utils";
import { getEnrollmentIcon } from "../../../../../core/utils";

function EnrollmentCell({ enrollmentStatus, ...restProps }) {
  return (
    <div
      role="button"
      id="enrollment-icon-label"
      data-tooltip-id="enrollment-icon-label"
      className="flex flex-col items-center"
      data-tooltip-content={EnrollmentDictionary[enrollmentStatus]}
      {...restProps}
    >
      {getEnrollmentIcon({
        width: 30,
        height: 30,
        enrollment: enrollmentStatus,
      })}
      {!enrollmentStatus && (
        <div
          className="
            py-2
            px-4
            mx-auto
            text-xs
            normal-case
            text-center
            font-semibold
            bg-light-gray
            text-gray-500
          "
        >
          No College Added
        </div>
      )}
    </div>
  );
}

EnrollmentCell.propTypes = {
  enrollmentStatus: PropTypes.string,
};

export { EnrollmentCell };
