import PropTypes from "prop-types";

import { ArrowNav } from "../../../core/icons";
import { ButtonDots } from "../../../core/compose";

import { Order } from "../../../utils/order";

function SortOption({ children, customId, isSelected, handlerOnClick }) {
  const styleSort = "text-lg font-semibold";

  return (
    <div
      role="button"
      id={customId}
      onClick={handlerOnClick}
      className={`
        flex
        flex-row
        space-x-2
        items-center
        ${isSelected && styleSort}
      `}
    >
      {children}
    </div>
  );
}

SortOption.propTypes = {
  customId: PropTypes.string,
  isSelected: PropTypes.bool,
  handlerOnClick: PropTypes.func,
};

function DotsButton({ order, toggleOrder }) {
  return (
    <ButtonDots width={160} size={20} customId="order-share-notes-button">
      <div
        className="
          text-sm
          divide-y
          capitalize
          text-center
          divide-solid
          divide-black
        "
      >
        <div className="flex flex-col space-y-3">
          <SortOption
            customId="notes-order-asc"
            isSelected={order === Order.asc}
            handlerOnClick={() => toggleOrder(Order.asc)}
          >
            <span>Sort A</span>
            <div className="transform rotate-180">
              <ArrowNav size={16} />
            </div>
            <span>Z</span>
          </SortOption>
          <SortOption
            customId="notes-order-desc"
            isSelected={order === Order.desc}
            handlerOnClick={() => toggleOrder(Order.desc)}
          >
            <span>Sort Z</span>
            <div className="transform rotate-180">
              <ArrowNav size={16} />
            </div>
            <span>A</span>
          </SortOption>
        </div>
      </div>
    </ButtonDots>
  );
}

DotsButton.propTypes = {
  order: PropTypes.string.isRequired,
  toggleOrder: PropTypes.func.isRequired,
};

export default DotsButton;
