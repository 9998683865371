import PropTypes from "prop-types";

import NotesButton from "../Common/NotesButton";
import StudentDisplay from "../Common/StudentDisplay";
import EllipsisDropdown from "../Common/EllipsisDropdown";
import CollegeYearButtons from "../Common/CollegeYearButtons";

import { Dropdown } from "../../../../core";
import { BackButton } from "../../../../core/buttons";
import { Colors } from "../../../../core/utils";
import { getAssigAdvisorList } from "../../../Modals/AssignAdvisorModal/Utils";

function StudentDetailHeader({
  student,
  advisors,
  openModal,
  showToggle,
  programLength,
  organizationId,
  onAssingAdvisor,
  setProgramLength,
  onReturnStudentList,
}) {
  const assingAdvisors = getAssigAdvisorList(advisors);

  const assignedAdvisor = assingAdvisors.find(
    (advisor) => advisor.id === student?.assignedAdvisor?.id
  );

  return (
    <div className="flex flex-col divide-y divide-solid divide-light-gray">
      <div className="pb-2 flex flex-1 flex-row justify-between">
        <BackButton onClick={onReturnStudentList} />
        {showToggle && (
          <CollegeYearButtons
            student={student}
            programLength={programLength}
            setProgramLength={setProgramLength}
          />
        )}
      </div>
      <div className="py-2 flex flex-col">
        <StudentDisplay student={student} className="w-full" />
        <div className="py-4 flex flex-1 flex-row items-center space-x-8">
          <div className="flex w-24rem flex-row space-x-4 items-center">
            <p className="font-semibold min-w-fit">Assigned Advisor:</p>
            <div className="w-full text-sm">
              <Dropdown
                color={Colors.BLUE}
                options={assingAdvisors}
                id="assigned-advisor-dropdown"
                currentOption={assignedAdvisor}
                handleOnSelect={onAssingAdvisor}
              />
            </div>
          </div>
          <div className="flex flex-1 flex-row">
            <div className="flex flex-col">
              <p className="font-semibold">Lists:</p>
              <EllipsisDropdown
                lines={1}
                separator=", "
                more="more..."
                text={student.studentListNames.join(", ")}
                titleDropdown={`All ${student?.firstName}‘s Lists:`}
                classMore="text-sm underline uppercase font-semibold"
              />
            </div>
          </div>
          <div className="flex flex-row w-13.5rem">
            <NotesButton
              openModal={openModal}
              userId={student.userId}
              studentId={student.studentId}
              studentName={student.firstName}
              organizationId={organizationId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

StudentDetailHeader.propTypes = {
  openModal: PropTypes.func,
  showToggle: PropTypes.bool,
  organizationId: PropTypes.string,
  student: PropTypes.shape().isRequired,
  programLength: PropTypes.string.isRequired,
  onAssingAdvisor: PropTypes.func.isRequired,
  setProgramLength: PropTypes.func.isRequired,
  onReturnStudentList: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default StudentDetailHeader;
