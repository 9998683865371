import { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function CategoryItem({ list, pathname }) {
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const length = location.pathname.split("/").length;
    if (
      location.pathname.includes(pathname) &&
      location.pathname.includes("list") &&
      location.pathname.split("/")[length - 1] === list.id
    ) {
      setIsActive(true);
    } else {
      if (
        location.pathname.includes(pathname) &&
        list.id ===
          `archived-${pathname}-${location.pathname.split("/")[length - 2]}`
      )
        setIsActive(true);
      else setIsActive(false);
    }
  }, [location.pathname, pathname, list.id]);

  const hoverStyle =
    "hover:font-semibold hover:text-primary-blue hover:bg-secondary-blue";

  return (
    <div
      className={`
        pr-0
        pl-4
        w-full
        ${hoverStyle} 
        ${
          isActive
            ? "font-bold text-primary-blue bg-secondary-blue"
            : "bg-white text-black"
        }
      `}
    >
      <NavLink
        to={
          list?.directPath
            ? list.directPath
            : `/dashboard/${pathname}/list/${list.id}`
        }
      >
        <div className="truncate">{list.name}</div>
      </NavLink>
    </div>
  );
}

CategoryItem.propTypes = {
  pathname: PropTypes.any,
  list: PropTypes.shape(),
};

export default CategoryItem;
