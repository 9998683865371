import PropTypes from "prop-types";
// Components
import { Button } from "../../../../../../core/buttons";

function Buttons({ onAdd, onError }) {
  return (
    <>
      <Button
        onClick={onAdd}
        id="award-looks-correct-button"
        className="
          w-1/2 
          text-white
          bg-primary-blue
          border-primary-blue
          hover:bg-white
          active:bg-white
          hover:text-primary-blue
          active:text-primary-blue
        "
      >
        add additional financial aid
      </Button>
      <Button
        onClick={onError}
        id="award-report-error-button"
        className="
          w-1/2 
          bg-white
          normal-case
          text-primary-blue
          border-primary-blue
          hover:text-white
          active:text-white
          hover:bg-primary-blue
          active:bg-primary-blue
        "
      >
        Report an Error
      </Button>
    </>
  );
}

Buttons.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Buttons;
