import PropTypes from "prop-types";

function Upload({ color = "fill-black", width = 30, height = 25 }) {
  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 144 130"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M117.808 93.8073L105.224 81.2236L114.562 76.6902C115.201 76.3844 115.585 75.7195 115.543 75.0101C115.507 74.3007 115.036 73.6891 114.37 73.4612L99.134 68.2196L99.1329 37.5709H102.922C103.893 37.5709 104.672 36.7914 104.672 35.8208C104.672 34.8578 103.893 34.0706 102.922 34.0706H99.1329V17.5129C99.1329 16.5421 98.3457 15.7627 97.3828 15.7627H24.1243C23.1536 15.7627 22.3742 16.5422 22.3742 17.5129V34.0706H18.3224C17.3595 34.0706 16.5723 34.8578 16.5723 35.8208C16.5723 36.7915 17.3595 37.5709 18.3224 37.5709H22.3742V78.84C22.3742 79.8029 23.1537 80.5901 24.1243 80.5901H83.133L89.302 98.5222C89.5378 99.1871 90.1515 99.6508 90.8509 99.6953C91.5604 99.7475 92.2253 99.3539 92.5311 98.7146L97.0721 89.3765L109.648 101.961C109.982 102.285 110.428 102.468 110.891 102.468C110.979 102.468 111.075 102.461 111.163 102.443C111.215 102.433 111.268 102.425 111.32 102.407C111.381 102.397 111.443 102.382 111.504 102.355C111.522 102.355 111.54 102.347 111.556 102.337C111.766 102.249 111.96 102.119 112.126 101.961L117.805 96.2816C118.129 95.958 118.321 95.5122 118.321 95.0474C118.321 94.5848 118.13 94.1378 117.806 93.8053L117.808 93.8073ZM110.896 98.2427L97.8132 85.1598C97.4096 84.7561 96.8425 84.5827 96.281 84.6705C95.7217 84.7661 95.248 85.1253 94.9945 85.6334L91.2405 93.3792L86.0601 78.327C86.0423 78.2669 86.0245 78.2135 86 78.1613L81.8079 65.9625L109.226 75.3962L101.49 79.1502C100.974 79.3959 100.615 79.8774 100.527 80.4367C100.431 80.9959 100.615 81.5652 101.009 81.9689L114.1 95.0519L110.896 98.2427Z"
      />
      <path
        className={color}
        d="M112.129 101.963C111.963 102.129 111.77 102.261 111.56 102.339C111.77 102.251 111.961 102.121 112.129 101.963Z"
      />
      <path
        className={color}
        d="M125.286 37.5736H114.107C113.14 37.5736 112.356 36.7897 112.356 35.8234C112.356 34.8572 113.14 34.0732 114.107 34.0732H125.286C126.252 34.0732 127.036 34.8572 127.036 35.8234C127.036 36.7897 126.252 37.5736 125.286 37.5736Z"
      />
      <path
        className={color}
        d="M141.716 42.8251C140.749 42.8251 139.965 42.0412 139.965 41.0749V37.5745H136.465C135.499 37.5745 134.715 36.7906 134.715 35.8244C134.715 34.8581 135.499 34.0742 136.465 34.0742H141.715C142.682 34.0742 143.466 34.8581 143.466 35.8244V41.0748C143.466 42.0433 142.682 42.8251 141.716 42.8251Z"
      />
      <path
        className={color}
        d="M141.716 112.901C140.75 112.901 139.966 112.117 139.966 111.15V99.472C139.966 98.5057 140.75 97.7218 141.716 97.7218C142.682 97.7218 143.466 98.5046 143.466 99.472V111.15C143.466 112.118 142.682 112.901 141.716 112.901ZM141.716 89.5422C140.75 89.5422 139.966 88.7583 139.966 87.7921V76.1125C139.966 75.1462 140.75 74.3623 141.716 74.3623C142.682 74.3623 143.466 75.1462 143.466 76.1125V87.7921C143.466 88.7583 142.682 89.5422 141.716 89.5422ZM141.716 66.1839C140.75 66.1839 139.966 65.3999 139.966 64.4337V52.7541C139.966 51.7878 140.75 51.0039 141.716 51.0039C142.682 51.0039 143.466 51.7889 143.466 52.7541V64.4337C143.466 65.3999 142.682 66.1839 141.716 66.1839Z"
      />
      <path
        className={color}
        d="M141.716 129.83H136.466C135.499 129.83 134.715 129.046 134.715 128.08C134.715 127.114 135.499 126.33 136.466 126.33H139.966V122.829C139.966 121.863 140.75 121.079 141.716 121.079C142.682 121.078 143.466 121.863 143.466 122.828V128.079C143.466 129.045 142.682 129.83 141.716 129.83Z"
      />
      <path
        className={color}
        d="M126.506 129.829H116.548C115.582 129.829 114.798 129.046 114.798 128.079C114.798 127.113 115.582 126.329 116.548 126.329H126.506C127.473 126.329 128.257 127.113 128.257 128.079C128.257 129.044 127.473 129.829 126.506 129.829ZM106.588 129.829H96.6301C95.6638 129.829 94.8799 129.046 94.8799 128.079C94.8799 127.113 95.6638 126.329 96.6301 126.329H106.588C107.555 126.329 108.339 127.113 108.339 128.079C108.339 129.044 107.555 129.829 106.588 129.829ZM86.6703 129.829H76.712C75.7458 129.829 74.9619 129.046 74.9619 128.079C74.9619 127.113 75.7458 126.329 76.712 126.329H86.6703C87.6366 126.329 88.4205 127.113 88.4205 128.079C88.4205 129.044 87.6366 129.829 86.6703 129.829ZM66.7523 129.829H56.794C55.8277 129.829 55.0438 129.046 55.0438 128.079C55.0438 127.113 55.8277 126.329 56.794 126.329H66.7523C67.7185 126.329 68.5024 127.113 68.5024 128.079C68.5036 129.044 67.7208 129.829 66.7523 129.829ZM46.8365 129.829H36.8782C35.9119 129.829 35.128 129.046 35.128 128.079C35.128 127.113 35.9119 126.329 36.8782 126.329H46.8365C47.8028 126.329 48.5867 127.113 48.5867 128.079C48.5867 129.044 47.8028 129.829 46.8365 129.829ZM26.9184 129.829H16.9601C15.9939 129.829 15.21 129.046 15.21 128.079C15.21 127.113 15.9939 126.329 16.9601 126.329H26.9184C27.8847 126.329 28.6686 127.113 28.6686 128.079C28.6686 129.044 27.8847 129.829 26.9184 129.829Z"
      />
      <path
        className={color}
        d="M7.0006 129.83H1.75016C0.783907 129.83 0 129.045 0 128.08V122.829C0 121.863 0.783907 121.079 1.75016 121.079C2.71642 121.079 3.50033 121.863 3.50033 122.829V126.33H7.00071C7.96697 126.33 8.75088 127.114 8.75088 128.08C8.75088 129.045 7.96685 129.83 7.0006 129.83Z"
      />
      <path
        className={color}
        d="M1.75163 112.901C0.785372 112.901 0.00146484 112.119 0.00146484 111.15V99.4704C0.00146484 98.5042 0.785372 97.7202 1.75163 97.7202C2.71788 97.7202 3.50179 98.5042 3.50179 99.4704V111.15C3.50179 112.119 2.71788 112.901 1.75163 112.901ZM1.75163 89.5429C0.785372 89.5429 0.00146484 88.759 0.00146484 87.7928V76.1131C0.00146484 75.1469 0.785372 74.363 1.75163 74.363C2.71788 74.363 3.50179 75.1469 3.50179 76.1131V87.7928C3.50179 88.759 2.71788 89.5429 1.75163 89.5429ZM1.75163 66.1837C0.785372 66.1837 0.00146484 65.4009 0.00146484 64.4335V52.755C0.00146484 51.7888 0.785372 51.0049 1.75163 51.0049C2.71788 51.0049 3.50179 51.7888 3.50179 52.755V64.4335C3.50179 65.4009 2.71788 66.1837 1.75163 66.1837Z"
      />
      <path
        className={color}
        d="M1.75163 42.826C0.785372 42.826 0.00146484 42.0443 0.00146484 41.0758V35.8254C0.00146484 34.8591 0.785372 34.0752 1.75163 34.0752H7.00206C7.96832 34.0752 8.75222 34.8591 8.75222 35.8254C8.75222 36.7916 7.96832 37.5755 7.00206 37.5755H3.50168V41.0759C3.50168 42.0444 2.71788 42.826 1.75163 42.826Z"
      />
      <path
        fill="white"
        d="M76.7288 61.5616L82.3794 77.9186H25.8735V19.6283H96.0597V66.9148L76.7288 61.5616Z"
      />
      <line
        x1="38"
        y1="32"
        x2="83.9631"
        y2="32"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="38"
        y1="49"
        x2="83.9631"
        y2="49"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <line
        x1="38"
        y1="66"
        x2="78"
        y2="66"
        fill="black"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}

Upload.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Upload;
