import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import DeleteAdvisor from "./DeleteAdvisor";

import Api from "../../../services/Api";

function DeleteAdvisorModal({
  onClose,
  advisors,
  organizationId,
  deleteAdvisors,
  setConfirmModal,
  finishCloseModal,
}) {
  const [confirm, setConfirm] = useState(false);

  const removeAdvisorOnClick = async () => {
    await Api.removeAdvisorFromOrg({
      organizationId: organizationId,
      advisorIds: advisors.map((advisor) => advisor.advisorId),
    });
    setConfirm(true);
    setConfirmModal(true);
  };

  const advisor = deleteAdvisors === 1 ? advisors[0] : null;

  const firstTextConfirmation = (
    <span>
      Great!
      {advisor ? (
        <span className="text-black">
          {` ${advisor.firstName} ${advisor.lastName} `}
        </span>
      ) : (
        ` ${deleteAdvisors} advisors `
      )}
      {advisor ? "has " : "have "}
      been deleted from your organization.
    </span>
  );

  const secondTextConfirmation = (
    <span className="text-2xl font-medium">
      This change will now be visible to all admins in your organization.
    </span>
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <DeleteAdvisor
          advisor={advisor}
          onClick={removeAdvisorOnClick}
          deleteAdvisors={deleteAdvisors}
          finishCloseModal={finishCloseModal}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={firstTextConfirmation}
          secondText={secondTextConfirmation}
          buttonText="Go Back to All Advisors"
        />
      )}
    </div>
  );
}

DeleteAdvisorModal.propTypes = {
  deleteAdvisors: PropTypes.number,
  organizationId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setConfirmModal: PropTypes.func.isRequired,
  finishCloseModal: PropTypes.func.isRequired,
  advisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default DeleteAdvisorModal;
