import PropTypes from "prop-types";

function Info({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M11.4974 23.9994C4.98835 23.9724 -0.183705 18.4127 0.00500438 11.6398C0.185264 5.16888 5.26465 -0.0455308 11.5892 0.000299811C17.9589 0.046718 23.0625 5.475 22.9994 12.0969C22.9363 18.6986 17.7491 24.0664 11.4974 23.9994ZM11.7413 10.2919C10.5756 10.3319 9.53315 10.7529 8.57073 11.4165C8.48934 11.4726 8.41132 11.542 8.34963 11.6204C8.12994 11.9001 8.20514 12.1369 8.53637 12.2271C8.66368 12.2618 8.79437 12.2855 8.91942 12.3273C9.44837 12.5032 9.59089 12.7738 9.45682 13.3458C9.13067 14.7363 8.79803 16.1253 8.47751 17.5173C8.23979 18.5502 8.74142 19.3949 9.69201 19.4716C11.0989 19.5847 12.3545 19.1005 13.4839 18.2503C13.6036 18.1604 13.6963 17.9365 13.6836 17.7855C13.676 17.6939 13.4442 17.6183 13.3085 17.5449C13.2513 17.5141 13.182 17.5082 13.1184 17.4903C12.4891 17.3149 12.3255 17.0405 12.4779 16.3856C12.7933 15.0277 13.1288 13.6749 13.4234 12.312C13.4932 11.9894 13.498 11.6187 13.4197 11.3005C13.2443 10.5919 12.6497 10.2643 11.7413 10.2919ZM14.6683 6.13279C14.67 4.8795 13.7414 3.89061 12.5548 3.88239C11.3481 3.87387 10.3792 4.8745 10.3894 6.1181C10.3995 7.35758 11.3439 8.3306 12.5412 8.33471C13.7087 8.33883 14.6663 7.3473 14.6683 6.13279Z"
      />
    </svg>
  );
}

Info.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Info;
