import Types from "./types";

const INITIAL_STATE = {
  error: null,
  isReady: false,
  isLoading: false,
  permissions: null,
};

export const permissionsReducer = (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case Types.RESET:
      return { ...INITIAL_STATE };
    case Types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Types.SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      };
    case Types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case Types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    default:
      return state;
  }
};
