import PropTypes from "prop-types";

function TwoPeople({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 100.55 88.22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M80.52,49.48c5.68-2.96,9.61-8.83,9.61-15.66,0-9.77-7.95-17.72-17.72-17.72s-17.72,7.95-17.72,17.72c0,6.83,3.93,12.71,9.61,15.66-3.21,.97-6.16,2.51-8.77,4.48-2.28-9.82-9.7-17.68-19.27-20.57,5.68-2.96,9.6-8.83,9.6-15.66C45.86,7.95,37.91,0,28.14,0S10.41,7.95,10.41,17.72c0,6.83,3.93,12.71,9.61,15.66C8.45,36.88,0,47.63,0,60.32v27.9H100.55v-11.81c0-12.69-8.45-23.44-20.02-26.93Zm-19.67-15.66c0-6.37,5.18-11.56,11.55-11.56s11.56,5.18,11.56,11.56-5.18,11.55-11.56,11.55-11.55-5.18-11.55-11.55ZM16.58,17.72c0-6.37,5.18-11.55,11.56-11.55s11.55,5.18,11.55,11.55-5.18,11.56-11.55,11.56-11.56-5.18-11.56-11.56Zm27.69,64.33H6.17v-21.73c0-12.12,9.86-21.97,21.97-21.97s21.41,9.3,21.94,20.94c-.1,.13-.17,.29-.27,.42-.8,1.08-1.55,2.21-2.19,3.4-.04,.07-.06,.14-.1,.2-.63,1.19-1.16,2.44-1.62,3.73-.06,.17-.13,.33-.18,.5-.43,1.28-.76,2.61-1,3.96-.04,.24-.07,.48-.11,.72-.2,1.37-.34,2.75-.34,4.18v5.64Zm50.11,0H50.44v-5.64c0-1.19,.12-2.37,.31-3.53,.02-.15,.04-.3,.07-.45,.21-1.12,.51-2.22,.88-3.29,.05-.15,.11-.3,.16-.45,.4-1.06,.87-2.09,1.43-3.08,.08-.13,.16-.26,.24-.39,.59-.99,1.24-1.95,1.98-2.84,4.03-4.85,10.11-7.94,16.89-7.94,12.12,0,21.97,9.86,21.97,21.97v5.64Z"
      />
    </svg>
  );
}

TwoPeople.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default TwoPeople;
