import { useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import AddAdvisorList from "./AddAdvisorList";

import Api from "../../../services/Api";

function AddAdvisorListModal({
  filters,
  onClose,
  advisors,
  organizationId,
  requestedClose,
  setConfirmModal,
  setOnRequestClose,
}) {
  const [save, setSave] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState("");
  const [saveChangesError, setSaveChangesError] = useState(null);

  const addAdvisorListOnSubmit = async (data) => {
    const dataMutation = {
      organizationId,
      advisorListIds: data.selectedLists.map((list) => list.id),
      addAdvisors: {
        filter: advisors?.length > 0 ? null : filters,
        advisorIds: advisors?.length > 0 ? advisors : null,
      },
    };

    await Api.addOrRemoveAdvisorsToList(dataMutation);

    const isSharedList = data.selectedLists.some((list) => list.shared);

    setTextConfirmation(
      <span className="text-3xl text-black font-semibold">
        Great! You have added one or more advisor(s) to the
        {data.selectedLists.length === 1 ? " list" : " following lists:"}
        {data.selectedLists.map((list, index) => (
          <span key={`${index}-list-${list.id}`}>
            <span className="text-primary-blue"> "{list.label}"</span>
            {index !== data.selectedLists.length - 1 && ","}
          </span>
        ))}
        .
        {isSharedList && (
          <span>
            {data.selectedLists.length > 1 && <span> One list is shared.</span>}
            <span> All admins in your organization will see this change</span>
          </span>
        )}
      </span>
    );
    setConfirm(true);
    setConfirmModal(true);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    if (saveData.selectedLists.length === 0)
      setSaveChangesError("selectedLists");
    else await addAdvisorListOnSubmit(saveData);

    setIsLoading(false);
    setSave(false);
    setOnRequestClose(false);
  };

  const backCloseHandle = () => {
    setSave(false);
    setOnRequestClose(false);
  };

  const requestCloseHandle = (data) => {
    setSaveChangesError(null);
    setSaveData(data);
    setSave(true);
  };

  const defaultValues = {
    selectedLists: saveData.selectedLists || [],
  };

  return (
    <div className="flex flex-col h-full">
      {!confirm && !save && (
        <AddAdvisorList
          defaultValues={defaultValues}
          requestedClose={requestedClose}
          organizationId={organizationId}
          onSubmit={addAdvisorListOnSubmit}
          saveChangesError={saveChangesError}
          requestCloseHandle={requestCloseHandle}
        />
      )}
      {confirm && !save && (
        <Confirm
          onClick={onClose}
          firstText={textConfirmation}
          buttonText="Back to All Advisors"
        />
      )}
      {save && (
        <Confirm
          secondButton={true}
          isLoading={isLoading}
          onClick={saveChanges}
          buttonText="Save Changes"
          secondOnClick={backCloseHandle}
          firstText="Do you want to save your changes?"
        />
      )}
    </div>
  );
}

AddAdvisorListModal.propTypes = {
  onClose: PropTypes.func,
  filters: PropTypes.shape(),
  requestedClose: PropTypes.bool,
  setConfirmModal: PropTypes.func,
  organizationId: PropTypes.string,
  setOnRequestClose: PropTypes.func,
  advisors: PropTypes.arrayOf(PropTypes.string),
};

export default AddAdvisorListModal;
