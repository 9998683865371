import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Common/Confirm";
import AssignAdvisor from "./AssignAdvisor";

import Api from "../../../services/Api";
import { getAssigAdvisorList } from "./Utils";

function AssignAdvisorModal({
  filter,
  onClose,
  students,
  confirmAssign,
  organizationId,
  assingAdvisors,
  assignStudents,
  setConfirmModal,
}) {
  const [confirm, setConfirm] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState("");

  const getTextConfirmation = useCallback(
    ({ studentName, advisorName }) => {
      if (advisorName === "Unassigned") {
        return (
          <span className="text-black font-semibold">
            {studentName ? (
              <span>
                <span className="text-primary-blue">{studentName}</span> is{" "}
              </span>
            ) : (
              `${assignStudents} students are `
            )}
            now unassigned from all advisors.
          </span>
        );
      } else {
        return (
          <span className="text-black font-medium">
            <span className="text-primary-blue">{advisorName} </span>
            {studentName ? (
              <span>
                is now assigned to
                <span className="text-primary-blue"> {studentName}</span>.
              </span>
            ) : (
              <span>is currently assigned to {assignStudents} students.</span>
            )}
          </span>
        );
      }
    },
    [assignStudents]
  );

  useEffect(() => {
    if (confirmAssign) {
      const advisor = confirmAssign.advisor;
      const student = confirmAssign.student;

      setTextConfirmation(
        getTextConfirmation({
          studentName: student.name,
          advisorName: advisor.name,
        })
      );
      setConfirm(true);
      setConfirmModal(true);
    }
  }, [confirmAssign, assignStudents, setConfirmModal, getTextConfirmation]);

  const onAssingAdvisor = useCallback(
    async (advisor) => {
      try {
        const { ok } = await Api.assignAdvisorToStudents({
          organizationId,
          advisorId: advisor.id,
          students: {
            filter: students?.length > 0 ? null : filter,
            studentIds:
              students?.length > 0
                ? students.map((student) => student.studentId)
                : null,
          },
        });
        if (ok) {
          let studentName = null;
          if (assignStudents === 1)
            studentName = `${students[0]?.firstName} ${students[0]?.lastName}`;
          setTextConfirmation(
            getTextConfirmation({ studentName, advisorName: advisor.name })
          );

          setConfirm(true);
          setConfirmModal(true);
        }
      } catch (error) {
        throw error;
      }
    },
    [
      filter,
      students,
      assignStudents,
      organizationId,
      setConfirmModal,
      getTextConfirmation,
    ]
  );

  return (
    <div className="flex flex-col h-full">
      {!confirm ? (
        <AssignAdvisor
          students={students}
          onAssingAdvisor={onAssingAdvisor}
          assingAdvisors={getAssigAdvisorList(assingAdvisors)}
        />
      ) : (
        <Confirm
          onClick={onClose}
          firstText={textConfirmation}
          buttonText={
            confirmAssign ? "Back to Student View" : "Back to All Students"
          }
        />
      )}
    </div>
  );
}

AssignAdvisorModal.propTypes = {
  onClose: PropTypes.func,
  filter: PropTypes.shape(),
  setConfirmModal: PropTypes.func,
  confirmAssign: PropTypes.shape(),
  organizationId: PropTypes.string,
  assignStudents: PropTypes.number,
  students: PropTypes.arrayOf(PropTypes.shape()),
  assingAdvisors: PropTypes.arrayOf(PropTypes.shape()),
};

export default AssignAdvisorModal;
