import { useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

function SmallModal({ open, idRef = "modal-root", children }) {
  const el = document.createElement("div");
  const modalRoot = document.getElementById(idRef);

  useEffect(() => {
    modalRoot.appendChild(el);

    return function cleanUp() {
      modalRoot.innerHTML = "";
    };
  });

  if (open)
    return createPortal(
      <div
        className="fixed w-full h-full flex flex-1 items-center justify-center"
        style={{
          zIndex: 1000,
          backgroundColor: "rgba(0,0,0,0.3)"
        }}
      >
        <div className="p-12 flex flex-col shadow-xl bg-white min-h-15rem">
          <div className="max-w-30rem">{children}</div>
        </div>
      </div>,
      el
    );

  return null;
}

SmallModal.propTypes = {
  open: PropTypes.bool,
  idRef: PropTypes.string,
  children: PropTypes.any
};

export default SmallModal;
