import PropTypes from "prop-types";

import { interactionEventDataLayer } from "../../../../utils/DataLayers";

function ShowMoreButton({ toggle, onClick = () => {} }) {
  const handlerOnClick = () => {
    onClick();
    if (!toggle) {
      interactionEventDataLayer({ eventName: "show_more_lists" });
    }
  };

  return (
    <div
      role="button"
      onClick={handlerOnClick}
      className="pl-4 font-bold text-dark-gray"
    >
      Show {toggle ? "Less" : "More"} Lists
    </div>
  );
}

ShowMoreButton.propTypes = {
  toggle: PropTypes.bool,
  onClick: PropTypes.func
};

export default ShowMoreButton;
