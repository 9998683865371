import PropTypes from "prop-types";

import { Panel } from "../../compose";
import { Button } from "../../buttons";
import ChartMobile from "../../charts/AffordableChart/Mobile";
import {
  Budget,
  CoveredOutOf,
  TotalEstimatedCost,
  AffordabilityThumbs
} from "../../compose/affordability";

import {
  Colors,
  AwardStatus,
  SchoolStatus,
  getTheme,
  getColors,
  getGrantsAndScholarships
} from "../../utils";

function DescriptionContainer({ children }) {
  return (
    <div className="py-2 px-4 my-4 text-sm bg-alternative-yellow">
      {children}
    </div>
  );
}

function AffordabilityCard({
  id,
  chosenSchool,
  executeScroll,
  addYourMoneyComponent,
  reuploadAwardComponent,
  showAddYourMoneyComponent = true,
  affordableButtonsComponent
}) {
  if (!chosenSchool) return null;

  const { award, schoolCosts } = chosenSchool || {};
  const theme = getTheme({
    affordability: award?.postAwardSchoolCosts?.affordability
  });
  const colors = getColors(theme.color);

  const ready = award?.status === AwardStatus.READY;
  const inReview =
    award?.status === AwardStatus.PENDING ||
    award?.status === AwardStatus.MANUAL_REVIEW;

  const invalid = !ready && !inReview && typeof award?.status !== "undefined";

  const grantsReviewed =
    ready &&
    getGrantsAndScholarships(award?.aidAmounts).reduce(
      (accumulator, aidAmount) => accumulator + aidAmount.value,
      0
    ) === 0;

  const getSchoolStatus = () => {
    const optionsArray = [
      { name: SchoolStatus.READY, value: ready && !grantsReviewed },
      { name: SchoolStatus.INVALID, value: invalid },
      { name: SchoolStatus.IN_REVIEW, value: inReview },
      { name: SchoolStatus.GRANTS_REVIEWED, value: grantsReviewed }
    ];
    const schoolStatus = optionsArray.find((e) => e.value === true);
    return schoolStatus?.name;
  };

  const hasAward = !!chosenSchool.award;

  const schoolStatus = hasAward ? getSchoolStatus() : SchoolStatus.ESTIMATED;

  let collegeCosts;
  if (
    chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool?.schoolStatus === SchoolStatus.INVALID
  ) {
    collegeCosts =
      chosenSchool.estimatedPostAwardCosts.schoolCosts ||
      chosenSchool.schoolCosts.totalCollegeCosts;
  } else {
    collegeCosts = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.schoolCosts
      : chosenSchool.schoolCosts.totalCollegeCosts;
  }

  let lifeBudget;
  if (
    chosenSchool?.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool?.schoolStatus === SchoolStatus.INVALID
  ) {
    lifeBudget =
      chosenSchool.estimatedPostAwardCosts.lifeBudget ||
      chosenSchool.schoolCosts.lifeBudget;
  } else {
    lifeBudget = award?.postAwardSchoolCosts
      ? award.postAwardSchoolCosts.lifeBudget
      : chosenSchool.schoolCosts.lifeBudget;
  }

  const coverage = hasAward
    ? chosenSchool?.award?.postAwardSchoolCosts?.coverage
    : chosenSchool?.estimatedPostAwardCosts?.coverage || 0;

  const affordability =
    schoolStatus !== SchoolStatus.GRANTS_REVIEWED && hasAward
      ? chosenSchool?.award?.postAwardSchoolCosts?.affordability || null
      : schoolStatus !== SchoolStatus.GRANTS_REVIEWED && !hasAward
      ? chosenSchool?.estimatedPostAwardCosts
        ? SchoolStatus.ESTIMATED
        : null
      : null;

  const totalAward =
    chosenSchool.schoolStatus === SchoolStatus.ESTIMATED ||
    chosenSchool.schoolStatus === SchoolStatus.INVALID
      ? chosenSchool?.estimatedPostAwardCosts?.totalAward
      : chosenSchool?.award?.postAwardSchoolCosts?.totalAward || 0;

  const totalCost = collegeCosts + lifeBudget;

  const getChartConfig = () => {
    switch (schoolStatus) {
      case SchoolStatus.READY:
        return (
          <>
            <div className="mb-2 flex flex-col items-center">
              <CoveredOutOf
                tooltip={false}
                color={theme.color}
                totalSchoolCosts={totalCost}
                totalAward={totalAward}
              />
            </div>
            {affordableButtonsComponent}
          </>
        );

      case SchoolStatus.INVALID:
        return (
          <>
            {totalAward > 0 && (
              <p
                className={
                  "text-center font-bold text-primary-blue text-md mb-2"
                }
              >
                Total estimated financial aid: ${totalAward.toLocaleString()}
              </p>
            )}
            <DescriptionContainer>
              {award?.status === AwardStatus.TIMEOUT ? (
                <p className="text-center text-sm">
                  There was an issue while uploading your award image. Please
                  try again.
                </p>
              ) : (
                <p className="text-center text-sm">
                  The image you uploaded did not have any financial information
                  on it.
                </p>
              )}
            </DescriptionContainer>
            {showAddYourMoneyComponent ? (
              addYourMoneyComponent
            ) : (
              <div className="my-2"></div>
            )}
            <TotalEstimatedCost
              totalCost={totalCost}
              className="mb-2 px-3 text-lg"
            />
          </>
        );

      case SchoolStatus.IN_REVIEW:
        return (
          <>
            <DescriptionContainer>
              <p className="text-center text-sm">
                One of our team members will check out your award as soon as
                possible. We'll email you when your results are ready.
              </p>
            </DescriptionContainer>
            {reuploadAwardComponent}
            <TotalEstimatedCost
              totalCost={totalCost}
              className="mb-2 px-3 text-lg"
            />
          </>
        );

      case SchoolStatus.GRANTS_REVIEWED:
        return (
          <>
            <p className="font-bold">
              We didn't find any grants or scholarships in this letter
            </p>
            <div className="mb-2 flex flex-col">
              <CoveredOutOf
                tooltip={false}
                color={theme.color}
                totalSchoolCosts={totalCost}
                totalAward={totalAward}
              />
            </div>
            <DescriptionContainer>
              <p className="text-sm text-center">
                One of our team members reviewed your award letter, and we don't
                see any funds awarded that are guaranteed free money. You can
                re-upload your award letter if anything changed in your
                financial aid information.
              </p>
            </DescriptionContainer>
            {affordableButtonsComponent}
          </>
        );
      case SchoolStatus.ESTIMATED:
        return (
          <>
            <TotalEstimatedCost
              totalCost={totalCost}
              className="mt-5 text-md"
            />
            {chosenSchool?.estimatedPostAwardCosts?.totalAward > 0 && (
              <p className="text-center text-md font-bold text-primary-blue mb-2">
                Total estimated financial aid: $
                {chosenSchool?.estimatedPostAwardCosts?.totalAward.toLocaleString()}
              </p>
            )}
            <DescriptionContainer>
              <p className="text-center text-sm">
                Once you have your award letter, upload it to DecidED to see how
                much of the estimated costs will be covered by free money.
              </p>
            </DescriptionContainer>
          </>
        );
      default:
        return (
          <>
            <DescriptionContainer>
              <p className="text-sm text-center">
                Once you have your award letter, upload it to DecidED to see how
                much of the estimated costs will be covered by free money.
              </p>
            </DescriptionContainer>
            {addYourMoneyComponent}
            <TotalEstimatedCost
              totalCost={totalCost}
              className="mb-2 px-3 text-lg"
            />
          </>
        );
    }
  };

  const chartConfig = getChartConfig();

  return (
    <Panel
      id={id}
      title="affordability"
      className="p-4 mb-2 bg-white shadow-lg"
      titleClassName="font-semibold capitalize"
    >
      {(schoolStatus === SchoolStatus.READY ||
        schoolStatus === SchoolStatus.ESTIMATED) && (
        <>
          {schoolStatus === SchoolStatus.READY && (
            <div className="-mt-5 mobile-sm:-mt-4">
              <AffordabilityThumbs
                theme={theme}
                className="flex flex-1 flex-row text-xl mobile-sm:text-lg"
              />
            </div>
          )}
          <div className="-my-10 px-10 mobile-sm:-my-6">
            <ChartMobile percent={coverage} affordability={affordability} />
          </div>
        </>
      )}
      {chartConfig}
      <Budget
        color={theme.color}
        classNameCost="font-bold"
        classNameLink="text-xs font-bold"
        lifeBudget={
          award?.postAwardSchoolCosts
            ? award?.postAwardSchoolCosts?.lifeBudget
            : schoolCosts?.lifeBudget
        }
        collegeCosts={
          award?.postAwardSchoolCosts
            ? award?.postAwardSchoolCosts?.schoolCosts
            : schoolCosts?.totalCollegeCosts
        }
      />
      <Button
        onClick={executeScroll}
        className={`
          my-2
          w-full
          text-black  
          border-black
          font-extrabold
          ${colors.secondary.bg}
        `}
      >
        first year breakdown
      </Button>
    </Panel>
  );
}

AffordabilityCard.propTypes = {
  id: PropTypes.string,
  executeScroll: PropTypes.func,
  chosenSchool: PropTypes.shape(),
  addYourMoneyComponent: PropTypes.any,
  reuploadAwardComponent: PropTypes.any,
  showAddYourMoneyComponent: PropTypes.bool,
  affordableButtonsComponent: PropTypes.any,
  color: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default AffordabilityCard;
