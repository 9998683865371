import PropTypes from "prop-types";

import Category from "./Category";

function SideBar({ sections }) {
  return (
    <div
      className="
        pt-6
        flex
        w-full
        flex-1
        flex-col
        overflow-y-auto
      "
    >
      {sections.map((section, index) => (
        <div key={section.id}>
          <Category
            index={index}
            key={section.id}
            icon={section.icon}
            list={section.list}
            label={section.label}
            pathname={section.id}
          />
        </div>
      ))}
    </div>
  );
}

SideBar.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SideBar;
