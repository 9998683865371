import PropTypes from "prop-types";

function Student({ size = 25, color = "fill-black" }) {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 70.53 101.74"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={color}
        d="M62.36,36.26l.49-17.82,7.68-2.86L35.23,0,0,13.43l13.78,6.39-1.09,18.01,1.8,.11c-.15,.97-.23,1.95-.23,2.94,0,7.88,4.7,14.68,11.43,17.77-13.32,3.59-23.16,15.74-23.16,30.18v12.91h62.57v-12.91c0-14.44-9.84-26.58-23.16-30.18,6.73-3.09,11.43-9.89,11.43-17.77,0-.18-.04-.35-.04-.53l1.35,.08,.64-19.19,1.29-.48-.42,15.33,6.17,.17ZM35.06,6.67l19.1,8.43-19.9,7.41L15.87,13.98l19.19-7.32Zm23.88,82.16v6.74H8.7v-6.74c0-13.85,11.27-25.12,25.12-25.12s25.12,11.27,25.12,25.12Zm-11.72-47.95c0,7.39-6.01,13.4-13.39,13.4s-13.4-6.01-13.4-13.4c0-.87,.11-1.72,.27-2.56l26.45,1.64c.02,.31,.07,.61,.07,.92Zm1.52-6.98l-29.51-1.83,.57-9.47,14.2,6.58,15.08-5.61-.35,10.34Z"
      />
    </svg>
  );
}

Student.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Student;
