import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProptTypes from "prop-types";

import { Button } from "../../../../../core/buttons";
import { Close, ShortArrow } from "../../../../../core/icons";
import ReportErrorForm from "./ReportErrorForm";

import Api from "../../../../../services/Api";
import { useOrganizations } from "../../../../../redux/Organizations/hooks";

function ReportAnError({ onBack, onNext }) {
  const navigate = useNavigate();
  const { studentId, collegeId } = useParams();
  const { selectedOrganizationId } = useOrganizations();

  const [awardIdSelected, setAwardIdSelected] = useState(null);

  useEffect(() => {
    const getAwardLetter = async () => {
      const { student } = await Api.fetchAdvisorStudentDetail({
        organizationId: selectedOrganizationId,
        filter: {
          studentId: studentId,
        },
      });
      const chosenSchool = student.chosenSchools.find((school) => {
        return school.school.ipedsId === collegeId;
      });
      const awardId = chosenSchool.award.id;
      if (awardId) {
        setAwardIdSelected(awardId);
      } else {
        setTimeout(getAwardLetter, 1000);
      }
    };

    getAwardLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      const reportInput = {
        awardId: awardIdSelected,
        errorType: data.error.value,
        description: data.description,
      };
      await Api.createAwardLetterErrorReport({
        reportInput,
        organizationId: selectedOrganizationId,
      });
      navigate(
        `/dashboard/students/${studentId}/${collegeId}/upload-award/report-error/success`
      );
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="flex flex-col m-8">
      <div className="flex flex-row justify-between items-center">
        <div role="button" onClick={onBack}>
          <ShortArrow size={30} />
        </div>
        <span className={`ml-4 flex-1 text-3xl font-black`}>
          Report an Error
        </span>
        <div
          role="button"
          onClick={() => navigate(`/dashboard/students/${studentId}`)}
        >
          <Close />
        </div>
      </div>
      <div className="p-12 flex flex-row space-x-8">
        <div className="w-2/3">
          <div
            className="
              p-16
              border
              border-black
            "
          >
            <ReportErrorForm onSubmit={onSubmit} />
          </div>
        </div>
        <div className="pt-4 w-1/3">
          <div className="mt-16 flex justify-center">
            <p className="text-primary-blue font-extrabold">
              Want to try again?
            </p>
          </div>
          <Button
            onClick={onNext}
            id="error-reupload-letter-button"
            className="
              my-4
              w-full
              text-white
              bg-primary-blue
              border-primary-blue
              hover:bg-white
              active:bg-white
              hover:text-primary-blue
              active:text-primary-blue
            "
          >
            re-upload my letter
          </Button>
        </div>
      </div>
    </div>
  );
}

ReportAnError.propTypes = {
  onBack: ProptTypes.func.isRequired,
  onNext: ProptTypes.func.isRequired,
};

export default ReportAnError;
