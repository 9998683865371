import PropTypes from "prop-types";

function ContainerBox({ children, className, showControls }) {
  return (
    <div className={`z-30 bg-white shadow-black ${className}`}>
      <div
        className={`
          p-4
          flex
          mb-1
          ml-1
          -mt-1
          -mr-1
          flex-col
          bg-black
          justify-end
          bg-primary-blue
          ${className}
          ${showControls ? "min-h-6rem" : "min-h-3rem"}
        `}
      >
        {children}
      </div>
    </div>
  );
}

ContainerBox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  showControls: PropTypes.bool,
};

export default ContainerBox;
