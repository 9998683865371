import PropTypes from "prop-types";

import { CampusEnvironmentCard as CommonCampusEnvironmentCard } from "../../../../../core/compose/cards";

function CampusEnvironmentCard({
  open,
  chosenSchool,
  externalToggle,
  callbackToggle,
}) {
  return (
    <CommonCampusEnvironmentCard
      open={open}
      chosenSchool={chosenSchool}
      externalToggle={externalToggle}
      callbackToggle={callbackToggle}
    />
  );
}

CampusEnvironmentCard.propTypes = {
  open: PropTypes.bool,
  externalToggle: PropTypes.bool,
  callbackToggle: PropTypes.func,
  chosenSchool: PropTypes.shape(),
};

export default CampusEnvironmentCard;
