import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Tooltip } from "../";
import { Question } from "../icons";
import { Colors } from "../utils";

function ButtonTooltip({
  text,
  gaOnClickFunc,
  tooltipOptions,
  tooltipDirection,
  tooltipCloseButton,
  tooltipCustomToggle,
  tooltipCloseOnClickOutside,
  tooltipColor = Colors.DEFAULT,
  tooltipClassName = "-left-7 bottom-20",
  questionSize = 25
}) {
  const containerRef = useRef();

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(tooltipOptions?.open ?? false);
  }, [tooltipOptions]);

  const handlerGAonClick = () => {
    if (!gaOnClickFunc) return;
    if (showTooltip === false) {
      gaOnClickFunc({ text: text });
    }
  };

  const handlerOnClose = () => {
    setShowTooltip(false);
    if (tooltipOptions && tooltipOptions.onClose) {
      tooltipOptions.onClose();
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={(text) => handlerGAonClick(text)}
      className="relative"
    >
      <Tooltip
        text={tooltipOptions ? tooltipOptions.text : text}
        open={showTooltip}
        tooltipColor={tooltipColor}
        direction={tooltipDirection}
        tooltipContainerRef={containerRef}
        tooltipClassName={tooltipClassName}
        tooltipCloseButton={tooltipCloseButton}
        tooltipHandleOnClose={handlerOnClose}
        tooltipCloseOnClickOutside={tooltipCloseOnClickOutside}
      />
      <Question
        size={questionSize}
        className="mx-2 cursor-pointer"
        onClick={
          !tooltipCustomToggle
            ? () => setShowTooltip(!showTooltip)
            : () => tooltipCustomToggle()
        }
      />
    </div>
  );
}

ButtonTooltip.propTypes = {
  text: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipCloseButton: PropTypes.bool,
  tooltipCustomToggle: PropTypes.func,
  tooltipCloseOnClickOutside: PropTypes.bool,
  tooltipColor: PropTypes.oneOf([
    Colors.RED,
    Colors.BLUE,
    Colors.GREEN,
    Colors.ORANGE,
    Colors.DEFAULT
  ])
};

export default ButtonTooltip;
