import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";

import Table from "../../../Common/Table";

const noStudentsComponent = () => (
  <div
    className="
      flex
      flex-col
      justify-center
      items-center
      h-1/3
      bg-gray-100
      mx-12
      my-16
      p-4
      space-y-4"
  >
    <p className="font-bold text-2xl text-primary-blue">
      Your list currently has no students.
    </p>
    <p className="text-center texts-md font-bold">
      Select one or more students from the "All Students" view and select "Add
      to list" in the action menu to add them to your list.
    </p>
  </div>
);

function StudentTableBody({
  data,
  columns,
  pageSize,
  itemsCount,
  currentPage,
  onPageChange,
  studentListId,
  handlerOnSelectStudents,
}) {
  return (
    <>
      <Table
        data={data}
        columns={columns}
        pageSize={pageSize}
        itemsCount={itemsCount}
        currentPage={currentPage}
        addSelectionColumn={true}
        onPageChange={onPageChange}
        tableSelector="viewport-student-table"
        handlerOnSelectRows={handlerOnSelectStudents}
        propsBody={{ className: "min-h-15rem h-screen-footer-xl" }}
        noElementsComponent={studentListId ? noStudentsComponent : null}
      />
      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="affordability-square"
      />
      <Tooltip place="bottom" effect="float" delayShow={500} id="student-row" />
      <Tooltip
        place="bottom"
        effect="solid"
        delayShow={500}
        id="enrollment-icon-label"
        getContent={(statusName) => {
          return (
            <div className="text-center">
              <p className="text-xsm font-bold normal-case">{statusName}</p>
            </div>
          );
        }}
      />
    </>
  );
}

StudentTableBody.propTypes = {
  pageSize: PropTypes.number,
  itemsCount: PropTypes.number,
  currentPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  handlerOnSelectStudents: PropTypes.func.isRequired,
};

export default StudentTableBody;
